import React, { useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import api from "../../api";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import Swal from "../Swal";
import _ from "lodash";
import routes from "../../constants/routesConstants";
import AuthContext from "../../context/authContext";
import { useContext } from "react";

const validationSchema = yup.object().shape({
  password: yup
    .string()
    .min(6, "Password must be 6 characters long")
    .max(42)
    .required("Password is required."),
  confirmation_password: yup
    .string()
    .min(6, "Password must be 6 characters long")
    .max(42)
    .required("Confirm Password is required.")
    .oneOf([yup.ref("password"), null], "Passwords must match."),
});

const BusinessCreatePassword = () => {
  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();
  const { state } = useLocation();
  const { id, name } = useParams();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const showPasswordHandler = () => {
    setShowPassword(!showPassword);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (formValue) => {
    formValue.user_id = id;
    setLoading(true);
    await api
      .post("api/dealers/create-dealer-password", formValue)
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          authCtx.login(response.data.data);
          navigate(state?.path || routes.Dashboard);
        } else {
          Swal.fire(response.data.message, "", "info");
        }
        navigate(routes.Login);
      })
      .catch((error) => {
        setLoading(false);
        Swal.fire(
          "Opps!",
          "Sorry we are not able to complete your request at this time. Please try again later.",
          "error"
        );
      });
  };

  return (
    <section className="reset-password-section user-reset-password-section">
      <div className="login-upper-section">
        <div className="container-fluid">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-5">
              <div className="login-inner">
                <h2 className="login-title">
                  Create an account in Vehicle Appraisal System
                </h2>
                <p className="login-text">
                  Please make sure to fill in all the required fields to
                  complete your registration.
                </p>
                <div className="login-form">
                  <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                    <div className="login-form-inner">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label className="form-label">Name</label>
                            <div className="position-relative">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Full Name"
                                {...register("name")}
                                defaultValue={name !== undefined ? name : ""}
                              />
                              {errors.name?.message != undefined && (
                                <span className="form-error">
                                  {errors.name?.message}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label className="form-label">
                              Create your own Password <span>*</span>
                            </label>
                            <div className="form-group-with-password">
                              <input
                                type={`${showPassword ? "text" : "password"}`}
                                className="form-control password"
                                placeholder="Use strong password"
                                {...register("password")}
                                maxLength={42}
                              />
                              <i
                                className="icon icon-eye"
                                onClick={showPasswordHandler}></i>
                              {errors.password?.message != undefined && (
                                <span className="form-error">
                                  {errors.password?.message}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <div className="form-group-with-password">
                              <input
                                type="password"
                                className="form-control password"
                                placeholder="Confirm Password"
                                {...register("confirmation_password")}
                                maxLength={42}
                              />
                              {errors.confirmation_password?.message !=
                                undefined && (
                                <span className="form-error">
                                  {errors.confirmation_password?.message}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <button
                            type="submit"
                            className="btn btn-pink border-width-0"
                            disabled={loading}>
                            Create account
                          </button>
                        </div>
                        <div className="col-lg-12">
                          <p className="login-form-login-link">
                            You have an account already{" "}
                            <Link to={routes.Login}>Sign in now</Link>
                          </p>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="login-down-section">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="login-copyright">
                <span className="me-0">
                  ©{new Date().getFullYear()} Vehicle Appraisal System | All
                  rights Reserved
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BusinessCreatePassword;
