import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../api";
import routes from "../../constants/routesConstants";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import AuthContext from "../../context/authContext";
import { useForm } from "react-hook-form";
import Swal from "../Swal";
import _ from "lodash";

const validationSchema = yup.object().shape({
  cate_id: yup.string().required("Category is required."),
  question: yup.string().max(120).required("Question is required."),
  answer: yup.string().max(260).required("Answer is required."),
});

const FaqsCreate = () => {
  const [loading, setLoading] = useState(false);
  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (authCtx.userData?.user_type != 3) {
      navigate(routes.Dashboard);
    }
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const watchAnswerLength = watch("answer");

  const onSubmit = async (formValue) => {
    setLoading(true);
    await api
      .post("api/faq/add-faq", formValue, {
        headers: {
          Authorization: `Bearer ${authCtx.userData?.token}`,
        },
      })
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          Swal.fire(response.data.message, "", "success");
          reset();
        } else {
          Swal.fire("Try Again!", response.data.message, "info");
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          authCtx.logout();
        } else {
          Swal.fire(
            "Opps!",
            "Sorry we are not able to complete your request at this time. Please try again later.",
            "error"
          );
        }
      });
  };

  return (
    <>
      <div className="appraisal-head-wrap mb-34">
        <div className="row">
          <div className="col">
            <div className="appraisal-head-title align-items-start">
              <button
                type="button"
                className="btn-icon"
                onClick={() => navigate(-1)}
              >
                <i className="icon icon-back-aero"></i>
              </button>
              <div className="page-head">
                <h2 className="page-title">Add New FAQ</h2>
                <p className="page-dec">Fill the required informations</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="login-inner add-new-user-inner">
        <div className="login-head">
          <h2 className="login-title">Create FAQs</h2>
          <p className="login-text">
            Please make sure to fill in all the required fields.
          </p>
        </div>
        <div className="login-form">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="login-form-inner">
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-group">
                    <label className="form-label">
                      Category <span>*</span>
                    </label>
                    <select
                      className="form-control form-select"
                      {...register("cate_id")}
                    >
                      <option value="getting_started">Getting Started</option>
                      <option value="how_it_works">How It Works</option>
                      <option value="manage_account">Manage Account</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="form-label">
                      Question <span>*</span>
                    </label>
                    <div className="position-relative">
                      <input
                        className="form-control"
                        {...register("question")}
                        maxLength={120}
                      />
                      {errors.question?.message != undefined && (
                        <span className="form-error">
                          {errors.question?.message}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group">
                    <label className="form-label">
                      Answer <span>*</span>
                    </label>
                    <div className="position-relative">
                      <textarea
                        cols="1"
                        rows="5"
                        className="form-control"
                        {...register("answer")}
                        maxLength={260}
                      ></textarea>
                      <small className="form-limit-text">{watchAnswerLength?.length}/260</small>
                      {errors.answer?.message != undefined && (
                        <span className="form-error">
                          {errors.answer?.message}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group">
                    <label className="form-label">Embed Code</label>
                    <div className="position-relative">
                      <textarea
                        cols="1"
                        rows="5"
                        className="form-control"
                        {...register("embed_code")}
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <button
                    type="submit"
                    className="btn btn-pink"
                    disabled={loading}
                  >
                    <i className="icon icon-appraisals"></i>
                    Add FAQ
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default FaqsCreate;
