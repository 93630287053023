import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router";
import routes from "../../constants/routesConstants";
import logoImg from "../../assets/img/logo.svg";
import logoShape from "../../assets/img/logo-shape.svg";
import userImg from "../../assets/img/user.jpg";
import AuthContext from "../../context/authContext";
import ThemeContext from "../../context/themeContext";
import { Link } from "react-router-dom";
import useDebounce from "../../hook/useDebounce";
import api from "../../api";
import vehicleImg from "../../assets/img/vehicle.jpg";
import Swal from "../Swal";
import moment from "moment";
import _ from "lodash";
import BusinessContext from "../../context/businessContext";
import usePermission from "../../hook/usePermission";
import {
  APPRAISALS_ADD,
  BUSINESS_LIST,
} from "../../constants/permissionConstants";
import SendAppraisalForm from "../Appraisals/sendAppraisalForm";

const Navbar = () => {
  const [menuVisible, setMenuVisible] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const authCtx = useContext(AuthContext);
  const themeCtx = useContext(ThemeContext);
  const businessCtx = useContext(BusinessContext);
  const [search, setSearch] = useState("");
  const searchDebouncedValue = useDebounce(search, 500);
  const [responseData, setResponseData] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [showDropdown, setShowDropdown] = useState(true);
  const [loading, setLoading] = useState(false);
  const searchDropdownWrap = useRef(null);
  const notificationDropdownWrap = useRef(null);
  const menuDropdownWrap = useRef(null);
  const isAppraisalAddAllow = usePermission(APPRAISALS_ADD);
  const isBusinessAllow = usePermission(BUSINESS_LIST);

  useEffect(() => {
    themeCtx.contactPopupClose();
    themeCtx.getHelpPopupClose();
    themeCtx.hideDropShadowHandler();
    if (themeCtx.isSidebarOpen) {
      themeCtx.toggleSidebar();
    }
  }, [location]);

  useEffect(() => {
    api
      .get(`api/role/get-role-permission`, {
        headers: {
          Authorization: `Bearer ${authCtx.userData?.token}`,
        },
      })
      .then((response) => {
        if (response.data.status) {
          authCtx.login({
            ...authCtx.userData,
            permissions: response.data.data?.permissions,
            allow_everything: response.data.data?.allow_everything,
          });
        } else {
          if (
            response.data.data?.subscription_alert === 0 &&
            authCtx.userData?.user_type === 0
          ) {
            authCtx.login({});
            navigate(routes.Login);
          }
        }
      })
      .catch((error) => { });

    api
      .get(`api/users/status`, {
        headers: {
          Authorization: `Bearer ${authCtx.userData?.token}`,
        },
      })
      .then((response) => {
        if (response.data.status) {
          if (response.data.data.status == 0) {
            authCtx.logout();
          }
        }
      })
      .catch((error) => { });
  }, [location]);

  const logoutHandler = () => {
    authCtx.logout();
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        searchDropdownWrap.current &&
        !searchDropdownWrap.current.contains(event.target)
      ) {
        setShowDropdown(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [searchDropdownWrap, showDropdown]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        notificationDropdownWrap.current &&
        !notificationDropdownWrap.current.contains(event.target)
      ) {
        setShowNotification(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [notificationDropdownWrap, showNotification]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        menuDropdownWrap.current &&
        !menuDropdownWrap.current.contains(event.target)
      ) {
        setMenuVisible(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuDropdownWrap, menuVisible]);

  useEffect(() => {
    setShowNotification(false);
    setShowDropdown(false);
    api
      .post(
        `api/leads/notification`,
        { per_page: 10, is_unread: 1 },
        {
          headers: {
            Authorization: `Bearer ${authCtx.userData?.token}`,
          },
        }
      )
      .then((response) => {
        setNotifications([]);
        if (response.data.status) {
          let notificationArray = [];
          for (let day in response.data.data?.notification) {
            response.data.data?.notification[day].map((n) => {
              notificationArray.push(n);
            });
          }
          setNotifications(notificationArray);
        }
      })
      .catch((error) => {
        if (error.response.status == 401) {
          authCtx.logout();
        } else {
          Swal.fire(
            "Opps!",
            "Sorry we are not able to complete your request at this time. Please try again later.",
            "error"
          );
        }
      });
  }, [location, themeCtx.reload]);

  useEffect(() => {
    if (searchDebouncedValue != null && searchDebouncedValue.length >= 3) {
      setLoading(true);
      api
        .post(
          `api/leads/auto-search`,
          { search: searchDebouncedValue },
          {
            headers: {
              Authorization: `Bearer ${authCtx.userData?.token}`,
            },
          }
        )
        .then((response) => {
          setLoading(false);
          if (response.data.status) {
            setShowDropdown(true);
            setResponseData(response.data.data);
          } else {
            setResponseData([]);
          }
        })
        .catch((error) => {
          setLoading(false);
          if (error.response.status == 401) {
            authCtx.logout();
          } else {
            Swal.fire(
              "Opps!",
              "Sorry we are not able to complete your request at this time. Please try again later.",
              "error"
            );
          }
        });
    }
  }, [searchDebouncedValue]);

  const notificationToggle = () => {
    setShowNotification(!showNotification);
  };

  const markAllReadHandler = () => {
    api
      .post(
        `api/leads/read-notification`,
        {},
        {
          headers: {
            Authorization: `Bearer ${authCtx.userData?.token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.status) {
          setShowNotification(false);
          setNotifications([]);
        } else {
          Swal.fire("Try Again!", response.data.message, "info");
        }
      })
      .catch((error) => {
        if (error.response.status == 401) {
          authCtx.logout();
        } else {
          Swal.fire(
            "Opps!",
            "Sorry we are not able to complete your request at this time. Please try again later.",
            "error"
          );
        }
      });
  };

  const markReadHandler = (notification_id, lead_id) => {
    api
      .post(
        `api/leads/get-lead`,
        {
          notification_id: notification_id,
          lead_id: lead_id,
        },
        {
          headers: {
            Authorization: `Bearer ${authCtx.userData?.token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.status) {
          setShowNotification(false);
          navigate(`/appraisals/view/${lead_id}`);
        }
      })
      .catch((error) => {
        if (error.response.status == 401) {
          authCtx.logout();
        } else {
          Swal.fire(
            "Opps!",
            "Sorry we are not able to complete your request at this time. Please try again later.",
            "error"
          );
        }
      });
  };

  const redirectToAppraisal = (id) => {
    setSearch("");
    navigate(`/appraisals/view/${id}`);
  };

  return (
    <div className="dashboard-navbar">
      <div className="dashboard-navbar-logo">
        <div
          className={`menuIcon ${themeCtx.isSidebarOpen ? "show" : ""}`}
          onClick={() => themeCtx.toggleSidebar()}>
          <span></span>
        </div>
        <Link to={routes.Dashboard}>
          <picture>
            <source media="(max-width:1199px)" srcSet={logoShape} />
            <img src={logoImg} alt="" />
          </picture>
        </Link>
      </div>
      <div className="dashboard-navbar-search">
        <div className="form-group mb-0">
          <input
            type="text"
            className="form-control"
            placeholder="Search by Customer or Vehicle"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        {responseData?.lead && responseData?.lead.length != 0 && (
          <div
            className={`serchbox-dropdown ${!showDropdown ? "d-none" : ""}`}
            ref={searchDropdownWrap}>
            <div className="searchboxlist">
              <ul>
                {responseData?.lead.map((data) => (
                  <li
                    className="vehicle-searclistbox"
                    key={data?.id}
                    onClick={() => redirectToAppraisal(data?.id)}>
                    <div className="vehicle-image">
                      <img
                        src={
                          data?.vehicleImage != ""
                            ? data?.vehicleImage
                            : vehicleImg
                        }
                        alt=""
                      />
                    </div>
                    <div className="vehicle-Head">
                      <h5 className="vehicle-Title">
                        {data?.year} {data?.make} {data?.model} {data?.trim}
                      </h5>
                      <p>
                        <b>Customer Name: </b>
                        {data?.first_name} {data?.last_name}
                      </p>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </div>
      <div className="dashboard-links d-none d-xl-inline-block">
        <ul>
          <li>
            <button type="button" onClick={() => themeCtx.getHelpPopupOpen()}>
              Get Help
            </button>
          </li>
          <li>
            <button type="button" onClick={() => themeCtx.contactPopupOpen()}>
              Contact Us
            </button>
          </li>
        </ul>
      </div>
      <div className="dashboard-button">
        <div className="dashboard-button-box">
          {isAppraisalAddAllow &&
            location.pathname !== "/appraisals/create" && (
              <Link to={routes.AppraisalCreate} className="btn btn-pink">
                <i className="icon icon-add"></i>New Appraisal
              </Link>
            )}
          {isAppraisalAddAllow && authCtx.userData?.subDomainURL !== undefined && (
            <SendAppraisalForm url={authCtx.userData?.subDomainURL} />
          )}
        </div>
        {isBusinessAllow && (
          <div className="dashboard-count-filter pe-md-3 pe-0">
            <div className="form-group">
              <select
                className="form-select"
                onChange={(e) => businessCtx.onBusinessChange(e.target.value)}
                value={businessCtx.selectedBusinessId}>
                <option value="">All</option>
                {_.map(businessCtx.businessList, (option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
        )}
        <div className="notificationboxMain" ref={notificationDropdownWrap}>
          <div className="dashboard-button-box">
            <button
              type="button"
              className="btn-icon"
              onClick={() => notificationToggle()}>
              <i
                className={`icon icon-notification ${notifications && notifications.length != 0 ? "is-new" : ""
                  }`}></i>
            </button>
          </div>
          <div className={`notificationbox ${showNotification ? "show" : ""}`}>
            <button
              type="button"
              className="notification-close"
              onClick={() => setShowNotification(false)}>
              <i className="icon icon-close"></i>
            </button>
            <h5 className="notifiTitle">Notifications</h5>
            <div className="notifibox">
              <ul>
                {notifications && notifications.length != 0 ? (
                  notifications.map((data, key) => (
                    <li
                      key={key}
                      onClick={() =>
                        markReadHandler(data?.id, data?.reference_id)
                      }>
                      {data?.title}
                      <span>{moment(data?.created_at).fromNow(true)} ago</span>
                    </li>
                  ))
                ) : (
                  <li>No unread notifications found.</li>
                )}
              </ul>
            </div>
            <div className="row">
              <div className="col">
                <Link to={routes.Notifications} className="viewmore">
                  View all
                </Link>
              </div>
              {notifications && notifications.length != 0 && (
                <div className="col-auto text-end">
                  <span
                    className="viewmore viewmore-pink"
                    onClick={() => markAllReadHandler()}>
                    Mark all as read
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="dashboard-button-box" ref={menuDropdownWrap}>
          <div
            className="dashboard-profile-dropdown"
            onClick={() => setMenuVisible(!menuVisible)}>
            <button type="button" className="dashboard-profile-dropdown-button">
              <img
                src={
                  authCtx?.userData?.image != "" &&
                    authCtx?.userData?.image != undefined
                    ? authCtx?.userData?.image
                    : userImg
                }
                alt=""
              />
              <span>{authCtx?.userData?.name}</span>
              <i className="icon icon-chevron-down"></i>
            </button>
            <ul className={`${menuVisible == true ? "show" : ""}`}>
              <li>
                <Link to={routes.Profile}>My Profile</Link>
              </li>
              <li>
                <a href="#" onClick={() => logoutHandler()}>
                  Logout
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
