import React, { useContext, useEffect, useState } from "react";
import api from "../../api";
import useDebounce from "../../hook/useDebounce";
import AuthContext from "../../context/authContext";
import ReactPaginate from "react-paginate";
import moment from "moment";
import Swal from "../Swal";
import { Link, useNavigate } from "react-router-dom";
import routes from "../../constants/routesConstants";

const Faqs = () => {
  const [responseData, setResponsedata] = useState({});
  const [loading, setLoading] = useState(false);
  const [sortField, setSortField] = useState("");
  const [sortType, setSortType] = useState("desc");
  const [pagination, setPagination] = useState({
    total_records: 0,
    total_pages: 0,
    current: 0,
  });

  const authCtx = useContext(AuthContext);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [search, setSearch] = useState("");
  const searchDebouncedValue = useDebounce(search, 1000);
  const navigate = useNavigate();

  useEffect(() => {
    if (authCtx.userData?.user_type != 3) {
      navigate(routes.Dashboard);
    }
  }, []);

  useEffect(() => {
    let filterData = {};
    if (searchDebouncedValue != "") {
      filterData = {
        ...filterData,
        search: searchDebouncedValue,
      };
    }
    if (selectedCategory != null) {
      filterData = {
        ...filterData,
        cate_id: selectedCategory,
      };
    }
    fetchFaq(filterData, 1);
  }, [searchDebouncedValue, selectedCategory]);

  async function fetchFaq(formData = {}, page) {
    setLoading(true);
    await api
      .post(`api/faq?page=${page}`, formData, {
        headers: {
          Authorization: `Bearer ${authCtx.userData?.token}`,
        },
      })
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          setResponsedata(response.data.data);
          setPagination(response.data.data.paginate);
        } else {
          setResponsedata([]);
          setPagination({
            total_records: 0,
            total_pages: 0,
            current: 0,
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response?.status == 401) {
          authCtx.logout();
        } else {
          Swal.fire(
            "Opps!",
            "Sorry we are not able to complete your request at this time. Please try again later.",
            "error"
          );
        }
      });
  }

  function sort(field, currentField, sort, page) {
    let filterData = {};
    if (searchDebouncedValue != "") {
      filterData = {
        ...filterData,
        search: searchDebouncedValue,
      };
    }
    if (selectedCategory != null) {
      filterData = {
        ...filterData,
        cate_id: selectedCategory,
      };
    }
    if (field == currentField) {
      if (sort == "desc") {
        setSortType("asc");
        filterData = {
          ...filterData,
          field_name: field,
          order_by: "asc",
        };
        fetchFaq(filterData, page);
      } else {
        setSortType("desc");
        filterData = {
          ...filterData,
          field_name: field,
          order_by: "desc",
        };
        fetchFaq(filterData, page);
      }
    } else {
      setSortField(field);
      setSortType("desc");
      filterData = {
        ...filterData,
        field_name: field,
        order_by: "desc",
      };
      fetchFaq(filterData, page);
    }
  }

  const onPaginationFetch = (e) => {
    let filterData = {};
    if (sortField != "" && sortType != "") {
      filterData = {
        ...filterData,
        field_name: sortField,
        order_by: sortType,
      };
    }
    if (selectedCategory != null) {
      filterData = {
        ...filterData,
        cate_id: selectedCategory,
      };
    }
    if (searchDebouncedValue != "") {
      filterData = {
        ...filterData,
        search: searchDebouncedValue,
      };
    }
    fetchFaq(filterData, e.selected + 1);
  };

  const deleteHandler = (id) => {
    if (id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          setLoading(true);
          api
            .post(
              "api/faq/delete-faq",
              { faq_id: id },
              {
                headers: {
                  Authorization: `Bearer ${authCtx.userData?.token}`,
                },
              }
            )
            .then((response) => {
              setLoading(false);
              if (response.data.status) {
                fetchFaq({}, 1);
                Swal.fire(response.data.message, "", "success");
              } else {
                Swal.fire("Try Again!", response.data.message, "info");
              }
            })
            .catch((error) => {
              setLoading(false);
              if (error.response.status == 401) {
                authCtx.logout();
              } else {
                Swal.fire(
                  "Opps!",
                  "Sorry we are not able to complete your request at this time. Please try again later.",
                  "error"
                );
              }
            });
        }
      });
    }
    return false;
  };

  const onStatusChange = (value, id) => {
    api
      .post(
        "api/faq/update-faq-status",
        { faq_id: id, status: value == true ? 1 : 0 },
        {
          headers: {
            Authorization: `Bearer ${authCtx.userData?.token}`,
          },
        }
      )
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          Swal.fire(response.data.message, "", "success");
        } else {
          Swal.fire(response.data.message, "", "info");
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          authCtx.logout();
        } else {
          Swal.fire(
            "Opps!",
            "Sorry we are not able to complete your request at this time. Please try again later.",
            "error"
          );
        }
      });
  };

  const clearHandler = () => {
    setSearch("");
    setSelectedCategory("");
  };

  return (
    <>
      <div className="dashboard-count-filter dashboard-appraisals  mb-24">
        <div className="row align-items-center">
          <div className="col">
            <h2 className="page-title">FAQs</h2>
            <p className="page-dec">
              In total there is <b>{pagination?.total_records}</b> FAQs
            </p>
          </div>
        </div>
      </div>
      <div className="dashboard-latest-appraisals">
        <div className="dashboard-card">
          <div className="dashboard-card-filter dashboard-count-filter">
            <div className="row align-items-center">
              <div className="col">
                <div className="dashboard-navbar-search dashboardml">
                  <div className="form-group mb-0">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </div>
                </div>
                <div className="dashboard-navbar-month dashboardml">
                  <div className="form-group">
                    <select
                      className="form-select"
                      onChange={(e) => setSelectedCategory(e.target.value)}
                      value={selectedCategory}
                    >
                      <option value="">Select Category</option>
                      <option value="getting_started">Getting Started</option>
                      <option value="how_it_works">How It Works</option>
                      <option value="manage_account">Manage Account</option>
                    </select>
                  </div>
                </div>
                <div className="dashboard-navbar-date-filter dashboardml">
                  <button
                    type="button"
                    className="date-filter"
                    onClick={() => clearHandler()}
                  >
                    Clear <i className="icon icon-reset"></i>
                  </button>
                </div>
              </div>
              <div className="col-auto">
                <div className="dashboard-export">
                  <Link to={routes.FaqsCreate} className="btn btn-pink">
                    <i className="icon icon-add"></i> Add FAQ
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="dashboard-latest-apraisals faq-table table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>#</th>
                  <th width="10%">
                    Category{" "}
                    <span
                      className={`table-sort ${
                        sortField == "cate_id" ? sortType : ""
                      }`}
                      onClick={() =>
                        sort("cate_id", sortField, sortType, pagination.current)
                      }
                    ></span>
                  </th>
                  <th width="25%">
                    Question{" "}
                    <span
                      className={`table-sort ${
                        sortField == "question" ? sortType : ""
                      }`}
                      onClick={() =>
                        sort(
                          "question",
                          sortField,
                          sortType,
                          pagination.current
                        )
                      }
                    ></span>
                  </th>
                  <th width="45%">
                    Answer{" "}
                    <span
                      className={`table-sort ${
                        sortField == "answer" ? sortType : ""
                      }`}
                      onClick={() =>
                        sort("answer", sortField, sortType, pagination.current)
                      }
                    ></span>
                  </th>
                  <th>
                    Status{" "}
                    <span
                      className={`table-sort ${
                        sortField == "status" ? sortType : ""
                      }`}
                      onClick={() =>
                        sort("status", sortField, sortType, pagination.current)
                      }
                    ></span>
                  </th>
                  <th width="10%">
                    Created At{" "}
                    <span
                      className={`table-sort ${
                        sortField == "created_at" ? sortType : ""
                      }`}
                      onClick={() =>
                        sort(
                          "created_at",
                          sortField,
                          sortType,
                          pagination.current
                        )
                      }
                    ></span>
                  </th>
                  <th width="15%">Action</th>
                </tr>
              </thead>
              <tbody>
                {responseData?.faq && responseData?.faq.length != 0 ? (
                  responseData?.faq.map((data) => (
                    <tr key={data?.id}>
                      <td>{data?.id}</td>
                      <td className="text-capitalize">
                        {data?.cate_id.replace(/_/g, " ")}
                      </td>
                      <td>{data?.question}</td>
                      <td>{data?.answer}</td>
                      <td>
                        <div className="switch-button">
                          <input
                            type="checkbox"
                            hidden="hidden"
                            id={`status${data?.id}`}
                            defaultChecked={data?.status}
                            onChange={(e) =>
                              onStatusChange(e.target.checked, data?.id)
                            }
                          />
                          <label
                            className="switch"
                            htmlFor={`status${data?.id}`}
                          ></label>
                        </div>
                      </td>
                      <td>{moment.utc(data?.created_at).format("MMMM D, YYYY")}</td>
                      <td>
                        <button
                          type="button"
                          className="btn-icon"
                          onClick={() => deleteHandler(data?.id)}
                        >
                          <i className="icon icon-delete"></i>
                        </button>
                        <Link
                          to={`/faqs/edit/${data?.id}`}
                          className="btn-icon"
                        >
                          <i className="icon icon-edit"></i>
                        </Link>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr className="text-start text-sm-center">
                    <td colSpan={7}>
                      {loading ? "Loading..." : "No FAQs Found."}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {pagination?.total_pages != undefined && pagination?.total_pages != 1 && (
        <nav className="navigation pagination">
          <ReactPaginate
            breakLabel="..."
            onPageChange={onPaginationFetch}
            pageRangeDisplayed={2}
            pageCount={pagination?.total_pages}
            renderOnZeroPageCount={null}
            containerClassName="nav-links"
            activeLinkClassName="current"
            pageLinkClassName="page-numbers"
            previousLinkClassName="prev page-numbers"
            previousLabel={<i className="icon icon-arrow-left"></i>}
            nextLinkClassName="next page-numbers"
            nextLabel={<i className="icon icon-arrow-right"></i>}
          />
        </nav>
      )}
    </>
  );
};

export default Faqs;
