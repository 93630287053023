import React, { Fragment, useContext, useEffect, useState } from "react";
import api from "../../api";
import AuthContext from "../../context/authContext";
import ThemeContext from "../../context/themeContext";
import useDebounce from "../../hook/useDebounce";
import Swal from "../Swal";
import gettingStarted from "../../assets/img/svg/getting-started.svg";
import workImg from "../../assets/img/svg/work.svg";
import accountImg from "../../assets/img/svg/account.svg";
import parse from "html-react-parser";

const GetHelp = () => {
  const [loading, setLoading] = useState(false);
  const [responseData, setResponsedata] = useState(null);
  const authCtx = useContext(AuthContext);
  const themeCtx = useContext(ThemeContext);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [search, setSearch] = useState(null);
  const searchDebouncedValue = useDebounce(search, 500);

  useEffect(() => {
    let filterData = {};
    if (selectedCategory != null) {
      filterData = {
        ...filterData,
        cate_id: selectedCategory,
      };
    }
    if (searchDebouncedValue != null) {
      filterData = {
        ...filterData,
        search: searchDebouncedValue,
      };
    }
    if (themeCtx.isGetHelpPopupShow) {
      getFaq(filterData);
    }
  }, [themeCtx.isGetHelpPopupShow, searchDebouncedValue, selectedCategory]);

  const getFaq = async (formData = {}) => {
    await api
      .post("api/faq", formData, {
        headers: {
          Authorization: `Bearer ${authCtx.userData?.token}`,
        },
      })
      .then((res) => {
        setResponsedata(res.data.data);
      })
      .catch((error) => {
        if (error.response.status == 401) {
          authCtx.logout();
        } else {
          Swal.fire(
            "Opps!",
            "Sorry we are not able to complete your request at this time. Please try again later.",
            "error"
          );
        }
      });
  };

  return (
    <div
      className={`dashboard-popup get-help-popup ${
        themeCtx.isGetHelpPopupShow ? "show" : ""
      }`}
    >
      <div className="top-employee-popup dashboard-popup-inner">
        <button
          type="button"
          className="dashboard-popup-close"
          onClick={() => themeCtx.getHelpPopupClose()}
        >
          <i className="icon icon-close"></i>
        </button>
        <h5 className="get-help-Title text-center">How we can help you ?</h5>
        <div className="question-search">
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder="Type your question here"
              onChange={(e) => setSearch(e.target.value)}
            />
            <button className="search-button">
              <i className="icon icon-search"></i>
            </button>
          </div>
        </div>
        {/* <div className="helpyouboxMain">
          <div className="row helpyouboxrow justify-content-center">
            <div className="col-sm-4 col-6 helpyouboxcol">
              <div
                className={`helpyoubox text-center ${
                  selectedCategory == "getting_started" ? "active" : ""
                }`}
                onClick={() => setSelectedCategory("getting_started")}
              >
                <div className="helpyouboximg">
                  <img src={gettingStarted} alt="" />
                </div>
                <h5 className="helpyouboxTitle mb-0">Getting Started</h5>
                <p></p>
              </div>
            </div>
            {authCtx.isLoggedIn && (
              <div className="col-sm-4 col-6 helpyouboxcol">
                <div
                  className={`helpyoubox text-center ${
                    selectedCategory == "how_it_works" ? "active" : ""
                  }`}
                  onClick={() => setSelectedCategory("how_it_works")}
                >
                  <div className="helpyouboximg">
                    <img src={workImg} alt="" />
                  </div>
                  <h5 className="helpyouboxTitle mb-0">How it work</h5>
                  <p></p>
                </div>
              </div>
            )}
            <div className="col-sm-4 col-6 helpyouboxcol">
              <div
                className={`helpyoubox text-center ${
                  selectedCategory == "manage_account" ? "active" : ""
                }`}
                onClick={() => setSelectedCategory("manage_account")}
              >
                <div className="helpyouboximg">
                  <img src={accountImg} alt="" />
                </div>
                <h5 className="helpyouboxTitle mb-0">Manage Account</h5>
                <p></p>
              </div>
            </div>
          </div>
        </div> */}
        <div className="faqMian">
          <div className="secHead text-center">
            <h4 className="secTitle">Frequently Asked Questions</h4>
            <p>
              Find answers to the most frequently asked questions about the
              iLusso Vehicle Appraisal Platform.
            </p>
          </div>

          <div
            className="accordion accordion-flush faq-accordionMian"
            id="accordionfaq"
          >
            {responseData?.faq && responseData?.faq.length != 0 ? (
              responseData?.faq.map((data, key) => (
                <Fragment key={key}>
                  {data?.status == 1 && (
                    <div className="accordion-item">
                      <h2
                        className="accordion-header"
                        id={`flush-heading-${key}`}
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#flush-collapse-${key}`}
                          aria-expanded="false"
                          aria-controls="flush-collapse"
                        >
                          {data?.question != undefined && data?.question}
                        </button>
                      </h2>
                      <div
                        id={`flush-collapse-${key}`}
                        className="accordion-collapse collapse"
                        aria-labelledby={`flush-heading-${key}`}
                        data-bs-parent="#accordionfaq"
                      >
                        <div className="accordion-body cmsCon">
                          {data?.answer != undefined && <p>{data?.answer}</p>}
                          {data?.embed_code != undefined
                            ? parse(data?.embed_code)
                            : ""}
                        </div>
                      </div>
                    </div>
                  )}
                </Fragment>
              ))
            ) : (
              <h5 className="text-center">No Data Found.</h5>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetHelp;
