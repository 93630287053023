import React, { useContext, useEffect, useState } from "react";
import api from "../../api";
import AuthContext from "../../context/authContext";
import Swal from "../Swal";
import moment from "moment";
import _ from "lodash";
import { formatCurrency } from "../../utils/formats";
import UnpaidSubscribers from "./UnpaidSubscribers";

const SubscriptionDashboard = () => {
  const [responseData, setResponseData] = useState({});
  const [period, setPeriod] = useState("");
  const [monthlyPeriod, setMonthlyPeriod] = useState([]);

  const authCtx = useContext(AuthContext);

  useEffect(() => {
    getMonths();
    getDashboardData();
  }, []);

  function getMonths() {
    let monthsArray = [];
    let monthName = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    let d = new Date();
    d.setDate(1);
    for (let i = 0; i <= 11; i++) {
      d.setMonth(d.getMonth() - 1);
      monthsArray.push({
        label: monthName[d.getMonth()] + " " + d.getFullYear(),
        value: moment(new Date(d.getFullYear(), d.getMonth(), 1)).format(
          "YYYY-MM-DD"
        ),
      });
    }
    setMonthlyPeriod(monthsArray);
  }

  const getDashboardData = async (formData = {}) => {
    await api
      .post("api/subscription-dashboard", formData, {
        headers: {
          Authorization: `Bearer ${authCtx.userData?.token}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          setResponseData(res.data.data);
          if (formData?.isSelect == undefined) {
            setPeriod(res.data.data?.this_month);
          }
        }
      })
      .catch((error) => {
        if (error.response.status == 401) {
          authCtx.logout();
        } else {
          Swal.fire(
            "Opps!",
            "Sorry we are not able to complete your request at this time. Please try again later.",
            "error"
          );
        }
      });
  };

  const onPeriodChange = (value) => {
    if (value !== "") {
      getDashboardData({ period: value, isSelect: false });
    } else {
      getDashboardData();
    }
    setPeriod(value);
  };

  return (
    <>
      <div className="dashboard-count-filter mb-24">
        <div className="row align-items-center">
          <div className="col">
            <h2 className="page-title">Subscription Dashboard</h2>
          </div>
          <div className="col-auto">
            <div className="form-group">
              <select
                className="form-select"
                onChange={(e) => onPeriodChange(e.target.value)}
                value={period}>
                <option value="">This Month</option>
                {_.map(monthlyPeriod, (option, key) => (
                  <option key={key} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className="dashboard-count-wrap mb-24">
        <div className="row">
          <div className="col-xl-2 col-lg-4 col-6">
            <div className="dashboard-card is-total-won">
              <div className="dashboard-card-row">
                <div className="dashboard-card-icon">
                  <i className="icon icon-won"></i>
                </div>
                <div className="dashboard-card-text">
                  <h5 className="dashboard-card-count">
                    {responseData?.total_subscriptions != undefined
                      ? responseData?.total_subscriptions
                      : 0}
                  </h5>
                  <span className="dashboard-card-label">Total Invoice</span>
                  <div className="progress">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: "50%" }}
                      aria-valuenow="50"
                      aria-valuemin="0"
                      aria-valuemax="10"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-2 col-lg-4 col-6">
            <div className="dashboard-card is-new-appraisal">
              <div className="dashboard-card-row">
                <div className="dashboard-card-icon">
                  <i className="icon icon-new-appraisals"></i>
                </div>
                <div className="dashboard-card-text">
                  <h5 className="dashboard-card-count">
                    {responseData?.paid_subscriptions != undefined
                      ? responseData?.paid_subscriptions
                      : 0}
                  </h5>
                  <span className="dashboard-card-label">Total Paid</span>
                  <div className="progress">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: "50%" }}
                      aria-valuenow="50"
                      aria-valuemin="0"
                      aria-valuemax="10"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-2 col-lg-4 col-6">
            <div className="dashboard-card is-offer-made">
              <div className="dashboard-card-row">
                <div className="dashboard-card-icon">
                  <i className="icon icon-offer-made"></i>
                </div>
                <div className="dashboard-card-text">
                  <h5 className="dashboard-card-count">
                    {responseData?.unpaid_subscriptions != undefined
                      ? responseData?.unpaid_subscriptions
                      : 0}
                  </h5>
                  <span className="dashboard-card-label">Total Unpaid</span>
                  <div className="progress">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: "20%" }}
                      aria-valuenow="50"
                      aria-valuemin="0"
                      aria-valuemax="10"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-2 col-lg-4 col-6">
            <div className="dashboard-card is-total-appraisals">
              <div className="dashboard-card-row">
                <div className="dashboard-card-icon">
                  <i className="icon icon-total-appraisal"></i>
                </div>
                <div className="dashboard-card-text">
                  <h5 className="dashboard-card-count">
                    {responseData?.renewed_subscriptions != undefined
                      ? responseData?.renewed_subscriptions
                      : 0}
                  </h5>
                  <span className="dashboard-card-label">Renewed</span>
                  <div className="progress">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: "75%" }}
                      aria-valuenow="75"
                      aria-valuemin="0"
                      aria-valuemax="10"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-2 col-lg-4 col-6">
            <div className="dashboard-card is-working">
              <div className="dashboard-card-row">
                <div className="dashboard-card-icon">
                  <i className="icon icon-working"></i>
                </div>
                <div className="dashboard-card-text">
                  <h5 className="dashboard-card-count">
                    {responseData?.sum_paid_subscriptions != undefined
                      ? formatCurrency(responseData?.sum_paid_subscriptions)
                      : 0}
                  </h5>
                  <span className="dashboard-card-label">Amount/Mo.</span>
                  <div className="progress">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: "40%" }}
                      aria-valuenow="50"
                      aria-valuemin="0"
                      aria-valuemax="10"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-2 col-lg-4 col-6">
            <div className="dashboard-card is-no-deals">
              <div className="dashboard-card-row">
                <div className="dashboard-card-icon">
                  <i className="icon icon-dollar"></i>
                </div>
                <div className="dashboard-card-text">
                  <h5 className="dashboard-card-count">
                    {responseData?.gran_total_subscriptions != undefined
                      ? formatCurrency(responseData?.gran_total_subscriptions)
                      : 0}
                  </h5>
                  <span className="dashboard-card-label">Grand Total</span>
                  <div className="progress">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: "60%" }}
                      aria-valuenow="50"
                      aria-valuemin="0"
                      aria-valuemax="10"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <UnpaidSubscribers />
    </>
  );
};

export default SubscriptionDashboard;