import React, { useContext, useState } from "react";
import QRCode from "react-qr-code";
import ThemeContext from "../../context/themeContext";
import { CopyOutlined, CopyFilled } from "@ant-design/icons";
import { useLocation } from "react-router-dom";

const SendAppraisalForm = ({ url }) => {
  const themeCtx = useContext(ThemeContext);

  const location = useLocation();

  const [isCopied, setIsCopied] = useState(false);

  const onCopyClick = () => {
    setIsCopied(true);
    navigator.clipboard.writeText(url);
  };

  return (
    <>
      <button
        type="button"
        className={`btn btn-pink ${
          location.pathname === "/appraisals/create" ? "w-100" : ""
        }`}
        onClick={() => {
          themeCtx.sendLinkPopupOpen();
        }}>
        <i className="icon icon-mostaccepted"></i>Send Link
      </button>
      <div
        className={`dashboard-popup send_main_popup get-help-popup ${
          themeCtx.isSendLinkPopupShow ? "show" : ""
        }`}>
        <div className="top-employee-popup send_popup dashboard-popup-inner">
          <button
            type="button"
            className="dashboard-popup-close"
            onClick={() => themeCtx.sendLinkPopupClose()}>
            <i className="icon icon-close"></i>
          </button>
          <h5 className="get-help-Title text-center">Send Link</h5>
          <p>
            Click on below button to <br />
            copy customer appraisal form link.
          </p>
          <div className="formCopyLinkWrap">
            <span>{url}</span>
            <button type="button" onClick={() => onCopyClick()}>
              {isCopied ? <CopyFilled /> : <CopyOutlined />}
            </button>
          </div>
          <div className="qr_code">
            <QRCode value={url || ""} />
          </div>
        </div>
      </div>
    </>
  );
};

export default SendAppraisalForm;
