import React, { useContext, useEffect, useRef, useState } from "react";
import { Outlet, Navigate, useLocation } from "react-router-dom";
import AuthContext from "../../context/authContext";
import ThemeContext from "../../context/themeContext";
import ContactUs from "../ContactUs";
import GetHelp from "../GetHelp";
import Navbar from "../Navbar";
import Sidebar from "../Sidebar";

const Wrapper = () => {
  const [isSticky, setIsSticky] = useState(false);
  const themeCtx = useContext(ThemeContext);
  const authCtx = useContext(AuthContext);
  const dashboardInner = useRef();
  const location = useLocation();
  const onDashboardInnerScollHandler = () => {
    const scrollTop = dashboardInner.current.scrollTop;
    if (scrollTop > 250) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };

  useEffect(() => {
    dashboardInner.current.scrollTo({
      top: dashboardInner.current.offsetTop,
      behavior: "smooth",
    });
  }, [location]);
  return (
    <>
      <Navbar />
      <section
        className={`dashboard-wrapper`}>
        <Sidebar />
        <div
          className={`dashboard-inner ${isSticky ? "is-sticky" : ""}`}
          ref={dashboardInner}
          onScroll={onDashboardInnerScollHandler}>
          {authCtx.isLoggedIn ? <Outlet /> : <Navigate to="/" replace />}
        </div>
      </section>
      <div
        className={`dashboard-backdrop ${
          themeCtx.showDropShadow ? "show" : ""
        }`}></div>
      <ContactUs />
      <GetHelp />
    </>
  );
};

export default Wrapper;
