import React, { useContext, useEffect, useState, useCallback } from "react";
import userImg from "../../assets/img/user.jpg";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../context/authContext";
import Swal from "../Swal";
import api from "../../api";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { formatPhoneNumber } from "../../utils/formats";
import debounce from "lodash.debounce";

const validationSchema = yup.object().shape({
  name: yup.string().required("Name is required."),
  email: yup.string().email("Email is invalid.").max(255).required("Email is required."),
  mobile_no: yup
    .string()
    .required("Phone number is required.")
    .min(10, "Phone number is to short.")
    .max(12, "Phone number is to long."),
});

const Profile = () => {
  const authCtx = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [emailNotification, setEmailNotification] = useState(false);
  const [smsNotification, setSmsNotification] = useState(false);
  const [alertNotification, setAlertNotification] = useState(false);
  const [previewImage, setPreviewImage] = useState({ imageUrl: "" });
  const navigate = useNavigate();
  const [formData, setFormData] = useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    trigger,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: authCtx.userData?.name,
      email: authCtx.userData?.email,
      mobile_no: authCtx.userData?.user_type === 0 ? authCtx.userData?.business_phone : authCtx.userData?.mobile_no,
      business_address: authCtx.userData?.business_address,
      vauto_entity_id: authCtx.userData?.vauto_entity_id,
      vauto_username: authCtx.userData?.vauto_username,
    },
  });


  useEffect(() => {
    trigger();
  }, [trigger]);



  useEffect(() => {
    if (authCtx.userData?.email_notification == 1) {
      setEmailNotification(true);
    }
    if (authCtx.userData?.sms_notification == 1) {
      setSmsNotification(true);
    }
    if (authCtx.userData?.alert_notification == 1) {
      setAlertNotification(true);
    }
  }, [authCtx]);

  const mobile_no = watch("mobile_no");
  useEffect(() => {
    setValue("mobile_no", formatPhoneNumber(mobile_no).formatted);
  }, [mobile_no]);

  const uploadedImage = watch("image");
  useEffect(() => {
    if (uploadedImage != undefined && uploadedImage.length != 0) {
      setPreviewImage({ imageUrl: URL.createObjectURL(uploadedImage[0]) });
    }
  }, [uploadedImage]);

  const onSubmit = async (formValue) => {
    setLoading(true);
    let formData = new FormData();
    formData.append("name", formValue.name);
    formData.append("email", formValue.email);
    formData.append(`${authCtx.userData?.user_type === 0 ? "business_phone" : "mobile_no"}`, formatPhoneNumber(formValue.mobile_no).base);
    if (
      formValue.old_password != undefined &&
      formValue.new_password != undefined
    ) {
      formData.append("old_password", formValue.old_password);
      formData.append("new_password", formValue.new_password);
    }
    formData.append("alert_notification", alertNotification ? 1 : 0);
    formData.append("email_notification", emailNotification ? 1 : 0);
    formData.append("sms_notification", smsNotification ? 1 : 0);
    if (formValue.image != undefined) {
      formData.append("image", formValue.image[0]);
    }
    if (
      authCtx.userData?.user_type == 0 &&
      formValue.business_address != undefined
    ) {
      formData.append("business_address", formValue.business_address);
    }
    if (
      formValue.subscription_plan_price !== undefined
    ) {
      formData.append(
        "subscription_plan_name",
        formValue.subscription_plan_name
      );
      formData.append(
        "subscription_plan_price",
        formValue.subscription_plan_price
      );
    }

    if (authCtx.userData?.user_type == 0) {
      formData.append("vauto_entity_id", formValue.vauto_entity_id);
      formData.append("vauto_username", formValue.vauto_username);
    }
    if (
      formValue.subscription_plan_name !== undefined &&
      formValue.subscription_plan_price !== undefined
    ) {
      formData.append(
        "subscription_plan_name",
        formValue.subscription_plan_name
      );
      formData.append(
        "subscription_plan_price",
        formValue.subscription_plan_price
      );
    }
    await api
      .post("api/users/update-user-profile", formData, {
        headers: {
          "Content-type": "multipart/form-data",
          Authorization: `Bearer ${authCtx.userData?.token}`,
        },
      })
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          const oldData = {
            ...authCtx.userData,
            ...response.data.data,
          };
          authCtx.login(oldData);
          Swal.fire(response.data.message, "", "success");
          setIsEdit(false);
        } else {
          Swal.fire(response.data.message, "", "info");
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          authCtx.logout();
        } else {
          Swal.fire(
            "Opps!",
            "Sorry we are not able to complete your request at this time. Please try again later.",
            "error"
          );
        }
      });
  };

  const onChangeHandler = (type) => {
    if (type == "email_notification") {
      setEmailNotification(!emailNotification);
    }
    if (type == "sms_notification") {
      setSmsNotification(!smsNotification);
    }
    if (type == "alert_notification") {
      setAlertNotification(!alertNotification);
    }
  };


  const debouncedSubmit = useCallback(
    debounce((formData) => {
      onSubmit(formData);
    }, 1000),
    []
  );

  useEffect(() => {
    if (formData) {
      debouncedSubmit(formData);
    }
  }, [formData, debouncedSubmit]);




  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        onChange={() => {
          setFormData({
            name: watch("name"),
            email: watch("email"),
            mobile_no: watch("mobile_no"),
            business_address: watch("business_address"),
            vauto_entity_id: watch("vauto_entity_id"),
            vauto_username: watch("vauto_username"),
            subscription_plan_name: watch("subscription_plan_name"),
            subscription_plan_price: watch("subscription_plan_price"),
            image: watch("image"),
          });
        }}
      >
        <div className="appraisal-head-wrap mb-34">
          <div className="row">
            <div className="col">
              <div className="appraisal-head-title align-items-start">
                <button
                  type="button"
                  className="btn-icon"
                  onClick={() => navigate(-1)}>
                  <i className="icon icon-back-aero"></i>
                </button>
                <div className="page-head">
                  <h2 className="page-title">My Profile</h2>
                  <p className="page-dec">Fill the required informations</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="notification edit-profile my-edit-profile mb-24">
          <div className="manager-details-inner mt-0">
            <div className="row align-items-center">
              <div className="col managercol">
                <div className="manager-details-user">
                  <div className="manager-image">
                    <img
                      src={
                        previewImage.imageUrl
                          ? previewImage.imageUrl
                          : authCtx.userData?.image != "" &&
                            authCtx.userData?.image != undefined
                            ? authCtx.userData?.image
                            : userImg
                      }
                      alt=""
                    />
                    {isEdit && (
                      <>
                        <i className="icon icon-add-picture"></i>
                        <input
                          type="file"
                          {...register("image")}
                          accept="image/png, image/jpg, image/jpeg"
                        />
                      </>
                    )}
                  </div>
                  <div className="manager-info">
                    <h4>{authCtx.userData?.name}</h4>
                    <span>
                      {authCtx?.userData?.user_type == 0 && "Dealer"}
                      {authCtx?.userData?.user_type == 1 && "Used Car Manager"}
                      {authCtx?.userData?.user_type == 2 && "Service Advisors"}
                      {authCtx?.userData?.user_type == 4 && "Sales"}
                    </span>
                  </div>
                </div>
              </div>

              {isEdit ? (
                <div className="col-auto text-end managercol myprofilecol">
                  <button
                    type="button"
                    className="btn btn-outline-secondary"
                    onClick={() => setIsEdit(false)}>
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="btn btn-outline-primary d-inline-flex align-items-center">
                    <i className="icon icon-save"></i> Save Changes
                  </button>
                </div>
              ) : (
                <div className="col-auto managercol">
                  <button
                    type="button"
                    className="btn btn-outline-secondary"
                    onClick={() => setIsEdit(true)}>
                    <i className="icon icon-edit"></i> Edit Profile
                  </button>
                </div>
              )}
            </div>
          </div>

          <div className="appraisal-form-inner">
            <div className="row">
              <div className="col-md-12">
                <div className={`form-group ${!isEdit ? "disabled" : ""}`}>
                  <label className="col-form-label">Name</label>
                  <input
                    className="form-control"
                    type="text"
                    disabled={!isEdit}
                    {...register("name")}
                  />
                  {errors.name?.message != undefined && (
                    <span className="form-error">{errors.name?.message}</span>
                  )}
                </div>
              </div>
              <div className="col-md-12">
                <div className={`form-group ${!isEdit ? "disabled" : ""}`}>
                  <label className="col-form-label">Phone</label>
                  <input
                    className="form-control"
                    type="text"
                    disabled={!isEdit}
                    maxLength={12}
                    {...register("mobile_no")}
                  />
                  {errors.mobile_no?.message != undefined && (
                    <span className="form-error">
                      {errors.mobile_no?.message}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-md-12">
                <div className={`form-group ${!isEdit ? "disabled" : ""}`}>
                  <label className="col-form-label">Email</label>
                  <input
                    className="form-control"
                    type="email"
                    disabled={!isEdit}
                    {...register("email")}
                  />
                </div>
              </div>
              {isEdit && (
                <>
                  {authCtx.userData?.user_type == 0 && (
                    <>
                      <div className="col-md-12">
                        <div
                          className={`form-group ${!isEdit ? "disabled" : ""}`}>
                          <label className="col-form-label">
                            Business Address
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            disabled={!isEdit}
                            {...register("business_address")}
                          />
                          {errors.business_address?.message != undefined && (
                            <span className="form-error">
                              {errors.business_address?.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className={`form-group ${!isEdit ? "disabled" : ""}`}>
                          <label className="col-form-label">VAuto Entity ID</label>
                          <input
                            className="form-control"
                            type="text"
                            disabled={!isEdit}
                            {...register("vauto_entity_id")}
                            maxLength={100}
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className={`form-group ${!isEdit ? "disabled" : ""}`}>
                          <label className="col-form-label">VAuto Username</label>
                          <input
                            className="form-control"
                            type="text"
                            disabled={!isEdit}
                            {...register("vauto_username")}
                            maxLength={100}
                          />
                        </div>
                      </div>
                    </>
                  )}
                  <div className="col-sm-6">
                    <div className="form-group">
                      <input
                        className="form-control"
                        type="password"
                        placeholder="Old Password"
                        disabled={!isEdit}
                        {...register("old_password")}
                      />
                      {errors.old_password?.message != undefined && (
                        <span className="form-error">
                          {errors.old_password?.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <input
                        className="form-control"
                        type="password"
                        placeholder="New Password"
                        disabled={!isEdit}
                        {...register("new_password")}
                      />
                      {errors.new_password?.message != undefined && (
                        <span className="form-error">
                          {errors.new_password?.message}
                        </span>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="notification no-border settings-notification">
          <div className="settings-notificationbox mb-24">
            <div className="row justify-content-end mb-24">
              <div className="col-auto text-end managercol myprofilecol">
                <button
                  type="submit"
                  className="btn btn-outline-primary d-inline-flex align-items-center"
                  disabled={loading}>
                  <i className="icon icon-save"></i> Update Changes
                </button>
              </div>
            </div>
            <h5 className="notification-Title ffr">Notification preference</h5>
            <div className="dashboard-notification table-responsive">
              <table className="table" align="center">
                <thead>
                  <tr>
                    <th width="40%"></th>
                    <th width="10%">Email</th>
                    <th width="11%">SMS</th>
                    <th width="29%">Alert (Notification bell)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Notifications</td>
                    <td>
                      <label className="customCheckbox">
                        <input
                          type="checkbox"
                          {...register("email_notification")}
                          checked={emailNotification}
                          onChange={() => onChangeHandler("email_notification")}
                        />
                        <span className="lable-text"></span>
                      </label>
                    </td>
                    <td>
                      <label className="customCheckbox">
                        <input
                          type="checkbox"
                          {...register("sms_notification")}
                          checked={smsNotification}
                          onChange={() => onChangeHandler("sms_notification")}
                        />
                        <span className="lable-text"></span>
                      </label>
                    </td>
                    <td>
                      <label className="customCheckbox">
                        <input
                          type="checkbox"
                          {...register("alert_notification")}
                          checked={alertNotification}
                          onChange={() => onChangeHandler("alert_notification")}
                          defaultChecked={true}
                        />
                        <span className="lable-text"></span>
                      </label>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          {authCtx.userData?.user_type == 3 && (
            <div className="settings-notificationbox">
              <h5 className="notification-Title ffr">
                Subscription plan preference
              </h5>
              <div className="appraisal-form-inner">
                <div className="row">
                  <div className="col-md-12">
                    <div className={`form-group mt-0`}>
                      <label className="col-form-label">Plan Title</label>
                      <input
                        className="form-control"
                        type="text"
                        {...register("subscription_plan_name")}
                        defaultValue={authCtx.userData?.subscription_plan_name}
                      />
                      {errors.subscription_plan_name?.message != undefined && (
                        <span className="form-error">
                          {errors.subscription_plan_name?.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className={`form-group`}>
                      <label className="col-form-label">Price</label>
                      <input
                        className="form-control"
                        type="number"
                        {...register("subscription_plan_price")}
                        defaultValue={authCtx.userData?.subscription_plan_price}
                      />
                      {errors.subscription_plan_price?.message != undefined && (
                        <span className="form-error">
                          {errors.subscription_plan_price?.message}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </form>
    </>
  );
};

export default Profile;
