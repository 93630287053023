import React, { useContext, useEffect, useState } from "react";
import api from "../../api";
import AuthContext from "../../context/authContext";
import ThemeContext from "../../context/themeContext";
import Swal from "../Swal";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import contactImg from "../../assets/img/contactimg.svg";
import { formatPhoneNumber } from "../../utils/formats";

let validationSchema = {};

const ContactUs = () => {
  const [loading, setLoading] = useState(false);
  const [responseData, setResponsedata] = useState(null);
  const authCtx = useContext(AuthContext);
  const themeCtx = useContext(ThemeContext);

  if (authCtx.isLoggedIn) {
    validationSchema = yup.object().shape({
      subject: yup.string().max(42).required("Subject is required."),
      message: yup.string().max(42).required("Message is required."),
    });
  } else {
    validationSchema = yup.object().shape({
      subject: yup.string().max(42).required("Subject is required."),
      message: yup.string().max(180).required("Message is required."),
      name: yup.string().max(42).required("Name is required."),
      email: yup
        .string()
        .email("Email is invalid.")
        .max(42)
        .required("Email is required."),
      mobile_no: yup
        .string()
        .required("Phone number is required.")
        .min(10, "Phone number is to short.")
        .max(12, "Phone number is to long."),
    });
  }

  useEffect(() => {
    if (themeCtx.isContactPopupShow) {
      getContact();
    }
  }, [themeCtx.isContactPopupShow]);

  const getContact = async () => {
    await api
      .post(
        "api/contact-us",
        {},
        {
          headers: {
            Authorization: `Bearer ${authCtx.userData?.token}`,
          },
        }
      )
      .then((res) => {
        setResponsedata(res.data.data);
      })
      .catch((error) => {
        if (error.response.status == 401) {
          authCtx.logout();
        } else {
          Swal.fire(
            "Opps!",
            "Sorry we are not able to complete your request at this time. Please try again later.",
            "error"
          );
        }
      });
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const mobile_no = watch("mobile_no");
  useEffect(() => {
    setValue("mobile_no", formatPhoneNumber(mobile_no).formatted);
  }, [mobile_no]);

  const onSubmit = async (formValue) => {
    setLoading(true);
    let formData = {};
    if (authCtx.isLoggedIn) {
      formData = {
        subject: formValue.subject,
        message: formValue.message,
        user_id: authCtx.userData?.id,
        name: authCtx.userData?.name,
        email: authCtx.userData?.email,
        mobile_no: authCtx.userData?.mobile_no,
      };
    } else {
      formData = {
        subject: formValue.subject,
        message: formValue.message,
        name: formValue.name,
        email: formValue.email,
        mobile_no: formatPhoneNumber(formValue.mobile_no).base,
      };
    }
    await api
      .post("api/contact-us/send-inquiry", formData, {
        headers: {
          Authorization: `Bearer ${authCtx.userData?.token}`,
        },
      })
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          Swal.fire(response.data.message, "", "success");
          themeCtx.contactPopupClose();
          reset();
        } else {
          Swal.fire("Try Again!", response.data.message, "info");
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          authCtx.logout();
        } else {
          Swal.fire(
            "Opps!",
            "Sorry we are not able to complete your request at this time. Please try again later.",
            "error"
          );
        }
      });
  };

  return (
    <div
      className={`dashboard-popup get-help-popup contact-popup ${
        themeCtx.isContactPopupShow ? "show" : ""
      }`}>
      <div className="top-employee-popup dashboard-popup-inner">
        <button
          type="button"
          className="dashboard-popup-close"
          onClick={() => themeCtx.contactPopupClose()}>
          <i className="icon icon-close"></i>
        </button>
        <div className="contactMian">
          <div className="row flex-row-reverse contactrow">
            <div className="col-sm-5 contactcol">
              <div className="contactimg">
                <img src={contactImg} alt="" />
              </div>
            </div>
            <div className="col-sm-7 contactcol">
              <div className="secHead">
                <h4 className="secTitle">Contact Us</h4>
                <p>
                  Any questions, remarks or simply a message, we will be happy
                  to get back to you.
                </p>
                <button
                  onClick={() => themeCtx.getHelpPopupOpen()}
                  className="btn btn-pink">
                  FAQ
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="contactinfoMian">
          <div className="row flex-row-reverse contactinforow">
            <div className="col-sm-7 contactinfocol">
              <div className="contact-inner">
                <div className="secHead">
                  <h4 className="secTitle size24">Send Us A Message</h4>
                </div>
                <div className="contact-form">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="login-form-inner">
                      <div className="row">
                        {!authCtx.isLoggedIn && (
                          <>
                            <div className="col-sm-12">
                              <div className="form-group">
                                <label className="form-label">Name</label>
                                <div className="position-relative">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter name here"
                                    {...register("name")}
                                    maxLength={42}
                                  />
                                  {errors.name?.message != undefined && (
                                    <span className="form-error">
                                      {errors.name?.message}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-12">
                              <div className="form-group">
                                <label className="form-label">Email</label>
                                <div className="position-relative">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter email here"
                                    {...register("email")}
                                    maxLength={42}
                                  />
                                  {errors.email?.message != undefined && (
                                    <span className="form-error">
                                      {errors.email?.message}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-12">
                              <div className="form-group">
                                <label className="form-label">
                                  Phone number
                                </label>
                                <div className="position-relative">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter phone number"
                                    {...register("mobile_no")}
                                    maxLength={12}
                                  />
                                  {errors.mobile_no?.message != undefined && (
                                    <span className="form-error">
                                      {errors.mobile_no?.message}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                        <div className="col-sm-12">
                          <div className="form-group">
                            <label className="form-label">Subject</label>
                            <div className="position-relative">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter title here"
                                {...register("subject")}
                                maxLength={42}
                              />
                              {errors.subject?.message != undefined && (
                                <span className="form-error">
                                  {errors.subject?.message}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div className="form-group">
                            <label className="form-label">Your Message</label>
                            <div className="position-relative">
                              <textarea
                                className="form-control textareaField"
                                placeholder="Write something here"
                                {...register("message")}
                                maxLength={180}></textarea>
                              {errors.message?.message != undefined && (
                                <span className="form-error">
                                  {errors.message?.message}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <button
                            type="submit"
                            className="btn btn-pink"
                            disabled={loading}>
                            Send Message
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-sm-5 contactinfocol">
              <div className="contactdetails">
                <div className="secHead">
                  <h4 className="secTitle size24">Contact Information</h4>
                  {/*<p>Reach us on social media or call us anytime.</p>*/}
                </div>
                <div className="continfo">
                  <ul>
                    {responseData?.email != "" && (
                      <li>
                        <a
                          className="infodetail"
                          href={`mailto:${responseData?.email}`}>
                          <i className="icon icon-email"></i>{" "}
                          {responseData?.email}
                        </a>
                      </li>
                    )}
                    {responseData?.email_1 != "" && (
                      <li>
                        <a
                          className="infodetail"
                          href={`mailto:${responseData?.email_1}`}>
                          <i className="icon icon-email"></i>{" "}
                          {responseData?.email_1}
                        </a>
                      </li>
                    )}
                    {/*responseData?.phone != "" && (
                      <li>
                        <a
                          className="infodetail"
                          href={`tel:${responseData?.phone}`}
                        >
                          <i className="icon icon-call"></i>{" "}
                          {formatPhoneNumber(responseData?.phone).formatted}
                        </a>
                      </li>
                    )*/}
                    {responseData?.phone != "" && (
                      <li>
                        <span className="infodetail">
                          <i className="icon icon-call"></i>{" "}
                          {responseData?.phone}
                        </span>
                      </li>
                    )}
                    {responseData?.address != "" && (
                      <li>
                        <p className="infodetail">
                          <i className="icon icon-map"></i>{" "}
                          {responseData?.address}
                        </p>
                      </li>
                    )}
                  </ul>
                </div>
                <div className="socialMeadia">
                  {responseData?.instagram != "" && (
                    <a
                      className="instagram"
                      target="_blank"
                      href={responseData?.instagram}>
                      <i className="icon icon-instagram"></i>
                    </a>
                  )}
                  {responseData?.twitter != "" && (
                    <a
                      className="twitter"
                      target="_blank"
                      href={responseData?.twitter}>
                      <i className="icon icon-twitter"></i>
                    </a>
                  )}
                  {responseData?.youtube != "" && (
                    <a
                      className="youtube"
                      target="_blank"
                      href={responseData?.youtube}>
                      <i className="icon icon-youtube"></i>
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
