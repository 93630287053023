import Swal from "sweetalert2";

export default Swal.mixin({
  background: "#fff",
  color: "#212121",
  customClass: {
    closeButton: "btn btn-outline-secondary",
    confirmButton: "btn btn-pink",
    denyButton: "btn btn-outline-secondary",
    cancelButton: "btn btn-outline-secondary",
  },
  confirmButtonText: "Okay",
  buttonsStyling: false,
});
