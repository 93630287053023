import React, { useContext, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import routes from "../../constants/routesConstants";
import logoImg from "../../assets/img/logo.svg";
import logoShape from "../../assets/img/logo-shape.svg";
import ThemeContext from "../../context/themeContext";
import AuthContext from "../../context/authContext";

const NavbarTwo = () => {
  const themeCtx = useContext(ThemeContext);
  const authCtx = useContext(AuthContext);
  const location = useLocation();
  useEffect(() => {
    if (location.hash === "#contact") {
      themeCtx.contactPopupOpen();
    } else if (location.hash === "#gethelp") {
      themeCtx.getHelpPopupOpen();
    }
  }, [location]);
  return (
    <header className="header">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col">
            <div className="header-logo">
              <div
                className={`menuIcon ${themeCtx.isSidebarOpen ? "show" : ""}`}
                onClick={() => themeCtx.toggleSidebar()}>
                <span></span>
              </div>
              <Link to={routes.Dashboard}>
                <picture>
                  <source media="(max-width:992px)" srcSet={logoShape} />
                  <img src={logoImg} alt="" />
                </picture>
              </Link>
            </div>
          </div>
          <div className="col-auto d-none d-lg-inline-block">
            <div className="header-links">
              <ul>
                <li onClick={() => themeCtx.getHelpPopupOpen()}>Get Help</li>
                <li onClick={() => themeCtx.contactPopupOpen()}>Contact Us</li>
              </ul>
            </div>
          </div>
          <div className="col-auto">
            <div className="header-buttons">
              <Link
                to={authCtx.isLoggedIn ? routes.Dashboard : routes.Login}
                className="btn btn-outline-secondary">
                {authCtx.isLoggedIn ? "Dashboard" : "Login"}
              </Link>
              <Link to={`${routes.ScheduleDemo}`} className="btn btn-pink">
                Schedule a Demo
              </Link>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default NavbarTwo;
