import React, { useContext, useEffect, useState } from "react";
import _ from "lodash";
import api from "../../api";
import AuthContext from "../../context/authContext";
import Swal from "../Swal";
import { useNavigate } from "react-router-dom";
import routes from "../../constants/routesConstants";

const NotificationsPermissions = ({ businessId }) => {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (
      authCtx.userData?.user_type === 2 ||
      authCtx.userData?.user_type === 4 ||
      (businessId === undefined && authCtx.userData?.user_type === 3)
    ) {
      navigate(routes.Dashboard);
    }
  }, []);

  useEffect(() => {
    getNotifications();
  }, [businessId]);

  const getNotifications = () => {
    let formData = {
      business_id: authCtx.userData?.business_id,
    };
    if (businessId !== undefined) {
      formData = {
        ...formData,
        business_id: businessId,
      };
    }
    api
      .post("api/notifications/get", formData, {
        headers: {
          Authorization: `Bearer ${authCtx.userData?.token}`,
        },
      })
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          setNotifications(response.data.data);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          authCtx.logout();
        } else {
          Swal.fire(
            "Opps!",
            "Sorry we are not able to complete your request at this time. Please try again later.",
            "error"
          );
        }
      });
  };

  const onNotificationCheck = (e, notificationIdx, roleIdx) => {
    let rawNotifications = notifications;
    rawNotifications.map((element, nidx) => {
      if (nidx === notificationIdx) {
        element.roles.map((element, ridx) => {
          if (ridx === roleIdx) {
            if (e.target.checked) {
              element.notification_sent = "Yes";
            } else {
              element.notification_sent = "No";
            }
          }
        });
      }
    });
    setNotifications(rawNotifications);
  };

  const onNotificationSave = async () => {
    let formData = {
      user_id:
        businessId !== undefined ? businessId : authCtx.userData?.business_id,
      data: notifications,
    };
    await api
      .post("api/notifications/save", formData, {
        headers: {
          Authorization: `Bearer ${authCtx.userData?.token}`,
        },
      })
      .then((response) => {
        if (response.data.status) {
          Swal.fire(response.data.message, "", "success");
        } else {
          Swal.fire("Try Again!", response.data.message, "info");
        }
      })
      .catch((error) => {
        Swal.fire(
          "Opps!",
          "Sorry we are not able to complete your request at this time. Please try again later.",
          "error"
        );
      });
  };

  return (
    <>
      <div className="dashboard-count-filter dashboard-appraisals mb-24">
        <div className="row align-items-center">
          <div className="col">
            <h2 className="page-title">Notification Preference</h2>
            <p className="page-dec">
              In total there is <b>{notifications?.length}</b> types
            </p>
          </div>
          <div className="col-auto">
            <div className="appraisal-head-buttons">
              <button
                type="button"
                className="btn btn-outline-primary d-inline-flex align-items-center"
                onClick={() => onNotificationSave()}>
                <i className="icon icon-save"></i> Update
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="dashboard-latest-appraisals">
        <div className="dashboard-card">
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>Types</th>
                  {notifications[0]?.roles?.map((role, roleIdx) => (
                    <th className="text-center" key={roleIdx}>
                      {role?.role_name}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {notifications?.length > 0 ? (
                  notifications.map((notification, notificationIdx) => (
                    <tr key={notificationIdx}>
                      <td>{notification?.title}</td>
                      {notification?.roles?.map((role, roleIdx) => (
                        <td className="text-center" key={roleIdx}>
                          <label className="customCheckbox">
                            <input
                              type="checkbox"
                              onChange={(e) =>
                                onNotificationCheck(e, notificationIdx, roleIdx)
                              }
                              defaultChecked={role.notification_sent === "Yes"}
                            />
                            <span className="lable-text"></span>
                          </label>
                        </td>
                      ))}
                    </tr>
                  ))
                ) : (
                  <tr className="text-start text-sm-center">
                    <td colSpan={6}>
                      {loading ? "Loading..." : "No Types Found."}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotificationsPermissions;
