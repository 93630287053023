import React, { useContext, useEffect, useState } from "react";
import vehicleImg from "../../assets/img/vehicle.jpg";
import moment from "moment";
import { useNavigate } from "react-router";
import api from "../../api";
import useDebounce from "../../hook/useDebounce";
import AuthContext from "../../context/authContext";
import userImg from "../../assets/img/user.jpg";
import ReactPaginate from "react-paginate";
import { Link, useSearchParams } from "react-router-dom";
import Swal from "../Swal";
import _ from "lodash";
import DateRangePicker from "@wojtekmaj/react-daterange-picker/dist/DateRangePicker";
import { formatCurrency } from "../../utils/formats";
import BusinessContext from "../../context/businessContext";
import {
  APPRAISALS_ALL_LIST,
  APPRAISALS_DELETE,
  APPRAISALS_EDIT,
  APPRAISALS_VIEW,
} from "../../constants/permissionConstants";
import usePermission from "../../hook/usePermission";
import SendAppraisalForm from "./sendAppraisalForm";

const Appraisals = () => {
  const authCtx = useContext(AuthContext);
  const businessCtx = useContext(BusinessContext);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [responseData, setResponsedata] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(
    searchParams.get("uid") || ""
  );
  const [selectedStatus, setSelectedStatus] = useState(
    searchParams.get("status") || ""
  );
  const [sortField, setSortField] = useState("");
  const [sortType, setSortType] = useState("desc");
  const [pagination, setPagination] = useState({
    total_records: 0,
    total_pages: 0,
    current: 0,
  });
  const [search, setSearch] = useState("");
  const searchDebouncedValue = useDebounce(search, 1000);
  const [selectedDate, setSelectedDate] = useState(null);
  const [filterType, setFilterType] = useState(searchParams.get("type") || "");
  const isAppraisalAllow = usePermission(APPRAISALS_ALL_LIST);
  const isAppraisalEditAllow = usePermission(APPRAISALS_EDIT);
  const isAppraisalDeleteAllow = usePermission(APPRAISALS_DELETE);
  const isAppraisalViewAllow = usePermission(APPRAISALS_VIEW);

  async function fetchAppraisals(formData = {}, page) {
    setLoading(true);
    if (
      authCtx.userData?.user_type == 3 &&
      businessCtx.selectedBusinessId != ""
    ) {
      formData.business_id = businessCtx.selectedBusinessId;
    }
    if (selectedStatus !== "") {
      formData.status = selectedStatus;
    }
    await api
      .post(`api/leads?page=${page}`, formData, {
        headers: {
          Authorization: `Bearer ${authCtx.userData?.token}`,
        },
      })
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          setResponsedata(response.data.data);
          setPagination(response.data.data.paginate);
        } else {
          setResponsedata([]);
          setPagination({
            total_records: 0,
            total_pages: 0,
            current: 0,
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          authCtx.logout();
        } else {
          Swal.fire(
            "Opps!",
            "Sorry we are not able to complete your request at this time. Please try again later.",
            "error"
          );
        }
      });
  }

  function sort(field, currentField, sort, page) {
    let filterData = {};
    if (selectedEmployee != "") {
      filterData = {
        ...filterData,
        employee_user_id: selectedEmployee,
      };
    }
    if (searchDebouncedValue != "") {
      filterData = {
        ...filterData,
        search: searchDebouncedValue,
      };
    }
    if (filterType != "5") {
      filterData = {
        ...filterData,
        duration: filterType,
      };
    } else {
      if (selectedDate != null) {
        filterData = {
          ...filterData,
          duration: "5",
          start_date: moment(selectedDate[0]).format("YYYY-MM-DD"),
          end_date: moment(selectedDate[1]).format("YYYY-MM-DD"),
        };
      }
    }

    if (field == currentField) {
      if (sort == "desc") {
        setSortType("asc");
        filterData = {
          ...filterData,
          field_name: field,
          order_by: "asc",
        };
        fetchAppraisals(filterData, page);
      } else {
        setSortType("desc");
        filterData = {
          ...filterData,
          field_name: field,
          order_by: "desc",
        };
        fetchAppraisals(filterData, page);
      }
    } else {
      setSortField(field);
      setSortType("desc");
      filterData = {
        ...filterData,
        field_name: field,
        order_by: "desc",
      };
      fetchAppraisals(filterData, page);
    }
  }

  useEffect(() => {
    if (
      searchParams.get("type") == 5 &&
      searchParams.get("start_date") !== null &&
      searchParams.get("end_date") !== null
    ) {
      setSelectedDate([
        new Date(searchParams.get("start_date")),
        new Date(searchParams.get("end_date")),
      ]);
    }
  }, []);

  useEffect(() => {
    let filterData = {};
    if (selectedEmployee != "") {
      filterData = {
        ...filterData,
        employee_user_id: selectedEmployee,
      };
    }
    if (searchDebouncedValue != "") {
      filterData = {
        ...filterData,
        search: searchDebouncedValue,
      };
    }
    if (filterType != "5") {
      filterData = {
        ...filterData,
        duration: filterType,
      };
    } else {
      if (selectedDate != null) {
        filterData = {
          ...filterData,
          duration: "5",
          start_date: moment(selectedDate[0]).format("YYYY-MM-DD"),
          end_date: moment(selectedDate[1]).format("YYYY-MM-DD"),
        };
      }
    }

    fetchAppraisals(filterData, 1);
  }, [
    searchDebouncedValue,
    selectedDate,
    filterType,
    selectedEmployee,
    businessCtx.selectedBusinessId,
    selectedStatus,
  ]);

  const onPaginationFetch = (e) => {
    let filterData = {};
    if (sortField != "" && sortType != "") {
      filterData = {
        ...filterData,
        field_name: sortField,
        order_by: sortType,
      };
    }
    if (selectedEmployee != "") {
      filterData = {
        ...filterData,
        employee_user_id: selectedEmployee,
      };
    }
    if (searchDebouncedValue != "") {
      filterData = {
        ...filterData,
        search: searchDebouncedValue,
      };
    }
    if (filterType != "5") {
      filterData = {
        ...filterData,
        duration: filterType,
      };
    } else {
      if (selectedDate != null) {
        filterData = {
          ...filterData,
          duration: "5",
          start_date: moment(selectedDate[0]).format("YYYY-MM-DD"),
          end_date: moment(selectedDate[1]).format("YYYY-MM-DD"),
        };
      }
    }
    fetchAppraisals(filterData, e.selected + 1);
  };

  const onEmployeeChange = async (value) => {
    setSelectedEmployee(value);
  };

  const onRangeChange = (value) => {
    setSelectedDate(value);
  };

  const filterOnChange = (value) => {
    setFilterType(value);
  };

  const deleteHandler = (id) => {
    if (id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          setLoading(true);
          api
            .post(
              "api/leads/delete-lead",
              { lead_id: id },
              {
                headers: {
                  Authorization: `Bearer ${authCtx.userData?.token}`,
                },
              }
            )
            .then((response) => {
              setLoading(false);
              if (response.data.status) {
                fetchAppraisals({}, 1);
                Swal.fire(response.data.message, "", "success");
              } else {
                Swal.fire("Try Again!", response.data.message, "info");
              }
            })
            .catch((error) => {
              setLoading(false);
              Swal.fire(
                "Opps!",
                "Sorry we are not able to complete your request at this time. Please try again later.",
                "error"
              );
            });
        }
      });
    }
    return false;
  };

  useEffect(() => {
    if (isAppraisalAllow) {
      api
        .post(
          `api/business-member`,
          { business_id: businessCtx.selectedBusinessId },
          {
            headers: {
              Authorization: `Bearer ${authCtx.userData?.token}`,
            },
          }
        )
        .then((response) => {
          if (response.data.status) {
            let employeesArray = [];
            if (response.data.data.length > 0) {
              response.data.data.map((element) => {
                employeesArray.push({
                  value: element.id,
                  label: element.name,
                });
              });
              setEmployees(employeesArray);
            } else {
              setEmployees([]);
            }
          } else {
            setEmployees([]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            authCtx.logout();
          } else {
            Swal.fire(
              "Opps!",
              "Sorry we are not able to complete your request at this time. Please try again later.",
              "error"
            );
          }
        });
    }
  }, [businessCtx.selectedBusinessId]);

  const clearHandler = () => {
    setSearch("");
    setSelectedStatus("");
    setSelectedEmployee("");
    setSelectedDate(null);
    setFilterType("");
  };

  const redirectToEdit = (id) => {
    if (id && (isAppraisalEditAllow || isAppraisalViewAllow)) {
      navigate(`/appraisals/view/${id}`);
    }
  };

  const acceptedOfferClick = (id) => {
    if (id) {
      setLoading(true);
      api
        .post(
          "api/leads/accepted-offer-pdf",
          { lead_id: id },
          {
            headers: {
              Authorization: `Bearer ${authCtx.userData?.token}`,
            },
          }
        )
        .then((response) => {
          setLoading(false);
          if (response.data.status) {
            Swal.fire(response.data.message, "", "success");
          } else {
            Swal.fire("Try Again!", response.data.message, "info");
          }
        })
        .catch((error) => {
          setLoading(false);
          Swal.fire(
            "Opps!",
            "Sorry we are not able to complete your request at this time. Please try again later.",
            "error"
          );
        });
    }
  };

  return (
    <>
      <div className="dashboard-count-filter dashboard-appraisals mb-24">
        <div className="row align-items-center">
          <div className="col">
            <h2 className="page-title">Appraisals</h2>
            <p className="page-dec">
              In total there is <b>{pagination?.total_records}</b> appraisals
            </p>
          </div>
        </div>
      </div>
      <div className="dashboard-latest-appraisals with-cursor">
        <div className="dashboard-card">
          <div className="dashboard-card-filter dashboard-count-filter">
            <div className="row align-items-center">
              <div className="col">
                <div className="dashboard-navbar-search dashboardml">
                  <div className="form-group mb-0">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </div>
                </div>
                <div className="dashboard-navbar-month dashboardml">
                  <div className="form-group">
                    <select
                      className="form-select"
                      value={selectedStatus}
                      onChange={(e) => setSelectedStatus(e.target.value)}>
                      <option value="">All Status</option>
                      <option value="0">New</option>
                      <option value="1">Appraised</option>
                      <option value="4">Appraisal Assigned</option>
                      <option value="2">Offer Made</option>
                      <option value="6">Counter Offer</option>
                      <option value="7">Final Offer </option>
                      <option value="3">Accepted Offer</option>
                      <option value="5">No Deal</option>
                    </select>
                  </div>
                </div>

                {isAppraisalAllow && (
                  <div className="dashboard-navbar-month dashboardml">
                    <div className="form-group">
                      <select
                        className="form-select"
                        value={selectedEmployee}
                        onChange={(e) => onEmployeeChange(e.target.value)}>
                        <option value="">Select Employee</option>
                        {_.map(employees, (option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                )}
                <div className="dashboard-navbar-month dashboardml">
                  <div className="form-group">
                    <select
                      className="form-select"
                      value={filterType}
                      onChange={(e) => filterOnChange(e.target.value)}>
                      <option value="">All</option>
                      <option value="1">This day</option>
                      <option value="2">This week</option>
                      <option value="3">This month</option>
                      <option value="4">This year</option>
                      <option value="5">Range</option>
                    </select>
                  </div>
                </div>
                {filterType == 5 && (
                  <div className="dashboard-navbar-month dashboardml">
                    <DateRangePicker
                      onChange={(e) => onRangeChange(e)}
                      value={selectedDate}
                      format="M-d-y"
                      yearPlaceholder="yyyy"
                      monthPlaceholder="mm"
                      dayPlaceholder="dd"
                      calendarIcon={<i className="icon icon-calender"></i>}
                      maxDate={new Date()}
                      clearIcon={null}
                    />
                  </div>
                )}
                <div className="dashboard-navbar-date-filter dashboardml">
                  <button
                    type="button"
                    className="date-filter"
                    onClick={() => clearHandler()}>
                    Clear <i className="icon icon-reset"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="dashboard-latest-apraisals table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>#</th>
                  {authCtx.userData?.user_type == 3 && (
                    <th>
                      Business Name{" "}
                      <span
                        className={`table-sort ${
                          sortField == "businessName" ? sortType : ""
                        }`}
                        onClick={() =>
                          sort(
                            "businessName",
                            sortField,
                            sortType,
                            pagination.current
                          )
                        }></span>
                    </th>
                  )}
                  <th width="25%">
                    Vehicle{" "}
                    <span
                      className={`table-sort ${
                        sortField == "model" ? sortType : ""
                      }`}
                      onClick={() =>
                        sort("model", sortField, sortType, pagination.current)
                      }></span>
                  </th>
                  <th width="10%">
                    Customer{" "}
                    <span
                      className={`table-sort ${
                        sortField == "first_name" ? sortType : ""
                      }`}
                      onClick={() =>
                        sort(
                          "first_name",
                          sortField,
                          sortType,
                          pagination.current
                        )
                      }></span>
                  </th>
                  <th width="11%">
                    Status{" "}
                    <span
                      className={`table-sort ${
                        sortField == "status" ? sortType : ""
                      }`}
                      onClick={() =>
                        sort("status", sortField, sortType, pagination.current)
                      }></span>
                  </th>
                  <th width="10%">
                    Offer{" "}
                    <span
                      className={`table-sort ${
                        sortField == "deals_price" ? sortType : ""
                      }`}
                      onClick={() =>
                        sort(
                          "deals_price",
                          sortField,
                          sortType,
                          pagination.current
                        )
                      }></span>
                  </th>
                  <th width="12%">
                    Submitted Date{" "}
                    <span
                      className={`table-sort ${
                        sortField == "created_at" ? sortType : ""
                      }`}
                      onClick={() =>
                        sort(
                          "created_at",
                          sortField,
                          sortType,
                          pagination.current
                        )
                      }></span>
                  </th>
                  <th width="10%">
                    Assign To{" "}
                    {/* <span
                      className={`table-sort ${
                        sortField == "assign_name" ? sortType : ""
                      }`}
                      onClick={() =>
                        sort(
                          "assign_name",
                          sortField,
                          sortType,
                          pagination.current
                        )
                      }></span> */}
                  </th>
                  <th width="15%">
                    Added by{" "}
                    {/* <span
                      className={`table-sort ${
                        sortField == "name" ? sortType : ""
                      }`}
                      onClick={() =>
                        sort("name", sortField, sortType, pagination.current)
                      }></span> */}
                  </th>
                  {(isAppraisalEditAllow ||
                    isAppraisalDeleteAllow ||
                    isAppraisalViewAllow) && <th width="10%">Action</th>}
                </tr>
              </thead>
              <tbody>
                {responseData?.lead && responseData?.lead.length != 0 ? (
                  responseData?.lead.map((data, i) => (
                    <tr key={i}>
                      <td width="3%" onClick={() => redirectToEdit(data?.id)}>
                        {authCtx.userData?.user_type == 3
                          ? data?.id
                          : data?.sequence_id}
                      </td>
                      {authCtx.userData?.user_type == 3 && (
                        <td onClick={() => redirectToEdit(data?.id)}>
                          {data?.businessName}
                        </td>
                      )}
                      <td onClick={() => redirectToEdit(data?.id)}>
                        <div className="table-vehicle-image">
                          <img
                            src={
                              data?.vehicleImage != ""
                                ? data?.vehicleImage
                                : vehicleImg
                            }
                            alt=""
                          />
                        </div>
                        {data?.year} {data?.make} {data?.model}
                      </td>
                      <td onClick={() => redirectToEdit(data?.id)}>
                        {data?.first_name} {data?.last_name}
                      </td>
                      <td onClick={() => redirectToEdit(data?.id)}>
                        {data?.status == 0 && (
                          <span className="status-new">New</span>
                        )}
                        {data?.status == 1 && (
                          <span className="status-working">Appraised</span>
                        )}
                        {data?.status == 2 && (
                          <span className="status-offermade">Offer Made</span>
                        )}
                        {data?.status == 3 && (
                          <span className="status-accepted-purchase">
                            Accepted Offer
                          </span>
                        )}
                        {data?.status == 4 && (
                          <span className="status-appraised">Appraisal Assigned</span>
                        )}
                        {data?.status == 5 && (
                          <span className="status-no-deal">No Deal</span>
                        )}
                        {data?.status == 6 && (
                          <span className="status-counteroffer">
                            Counter Offer
                          </span>
                        )}
                        {data?.status == 7 && (
                          <span className="status-finaloffer">
                            Final Offer
                          </span>
                        )}
                      </td>
                      <td onClick={() => redirectToEdit(data?.id)}>
                        {data?.deals_price != null
                          ? formatCurrency(data?.deals_price)
                          : "No Offer"}
                      </td>
                      <td onClick={() => redirectToEdit(data?.id)}>
                        {moment.utc(data?.created_at).format("MMMM D, YYYY")}
                      </td>
                      <td
                        className="mxw-150"
                        onClick={() => redirectToEdit(data?.id)}>
                        {data?.assign_name !== null && data?.assign_name !== ""
                          ? data?.assign_name
                          : "Not Assigned"}
                      </td>
                      <td
                        className="mxw-150"
                        onClick={() => redirectToEdit(data?.id)}>
                        <div className="table-user-image">
                          <img
                            src={
                              data?.userImage != "" ? data?.userImage : userImg
                            }
                            alt=""
                          />
                        </div>
                        <span className="d-none d-md-inline">
                          {data?.userName}
                        </span>
                      </td>
                      {(isAppraisalEditAllow ||
                        isAppraisalDeleteAllow ||
                        isAppraisalViewAllow) && (
                        <td>
                          {isAppraisalDeleteAllow && data?.status != 3 && (
                            <button
                              type="button"
                              className="btn-icon"
                              onClick={() => deleteHandler(data?.id)}>
                              <i className="icon icon-delete"></i>
                            </button>
                          )}
                          {(isAppraisalEditAllow || isAppraisalViewAllow) && (
                            <Link
                              to={`/appraisals/view/${data?.id}`}
                              className="btn-icon">
                              {isAppraisalViewAllow && !isAppraisalEditAllow ? (
                                <i className="icon icon-eye-open"></i>
                              ) : (
                                <i className="icon icon-edit"></i>
                              )}
                            </Link>
                          )}
                          {data?.status == 3 && (
                            <button
                              type="button"
                              className="btn-icon"
                              onClick={() => acceptedOfferClick(data?.id)}>
                              <i className="icon icon-pdf"></i>
                            </button>
                          )}
                        </td>
                      )}
                    </tr>
                  ))
                ) : (
                  <tr className="text-start text-sm-center">
                    <td colSpan={10}>
                      {loading ? "Loading..." : "No Appraisals Found."}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {pagination?.total_pages != undefined && pagination?.total_pages != 1 && (
        <nav className="navigation pagination">
          <ReactPaginate
            breakLabel="..."
            onPageChange={onPaginationFetch}
            pageRangeDisplayed={2}
            forcePage={parseInt(pagination?.current - 1)}
            pageCount={pagination?.total_pages}
            renderOnZeroPageCount={null}
            containerClassName="nav-links"
            activeLinkClassName="current"
            pageLinkClassName="page-numbers"
            previousLinkClassName="prev page-numbers"
            previousLabel={<i className="icon icon-arrow-left"></i>}
            nextLinkClassName="next page-numbers"
            nextLabel={<i className="icon icon-arrow-right"></i>}
          />
        </nav>
      )}
    </>
  );
};

export default Appraisals;
