import React, { useContext, useEffect, useState, useCallback, useRef } from "react";
import userImg from "../../assets/img/user.jpg";
import { useNavigate, useParams } from "react-router-dom";
import AuthContext from "../../context/authContext";
import Swal from "../Swal";
import api from "../../api";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { formatPhoneNumber } from "../../utils/formats";
import routes from "../../constants/routesConstants";
import {
  EMPLOYEES_BONUS_PREFERENCE,
  EMPLOYEES_EDIT,
  EMPLOYEES_NOTIFICATION_PREFERENCE,
  EMPLOYEES_ROLE_PERMISSIONS,
} from "../../constants/permissionConstants";
import usePermission from "../../hook/usePermission";
import _ from "lodash";
import { CaretRightOutlined } from "@ant-design/icons";
import { Tree } from "antd";
import debounce from "lodash.debounce";

const validationSchema = yup.object().shape({
  name: yup.string().max(42).required("Name is required."),
  email: yup.string().email("Email is invalid.").max(42).required("Email is required."),
  mobile_no: yup
    .string()
    .required("Phone number is required.")
    .min(10, "Phone number is to short.")
    .max(12, "Phone number is to long."),
  password: yup.string().max(42),
  confirm_password: yup
    .string()
    .max(42)
    .oneOf([yup.ref("password"), null], "Passwords must match."),
  vauto_entity_id: yup.string().max(100),
  vauto_username: yup.string().max(100),
});

const EmployeesView = () => {
  const authCtx = useContext(AuthContext);
  const [responseData, setResponseData] = useState({});
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isUserActive, setIsUserActive] = useState(false);
  const [previewImage, setPreviewImage] = useState({ imageUrl: "" });
  const [emailNotification, setEmailNotification] = useState(false);
  const [smsNotification, setSmsNotification] = useState(false);
  const [alertNotification, setAlertNotification] = useState(false);
  const [treeData, setModuleData] = useState([]);
  const [SelectedModule, setSelectedModule] = useState([]);
  const [checkedModule, checkModuleData] = useState([]);
  const [roles, setRoles] = useState([]);
  const [roleId, setRoleId] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const isEmployeeBonusPreference = usePermission(EMPLOYEES_BONUS_PREFERENCE);
  const isEmployeeEdit = usePermission(EMPLOYEES_EDIT);
  const isEmployeeNotificationPreference = usePermission(
    EMPLOYEES_NOTIFICATION_PREFERENCE
  );


  const navigate = useNavigate();
  const { id } = useParams();

  let tempSelectedModule = [];
  let treeArray = [];

  useEffect(() => {
    if (!isEmployeeEdit) {
      navigate(routes.Dashboard);
    }
  }, []);

  useEffect(() => {
    getUserDetails();
  }, []);

  const getUserDetails = async () => {
    await api
      .post(
        "api/users/get-user",
        { user_id: id },
        {
          headers: {
            Authorization: `Bearer ${authCtx.userData?.token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.status) {
          getActiveRoles(response.data.data?.business_id);
          setResponseData(response.data.data);
          setValue("name", response.data.data?.name);
          setValue("mobile_no", response.data.data?.user_type === 0 ? response.data.data?.business_phone : response.data.data?.mobile_no);
          setValue("email", response.data.data?.email);
          setValue("business_address", response.data.data?.business_address);
          if (response.data.data?.user_type === 0) {
            setValue("vauto_entity_id", response.data.data?.vauto_entity_id);
            setValue("vauto_username", response.data.data?.vauto_username);
          }
          setRoleId(response.data.data?.role_id);
          if (response.data.data?.status == 1) {
            setIsUserActive(true);
          }
          if (response.data.data?.email_notification == 1) {
            setEmailNotification(true);
          }
          if (response.data.data?.sms_notification == 1) {
            setSmsNotification(true);
          }
          if (response.data.data?.alert_notification == 1) {
            setAlertNotification(true);
          }
          
          if (
            response.data.data?.appraisal_bonus != "0" &&
            response.data.data?.appraisal_bonus != null
          ) {
            setValue("appraisal_bonus", response.data.data?.appraisal_bonus);
          }
          if (response.data.data?.vehicle_appraisal_bonus_type == "0") {
          } else if (response.data.data?.vehicle_appraisal_bonus_type == "1") {
            setValue(
              "vehicle_appraisal_bonus_percentage_value",
              response.data.data?.vehicle_appraisal_bonus_value
            );
          } else if (response.data.data?.vehicle_appraisal_bonus_type == "2") {
            setValue(
              "vehicle_appraisal_bonus_dollar_value",
              response.data.data?.vehicle_appraisal_bonus_value
            );
          }
          if (response?.data?.data?.per_accepted_appraisal_manager !== null) {
            setValue(
              "per_accepted_appraisal_manager",
              response.data.data?.per_accepted_appraisal_manager
            );
          }
        }
      })
      .catch((error) => {
        if (error.response.status == 401) {
          authCtx.logout();
        } else {
          Swal.fire(
            "Opps!",
            "Sorry we are not able to complete your request at this time. Please try again later.",
            "error"
          );
        }
      });
  };

  const getActiveRoles = async (business_id = "") => {
    await api
      .post(
        `api/role/get-active-roles`,
        { business_id: business_id },
        {
          headers: {
            Authorization: `Bearer ${authCtx.userData?.token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.status) {
          let rolesArray = [];
          if (response.data.data.length > 0) {
            response.data.data.map((element) => {
              rolesArray.push({
                value: element.id,
                label: element.role_name,
              });
            });
            setRoles(rolesArray);
          } else {
            setRoles([]);
          }
        } else {
          setRoles([]);
        }
      })
      .catch((error) => {
        if (error.response?.status == 401) {
          authCtx.logout();
        }
      });
  };
  const updateUserStatus = async () => {
    await api
      .post(
        "api/users/update-status",
        { user_id: id, status: !isUserActive ? "1" : "0" },
        {
          headers: {
            Authorization: `Bearer ${authCtx.userData?.token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.status) {
          Swal.fire(response.data.message, "", "success");
          setIsUserActive(!isUserActive);
        } else {
          Swal.fire("Try Again!", response.data.message, "info");
        }
      })
      .catch((error) => {
        if (error.response.status == 401) {
          authCtx.logout();
        } else {
          Swal.fire(
            "Opps!",
            "Sorry we are not able to complete your request at this time. Please try again later.",
            "error"
          );
        }
      });
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    resetField,
    trigger,
  } = useForm({
    resolver: yupResolver(validationSchema)
  });

  const mobile_no = watch("mobile_no");
  useEffect(() => {
    setValue("mobile_no", formatPhoneNumber(mobile_no).formatted);
  }, [mobile_no]);

  const uploadedImage = watch("image");
  useEffect(() => {
    if (uploadedImage != undefined && uploadedImage.length != 0) {
      setPreviewImage({ imageUrl: URL.createObjectURL(uploadedImage[0]) });
    }
  }, [uploadedImage]);

  const [formSubmitted, setFormSubmitted] = useState(false);
  const watchFields = watch();
  const skipFirstRender = useRef(1);
  useEffect(() => {
    if (skipFirstRender.current) {
      skipFirstRender.current = skipFirstRender.current + 1;
    }
    if (skipFirstRender.current > 5) {
      const afterChangeTimer = setTimeout(async () => {
        const isAllowed = await trigger([
          "name",
          "email",
          "mobile_no",
          "password",
          "confirm_password",
          "vauto_entity_id",
          "vauto_username",
        ]);
        if (isAllowed & !formSubmitted) {
          setLoading(true);
          setFormSubmitted(true);
          let formData = new FormData();
          formData.append("user_id", id);
          formData.append("name", watchFields.name);
          formData.append("email", watchFields.email);
          formData.append(`${responseData?.user_type === 0 ? "business_phone" : "mobile_no"}`, formatPhoneNumber(watchFields.mobile_no).base);
          if (
            watchFields.password != undefined &&
            watchFields.confirm_password != undefined
          ) {
            formData.append("password", watchFields.password);
            formData.append("confirm_password", watchFields.confirm_password);
          }
          formData.append("alert_notification", alertNotification ? 1 : 0);
          formData.append("email_notification", emailNotification ? 1 : 0);
          formData.append("sms_notification", smsNotification ? 1 : 0);
          console.log(smsNotification);
          if (
            watchFields.appraisal_bonus !== undefined &&
            watchFields.appraisal_bonus !== "0"
          ) {
            formData.append("appraisal_bonus", watchFields.appraisal_bonus);
          } else {
            formData.append("appraisal_bonus", "0");
          }
          if (
            watchFields.vehicle_appraisal_bonus_dollar_value !== undefined &&
            watchFields.vehicle_appraisal_bonus_dollar_value !== ""
          ) {
            formData.append("vehicle_appraisal_bonus_type", "2");
            formData.append(
              "vehicle_appraisal_bonus_value",
              watchFields.vehicle_appraisal_bonus_dollar_value
            );
          } else {
            formData.append("vehicle_appraisal_bonus_value", 0);
            formData.append("vehicle_appraisal_bonus_type", "0");
          }
          if (watchFields.image !== undefined && watchFields.image !== null) {
            formData.append("image", watchFields.image[0]);
          }
          if (responseData?.user_type !== 0 && roleId !== "") {
            formData.append("role_id", roleId);
            checkedModule.forEach((item) => {
              formData.append("permission[]", item);
            });
          }
          if (responseData?.user_type === 0) {
            formData.append("business_address", watchFields.business_address);
            formData.append("vauto_entity_id", watchFields.vauto_entity_id);
            formData.append("vauto_username", watchFields.vauto_username);
          }
          if (
            watchFields.per_accepted_appraisal_manager !== undefined &&
            watchFields.per_accepted_appraisal_manager !== ""
          ) {
            formData.append(
              "per_accepted_appraisal_manager",
              watchFields.per_accepted_appraisal_manager
            );
          }
          await api
            .post("api/users/update-user", formData, {
              headers: {
                "Content-type": "multipart/form-data",
                Authorization: `Bearer ${authCtx.userData?.token}`,
              },
            })
            .then((response) => {
              setLoading(false);
              if (response.data.status) {
                Swal.fire(response.data.message, "", "success");
              } else {
                Swal.fire("Try Again!", response.data.message, "info");
              }
              setFormSubmitted(false);
            })
            .catch((error) => {
              setLoading(false);
              if (error.response.status == 401) {
                authCtx.logout();
              } else {
                Swal.fire(
                  "Opps!",
                  "Sorry we are not able to complete your request at this time. Please try again later.",
                  "error"
                );
              }
            });
            setIsEdit(false);
        }
      }, 1500);
      return () => {
        clearTimeout(afterChangeTimer);
      };
    }
  }, [
    watchFields.name,
    watchFields.email,
    watchFields.mobile_no,
    watchFields.password,
    watchFields.confirm_password,
    watchFields.appraisal_bonus,
    alertNotification,
    emailNotification,
    smsNotification,
    watchFields.vehicle_appraisal_bonus_dollar_value,
    watchFields.business_address,
    watchFields.vauto_entity_id,
    watchFields.vauto_username,
    watchFields.per_accepted_appraisal_manager,
    roleId,
  ]);
console.log(formSubmitted,'789');
  const onSubmit = async (formValue) => {
    setLoading(true);
    let formData = new FormData();
    formData.append("user_id", id);
    formData.append("name", formValue.name);
    formData.append("email", formValue.email);
    formData.append(`${responseData?.user_type === 0 ? "business_phone" : "mobile_no"}`, formatPhoneNumber(formValue.mobile_no).base);
    if (
      formValue.password != undefined &&
      formValue.confirm_password != undefined
    ) {
      formData.append("password", formValue.password);
      formData.append("confirm_password", formValue.confirm_password);
    }
    formData.append("alert_notification", alertNotification ? 1 : 0);
    formData.append("email_notification", emailNotification ? 1 : 0);
    formData.append("sms_notification", smsNotification ? 1 : 0);
    if (
      formValue.appraisal_bonus !== undefined &&
      formValue.appraisal_bonus !== "0"
    ) {
      formData.append("appraisal_bonus", formValue.appraisal_bonus);
    } else {
      formData.append("appraisal_bonus", "0");
    }
    if (
      formValue.vehicle_appraisal_bonus_dollar_value !== undefined &&
      formValue.vehicle_appraisal_bonus_dollar_value !== ""
    ) {
      formData.append("vehicle_appraisal_bonus_type", "2");
      formData.append(
        "vehicle_appraisal_bonus_value",
        formValue.vehicle_appraisal_bonus_dollar_value
      );
    } else {
      formData.append("vehicle_appraisal_bonus_value", 0);
      formData.append("vehicle_appraisal_bonus_type", "0");
    }
    if (formValue.image !== undefined && formValue.image !== null) {
      formData.append("image", formValue.image[0]);
    }
    if (responseData?.user_type !== 0 && roleId !== "") {
      formData.append("role_id", roleId);
      checkedModule.forEach((item) => {
        formData.append("permission[]", item);
      });
    }
    if (responseData?.user_type === 0) {
      formData.append("business_address", formValue.business_address);
      formData.append("vauto_entity_id", formValue.vauto_entity_id);
      formData.append("vauto_username", formValue.vauto_username);
    }
    if (
      formValue.per_accepted_appraisal_manager !== undefined &&
      formValue.per_accepted_appraisal_manager !== ""
    ) {
      formData.append(
        "per_accepted_appraisal_manager",
        formValue.per_accepted_appraisal_manager
      );
    }
    await api
      .post("api/users/update-user", formData, {
        headers: {
          "Content-type": "multipart/form-data",
          Authorization: `Bearer ${authCtx.userData?.token}`,
        },
      })
      .then((response) => {
        setLoading(false);
        setFormSubmitted(true);
        if (response.data.status) {
          Swal.fire(response.data.message, "", "success");
          setIsEdit(false);
          getUserDetails();
        } else {
          Swal.fire("Try Again!", response.data.message, "info");
        }

        setTimeout(function(){
          setFormSubmitted(false);
        },1500)
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          authCtx.logout();
        } else {
          Swal.fire(
            "Opps!",
            "Sorry we are not able to complete your request at this time. Please try again later.",
            "error"
          );
        }
      });
  };

  const onChangeHandler = (type) => {
    if (type == "email_notification") {
      setEmailNotification(!emailNotification);
    }
    if (type == "sms_notification") {
      setSmsNotification(!smsNotification);
    }
    if (type == "alert_notification") {
      setAlertNotification(!alertNotification);
    }
  };

  useEffect(() => {
    if (id != undefined) {
      api
        .post(
          `api/users/get-user-permission`,
          { user_id: id },
          {
            headers: { Authorization: `Bearer ${authCtx.userData?.token}` },
          }
        )
        .then((response) => {
          if (response.data.status) {
            tempSelectedModule = [];
            treeArray = [];
            tempSelectedModule = response.data.data;
            api
              .post(
                `api/modules`,
                {},
                {
                  headers: {
                    Authorization: `Bearer ${authCtx.userData.token}`,
                  },
                }
              )
              .then((response) => {
                setModuleData(response.data.data);
                treeArray = response.data.data;
                setSelectedPermission();
              })
              .catch((error) => {
                if (error.response?.status == 401) {
                  authCtx.logout();
                }
              });
          }
        })
        .catch((error) => {
          if (error.response?.status == 401) {
            authCtx.logout();
          }
        });
    }
  }, [id]);

  const onCheck = (checkedKeys, info) => {
    setSelectedModule(checkedKeys);
    const dataArray = [];
    const checkNodeAarray = info.checkedNodes;
    checkNodeAarray.forEach((item) => {
      if (item.module_id) {
        dataArray.push(`${item.module_id}-${item.id}`);
      }
    });
    checkModuleData(dataArray);
  };

  const onRoleChange = async (role_id) => {
    setRoleId(role_id);
    if (responseData?.role_id == role_id) {
      tempSelectedModule = [];
      treeArray = [];
      api
        .post(
          `api/users/get-user-permission`,
          { user_id: id },
          {
            headers: { Authorization: `Bearer ${authCtx.userData?.token}` },
          }
        )
        .then((response) => {
          if (response.data.status) {
            tempSelectedModule = response.data.data;
            api
              .post(
                `api/modules`,
                {},
                {
                  headers: {
                    Authorization: `Bearer ${authCtx.userData.token}`,
                  },
                }
              )
              .then((response) => {
                setModuleData(response.data.data);
                treeArray = response.data.data;
                setSelectedPermission();
              })
              .catch((error) => {
                if (error.response?.status == 401) {
                  authCtx.logout();
                }
              });
          }
        })
        .catch((error) => {
          if (error.response?.status == 401) {
            authCtx.logout();
          }
        });
    } else if (role_id !== "") {
      api
        .get(`api/role/get-role/${role_id}`, {
          headers: { Authorization: `Bearer ${authCtx.userData?.token}` },
        })
        .then((response) => {
          if (response.data.status) {
            tempSelectedModule = response.data.data.permission;
            api
              .post(
                `api/modules`,
                {},
                {
                  headers: {
                    Authorization: `Bearer ${authCtx.userData.token}`,
                  },
                }
              )
              .then((response) => {
                setModuleData(response.data.data);
                treeArray = response.data.data;
                setSelectedPermission();
              })
              .catch((error) => {
                if (error.response?.status == 401) {
                  authCtx.logout();
                }
              });
          }
        })
        .catch((error) => {
          if (error.response?.status == 401) {
            authCtx.logout();
          }
        });
    }
  };

  function setSelectedPermission() {
    const dataArray = [];
    const arrayTemp = [];
    tempSelectedModule.forEach((item) => {
      _.find(treeArray, (temp) => {
        if (temp.id == item.module_id) {
          _.find(temp.children, (tempClone) => {
            if (item.permission_id == tempClone.id) {
              dataArray.push(`${item.module_id}-${item.permission_id}`);
              arrayTemp.push(tempClone.key);
            }
          });
        }
      });
    });
    setSelectedModule(arrayTemp);
    checkModuleData(dataArray);
  }

  const onEditCancel = () => {
    setValue("name", watchFields?.name);
    setValue("mobile_no", watchFields?.user_type === 0 ? watchFields?.business_phone : watchFields?.mobile_no);
    setValue("email", watchFields?.email);
    setValue("business_address", watchFields?.business_address);
    setValue("vauto_entity_id", watchFields?.vauto_entity_id);
    setValue("vauto_username", watchFields?.vauto_username);
    resetField("password");
    resetField("password_confirmation");
    setIsEdit(false);
  };

  const showPasswordHandler = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="appraisal-head-wrap mb-34">
          <div className="row">
            <div className="col">
              <div className="appraisal-head-title align-items-start">
                <button
                  type="button"
                  className="btn-icon"
                  onClick={() => navigate(-1)}>
                  <i className="icon icon-back-aero"></i>
                </button>
                <div className="page-head">
                  <h2 className="page-title">Employee Profile</h2>
                  <p className="page-dec">Fill the required informations</p>
                </div>
              </div>
            </div>
            <div className="col-auto">
              <div className="dashboard-switch-button dashboardml">
                <div className="switch-buttonmain">
                  <span className="switch-button-text ffr">Active</span>
                  <div
                    className="switch-button"
                    onClick={() => updateUserStatus()}>
                    <input
                      type="checkbox"
                      hidden="hidden"
                      checked={isUserActive}
                    />
                    <label className="switch"></label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="notification edit-profile my-edit-profile mb-24">
          <div className="manager-details-inner mt-0">
            <div className="row align-items-center">
              <div className="col managercol">
                <div className="manager-details-user">
                  <div className="manager-image">
                    <img
                      src={
                        previewImage.imageUrl
                          ? previewImage.imageUrl
                          : responseData?.image != ""
                            ? responseData?.image
                            : userImg
                      }
                      alt=""
                    />
                    {isEdit && (
                      <>
                        <i className="icon icon-add-picture"></i>
                        <input
                          type="file"
                          accept="image/png, image/jpg, image/jpeg"
                          {...register("image")}
                        />
                      </>
                    )}
                  </div>
                  <div className="manager-info">
                    <h4>{responseData?.name}</h4>
                    <span>
                      {responseData?.user_type == 0 && "Dealer"}
                      {responseData?.user_type == 1 && "Used Car Manager"}
                      {responseData?.user_type == 2 && "Service Advisor"}
                      {responseData?.user_type == 4 && "Sales"}
                    </span>
                  </div>
                </div>
              </div>

              {isEdit ? (
                <div className="col-auto text-end managercol myprofilecol">
                  <button
                    type="button"
                    className="btn btn-outline-secondary"
                    onClick={() => onEditCancel()}>
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="btn btn-outline-primary d-inline-flex align-items-center">
                    <i className="icon icon-save"></i> Save Changes
                  </button>
                </div>
              ) : (
                <div className="col-auto managercol">
                  <button
                    type="button"
                    className="btn btn-outline-secondary"
                    onClick={() => setIsEdit(true)}>
                    <i className="icon icon-edit"></i> Edit Profile
                  </button>
                </div>
              )}
            </div>
          </div>

          <div className="appraisal-form-inner">
            <div className="row">
              <div className="col-md-12">
                <div className={`form-group ${!isEdit ? "disabled" : ""}`}>
                  <label className="col-form-label">Name</label>
                  <input
                    className="form-control"
                    type="text"
                    disabled={!isEdit}
                    {...register("name")}
                    maxLength={42}
                  />
                  {errors.name?.message != undefined && (
                    <span className="form-error">{errors.name?.message}</span>
                  )}
                </div>
              </div>
              <div className="col-md-12">
                <div className={`form-group ${!isEdit ? "disabled" : ""}`}>
                  <label className="col-form-label">Phone</label>
                  <input
                    className="form-control"
                    type="text"
                    disabled={!isEdit}
                    {...register("mobile_no")}
                    maxLength={12}
                  />
                  {errors.mobile_no?.message != undefined && (
                    <span className="form-error">
                      {errors.mobile_no?.message}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-md-12">
                <div className={`form-group ${!isEdit ? "disabled" : ""}`}>
                  <label className="col-form-label">Email</label>
                  <input
                    className="form-control"
                    type="email"
                    disabled={!isEdit}
                    {...register("email")}
                    maxLength={42}
                  />
                </div>
              </div>
              {responseData?.user_type === 0 &&
                <div className="col-md-12">
                  <div className={`form-group ${!isEdit ? "disabled" : ""}`}>
                    <label className="col-form-label">Address</label>
                    <input
                      className="form-control"
                      type="text"
                      disabled={!isEdit}
                      {...register("business_address")}
                      maxLength={150}
                    />
                  </div>
                </div>
              }
              {responseData?.user_type === 0 &&
                <>
                  <div className="col-md-12">
                    <div className={`form-group ${!isEdit ? "disabled" : ""}`}>
                      <label className="col-form-label">VAuto Entity ID</label>
                      <input
                        className="form-control"
                        type="text"
                        disabled={!isEdit}
                        {...register("vauto_entity_id")}
                        maxLength={100}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className={`form-group ${!isEdit ? "disabled" : ""}`}>
                      <label className="col-form-label">VAuto Username</label>
                      <input
                        className="form-control"
                        type="text"
                        disabled={!isEdit}
                        {...register("vauto_username")}
                        maxLength={100}
                      />
                    </div>
                  </div>
                </>
              }
              {isEdit && (
                <>
                  <div className="col-sm-6">
                    <div className="form-group form-group-with-password">
                      <input
                        className="form-control"
                        type={`${showPassword ? "text" : "password"}`}
                        placeholder="Password"
                        disabled={!isEdit}
                        {...register("password")}
                        maxLength={42}
                      />
                      <i
                        className="icon icon-eye"
                        onClick={showPasswordHandler}></i>
                      {errors.password?.message != undefined && (
                        <span className="form-error">
                          {errors.password?.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <input
                        className="form-control"
                        type="password"
                        placeholder="Confirm Password"
                        disabled={!isEdit}
                        {...register("confirm_password")}
                        maxLength={42}
                      />
                      {errors.confirm_password?.message != undefined && (
                        <span className="form-error">
                          {errors.confirm_password?.message}
                        </span>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="notification settings-notification">
          <div className="settings-notificationbox mb-24">
            {(isEmployeeBonusPreference ||
              isEmployeeNotificationPreference ||
              isEmployeeEdit) && (
                <div className="row justify-content-end mb-24">
                  <div className="col-auto text-end managercol myprofilecol">
                    <button
                      type="submit"
                      className="btn btn-outline-primary d-inline-flex align-items-center"
                      disabled={loading}>
                      <i className="icon icon-save"></i> Update Changes
                    </button>
                  </div>
                </div>
              )}
            {responseData?.user_type !== 0 && (
              <>
                <h5 className="notification-Title ffr">
                  Permissions preference
                </h5>
                <div className="appraisal-form-inner">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group mt-0">
                        <label className="col-form-label">Role</label>
                        <div className="position-relative flex-fill">
                          <select
                            className="form-select"
                            {...register("role_id")}
                            value={roleId}
                            onChange={(e) => onRoleChange(e.target.value)}>
                            <option value="">Select Role</option>
                            {_.map(roles, (option) => (
                              <option
                                key={option.value}
                                value={option.value}
                                selected={option.value === roleId}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                          {errors.role_id?.message != undefined && (
                            <span className="form-error">
                              {errors.role_id?.message}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group d-block border-0 mt-2">
                        <label className="form-label">Permissions</label>
                        <Tree
                          showLine={{ showLeafIcon: false }}
                          switcherIcon={<CaretRightOutlined />}
                          onCheck={onCheck}
                          checkable={true}
                          treeData={treeData}
                          checkedKeys={SelectedModule}
                          autoExpandParent={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="settings-notificationbox mb-24">
            {isEmployeeNotificationPreference && (
              <>
                <h5 className="notification-Title ffr">
                  Notification preference
                </h5>
                <div className="dashboard-notification table-responsive">
                  <table className="table" align="center">
                    <thead>
                      <tr>
                        <th width="40%"></th>
                        <th width="10%">Email</th>
                        <th width="11%">SMS</th>
                        <th width="29%">Alert (Notification bell)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Notifications</td>
                        <td>
                          <label className="customCheckbox">
                            <input
                              type="checkbox"
                              {...register("email_notification")}
                              checked={emailNotification}
                              onChange={() =>
                                onChangeHandler("email_notification")
                              }
                            />
                            <span className="lable-text"></span>
                          </label>
                        </td>
                        <td>
                          <label className="customCheckbox">
                            <input
                              type="checkbox"
                              {...register("sms_notification")}
                              checked={smsNotification}
                              onChange={() =>
                                onChangeHandler("sms_notification")
                              }
                            />
                            <span className="lable-text"></span>
                          </label>
                        </td>
                        <td>
                          <label className="customCheckbox">
                            <input
                              type="checkbox"
                              {...register("alert_notification")}
                              checked={alertNotification}
                              onChange={() =>
                                onChangeHandler("alert_notification")
                              }
                              defaultChecked={true}
                            />
                            <span className="lable-text"></span>
                          </label>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </>
            )}
          </div>
          {isEmployeeBonusPreference &&
            responseData?.user_type !== 0 &&
            responseData?.user_type !== 4 && (
              <div className="settings-notificationbox mb-24">
                <h5 className="notification-Title ffr">Spiffs</h5>
                <div className="dashboard-notification table-responsive">
                  <table className="table" align="center">
                    <tbody>
                      {responseData?.user_type === 1 ? (
                        <tr>
                          <td>
                            <div className="input-group">
                              <div className="form-group">
                                <span className="input-group-text">$</span>{" "}
                                <input
                                  className="form-control pl-0"
                                  type="text"
                                  {...register(
                                    "per_accepted_appraisal_manager"
                                  )}
                                  maxLength={7}
                                />
                              </div>
                              <div className="label-text w-50">
                                Per Accepted Appraisal Spiff
                              </div>
                            </div>
                          </td>
                        </tr>
                      ) : (
                        <>
                          <tr>
                            <td>
                              <div className="input-group">
                                <div className="form-group">
                                  <span className="input-group-text">$</span>{" "}
                                  <input
                                    className="form-control pl-0"
                                    type="text"
                                    {...register("appraisal_bonus")}
                                    maxLength={7}
                                  />
                                </div>
                                <div className="label-text w-50">
                                  Per Appraisal Spiff
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className="input-group">
                                <div className="form-group">
                                  <span className="input-group-text">$</span>{" "}
                                  <input
                                    className="form-control pl-0"
                                    type="text"
                                    {...register(
                                      "vehicle_appraisal_bonus_dollar_value"
                                    )}
                                    maxLength={7}
                                  />
                                </div>
                                <div className="label-text w-50">
                                  Per Accepted Appraisal Spiff
                                </div>
                              </div>
                            </td>
                          </tr>
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
        </div>
      </form>
    </>
  );
};

export default EmployeesView;
