import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { AuthContextProvider } from "./context/authContext";
import { ThemeContextProvider } from "./context/themeContext";
import { BusinessContextProvider } from "./context/businessContext";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "./assets/css/theme-icons.css";
import "./assets/css/style.css";
import "./assets/css/responsive.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AuthContextProvider>
    <ThemeContextProvider>
      <BusinessContextProvider>
        <App />
      </BusinessContextProvider>
    </ThemeContextProvider>
  </AuthContextProvider>
);
