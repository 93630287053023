import React, { useContext, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import ThemeContext from "../../context/themeContext";
import ContactUs from "../ContactUs";
import GetHelp from "../GetHelp";
import NavbarTwo from "../Navbar/NavbarTwo";
import Sidebar from "../Sidebar";

const WrapperTwo = () => {
  const themeCtx = useContext(ThemeContext);
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <>
      <NavbarTwo />
      <Sidebar />
      <Outlet />
      <div
        className={`dashboard-backdrop ${
          themeCtx.showDropShadow ? "show" : ""
        }`}
      ></div>
      <ContactUs />
      <GetHelp />
    </>
  );
};

export default WrapperTwo;
