import React, { Fragment, useContext, useEffect, useState } from "react";
import _ from "lodash";
import api from "../../api";
import AuthContext from "../../context/authContext";
import userImg from "../../assets/img/user.jpg";
import moment from "moment";
import Swal from "../Swal";
import { formatCurrency } from "../../utils/formats";

const EmployeesLogs = ({ employeeId, businessId }) => {
  const [leadHistory, setLeadHistory] = useState([]);
  const [employees, setEmployees] = useState([]);
  const authCtx = useContext(AuthContext);

  useEffect(() => {
    if (employeeId !== "") {
      getLeadHistory(employeeId);
    } else {
      getLeadHistory(businessId);
    }
  }, [employeeId]);

  const getLeadHistory = (id) => {
    api
      .post(
        "api/business/dealer/logs",
        { employee_id: id },
        {
          headers: {
            Authorization: `Bearer ${authCtx.userData?.token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.status) {
          setEmployees(response.data.data.employees);
          let employeesArray = [];
          if (response.data.data.employees.length > 0) {
            response.data.data.employees.map((element) => {
              employeesArray.push({
                value: element.id,
                label: element.name,
              });
            });
            setEmployees(employeesArray);
          }

          let historyArray = [];
          for (let day in response.data.data.leads_history) {
            historyArray.push(day);
            response.data.data.leads_history[day].map((n) => {
              historyArray.push(n);
            });
          }
          setLeadHistory(historyArray);
        }
      })
      .catch((error) => {
        Swal.fire(
          "Opps!",
          "Sorry we are not able to complete your request at this time. Please try again later.",
          "error"
        );
      });
  };

  return (
    <>
      <div className="dashboard-count-filter dashboard-appraisals  mb-24">
        <div className="row align-items-center">
          <div className="col">
            <h2 className="page-title">Employee Logs</h2>
            <p className="page-dec">Get below employee log</p>
          </div>
        </div>
      </div>
      <div className="dashboard-card-filter dashboard-count-filter">
        <div className="row align-items-center">
          <div className="col">
            <div className="dashboard-navbar-month dashboardml">
              <div className="form-group">
                <select
                  className="form-select"
                  onChange={(e) => getLeadHistory(e.target.value)}>
                  <option value={businessId}>Select User</option>
                  {_.map(employees, (option) => (
                    <option
                      key={option.value}
                      value={option.value}
                      selected={option.value === employeeId}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="offer-notes-inner history-notes-inner">
        {leadHistory.length != 0 &&
          leadHistory.map((data, key) => (
            <Fragment key={key}>
              {_.isString(data) ? (
                <div className="history-date">
                  <span className="date-Title">{data}</span>
                </div>
              ) : (
                <div className="offer-note">
                  <div className="row">
                    <div className="col-auto history-notescol">
                      <div className="offer-note-image">
                        <img
                          src={
                            data?.userImage != "" ? data?.userImage : userImg
                          }
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="col history-notescol">
                      <div
                        className={`offer-note-head ${data?.action == "Notes Delete" ? "mb-0" : ""
                          }`}>
                        <div className="offer-note-info">
                          <p>{data?.title}</p>
                          <span>
                            {moment(data?.created_at).fromNow(true)} ago
                          </span>
                        </div>
                      </div>
                      {(data?.action == "Lead Update" ||
                        data?.action == "Lead Change Status") && (
                          <div className="offer-details-inner history-note-detail">
                            <div className="row align-items-center historynoterow">
                              {data?.old_data?.lead?.deals_date != undefined && (
                                <>
                                  <div className="col-lg-3 historynotecol">
                                    <div className="form-group mw-162">
                                      <label className="form-label">
                                        Offer Date
                                      </label>
                                      <span className="amoiunt">
                                        {moment(
                                          data?.old_data?.lead?.deals_date
                                        ).format("MM-DD-YYYY")}
                                      </span>
                                    </div>
                                  </div>
                                  {data?.old_data?.lead?.deals_date && (
                                    <div className="col-lg-3 historynotecol mw-100">
                                      <span className="arrow-icon">
                                        <i className="icon icon-back-aero"></i>
                                      </span>
                                    </div>
                                  )}
                                </>
                              )}
                              {data?.new_data?.lead?.deals_date != undefined && (
                                <div className="col-lg-3 historynotecol">
                                  <div className="form-group mw-162">
                                    <label className="form-label">
                                      Offer date
                                    </label>
                                    <span className="amoiunt">
                                      {moment(
                                        data?.new_data?.lead?.deals_date
                                      ).format("MM-DD-YYYY")}
                                    </span>
                                  </div>
                                </div>
                              )}
                              {data?.old_data?.lead?.deals_price != undefined && (
                                <>
                                  <div className="col-lg-3 historynotecol">
                                    <div className="form-group mw-162">
                                      <label className="form-label">
                                        Offer amount
                                      </label>
                                      <span className="amoiunt">
                                        {formatCurrency(
                                          data?.old_data?.lead?.deals_price
                                        )}{" "}
                                        USD
                                      </span>
                                    </div>
                                  </div>
                                </>
                              )}
                              {data?.new_data?.lead?.deals_price != undefined && (
                                <>
                                  {data?.old_data?.lead?.deals_price !=
                                    undefined && (
                                      <div className="col-lg-3 historynotecol mw-100">
                                        <span className="arrow-icon">
                                          <i className="icon icon-back-aero"></i>
                                        </span>
                                      </div>
                                    )}
                                  <div className="col-lg-3 historynotecol">
                                    <div className="form-group mw-162">
                                      <label className="form-label">
                                        Offer amount
                                      </label>
                                      <span className="amoiunt">
                                        {formatCurrency(
                                          data?.new_data?.lead?.deals_price
                                        )}{" "}
                                        USD
                                      </span>
                                    </div>
                                  </div>
                                </>
                              )}
                              {data?.old_data?.lead?.status != undefined && (
                                <>
                                  <div className="col-lg-3 historynotecol mw-100">
                                    <div className="form-group mw-168">
                                      <label className="form-label">Status</label>
                                      {data?.old_data?.lead?.status == 0 && (
                                        <span className="status-new">New</span>
                                      )}
                                      {data?.old_data?.lead?.status == 1 && (
                                        <span className="status-working">
                                          Appraised
                                        </span>
                                      )}
                                      {data?.old_data?.lead?.status == 2 && (
                                        <span className="status-offermade">
                                          Offer Made
                                        </span>
                                      )}
                                      {data?.old_data?.lead?.status == 3 && (
                                        <span className="status-accepted-purchase">
                                          Accepted Offer
                                        </span>
                                      )}
                                      {data?.old_data?.lead?.status == 4 && (
                                        <span className="status-appraised">
                                          Appraisal Assigned
                                        </span>
                                      )}
                                      {data?.old_data?.lead?.status == 5 && (
                                        <span className="status-no-deal">
                                          No Deal
                                        </span>
                                      )}
                                      {data?.old_data?.lead?.status == 6 && (
                                        <span className="status-counteroffer">
                                          Counter Offer
                                        </span>
                                      )}
                                      {data?.old_data?.lead?.status == 7 && (
                                        <span className="status-finaloffer">
                                          Final Offer
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-lg-3 historynotecol mw-100">
                                    <span className="arrow-icon">
                                      <i className="icon icon-back-aero"></i>
                                    </span>
                                  </div>
                                </>
                              )}
                              {data?.new_data?.lead?.status != undefined && (
                                <div className="col-lg-3 historynotecol mw-100">
                                  <div className="form-group mw-168">
                                    <label className="form-label">Status</label>
                                    {data?.new_data?.lead?.status == 0 && (
                                      <span className="status-new">New</span>
                                    )}
                                    {data?.new_data?.lead?.status == 1 && (
                                      <span className="status-working">
                                        Appraised
                                      </span>
                                    )}
                                    {data?.new_data?.lead?.status == 2 && (
                                      <span className="status-offermade">
                                        Offer Made
                                      </span>
                                    )}
                                    {data?.new_data?.lead?.status == 3 && (
                                      <span className="status-accepted-purchase">
                                        Accepted Offer
                                      </span>
                                    )}
                                    {data?.new_data?.lead?.status == 4 && (
                                      <span className="status-appraised">
                                        Appraisal Assigned
                                      </span>
                                    )}
                                    {data?.new_data?.lead?.status == 5 && (
                                      <span className="status-no-deal">
                                        No Deal
                                      </span>
                                    )}
                                    {data?.new_data?.lead?.status == 6 && (
                                      <span className="status-counteroffer">
                                        Counter Offer
                                      </span>
                                    )}
                                    {data?.old_data?.lead?.status == 7 && (
                                      <span className="status-finaloffer">
                                        Final Offer
                                      </span>
                                    )}
                                  </div>
                                </div>
                              )}
                              {data?.new_data?.lead?.status == 5 && (
                                <div className="col-lg-3">
                                  <div className="form-group">
                                    <label className="form-label">
                                      No Deal Reason
                                    </label>
                                    <span class="d-block">
                                      {data?.new_data?.lead?.no_deal_reason}
                                    </span>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      {data?.action == "Lead Add" && (
                        <div className="offer-details-inner history-note-detail">
                          <div className="row historynoterow">
                            <div className="col-lg-3 historynotecol">
                              <div className="form-group mw-162">
                                <label className="form-label">Customer</label>
                                <span className="d-block">
                                  Name:
                                  <b>
                                    {data?.new_data?.first_name}{" "}
                                    {data?.new_data?.last_name}
                                  </b>
                                </span>
                                {data?.new_data?.email && (
                                  <span className="d-block">
                                    Email:{" "}
                                    <a href={`mailto:${data?.new_data?.email}`}>
                                      {data?.new_data?.email}
                                    </a>
                                  </span>
                                )}
                                {data?.new_data?.mobile_no && (
                                  <span className="d-block">
                                    Phone:{" "}
                                    <a
                                      href={`tel:${data?.new_data?.mobile_no}`}>
                                      {data?.new_data?.mobile_no}
                                    </a>
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="col-lg-3 historynotecol">
                              <div className="form-group w-522">
                                <ul>
                                  <li>
                                    <span>Year</span>{" "}
                                    <b>{data?.new_data?.year}</b>
                                  </li>
                                  <li>
                                    <span>Make</span>{" "}
                                    <b>{data?.new_data?.make}</b>
                                  </li>
                                  <li>
                                    <span>Model</span>{" "}
                                    <b>{data?.new_data?.model}</b>
                                  </li>
                                  <li>
                                    <span>Trim</span>{" "}
                                    <b>{data?.new_data?.trim}</b>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {data?.action == "Notes Add" && (
                        <div className="offer-details-inner history-note-detail">
                          <p>{data?.new_data?.lead_notes}</p>
                        </div>
                      )}
                      {data?.action == "Notes Update" && (
                        <div className="offer-details-inner history-note-detail">
                          <div className="row historynoterow align-items-center">
                            <div className="col-lg-4">
                              <div className="form-group mw-162">
                                <label className="form-label">Note</label>
                                <p>{data?.old_data?.lead_notes}</p>
                              </div>
                            </div>
                            <div className="col-lg-3 historynotecol mw-100">
                              <span className="arrow-icon">
                                <i className="icon icon-back-aero"></i>
                              </span>
                            </div>
                            <div className="col-lg-4">
                              <div className="form-group mw-162">
                                <label className="form-label">Note</label>
                                <p>{data?.new_data?.lead_notes}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </Fragment>
          ))}
      </div>
    </>
  );
};

export default EmployeesLogs;
