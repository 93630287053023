import "antd/dist/antd.css";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../api";
import routes from "../../constants/routesConstants";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import AuthContext from "../../context/authContext";
import { useForm } from "react-hook-form";
import Swal from "../Swal";
import _ from "lodash";
import { Tree, Switch } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import { ROLE_ADD } from "../../constants/permissionConstants";
import usePermission from "../../hook/usePermission";
import BusinessContext from "../../context/businessContext";

const validationSchema = yup.object().shape({
  role_name: yup.string().max(42).required("Role name is required."),
});

const RolesCreate = () => {
  const authCtx = useContext(AuthContext);
  const businessCtx = useContext(BusinessContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [treeData, setModuleData] = useState([]);
  const [checkedModule, checkModuleData] = useState([]);
  const [selectAll, selectAllModule] = useState(false);
  const [status, changeStatus] = useState(true);

  const isRoleAddAllow = usePermission(ROLE_ADD);
  useEffect(() => {
    if (!isRoleAddAllow) {
      navigate(routes.Dashboard);
    }
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onCheck = (checkedKeys, info) => {
    const dataArray = [];
    const checkNodeAarray = info.checkedNodes;
    checkNodeAarray.forEach((item) => {
      if (item.module_id) {
        dataArray.push(`${item.module_id}-${item.id}`);
      }
    });
    checkModuleData(dataArray);
  };

  useEffect(() => {
    getModules();
  }, []);

  const getModules = async () => {
    await api
      .post(
        `api/modules`,
        {},
        {
          headers: {
            Authorization: `Bearer ${authCtx.userData?.token}`,
          },
        }
      )
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          setModuleData(response.data.data);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  function statusToggle(checked) {
    changeStatus(checked);
  }

  const onSubmit = async (formValue) => {
    setLoading(true);
    const statusData = status ? 1 : 0;
    const formData = new FormData();
    formData.append("role_name", formValue.role_name);
    formData.append("status", statusData);
    if (authCtx.userData?.user_type == 3) {
      formData.append("business_id", formValue.business_id);
    }
    checkedModule.forEach((item) => {
      formData.append("permission[]", item);
    });
    await api
      .post("api/role/add-role", formData, {
        headers: {
          Authorization: `Bearer ${authCtx.userData?.token}`,
        },
      })
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          Swal.fire(response.data.message, "", "success");
          reset();
          navigate(routes.Roles);
        } else {
          Swal.fire("Try Again!", response.data.message, "info");
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          authCtx.logout();
        } else {
          Swal.fire(
            "Opps!",
            "Sorry we are not able to complete your request at this time. Please try again later.",
            "error"
          );
        }
      });
  };

  return (
    <>
      <div className="appraisal-head-wrap mb-34">
        <div className="row">
          <div className="col">
            <div className="appraisal-head-title align-items-start">
              <button
                type="button"
                className="btn-icon"
                onClick={() => navigate(-1)}>
                <i className="icon icon-back-aero"></i>
              </button>
              <div className="page-head">
                <h2 className="page-title">Add New Role</h2>
                <p className="page-dec">Fill the required informations</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="login-inner add-new-user-inner">
        <div className="login-head">
          <h2 className="login-title">Create Role</h2>
          <p className="login-text">
            Please make sure to fill in all the required fields.
          </p>
        </div>
        <div className="login-form">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="login-form-inner">
              <div className="row">
                {authCtx.userData?.user_type == 3 && (
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label className="form-label">
                        Business <span>*</span>
                      </label>
                      <div className="position-relative">
                        <select
                          className="form-control form-select"
                          {...register("business_id")}>
                          <option value="">Select Business</option>
                          {_.map(businessCtx.businessList, (option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                )}
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="form-label">
                      Name <span>*</span>
                    </label>
                    <div className="position-relative">
                      <input
                        className="form-control"
                        {...register("role_name")}
                        maxLength={42}
                      />
                      {errors.role_name?.message != undefined && (
                        <span className="form-error">
                          {errors.role_name?.message}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="form-label">
                      Status <span>*</span>
                    </label>
                    <div className="position-relative">
                      <Switch onChange={statusToggle} defaultChecked />
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group">
                    <label className="form-label">
                      Permissions <span>*</span>
                    </label>
                    <Tree
                      showLine={{ showLeafIcon: false }}
                      switcherIcon={<CaretRightOutlined />}
                      checkable={!selectAll}
                      onCheck={onCheck}
                      treeData={treeData}
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <button
                    type="submit"
                    className="btn btn-pink"
                    disabled={loading}>
                    Add Role
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default RolesCreate;
