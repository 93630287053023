import Footer from "../Footer";
import "./index.css";

const PrivacyPolicy = () => {
  return (
    <>
      <div className="cmsWrap">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="cmsCon">
                <h2>Privacy Policy — iLusso Vehicle Appraisal Platform 2022</h2>
                <p>
                  The iLusso Vehicle Appraisal Platform is an application that
                  allows auto dealership Service Advisors or other designated
                  employees to submit vehicle appraisals from vehicles currently
                  in for service. This provides a source of data on prospective
                  used autos that the Used Car Manager can review early in the
                  day. The goal is for the Used Car Manager to engage with these
                  vehicle owners while the vehicle is on the dealership property
                  to investigate opportunities for making purchase deals. This
                  platform is intended to be a “process” and a “communication
                  tool” between an authorized dealership employee and a manager.
                </p>
                <h5>Guiding Principles</h5>
                <p>
                  The iLusso Vehicle Appraisal Platform is committed to
                  safeguarding our users’ privacy, as well as that of your
                  clients. We gather certain types of information from users of
                  this platform; and we, in turn, ask users to gather certain
                  types of information about their clients. This privacy policy
                  statement is intended to help you understand the terms and
                  conditions surrounding the collection and use of that
                  information. This statement discloses the types of information
                  we gather, how it is used, and how you can gain access to and
                  edit any data that we’ve collected about you and your clients
                  at any time.
                </p>
                <h5>Respect of Privacy</h5>
                <p>
                  The iLusso Vehicle Appraisal Platform respects the privacy of
                  all persons and companies that use this application, as well
                  as your clients. It is part of our policy to never use “spam”
                  as a means of communication or advertising. We make every
                  reasonable effort to avoid excessive collection of data. We
                  also take considerable precautions to safeguard your data,
                  including physical, electronic, and administrative measures.
                  You will not receive unsolicited emails from the iLusso
                  Vehicle Appraisal Platform. We will not share any information
                  you’ve provided to us with anyone without your consent.
                </p>
                <h5>Information Provided Voluntarily</h5>
                <p>
                  Any information you provide to the iLusso Vehicle Appraisal
                  Platform, to include name, email address, phone number,
                  dealership information, specific user information, client
                  information, and vehicle information, is securely maintained.
                  You may opt out of this application service or stop receiving
                  future communications at any time.
                </p>
                <h5>Disclosure of Personal Information</h5>
                <p>
                  iLusso and the iLusso Vehicle Appraisal Platform value your
                  privacy. We collect and maintain the personal information of
                  our clients solely to provide you with this service. We do not
                  sell, rent or trade user or client information to third
                  parties.
                </p>
                <h5>Agents</h5>
                <p>
                  The iLusso Vehicle Appraisal Platform may use other companies
                  and individuals to perform certain functions on our behalf.
                  Examples include administering e-mail services and running
                  special promotions. Such parties only have access to the
                  personal information needed to perform these functions and may
                  not use or store the information for any other purpose. Users
                  will never receive unsolicited e-mail messages from agents
                  working on our behalf.
                </p>
                <h5>Compliance with the Law</h5>
                <p>
                  The iLusso Vehicle Appraisal Platform may disclose specific
                  personal information based on a good faith belief that such
                  disclosure is necessary to comply with or conform to the law,
                  or that such disclosure is necessary to protect the users of
                  this application or the public.
                </p>
                <h5>With Consent</h5>
                <p>
                  Other than as set forth in this Privacy Policy, you will be
                  notified when any personally-identifiable information about
                  you may be shared with third parties, and you will be given
                  the opportunity to decline to share that information.
                </p>
                <h5>Use of Cookies</h5>
                <p>
                  The iLusso Vehicle Appraisal Platform may use a browser
                  feature known as a “cookie.” Cookies are small files placed on
                  your hard drive that assist us in providing you with a
                  simplified browsing experience. We use cookies to provide you
                  with the convenience of not having to reenter information,
                  such as user IDs and passwords, multiple times during your
                  visits to our platform.
                </p>
                <p>
                  The “help” section of the toolbar on most browsers will inform
                  you on how to prevent your browser from accepting new cookies,
                  how to have the browser notify you upon the receipt of a new
                  cookie or how to disable the use of cookies completely. You
                  should know that if you configure your browser to decline
                  acceptance or use of cookies, certain features of our platform
                  may not function correctly and you may be required to renter
                  any user IDs and passwords each time you visit or seek to
                  login as a user.
                </p>
                <h5>External Links</h5>
                <p>
                  The iLusso Vehicle Appraisal Platform may occasionally contain
                  links to other sites. We are not responsible for the privacy
                  practices or the content of outside websites. To help ensure
                  the protection of your privacy, we recommend that you review
                  the privacy policy of any site you visit.
                </p>
                <h5>Passwords</h5>
                <p>
                  The paid user area of the iLusso Vehicle Appraisal Platform
                  and related information can only be accessed with the unique
                  login and password you associated with your dealership upon
                  creating a paid account. To protect the integrity of the
                  information contained in your unique user record, you should
                  not disclose or otherwise reveal your password to any
                  unauthorized persons, and ensure only authorized employees
                  have access to your login and password information.
                </p>
                <h5>Contacting iLusso Vehicle Appraisal Platform</h5>
                <p>
                  If you have questions concerning this Privacy Policy or the
                  operation of this application, you may contact the iLusso
                  Vehicle Appraisal Platform team in writing.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default PrivacyPolicy;
