import Footer from "../Footer";

const TermsCondition = () => {
  return (
    <>
      <div className="cmsWrap">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="cmsCon">
                <h4 className="text-center">Coming Soon...</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default TermsCondition;
