export const formatPhoneNumber = (phone) => {
  const base = String(phone || "").replace(/[^0-9]/g, "");
  let formatted = "";

  const len = base.length;
  if (len > 0) {
    const x = base.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    formatted = +x[1] + (x[2] ? `-${x[2]}` : "") + (x[3] ? `-${x[3]}` : "");
  }

  return {
    base,
    formatted,
  };
};

export const formatCurrency = (amount) => {
  const formatAmount = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
  }).format(amount);

  return formatAmount;
};

export const formatPrice = (amount) => {
  const base = String(amount || "").replace(/[$,]/g, "");
  let formatted = "$";
  formatted += base
    .toString()
    .replace(/[^0-9]/g, "")
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return {
    base,
    formatted,
  };
};
