import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Link, useLocation, useNavigate } from "react-router-dom";
import routes from "../../constants/routesConstants";
import AuthContext from "../../context/authContext";
import logoImg from "../../assets/img/logo.svg";
import logo2 from "../../assets/img/logo2.svg";
import Swal from "../Swal";
import api from "../../api";

const validationSchema = yup.object().shape({
  business_name: yup.string().max(255).required("Business Name is required."),
  business_address: yup.string().required("Business Address is required."),
  name: yup.string().max(255).required("Full Name is required."),
  email: yup.string().email("Email is invalid.").max(255).required("Email is required."),
  password: yup
    .string()
    .required("Password is required.")
    .min(6, "Password must be 6 characters long")
    .max(255),
  confirm_password: yup
    .string()
    .required("Confirm Password is required.")
    .min(6, "Password must be 6 characters long")
    .oneOf([yup.ref("password"), null], "Passwords must match."),
});

const Register = () => {
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();
  const { state } = useLocation();

  const showPasswordHandler = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (authCtx.isLoggedIn) {
      navigate(state?.path || routes.Dashboard);
    }
  }, [state, navigate, authCtx.isLoggedIn]);

  const onSubmit = async (formValue) => {
    setLoading(true);
    await api
      .post("api/add-business", formValue)
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          localStorage.setItem("userName", response.data.data?.name);
          navigate(routes.PaymentSuccess);
        } else {
          Swal.fire("Try Again!", response.data.message, "info");
        }
      })
      .catch((error) => {
        setLoading(false);
        Swal.fire(
          "Opps!",
          "Sorry we are not able to complete your request at this time. Please try again later.",
          "error"
        );
      });
  };

  return (
    <section className="login-section with-bg">
      <div className="login-upper-section h-100">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-lg-5">
              <div className="login-inner">
                <div className="login-logo">
                  <Link to={routes.Home}>
                    <picture>
                      <source media="(max-width:568px)" srcSet={logo2} />
                      <img src={logoImg} alt="" />
                    </picture>
                  </Link>
                </div>
                <h2 className="login-title">
                  Register in the Vehicle Appraisal System
                </h2>
                <p className="login-text">
                  Please make sure to fill in all the required fields to
                  complete your registration.
                </p>
                <div className="login-form">
                  <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                    <div className="login-form-inner">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label className="form-label">
                              Company name <span>*</span>
                            </label>
                            <div className="position-relative">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Company name"
                                {...register("business_name")}
                              />
                              {errors.business_name?.message != undefined && (
                                <span className="form-error">
                                  {errors.business_name?.message}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label className="form-label">
                              Full Name <span>*</span>
                            </label>
                            <div className="position-relative">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Full Name"
                                {...register("name")}
                              />
                              {errors.name?.message != undefined && (
                                <span className="form-error">
                                  {errors.name?.message}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label className="form-label">
                              Email <span>*</span>
                            </label>
                            <div className="position-relative">
                              <input
                                className="form-control email"
                                placeholder="Your email"
                                {...register("email")}
                              />
                              {errors.email?.message != undefined && (
                                <span className="form-error">
                                  {errors.email?.message}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label className="form-label">
                              Business Address <span>*</span>
                            </label>
                            <div className="position-relative">
                              <input
                                className="form-control"
                                placeholder="Address"
                                {...register("business_address")}
                              />
                              {errors.business_address?.message !=
                                undefined && (
                                <span className="form-error">
                                  {errors.business_address?.message}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label className="form-label">
                              Create your own Password <span>*</span>
                            </label>
                            <div className="form-group-with-password">
                              <input
                                type={`${showPassword ? "text" : "password"}`}
                                className="form-control password"
                                placeholder="Use strong password"
                                {...register("password")}
                              />
                              <i
                                className="icon icon-eye"
                                onClick={showPasswordHandler}></i>
                              {errors.password?.message != undefined && (
                                <span className="form-error">
                                  {errors.password?.message}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <div className="form-group-with-password">
                              <input
                                type="password"
                                className="form-control password"
                                placeholder="Confirm Password"
                                {...register("confirm_password")}
                              />
                              {errors.confirm_password?.message !=
                                undefined && (
                                <span className="form-error">
                                  {errors.confirm_password?.message}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <button
                            type="submit"
                            className="btn btn-pink"
                            disabled={loading}>
                            Create account
                          </button>
                        </div>
                        <div className="col-lg-12">
                          <p className="login-form-login-link">
                            You have an account already{" "}
                            <Link to={routes.Login}>Sign in now</Link>
                          </p>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-lg-7 d-none d-lg-block">
              <p className="register-hero-text">
                Vehicle Appraisal System Makes it Easy
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Register;
