export const APPRAISALS_ALL_LIST = "appraisals_all_list";
export const APPRAISALS_OWN_OR_ASSIGNED_LIST =
  "appraisals_own_or_assigned_list";
export const APPRAISALS_ADD = "appraisals_add";
export const APPRAISALS_EDIT = "appraisals_edit";
export const APPRAISALS_DELETE = "appraisals_delete";
export const APPRAISALS_VIEW = "appraisals_view";
export const APPRAISALS_ASSIGN = "appraisals_assign";
export const APPRAISALS_CHANGE_STATUS = "appraisals_change_status";
export const APPRAISALS_HISTORY = "appraisals_history";

export const REPORTS_LIST = "reports_list";

export const EMPLOYEES_LIST = "employees_list";
export const EMPLOYEES_VIEW = "employees_view";
export const EMPLOYEES_ADD = "employees_add";
export const EMPLOYEES_EDIT = "employees_edit";
export const EMPLOYEES_DELETE = "employees_delete";
export const EMPLOYEES_BONUS_PREFERENCE = "employees_bonus_preference";
export const EMPLOYEES_ROLE_PERMISSIONS = "employees_role_permissions";
export const EMPLOYEES_NOTIFICATION_PREFERENCE =
  "employees_notification_preference";

export const DASHBOARD_VIEW = "dashboard_view";

export const ROLE_LIST = "role_list";
export const ROLE_ADD = "role_add";
export const ROLE_EDIT = "role_edit";
export const ROLE_DELETE = "role_delete";

export const GETHELP_VIEW = "gethelp_view";
export const CONTACT_VIEW = "contact_view";

export const BUSINESS_LIST = "business_list";
export const INQUIRIES_LIST = "inquiries_list";
export const FAQ_LIST = "faq_list";
export const SCHEDULE_DEMO_LIST = "schedule_demo_list";
