import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../context/authContext";
import moment from "moment";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import { Tooltip } from "antd";
import Swal from "../Swal";
import api from "../../api";
import _ from "lodash";
import { formatCurrency } from "../../utils/formats";

const UnpaidSubscribers = () => {
  const [responseData, setResponseData] = useState({});
  const [loading, setLoading] = useState(false);
  const [period, setPeriod] = useState("");
  const [monthlyPeriod, setMonthlyPeriod] = useState([]);
  const [sortField, setSortField] = useState("");
  const [sortType, setSortType] = useState("desc");
  const [pagination, setPagination] = useState({
    total_records: 0,
    total_pages: 0,
    current: 0,
  });

  const authCtx = useContext(AuthContext);

  useEffect(() => {
    let filterData = {};
    fetchSubscriptions(filterData, 1);
    getMonths();
  }, [period]);

  function getMonths() {
    let monthsArray = [];
    let monthName = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    let d = new Date();
    d.setDate(1);
    for (let i = 0; i <= 11; i++) {
      if (i == 0) {
        d.setMonth(d.getMonth());
      } else {
        d.setMonth(d.getMonth() - 1);
      }
      monthsArray.push({
        label: monthName[d.getMonth()] + " " + d.getFullYear(),
        value: moment(new Date(d.getFullYear(), d.getMonth(), 1)).format(
          "YYYY-MM-DD"
        ),
      });
    }
    setMonthlyPeriod(monthsArray);
  }

  async function fetchSubscriptions(formData = {}, page) {
    setLoading(true);
    formData.paid = "0";
    if (period !== "") {
      formData.period = period;
    }
    await api
      .post(`api/invoice?page=${page}`, formData, {
        headers: {
          Authorization: `Bearer ${authCtx.userData?.token}`,
        },
      })
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          setResponseData(response.data.data);
          setPagination(response.data.data.paginate);
        } else {
          setResponseData([]);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          authCtx.logout();
        } else {
          Swal.fire(
            "Opps!",
            "Sorry we are not able to complete your request at this time. Please try again later.",
            "error"
          );
        }
      });
  }

  function sort(field, currentField, sort, page) {
    let filterData = {};
    if (field == currentField) {
      if (sort == "desc") {
        setSortType("asc");
        filterData = {
          ...filterData,
          field_name: field,
          order_by: "asc",
        };
        fetchSubscriptions(filterData, page);
      } else {
        setSortType("desc");
        filterData = {
          ...filterData,
          field_name: field,
          order_by: "desc",
        };
        fetchSubscriptions(filterData, page);
      }
    } else {
      setSortField(field);
      setSortType("desc");
      filterData = {
        ...filterData,
        field_name: field,
        order_by: "desc",
      };
      fetchSubscriptions(filterData, page);
    }
  }

  const onPaginationFetch = (e) => {
    let filterData = {};
    if (sortField != "" && sortType != "") {
      filterData = {
        ...filterData,
        field_name: sortField,
        order_by: sortType,
      };
    }
    fetchSubscriptions(filterData, e.selected + 1);
  };

  return (
    <div className="dashboard-latest-appraisals">
      <div className="dashboard-card">
        <div className="dashboard-card-filter">
          <div className="row align-items-center">
            <div className="col">
              <h4 className="card-title">Unpaid Invoices</h4>
            </div>
            <div className="col-auto">
              <div className="dashboard-card-select">
                <div className="form-group">
                  <select
                    className="form-select"
                    onChange={(e) => setPeriod(e.target.value)}
                    value={period}>
                    <option value="">All</option>
                    {_.map(monthlyPeriod, (option, key) => (
                      <option key={key} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="dashboard-latest-apraisals table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th>#</th>
                <th>
                  Business Name{" "}
                  <span
                    className={`table-sort ${sortField == "business_name" ? sortType : ""
                      }`}
                    onClick={() =>
                      sort(
                        "business_name",
                        sortField,
                        sortType,
                        pagination.current
                      )
                    }></span>
                </th>
                <th>
                  Business Address
                  <span
                    className={`table-sort ${sortField == "business_address" ? sortType : ""
                      }`}
                    onClick={() =>
                      sort(
                        "business_address",
                        sortField,
                        sortType,
                        pagination.current
                      )
                    }></span>
                </th>
                <th>
                  Subscription Period{" "}
                  <span
                    className={`table-sort ${sortField == "start_date" ? sortType : ""
                      }`}
                    onClick={() =>
                      sort(
                        "start_date",
                        sortField,
                        sortType,
                        pagination.current
                      )
                    }></span>
                </th>
                <th>
                  Amount{" "}
                  <span
                    className={`table-sort ${sortField == "amount" ? sortType : ""
                      }`}
                    onClick={() =>
                      sort("amount", sortField, sortType, pagination.current)
                    }></span>
                </th>
                <th>
                  Paid Amount{" "}
                  <span
                    className={`table-sort ${sortField == "paid_amount" ? sortType : ""
                      }`}
                    onClick={() =>
                      sort(
                        "paid_amount",
                        sortField,
                        sortType,
                        pagination.current
                      )
                    }></span>
                </th>
                <th>
                  Payment{" "}
                  <span
                    className={`table-sort ${sortField == "payment_status" ? sortType : ""
                      }`}
                    onClick={() =>
                      sort(
                        "payment_status",
                        sortField,
                        sortType,
                        pagination.current
                      )
                    }></span>
                </th>
                <th>
                  Payment Date{" "}
                  <span
                    className={`table-sort ${sortField == "payment_date" ? sortType : ""
                      }`}
                    onClick={() =>
                      sort(
                        "payment_date",
                        sortField,
                        sortType,
                        pagination.current
                      )
                    }></span>
                </th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {responseData?.invoice && responseData?.invoice.length != 0 ? (
                responseData?.invoice.map((data) => (
                  <tr key={data?.id}>
                    <td>{data?.id}</td>
                    <td>{data?.business_name}</td>
                    <td>{data?.business_address}</td>
                    <td>{data?.period}</td>
                    <td>{formatCurrency(data?.amount)}</td>
                    <td>{formatCurrency(data?.paid_amount)}</td>
                    <td>
                      <span
                        className={`${data?.payment_status !== undefined &&
                            data?.payment_status === "Paid"
                            ? "status-won"
                            : "" || data?.payment_status === "Unpaid"
                              ? "status-pending"
                              : "" || data?.payment_status === "Overdue"
                                ? "status-rejected"
                                : ""
                          }`}>
                        {data?.payment_status}
                      </span>
                    </td>
                    <td>
                      {data?.payment_date != ""
                        ? moment(data?.payment_date).format("MMMM D, YYYY")
                        : "-"}
                    </td>
                    <td>
                      <Tooltip placement="top" title="Add Payment">
                        <Link
                          to={`/payments/create/${data?.id}`}
                          className="btn-icon">
                          <i className="icon icon-money"></i>
                        </Link>
                      </Tooltip>
                      <Tooltip placement="top" title="View Invoice">
                        <a
                          href={data?.invoice_name}
                          target="_blank"
                          className="btn-icon">
                          <i className="icon icon-invoice"></i>
                        </a>
                      </Tooltip>
                    </td>
                  </tr>
                ))
              ) : (
                <tr className="text-start text-sm-center">
                  <td colSpan={8}>
                    {loading ? "Loading..." : "No Records Found."}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      {pagination?.total_pages != undefined && pagination?.total_pages != 1 && (
        <nav className="navigation pagination">
          <ReactPaginate
            breakLabel="..."
            onPageChange={onPaginationFetch}
            pageRangeDisplayed={2}
            forcePage={parseInt(pagination?.current - 1)}
            pageCount={pagination?.total_pages}
            renderOnZeroPageCount={null}
            containerClassName="nav-links"
            activeLinkClassName="current"
            pageLinkClassName="page-numbers"
            previousLinkClassName="prev page-numbers"
            previousLabel={<i className="icon icon-arrow-left"></i>}
            nextLinkClassName="next page-numbers"
            nextLabel={<i className="icon icon-arrow-right"></i>}
          />
        </nav>
      )}
    </div>
  );
};

export default UnpaidSubscribers;