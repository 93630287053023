import React, { useContext, useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import api from "../../api";
import AuthContext from "../../context/authContext";
import Swal from "../Swal";
import moment from "moment";
import useDebounce from "../../hook/useDebounce";
import { formatPhoneNumber } from "../../utils/formats";
import { Link, useNavigate } from "react-router-dom";
import BusinessContext from "../../context/businessContext";
import routes from "../../constants/routesConstants";

const ScheduleDemoList = () => {
  const [responseData, setResponsedata] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sortField, setSortField] = useState("");
  const [sortType, setSortType] = useState("desc");
  const [pagination, setPagination] = useState({
    total_records: 0,
    total_pages: 0,
    current: 0,
  });

  const authCtx = useContext(AuthContext);
  const businessCtx = useContext(BusinessContext);
  const [search, setSearch] = useState("");
  const searchDebouncedValue = useDebounce(search, 1000);
  const navigate = useNavigate();

  useEffect(() => {
    if (authCtx.userData?.user_type != 3) {
      navigate(routes.Dashboard);
    }
  }, []);

  useEffect(() => {
    let filterData = {};
    if (searchDebouncedValue != "") {
      filterData = {
        ...filterData,
        search: searchDebouncedValue,
      };
    }
    fetchScheduleDemoList(filterData, 1);
  }, [searchDebouncedValue, businessCtx.selectedBusinessId]);

  async function fetchScheduleDemoList(formData = {}, page) {
    setLoading(true);
    if (
      authCtx.userData?.user_type == 3 &&
      businessCtx.selectedBusinessId != ""
    ) {
      formData.business_id = businessCtx.selectedBusinessId;
    }
    await api
      .post(`api/schedule?page=${page}`, formData, {
        headers: {
          Authorization: `Bearer ${authCtx.userData?.token}`,
        },
      })
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          setResponsedata(response.data.data);
          setPagination(response.data.data.paginate);
        } else {
          setResponsedata([]);
          setPagination({
            total_records: 0,
            total_pages: 0,
            current: 0,
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response?.status == 401) {
          authCtx.logout();
        } else {
          Swal.fire(
            "Opps!",
            "Sorry we are not able to complete your request at this time. Please try again later.",
            "error"
          );
        }
      });
  }

  function sort(field, currentField, sort, page) {
    let filterData = {};
    if (searchDebouncedValue != "") {
      filterData = {
        ...filterData,
        search: searchDebouncedValue,
      };
    }
    if (field == currentField) {
      if (sort == "desc") {
        setSortType("asc");
        filterData = {
          ...filterData,
          field_name: field,
          order_by: "asc",
        };
        fetchScheduleDemoList(filterData, page);
      } else {
        setSortType("desc");
        filterData = {
          ...filterData,
          field_name: field,
          order_by: "desc",
        };
        fetchScheduleDemoList(filterData, page);
      }
    } else {
      setSortField(field);
      setSortType("desc");
      filterData = {
        ...filterData,
        field_name: field,
        order_by: "desc",
      };
      fetchScheduleDemoList(filterData, page);
    }
  }

  const onPaginationFetch = (e) => {
    let filterData = {};
    if (sortField != "" && sortType != "") {
      filterData = {
        ...filterData,
        field_name: sortField,
        order_by: sortType,
      };
    }
    if (searchDebouncedValue != "") {
      filterData = {
        ...filterData,
        search: searchDebouncedValue,
      };
    }
    fetchScheduleDemoList(filterData, e.selected + 1);
  };

  const deleteHandler = (id) => {
    if (id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          setLoading(true);
          api
            .post(
              "api/schedule/delete-schedule-demo",
              { schedule_demo_id: id },
              {
                headers: {
                  Authorization: `Bearer ${authCtx.userData?.token}`,
                },
              }
            )
            .then((response) => {
              setLoading(false);
              if (response.data.status) {
                fetchScheduleDemoList({}, 1);
                Swal.fire(response.data.message, "", "success");
              } else {
                Swal.fire("Try Again!", response.data.message, "info");
              }
            })
            .catch((error) => {
              setLoading(false);
              if (error.response.status == 401) {
                authCtx.logout();
              } else {
                Swal.fire(
                  "Opps!",
                  "Sorry we are not able to complete your request at this time. Please try again later.",
                  "error"
                );
              }
            });
        }
      });
    }
    return false;
  };

  return (
    <>
      <div className="dashboard-count-filter dashboard-appraisals mb-24">
        <div className="row align-items-center">
          <div className="col">
            <h2 className="page-title">Demo Inquiries</h2>
            <p className="page-dec">
              In total there is <b>{pagination?.total_records}</b> demo
              inquiries
            </p>
          </div>
        </div>
      </div>
      <div className="dashboard-latest-appraisals">
        <div className="dashboard-card">
          <div className="dashboard-card-filter dashboard-count-filter">
            <div className="row align-items-center">
              <div className="col">
                <div className="dashboard-navbar-search dashboardml">
                  <div className="form-group mb-0">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="dashboard-latest-apraisals business-table table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>
                    Name{" "}
                    <span
                      className={`table-sort ${
                        sortField == "name" ? sortType : ""
                      }`}
                      onClick={() =>
                        sort("name", sortField, sortType, pagination.current)
                      }></span>
                  </th>
                  <th>
                    Dealership Name{" "}
                    <span
                      className={`table-sort ${
                        sortField == "dealership_name" ? sortType : ""
                      }`}
                      onClick={() =>
                        sort("dealership_name", sortField, sortType, pagination.current)
                      }></span>
                  </th>
                  <th>
                    Email{" "}
                    <span
                      className={`table-sort ${
                        sortField == "email" ? sortType : ""
                      }`}
                      onClick={() =>
                        sort("email", sortField, sortType, pagination.current)
                      }></span>
                  </th>
                  <th>
                    Phone{" "}
                    <span
                      className={`table-sort ${
                        sortField == "mobile_no" ? sortType : ""
                      }`}
                      onClick={() =>
                        sort(
                          "mobile_no",
                          sortField,
                          sortType,
                          pagination.current
                        )
                      }></span>
                  </th>
                  <th>
                    Notes{" "}
                    <span
                      className={`table-sort ${
                        sortField == "notes" ? sortType : ""
                      }`}
                      onClick={() =>
                        sort("notes", sortField, sortType, pagination.current)
                      }></span>
                  </th>
                  <th>
                    Demo At{" "}
                    <span
                      className={`table-sort ${
                        sortField == "date_time" ? sortType : ""
                      }`}
                      onClick={() =>
                        sort(
                          "date_time",
                          sortField,
                          sortType,
                          pagination.current
                        )
                      }></span>
                  </th>
                  <th>
                    Created At{" "}
                    <span
                      className={`table-sort ${
                        sortField == "created_at" ? sortType : ""
                      }`}
                      onClick={() =>
                        sort(
                          "created_at",
                          sortField,
                          sortType,
                          pagination.current
                        )
                      }></span>
                  </th>
                  <th width="10%">Action</th>
                </tr>
              </thead>
              <tbody>
                {responseData?.scheduleDemo &&
                responseData?.scheduleDemo.length != 0 ? (
                  responseData?.scheduleDemo.map((data) => (
                    <tr key={data?.id}>
                      <td>{data?.name}</td>
                      <td>{data?.dealership_name}</td>
                      <td>{data?.email}</td>
                      <td>
                        {data?.phone_number != null
                          ? formatPhoneNumber(data?.phone_number).formatted
                          : "-"}
                      </td>
                      <td>{data?.notes}</td>
                      <td>
                        {data?.date_time != ""
                          ? moment(data?.date_time).format("MM-DD-YYYY hh:mm A")
                          : ""}
                      </td>
                      <td>
                        {data?.created_at != ""
                          ? moment(data?.created_at).format("MM-DD-YYYY")
                          : ""}
                      </td>
                      <td>
                        <button
                          type="button"
                          className="btn-icon"
                          onClick={() => deleteHandler(data?.id)}>
                          <i className="icon icon-delete"></i>
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr className="text-start text-sm-center">
                    <td colSpan={8}>
                      {loading ? "Loading..." : "No demo inquiries Found."}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {pagination?.total_pages != undefined && pagination?.total_pages != 1 && (
        <nav className="navigation pagination">
          <ReactPaginate
            breakLabel="..."
            onPageChange={onPaginationFetch}
            pageRangeDisplayed={2}
            pageCount={pagination?.total_pages}
            renderOnZeroPageCount={null}
            containerClassName="nav-links"
            activeLinkClassName="current"
            pageLinkClassName="page-numbers"
            previousLinkClassName="prev page-numbers"
            previousLabel={<i className="icon icon-arrow-left"></i>}
            nextLinkClassName="next page-numbers"
            nextLabel={<i className="icon icon-arrow-right"></i>}
          />
        </nav>
      )}
    </>
  );
};

export default ScheduleDemoList;
