import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import routes from "../../constants/routesConstants";
import logoImg from "../../assets/img/logo.svg";
import logo2 from "../../assets/img/logo2.svg";
import Swal from "../Swal";
import api from "../../api";
import { formatPhoneNumber } from "../../utils/formats";
import { DatePicker } from "antd";
import moment from "moment";

const validationSchema = yup.object().shape({
  name: yup.string().max(255).required("Full Name is required."),
  dealership_name: yup.string().max(255).required("Dealership Name is required."),
  email: yup.string().email("Email is invalid.").max(255).required("Email is required."),
  phone_number: yup
    .string()
    .required("Phone number is required.")
    .min(10, "Phone number is to short.")
    .max(12, "Phone number is to long."),
});

const ScheduleDemo = () => {
  const [loading, setLoading] = useState(false);
  const [scheduleTime, setScheduleTime] = useState(moment().add(1, "days"));

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const phone_number = watch("phone_number");
  useEffect(() => {
    setValue("phone_number", formatPhoneNumber(phone_number).formatted);
  }, [phone_number]);

  const onSubmit = async (formValue) => {
    setLoading(true);
    let formData = {
      ...formValue,
      date_time: moment(scheduleTime).format("YYYY-MM-DD HH:mm:ss"),
    };
    await api
      .post("api/schedule/add-schedule-demo", formData)
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          Swal.fire(response.data.message, "", "success");
          navigate(routes.Home);
        } else {
          Swal.fire("Try Again!", response.data.message, "info");
        }
      })
      .catch((error) => {
        setLoading(false);
        Swal.fire(
          "Opps!",
          "Sorry we are not able to complete your request at this time. Please try again later.",
          "error"
        );
      });
  };

  const onDateTimeChange = (value) => {
    setScheduleTime(value);
  };

  const disabledDate = (current) => {
    return current && current < moment().endOf("day");
  };

  return (
    <section className="login-section with-bg">
      <div className="login-upper-section h-100">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-lg-5">
              <div className="login-inner">
                <div className="login-logo">
                  <Link to={routes.Home}>
                    <picture>
                      <source media="(max-width:568px)" srcSet={logo2} />
                      <img src={logoImg} alt="" />
                    </picture>
                  </Link>
                </div>
                <h2 className="login-title">
                  Schedule Demo in the Vehicle Appraisal System
                </h2>
                <p className="login-text">
                  Please make sure to fill in all the required fields.
                </p>
                <div className="login-form">
                  <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                    <div className="login-form-inner">
                      <div className="row">
                      <div className="col-lg-12">
                          <div className="form-group">
                            <label className="form-label">
                              Dealership Name <span>*</span>
                            </label>
                            <div className="position-relative">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Dealership Name"
                                {...register("dealership_name")}
                              />
                              {errors.dealership_name?.message != undefined && (
                                <span className="form-error">
                                  {errors.dealership_name?.message}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label className="form-label">
                              Full Name <span>*</span>
                            </label>
                            <div className="position-relative">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Full Name"
                                {...register("name")}
                              />
                              {errors.name?.message != undefined && (
                                <span className="form-error">
                                  {errors.name?.message}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label className="form-label">
                              Email <span>*</span>
                            </label>
                            <div className="position-relative">
                              <input
                                className="form-control email"
                                placeholder="Your email"
                                {...register("email")}
                              />
                              {errors.email?.message != undefined && (
                                <span className="form-error">
                                  {errors.email?.message}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label className="form-label">
                              Phone number <span>*</span>
                            </label>
                            <div className="position-relative">
                              <input
                                type="text"
                                className="form-control phone"
                                placeholder="Ex: 65454646546"
                                {...register("phone_number")}
                                maxLength={12}
                              />
                              {errors.phone_number?.message != undefined && (
                                <span className="form-error">
                                  {errors.phone_number?.message}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label className="form-label">Notes</label>
                            <div className="position-relative">
                              <textarea
                                className="form-control"
                                rows={4}
                                placeholder="Enter notes here"
                                {...register("notes")}></textarea>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label className="form-label">
                              Demo Date & Time
                            </label>
                            <div className="position-relative">
                              <DatePicker
                                showTime
                                disabledDate={disabledDate}
                                value={scheduleTime}
                                allowClear={false}
                                onOk={onDateTimeChange}
                                onChange={onDateTimeChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <button
                            type="submit"
                            className="btn btn-pink mb-0"
                            disabled={loading}>
                            Submit for Schedule a Demo
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-lg-7 d-none d-lg-block">
              <p className="register-hero-text">
                Vehicle Appraisal System Makes it Easy
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ScheduleDemo;
