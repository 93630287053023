import React, { useContext, useEffect, useState } from "react";
import { BarcodeDetector } from "barcode-detector";
import { CapturePhoto } from '@georapbox/capture-photo-element/dist/capture-photo.js';
import ThemeContext from "../../context/themeContext";
import Swal from "../Swal";

const barcodeDetector = new BarcodeDetector({ formats: ["code_128", "code_39"] });
CapturePhoto.defineCustomElement();

const VinScanner = ({ onScanComplete }) => {
  const themeCtx = useContext(ThemeContext);
  const [isScannerOpen, setIsScannerOpen] = useState(false);
  const [isConfirmOpen, setConfirmOpen] = useState(false);
  const [vin, setVin] = useState("");
  let shouldRepeatScan = true;
  let rafId;

  function beep(duration, frequency, volume, type, callback) {
    const audioCtx = new (window.AudioContext || window.webkitAudioContext || window.audioContext)();

    if (!audioCtx) {
      return;
    }

    const oscillator = audioCtx.createOscillator();
    const gainNode = audioCtx.createGain();

    oscillator.connect(gainNode);
    gainNode.connect(audioCtx.destination);

    if (volume) {
      gainNode.gain.value = volume;
    }

    if (frequency) {
      oscillator.frequency.value = frequency;
    }

    if (type) {
      oscillator.type = type;
    }

    if (typeof callback === 'function') {
      oscillator.onended = callback;
    }

    oscillator.start(audioCtx.currentTime);
    oscillator.stop(audioCtx.currentTime + ((duration || 500) / 1000));
  }

  function detectBarcode(source) {
    return new Promise((resolve, reject) => {
      barcodeDetector.detect(source).then(results => {
        if (Array.isArray(results) && results.length > 0) {
          resolve(results[0]);
        } else {
          reject({
            message: 'Could not detect barcode from provided source.'
          });
        }
      }).catch(err => {
        reject(err);
      });
    });
  }

  const onScan = (data) => {
    setVin(data);
    themeCtx.showDropShadowHandler();
    setConfirmOpen(true);
  };

  async function initScan() {
    try {
      let barcode = {};
      const capturePhotoEl = document.querySelector('capture-photo');
      const capturePhotoVideoEl = capturePhotoEl.shadowRoot.querySelector('video');
      barcode = await detectBarcode(capturePhotoVideoEl);
      beep(200, 860, 0.03, 'square');
      window.cancelAnimationFrame(rafId);
      onScan(barcode.rawValue);
      return;
    } catch (err) { }

    if (shouldRepeatScan) {
      rafId = window.requestAnimationFrame(() => initScan());
    }
  }

  useEffect(() => {
    if (isScannerOpen) {
      const capturePhotoEl = document.querySelector('capture-photo');
      capturePhotoEl.addEventListener('capture-photo:video-play', evt => {
        initScan();
        const trackSettings = capturePhotoEl.getTrackSettings();
        const trackCapabilities = capturePhotoEl.getTrackCapabilities();
        const zoomLevelEl = document.getElementById('zoomLevel');
        if (trackSettings?.zoom && trackCapabilities?.zoom) {
          const zoomControls = document.getElementById('zoomControls');
          const minZoom = trackCapabilities?.zoom?.min || 0;
          const maxZoom = trackCapabilities?.zoom?.max || 10;
          let currentZoom = trackSettings?.zoom || 1;
          zoomControls.hidden = false;
          zoomLevelEl.textContent = currentZoom;
          zoomControls.addEventListener('click', evt => {
            const zoomInBtn = evt.target.closest('[data-action="zoom-in"]');
            const zoomOutBtn = evt.target.closest('[data-action="zoom-out"]');
            if (zoomInBtn && currentZoom < maxZoom) {
              currentZoom += 0.5;
            }
            if (zoomOutBtn && currentZoom > minZoom) {
              currentZoom -= 0.5;
            }
            zoomLevelEl.textContent = currentZoom;
            capturePhotoEl.zoom = currentZoom;
          });
        }
      }, {
        once: true
      });
      capturePhotoEl.addEventListener('capture-photo:error', evt => {
        const error = evt.detail.error;
        if (error.name === 'NotFoundError') {
          return;
        }
        const errorMessage = error.name === 'NotAllowedError'
          ? 'Permission to use webcam was denied or video Autoplay is disabled. Reload the page to give appropriate permissions to webcam.'
          : error.message;

        Swal.fire(
          "Opps!",
          errorMessage,
          "error"
        );
      }, {
        once: true
      });
    }
  }, [isScannerOpen]);

  const onScannerOpen = async () => {
    try {
      await navigator.mediaDevices.getUserMedia({ video: true });
      const capturePhotoEl = document.querySelector('capture-photo');
      if (capturePhotoEl != null && typeof capturePhotoEl.startVideoStream === 'function') {
        capturePhotoEl.startVideoStream();
      }
      setIsScannerOpen(true);
    } catch (error) {
      Swal.fire(
        "Opps!",
        "Permission to use webcam was denied or video Autoplay is disabled. Reload the page to give appropriate permissions to webcam.",
        "error"
      );
    }
  };

  const onScannerClose = () => {
    shouldRepeatScan = false;
    const capturePhotoEl = document.querySelector('capture-photo');
    if (capturePhotoEl != null && typeof capturePhotoEl.stopVideoStream === 'function') {
      capturePhotoEl.stopVideoStream();
    }
    setIsScannerOpen(false);
    setConfirmOpen(false);
    themeCtx.hideDropShadowHandler();
  };

  const onConfirmClick = () => {
    onScannerClose();
    onScanComplete(vin);
  };

  const onReScan = () => {
    setVin("");
    setConfirmOpen(false);
    themeCtx.hideDropShadowHandler();
    initScan();
  };

  return (
    <>
      <button
        type="button"
        className="scanner-open-btn position-absolute top-50 p-0 border-0 bg-transparent d-flex align-items-center justify-content-center"
        onClick={() => onScannerOpen()}>
        <i className="icon icon-barcode"></i>
      </button>
      {isScannerOpen &&
        <div className="scanner-wrap visible">
          <button
            type="button"
            className="scanner-close-btn border-0 position-absolute d-flex align-items-center justify-content-center rounded-circle"
            onClick={() => onScannerClose()}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
              <path d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z" />
            </svg>
          </button>
          <div className="container">
            <div className="scan-frame-container">
              <capture-photo facing-mode="environment" no-image auto-play>
                <span slot="capture-button" hidden />
              </capture-photo>
              <div id="scanFrame" className="scan-frame"></div>
            </div>
            <p className="text-center mt-3">Cover the <b>VIN</b> barcode completely within the visible camera screen for scanning.</p>
          </div>
          <div className={`confirm-popup ${isConfirmOpen ? "active" : ""}`}>
            <div className="confirm-popup-inr">
              <button
                type="button"
                className="popup-close-btn"
                onClick={() => onReScan()}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                  <path d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z" />
                </svg>
              </button>
              <div className="confirm-popup-con">
                <h5 className="confirm-popup-title text-white">
                  Please confirm VIN showing below!
                </h5>
                {vin !== "" && <p className="text-uppercase text-white">{vin}</p>}
              </div>
              <div className="confirm-popup-btns">
                <button
                  type="button"
                  className="btn btn-outline-secondary"
                  onClick={() => onReScan()}>
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-pink"
                  onClick={() => onConfirmClick()}>
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default VinScanner;
