import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import _ from "lodash";
import debounce from "lodash.debounce";
import api from "../../api";
import { useFieldArray, useForm } from "react-hook-form";
import AuthContext from "../../context/authContext";
import { Link, useNavigate, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import carfaxLogo from "../../assets/img/carfax.png";
import userImg from "../../assets/img/user.jpg";
import { useDropzone } from "react-dropzone";
import routes from "../../constants/routesConstants";
import moment from "moment";
import Swal from "../Swal";
import Fancybox from "../Fancybox";
import DatePicker from "react-date-picker";
import {
  formatCurrency,
  formatPhoneNumber,
  formatPrice,
} from "../../utils/formats";
import usePermission from "../../hook/usePermission";
import {
  APPRAISALS_ALL_LIST,
  APPRAISALS_CHANGE_STATUS,
  APPRAISALS_EDIT,
  APPRAISALS_HISTORY,
} from "../../constants/permissionConstants";
import VinScanner from "./vinScanner";

const years = _.map(
  new Array(new Date().getFullYear() + 1 - 1990),
  (_value, idx) => ({
    label: new Date().getFullYear() + 1 - idx,
    value: new Date().getFullYear() + 1 - idx,
  })
);

const validationSchema = yup.object().shape({
  first_name: yup.string().max(42).required("First name is required."),
  last_name: yup.string().max(42).required("Last name is required."),
  email: yup.string().max(42).email("Email is invalid."),
  mobile_no: yup.string().max(12, "Phone number is to long."),
  vin: yup
    .string()
    .required("VIN number is required")
    .min(17, "Please Enter Minimum 17 Characters.")
    .max(17, "Please Enter Maximum 17 Characters."),
  year: yup.string().required("Year is required."),
  make: yup.string().required("Make is required."),
  model: yup.string().required("Model is required."),
  trim: yup.string().required("Trim is required."),
  mileage: yup.string().max(42).required("Mileage is required."),
  status: yup.string().required("Status is required."),
  deals_price: yup
    .string()
    .notRequired()
    .when("status", {
      is: (val) => {
        return val != "0" || val != "5";
      },
      then: yup
        .string()
        .min(2, "Amount is required.")
        .max(10)
        .required("Amount is required."),
      otherwise: yup.string().notRequired(),
    }),
  counter_offer_price: yup
    .string()
    .notRequired()
    .when("status", {
      is: (val) => {
        return val != "0" || val != "5";
      },
      then: yup
        .string()
        .min(2, "Counter Offer is required.")
        .max(10)
        .required("Counter Offer is required."),
      otherwise: yup.string().notRequired(),
    }),
  final_offer_price: yup
    .string()
    .notRequired()
    .when("status", {
      is: (val) => {
        return val != "0" || val != "5";
      },
      then: yup
        .string()
        .min(2, "Final Offer is required.")
        .max(10)
        .required("Final Offer is required."),
      otherwise: yup.string().notRequired(),
    }),
});

const validationSchema2 = yup.object().shape({
  lead_notes: yup.string().required("Note Message is required."),
});

const AppraisalView = () => {
  const skipFirstRender = useRef(1);
  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [isAutoSaved, setIsAutoSaved] = useState(true);
  const [statusClass, setStatusClass] = useState(null);
  const [responseData, setResponseData] = useState("");
  const [rawNotes, setRawNotes] = useState([]);
  const [leadHistory, setLeadHistory] = useState([]);
  const [noteId, setNoteId] = useState(null);
  const [makes, setMakes] = useState([]);
  const [models, setModels] = useState([]);
  const [trims, setTrims] = useState([]);
  const [autoFill, setAutoFill] = useState(false);
  const [selectedMake, setSelectedMake] = useState("");
  const [selectedModel, setSelectedModel] = useState("");
  const [selectedTrim, setSelectedTrim] = useState("");
  const [dealDate, setDealDate] = useState(new Date());
  const [noDealReason, setNoDealReason] = useState("");
  const [managers, setManagers] = useState([]);
  const [salesUsers, setSalesUsers] = useState([]);
  const [isAppraisalLocked, setIsAppraisalLocked] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [finalDealAmount, setFinalDealAmount] = useState("");

  const isAppraisalHistoryAllow = usePermission(APPRAISALS_HISTORY);
  const isAppraisalEditAllow = usePermission(APPRAISALS_EDIT);
  const isAppraisalAllow = usePermission(APPRAISALS_ALL_LIST);

  useEffect(() => {
    if (authCtx.userData?.user_type === 0) {
      getManagers(null);
    }
    getSalesUsers();
    getLeadDetails();
    getLeadHistory();
  }, [id]);

  const getLeadHistory = () => {
    if (isAppraisalHistoryAllow) {
      api
        .post(
          "api/leads/history",
          { lead_id: id },
          {
            headers: {
              Authorization: `Bearer ${authCtx.userData?.token}`,
            },
          }
        )
        .then((response) => {
          if (response.data.status) {
            let historyArray = [];
            for (let day in response.data.data) {
              historyArray.push(day);
              response.data.data[day].map((n) => {
                historyArray.push(n);
              });
            }
            setLeadHistory(historyArray);
          }
        })
        .catch((error) => {
          Swal.fire(
            "Opps!",
            "Sorry we are not able to complete your request at this time. Please try again later.",
            "error"
          );
        });
    }
  };

  const getLeadDetails = async () => {
    await api
      .post(
        "api/leads/get-lead",
        { lead_id: id },
        {
          headers: {
            Authorization: `Bearer ${authCtx.userData?.token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.status) {
          setResponseData(response.data.data);
          setAutoFill(true);
          setValue("first_name", response.data.data?.lead?.first_name);
          setValue("last_name", response.data.data?.lead?.last_name);
          setValue("email", response.data.data?.lead?.email);
          setValue("mobile_no", response.data.data?.lead?.mobile_no);
          if (response.data.data?.lead?.deals_date != null) {
            setDealDate(
              new Date(
                moment(response.data.data?.lead?.deals_date).format(
                  "YYYY-MM-DD"
                )
              )
            );
          }
          setValue(
            "deals_price",
            formatPrice(parseInt(response.data.data?.lead?.old_deals_price))
              .formatted
          );
          setValue(
            "counter_offer_price",
            formatPrice(parseInt(response.data.data?.lead?.counter_offer_price))
              .formatted
          );
          setValue(
            "final_offer_price",
            formatPrice(parseInt(response.data.data?.lead?.final_offer_price))
              .formatted
          );

          if (response.data.data?.lead?.final_offer_price != 0) {
            setFinalDealAmount(
              formatPrice(parseInt(response.data.data?.lead?.final_offer_price))
                .base
            );
          } else if (response.data.data?.lead?.counter_offer_price != 0) {
            setFinalDealAmount(
              formatPrice(
                parseInt(response.data.data?.lead?.counter_offer_price)
              ).base
            );
          } else {
            setFinalDealAmount("");
          }

          setValue("status", response.data.data?.lead?.status);
          setValue("make", response.data.data?.vehicle?.make);
          setValue("model", response.data.data?.vehicle?.model);
          setValue("trim", response.data.data?.vehicle?.trim);
          setValue("vin", response.data.data?.vehicle?.vin);
          setValue("year", response.data.data?.vehicle?.year);
          setValue("mileage", response.data.data?.vehicle?.mileage);
          setValue(
            "interior_color",
            response.data.data?.vehicle?.interior_color
          );
          setValue(
            "exterior_color",
            response.data.data?.vehicle?.exterior_color
          );
          setValue(
            "estimated_repair_order_cost",
            response.data.data?.lead?.estimated_repair_order_cost
          );
          setValue(
            "estimated_service_time",
            response.data.data?.lead?.estimated_service_time
          );
          setValue(
            "price",
            formatPrice(parseInt(response.data.data?.vehicle?.price)).formatted
          );
          if (response.data.data?.lead?.status == 5) {
            setNoDealReason(response.data.data?.lead?.no_deal_reason);
          }
          setValue(
            "service_advisor_assign",
            response.data.data?.lead.service_advisor_assign
          );
          setValue("manager_assign", response.data.data?.lead.manager_assign);
          setValue("sales_assign", response.data.data?.lead.sales_assign);

          if (
            response.data.data?.lead?.status == 2 ||
            response.data.data?.lead?.status == 4 ||
            response.data.data?.lead?.status == 6 ||
            response.data.data?.lead?.status == 3 ||
            response.data.data?.lead?.status == 7
          ) {
            if (response.data.data?.lead?.service_advisor_assign != 0) {
              setValue("present_offer", 1);
            } else if (response.data.data?.lead?.manager_assign != 0) {
              setValue("present_offer", 2);
            } else if (response.data.data?.lead?.sales_assign != 0) {
              setValue("present_offer", 3);
            }
          }
          if (
            authCtx.userData?.user_type === 0 ||
            authCtx.userData?.user_type === 1 ||
            authCtx.userData?.user_type === 3
          ) {
            getManagers(response.data.data?.lead?.business_id);
            getSalesUsers(response.data.data?.lead?.id);
            getEmployees(response.data.data?.lead?.business_id);
          }
          const notesWrap = document.querySelector(".offer-notes-inner");
          setTimeout(() => {
            notesWrap.scrollTo({
              top: notesWrap.scrollHeight,
              behavior: "smooth",
            });
          }, 500);
        }
      })
      .catch((error) => {
        Swal.fire(
          "Opps!",
          "Sorry we are not able to complete your request at this time. Please try again later.",
          "error"
        );
      });
  };

  const getManagers = async (id) => {
    await api
      .post(
        `api/users/get-manager`,
        { business_id: id },
        {
          headers: {
            Authorization: `Bearer ${authCtx.userData?.token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.status) {
          let salesUsersArray = [];
          if (response.data.data.length > 0) {
            response.data.data.map((element) => {
              salesUsersArray.push({
                value: element.id,
                label: element.name,
              });
            });
            setManagers(salesUsersArray);
          } else {
            setManagers([]);
          }
        } else {
          setManagers([]);
        }
      })
      .catch((error) => {
        if (error.response.status == 401) {
          authCtx.logout();
        } else {
          Swal.fire(
            "Opps!",
            "Sorry we are not able to complete your request at this time. Please try again later.",
            "error"
          );
        }
      });
  };

  const getSalesUsers = async (id) => {
    //alert(id);
    await api
      .post(
        `/api/sales`,
        { lead_id: id },
        {
          headers: {
            Authorization: `Bearer ${authCtx.userData?.token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.status) {
          let salesUsersArray = [];
          if (response.data.data.length > 0) {
            response.data.data.map((element) => {
              salesUsersArray.push({
                value: element.id,
                label: element.name,
              });
            });
            setSalesUsers(salesUsersArray);
          } else {
            setSalesUsers([]);
          }
        } else {
          setSalesUsers([]);
        }
      })
      .catch((error) => {
        if (error.response.status == 401) {
          authCtx.logout();
        } else {
          Swal.fire(
            "Opps!",
            "Sorry we are not able to complete your request at this time. Please try again later.",
            "error"
          );
        }
      });
  };

  const getEmployees = async (id) => {
    await api
      .post(
        `api/service-writer`,
        { business_id: id },
        {
          headers: {
            Authorization: `Bearer ${authCtx.userData?.token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.status) {
          let employeesArray = [];
          if (response.data.data.length > 0) {
            response.data.data.map((element) => {
              employeesArray.push({
                value: element.id,
                label: element.name,
              });
            });
            setEmployees(employeesArray);
          }
        }
      })
      .catch((error) => {
        if (error.response.status == 401) {
          authCtx.logout();
        } else {
          Swal.fire(
            "Opps!",
            "Sorry we are not able to complete your request at this time. Please try again later.",
            "error"
          );
        }
      });
  };

  const {
    register,
    formState: { errors },
    control,
    watch,
    setValue,
    trigger,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const { fields, append, remove } = useFieldArray({
    control: control,
    name: "image",
  });

  const { getInputProps, getRootProps } = useDropzone({
    onDrop: (image) =>
      image.forEach((file) => {
        setUploadedImages((prev) => [
          ...prev,
          {
            file: file,
          },
        ]);
        append({
          name: file.name,
          preview: URL.createObjectURL(file),
          file: file,
        });
      }),
  });

  const formData = watch();

  useEffect(() => {
    if (formData.status == "0") {
      setStatusClass("status-new");
    } else if (formData.status == "1") {
      setStatusClass("status-working");
    } else if (formData.status == "2") {
      setStatusClass("status-offermade");
    } else if (formData.status == "3") {
      setStatusClass("status-accepted-purchase");
    } else if (formData.status == "4") {
      setStatusClass("status-appraised");
    } else if (formData.status == "5") {
      setStatusClass("status-no-deal");
    } else if (formData.status == "6") {
      setStatusClass("status-counteroffer");
    } else if (formData.status == "7") {
      setStatusClass("status-finaloffer");
    }

    if (formData.status == "3") {
      setIsAppraisalLocked(true);
    } else {
      setIsAppraisalLocked(false);
    }
  }, [formData.status]);

  useEffect(() => {
    if (skipFirstRender.current) {
      skipFirstRender.current = skipFirstRender.current + 1;
    }
    if (skipFirstRender.current > 5 && isAppraisalEditAllow) {
      const afterChangeTimer = setTimeout(async () => {
        const isAppraisalAllowed = await trigger([
          "first_name",
          "last_name",
          "email",
          "mobile_no",
          "vin",
          "year",
          "make",
          "model",
          "trim",
          "mileage",
          "estimated_repair_order_cost",
          "estimated_service_time",
        ]);
        if (isAppraisalAllowed) {
          setIsAutoSaved(false);
          let payloadData = new FormData();
          payloadData.append("lead_id", id);
          payloadData.append("first_name", formData.first_name);
          payloadData.append("last_name", formData.last_name);
          payloadData.append("email", formData.email);
          payloadData.append(
            "mobile_no",
            formatPhoneNumber(formData.mobile_no).base
          );
          payloadData.append("vin", formData.vin);
          payloadData.append("year", formData.year);
          payloadData.append("make", !autoFill ? selectedMake : formData.make);
          payloadData.append(
            "model",
            !autoFill ? selectedModel : formData.model
          );
          payloadData.append("trim", !autoFill ? selectedTrim : formData.trim);
          payloadData.append("mileage", formData.mileage);
          payloadData.append("price", formatPrice(formData.price).base);
          payloadData.append("interior_color", formData.interior_color);
          payloadData.append("exterior_color", formData.exterior_color);
          payloadData.append("status", formData.status);
          payloadData.append(
            "estimated_repair_order_cost",
            formData.estimated_repair_order_cost
          );
          payloadData.append(
            "estimated_service_time",
            formData.estimated_service_time
          );
          if (dealDate != null) {
            payloadData.append(
              "deals_date",
              moment(dealDate).format("YYYY-MM-DD")
            );
          }
          payloadData.append(
            "deals_price",
            formatPrice(formData.deals_price).base
          );
          payloadData.append(
            "counter_offer_price",
            formatPrice(formData.counter_offer_price).base
          );
          payloadData.append(
            "final_offer_price",
            formatPrice(formData.final_offer_price).base
          );

          if (formData.present_offer == 1) {
            payloadData.append(
              "service_advisor_assign",
              formData.service_advisor_assign
            );
          } else if (formData.present_offer == 2) {
            payloadData.append("manager_assign", formData.manager_assign);
          } else if (formData.present_offer == 3) {
            payloadData.append("present_offer", formData.present_offer);
            payloadData.append("sales_assign", formData.sales_assign);
          }

          if (formData.status == 5) {
            payloadData.append("no_deal_reason", noDealReason);
          }
          for (var i = 0; i < uploadedImages.length; i++) {
            payloadData.append("image[]", uploadedImages[i]?.file);
          }

          api
            .post("api/leads/update-lead", payloadData, {
              headers: {
                "Content-type": "multipart/form-data",
                Authorization: `Bearer ${authCtx.userData?.token}`,
              },
            })
            .then((response) => {
              if (response.data.status) {
                setUploadedImages([]);
                getLeadHistory();
                setIsAutoSaved(true);

                finalPriceFunction(formData.counter_offer_price, formData.final_offer_price);
              }
            })
            .catch((error) => {
              if (error.response.status == 401) {
                authCtx.logout();
              } else {
                Swal.fire(
                  "Opps!",
                  "Sorry we are not able to complete your request at this time. Please try again later.",
                  "error"
                );
              }
            });
        }
      }, 1500);
      return () => {
        clearTimeout(afterChangeTimer);
      };
    }
  }, [
    formData.first_name,
    formData.last_name,
    formData.mobile_no,
    formData.email,
    formData.vin,
    formData.year,
    formData.make,
    formData.model,
    formData.trim,
    formData.mileage,
    formData.exterior_color,
    formData.interior_color,
    formData.price,
    formData.estimated_repair_order_cost,
    formData.image,
    formData.setNoDealReason,
    formData.present_offer,
    formData.manager_assign,
    formData.service_advisor_assign,
    formData.sales_assign,
    formData.estimated_service_time,
  ]);

  useEffect(() => {
    setValue("mobile_no", formatPhoneNumber(formData.mobile_no).formatted);
    setValue("price", formatPrice(formData.price).formatted);
    setValue("counter_offer_price", formatPrice(formData.counter_offer_price).formatted);
    setValue("final_offer_price", formatPrice(formData.final_offer_price).formatted);
    setValue("deals_price", formatPrice(formData.deals_price).formatted);
  }, [
    formData.mobile_no,
    formData.price,
    formData.counter_offer_price,
    formData.final_offer_price,
    formData.deals_price
  ]);

  const onDealAmountBlur = async () => {
    const isAppraisalAllowed = await trigger(["deals_price"]);
    if (isAppraisalAllowed) {
      setIsAutoSaved(false);
      // setValue("counter_offer_price", 0);
      // setValue("final_offer_price", 0);
      let payloadData = {
        lead_id: id,
        first_name: formData.first_name,
        last_name: formData.last_name,
        email: formData.email,
        mobile_no: formatPhoneNumber(formData.mobile_no).base,
        vin: formData?.vin,
        year: formData?.year,
        make: !autoFill ? selectedMake : formData.make,
        model: !autoFill ? selectedModel : formData.model,
        trim: !autoFill ? selectedTrim : formData.trim,
        mileage: formData?.mileage,
        price: formatPrice(formData?.price).base,
        counter_offer_price: 0,
        final_offer_price: 0,
        interior_color: formData?.interior_color,
        exterior_color: formData?.exterior_color,
        status: formData?.status,
        estimated_repair_order_cost: formData?.estimated_repair_order_cost,
        estimated_service_time: formData?.estimated_service_time,
        deals_price: formatPrice(formData?.deals_price).base,
      };
      await api
        .post("api/leads/update-lead", payloadData, {
          headers: {
            Authorization: `Bearer ${authCtx.userData?.token}`,
          },
        })
        .then((response) => {
          if (response.data.status) {
            setValue("deals_price", formatPrice(parseInt(response.data.data?.lead?.deals_price)).formatted);
            setValue("status", response.data.data?.lead?.status);
            getLeadHistory();
            setIsAutoSaved(true);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            authCtx.logout();
          } else {
            Swal.fire(
              "Opps!",
              "Sorry we are not able to complete your request at this time. Please try again later.",
              "error"
            );
          }
        });
    }
  };


  const updateOfferPrice = async () => {
    setIsAutoSaved(false);
    let payloadData = {
      lead_id: id,
      first_name: formData.first_name,
      last_name: formData.last_name,
      email: formData.email,
      mobile_no: formatPhoneNumber(formData.mobile_no).base,
      vin: formData?.vin,
      year: formData?.year,
      make: !autoFill ? selectedMake : formData.make,
      model: !autoFill ? selectedModel : formData.model,
      trim: !autoFill ? selectedTrim : formData.trim,
      mileage: formData?.mileage,
      price: formatPrice(formData?.price).base,
      counter_offer_price: formatPrice(formData?.counter_offer_price).base,
      final_offer_price: formatPrice(formData?.final_offer_price).base,
      interior_color: formData?.interior_color,
      exterior_color: formData?.exterior_color,
      status: formData?.status,
      estimated_repair_order_cost: formData?.estimated_repair_order_cost,
      estimated_service_time: formData?.estimated_service_time,
      deals_price: formatPrice(formData?.deals_price).base,
    };
    await api
      .post("api/leads/update-lead", payloadData, {
        headers: {
          Authorization: `Bearer ${authCtx.userData?.token}`,
        },
      })
      .then((response) => {
        if (response.data.status) {
          setValue("deals_price", formatPrice(parseInt(response.data.data?.lead?.old_deals_price)).formatted);
          setValue("status", response.data.data?.lead?.status);
          getLeadHistory();
          setIsAutoSaved(true);
        }
      })
      .catch((error) => {
        if (error.response.status == 401) {
          authCtx.logout();
        } else {
          Swal.fire(
            "Opps!",
            "Sorry we are not able to complete your request at this time. Please try again later.",
            "error"
          );
        }
      });
  };

  const onStatusChangeHandler = async (e) => {
    setIsAutoSaved(false);
    const counterOfferPrice = formData.counter_offer_price !== null && formData.counter_offer_price !== "$" ? formatPrice(formData.counter_offer_price).base : '0';
    const finalOfferPrice = formData.final_offer_price !== null && formData.final_offer_price !== "$" ? formatPrice(formData.final_offer_price).base : '0';

    let payloadData = {
      lead_id: id,
      first_name: formData.first_name,
      last_name: formData.last_name,
      email: formData.email,
      mobile_no: formatPhoneNumber(formData.mobile_no).base,
      vin: formData?.vin,
      year: formData?.year,
      make: !autoFill ? selectedMake : formData.make,
      model: !autoFill ? selectedModel : formData.model,
      trim: !autoFill ? selectedTrim : formData.trim,
      mileage: formData?.mileage,
      price: formatPrice(formData?.price).base,
      interior_color: formData?.interior_color,
      exterior_color: formData?.exterior_color,
      estimated_repair_order_cost: formData.estimated_repair_order_cost,
      estimated_service_time: formData.estimated_service_time,
      status: e.target.value,
      counter_offer_price: counterOfferPrice,
      final_offer_price: finalOfferPrice,
    };

    // if (
    //   e.target.value == 0 ||
    //   e.target.value == 1 ||
    //   e.target.value == 2 ||
    //   e.target.value == 4 ||
    //   e.target.value == 6 ||
    //   e.target.value == 7
    // ) {
    //   payloadData = {
    //     ...payloadData,
    //     counter_offer_price: 0,
    //     final_offer_price: 0,
    //   };
    // }

    // if (e.target.value == 3) {
    //   payloadData = {
    //     ...payloadData,
    //     counter_offer_price: formatPrice(formData.counter_offer_price).base,
    //     final_offer_price: formatPrice(formData.final_offer_price).base,
    //   };
    // }

    payloadData = {
      ...payloadData,
      deals_price: formatPrice(formData.deals_price).base,
    };

    if (dealDate != null) {
      payloadData = {
        ...payloadData,
        deals_date: moment().format("YYYY-MM-DD"),
      };
    }
    await api
      .post("api/leads/update-lead", payloadData, {
        headers: {
          Authorization: `Bearer ${authCtx.userData?.token}`,
        },
      })
      .then((response) => {
        if (response.data.status) {
          setValue(
            "deals_price",
            formatPrice(parseInt(response.data.data?.lead?.old_deals_price))
              .formatted
          );

          finalPriceFunction(formData.counter_offer_price, formData.final_offer_price);

          setValue("status", response.data.data?.lead?.status);
          getLeadHistory();
          setIsAutoSaved(true);
        }
      })
      .catch((error) => {
        if (error.response.status == 401) {
          authCtx.logout();
        } else {
          Swal.fire(
            "Opps!",
            "Sorry we are not able to complete your request at this time. Please try again later.",
            "error"
          );
        }
      });
  };

  const finalPriceFunction = (counter, final) => {
    const finalOfferPrice = formatPrice(final).base;
    const finalCounterOfferPrice = formatPrice(counter).base;

    if (finalOfferPrice !== "$" && finalOfferPrice !== "") {
      setFinalDealAmount(parseInt(finalOfferPrice));
    } else if (finalCounterOfferPrice !== "$" && finalCounterOfferPrice !== "") {
      setFinalDealAmount(parseInt(finalCounterOfferPrice));
    }
  };


  const onChangeHandler = (type) => {
    setNoDealReason(type);
    setValue("setNoDealReason", type);
  };

  const onVinChange = (value) => {
    if (value.length == 17) {
      api
        .get(`api/leads/chrome-data?vin=${value}`, {
          headers: {
            Authorization: `Bearer ${authCtx.userData?.token}`,
          },
        })
        .then((response) => {
          if (response.data.status) {
            if (response.data.data.year != "") {
              if (response.data.data.year) {
                setAutoFill(true);
                setValue("year", response.data.data.year);
              }
              if (response.data.data.model != "") {
                setValue("model", response.data.data.model);
              }
              if (response.data.data.trim != "") {
                setValue("trim", response.data.data.trim);
              }
              if (response.data.data.make != "") {
                setValue("make", response.data.data.make);
              } else {
                setAutoFill(false);
              }
            } else {
              setAutoFill(false);
            }
          } else {
            setAutoFill(false);
          }
        })
        .catch((error) => { });
    } else {
      setAutoFill(false);
    }
  };

  const yearChange = async (value, key) => {
    setValue("year", value);
    await api
      .get(`api/leads/chrome-data?getMake=true&modelYear=${value}`, {
        headers: {
          Authorization: `Bearer ${authCtx.userData?.token}`,
        },
      })
      .then((response) => {
        if (response.data.status) {
          setModels([]);
          setTrims([]);
          setAutoFill(false);
          if (Object.keys(response.data.data).length > 0) {
            let makeArray = [];
            Object.entries(response.data.data).forEach((element) => {
              makeArray.push({
                value: element[0],
                label: element[1],
              });
            });
            setMakes(makeArray);
          } else {
            setMakes([]);
          }
        } else {
          setAutoFill(true);
          setMakes([]);
        }
      })
      .catch((error) => { });
  };

  const makeChange = async (value, key) => {
    setSelectedMake(key);
    await api
      .get(
        `api/leads/chrome-data?getModel=true&modelYear=${formData.year}&modelMake=${value}`,
        {
          headers: {
            Authorization: `Bearer ${authCtx.userData?.token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.status) {
          setTrims([]);
          if (Object.keys(response.data.data).length > 0) {
            let modelArray = [];
            Object.entries(response.data.data).forEach((element) => {
              modelArray.push({
                value: element[0],
                label: element[1],
              });
            });
            setModels(modelArray);
          } else {
            setModels([]);
          }
        } else {
          setModels([]);
        }
      })
      .catch((error) => { });
  };

  const modelChange = async (value, key) => {
    setSelectedModel(key);
    await api
      .get(`api/leads/chrome-data?getTrim=true&modelId=${value}`, {
        headers: {
          Authorization: `Bearer ${authCtx.userData?.token}`,
        },
      })
      .then((response) => {
        if (response.data.status) {
          if (Object.keys(response.data.data).length > 0) {
            let trimArray = [];
            Object.entries(response.data.data).forEach((element) => {
              trimArray.push({
                value: element[0],
                label: element[1],
              });
            });
            setTrims(trimArray);
          } else {
            setTrims([]);
          }
        } else {
          setTrims([]);
        }
      })
      .catch((error) => { });
  };

  const trimChange = (value, key) => {
    setSelectedTrim(key);
  };

  const deleteImageHandler = (image) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        api
          .post(
            "api/vehicles/delete-vehicle-image",
            {
              vehicle_id: responseData?.vehicle.id,
              lead_id: responseData?.vehicle.lead_id,
              image: image,
            },
            {
              headers: {
                Authorization: `Bearer ${authCtx.userData?.token}`,
              },
            }
          )
          .then((response) => {
            setLoading(false);
            if (response.data.status) {
              getLeadDetails();
              getLeadHistory();
              Swal.fire(response.data.message, "", "success");
            } else {
              Swal.fire("Try Again!", response.data.message, "info");
            }
          })
          .catch((error) => {
            setLoading(false);
            Swal.fire(
              "Opps!",
              "Sorry we are not able to complete your request at this time. Please try again later.",
              "error"
            );
          });
      }
    });
  };

  const {
    register: register2,
    formState: { errors: errors2 },
    handleSubmit: handleSubmit2,
    reset: reset2,
    setValue: setValue2,
  } = useForm({
    resolver: yupResolver(validationSchema2),
  });

  const onNoteSubmit = async (formValue) => {
    setLoading(true);
    formValue.lead_id = id;
    if (noteId != null) {
      formValue.note_id = noteId;
    }
    await api
      .post(
        `${noteId != null ? "api/notes/update-note" : "api/notes/add-note"}`,
        formValue,
        {
          headers: {
            Authorization: `Bearer ${authCtx.userData?.token}`,
          },
        }
      )
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          getLeadHistory();
          setNoteId(null);
          reset2();
          api
            .post(
              "api/leads/get-lead",
              { lead_id: id },
              {
                headers: {
                  Authorization: `Bearer ${authCtx.userData?.token}`,
                },
              }
            )
            .then((res) => {
              setRawNotes(res.data.data?.note);
              const notesWrap = document.querySelector(".offer-notes-inner");
              setTimeout(() => {
                notesWrap.scrollTo({
                  top: notesWrap.scrollHeight,
                  behavior: "smooth",
                });
              }, 500);
            })
            .catch((error) => {
              Swal.fire(
                "Opps!",
                "Sorry we are not able to complete your request at this time. Please try again later.",
                "error"
              );
            });
        } else {
          Swal.fire(response.data.message, "", "info");
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          authCtx.logout();
        } else {
          Swal.fire(
            "Opps!",
            "Sorry we are not able to complete your request at this time. Please try again later.",
            "error"
          );
        }
      });
  };

  const noteEditHandler = (data) => {
    setNoteId(data?.id);
    setValue2("lead_notes", data?.lead_notes);
  };

  const noteDeleteHandler = (data) => {
    if (id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          setLoading(true);
          api
            .post(
              "api/notes/delete-note",
              {
                note_id: data?.id,
                lead_id: id,
              },
              {
                headers: {
                  Authorization: `Bearer ${authCtx.userData?.token}`,
                },
              }
            )
            .then((response) => {
              setLoading(false);
              if (response.data.status) {
                getLeadDetails();
                setRawNotes([]);
              } else {
                Swal.fire("Try Again!", response.data.message, "info");
              }
            })
            .catch((error) => {
              setLoading(false);
              Swal.fire(
                "Opps!",
                "Sorry we are not able to complete your request at this time. Please try again later.",
                "error"
              );
            });
        }
      });
    }
    return false;
  };

  const noteCancelHandler = () => {
    reset2();
    setNoteId(null);
  };

  const onScanComplete = (vin) => {
    setValue("vin", vin);
  };

  return (
    <>
      <div className="appraisal-head-wrap edit-page mb-24">
        <div className="row">
          <div className="col">
            <div className="appraisal-head-title">
              <button
                type="button"
                className="btn-icon"
                onClick={() => navigate(-1)}>
                <i className="icon icon-back-aero"></i>
              </button>
              <h2 className="page-title mb-0">
                Appraisal #
                {authCtx.userData?.user_type == 3
                  ? id
                  : responseData?.lead?.sequence_id}
              </h2>
            </div>
          </div>
          <div className="col-auto">
            <div className="appraisal-head-buttons">
              <Link
                to={routes.Appraisals}
                className="btn btn-outline-secondary">
                Go Back
              </Link>
              {isAppraisalEditAllow && (
                <button
                  type="button"
                  className="btn btn-outline-primary d-inline-flex align-items-center"
                  disabled>
                  {!isAutoSaved ? (
                    <div
                      className="spinner-border spinner-border-sm me-2"
                      role="status">
                      <span className="visually-hidden">Loading</span>
                    </div>
                  ) : (
                    <i className="icon icon-save"></i>
                  )}
                  {isAutoSaved ? "Saved" : "Loading"}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="appraisal-tabs">
        {isAppraisalHistoryAllow && (
          <ul className="nav nav-pills" id="pills-tab" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className="nav-link active"
                id="pills-details-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-details"
                type="button"
                role="tab"
                aria-controls="pills-details"
                aria-selected="true">
                details
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="pills-history-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-history"
                type="button"
                role="tab"
                aria-controls="pills-history"
                aria-selected="false">
                history
              </button>
            </li>
          </ul>
        )}
        <div className="tab-content" id="pills-tabContent">
          <div
            className={`${isAppraisalHistoryAllow ? "tab-pane" : ""
              } fade show active`}
            id="pills-details"
            role="tabpanel"
            aria-labelledby="pills-details-tab">
            <div className="row">
              <div className="col-xl-6 col-lg-12 appraisalcol">
                <div className="dashboard-card">
                  <div className="appraisal-form-detail customer-details">
                    <div className="appraisal-form-head">
                      <div className="row">
                        <div className="col">
                          <h5 className="card-title">Customer</h5>
                        </div>
                      </div>
                    </div>
                    <div className="appraisal-form-inner mb-24">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="col-form-label">First Name</label>
                            <input
                              className="form-control"
                              type="text"
                              {...register("first_name")}
                              maxLength={42}
                              readOnly={isAppraisalLocked}
                            />
                            {errors.first_name?.message != undefined && (
                              <span className="form-error">
                                {errors.first_name?.message}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="col-form-label">Last Name</label>
                            <input
                              className="form-control"
                              type="text"
                              {...register("last_name")}
                              maxLength={42}
                              readOnly={isAppraisalLocked}
                            />
                            {errors.last_name?.message != undefined && (
                              <span className="form-error">
                                {errors.last_name?.message}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <label className="col-form-label">Phone</label>
                            <input
                              className="form-control"
                              type="text"
                              {...register("mobile_no")}
                              maxLength={12}
                              readOnly={isAppraisalLocked}
                            />
                            {errors.mobile_no?.message != undefined && (
                              <span className="form-error">
                                {errors.mobile_no?.message}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <label className="col-form-label">Email</label>
                            <input
                              className="form-control"
                              type="email"
                              {...register("email")}
                              maxLength={42}
                              readOnly={isAppraisalLocked}
                            />
                            {errors.email?.message != undefined && (
                              <span className="form-error">
                                {errors.email?.message}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="appraisal-form-detail vehicle-details">
                    <div className="appraisal-form-head">
                      <div className="row align-items-center">
                        <div className="col">
                          <h5 className="card-title">Vehicle</h5>
                        </div>
                        <div className="col-auto">
                          <a
                            href={`https://www.carfax.com/VehicleHistory/p/Report.cfx?partner=aan_0&vin=${formData.vin}`}
                            target="_blank">
                            <img src={carfaxLogo} alt="" />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="appraisal-form-inner mb-24">
                      <div className="row">
                        <div className="col-xxl-6 col-xl-12 col-md-6">
                          <div className="form-group">
                            <label className="col-form-label">Vin</label>
                            <div className="position-relative flex-fill">
                              <input
                                className="form-control"
                                type="text"
                                maxLength={17}
                                {...register("vin")}
                                onChange={(e) => onVinChange(e.target.value)}
                                readOnly={isAppraisalLocked}
                              />
                              {/* <VinScanner onScanComplete={onScanComplete} /> */}
                            </div>
                            {errors.vin?.message != undefined && (
                              <span className="form-error">
                                {errors.vin?.message}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="col-form-label">Year</label>
                            <select
                              className="form-select"
                              {...register("year")}
                              onChange={(e) =>
                                yearChange(
                                  e.target.value,
                                  e.target.options[e.target.selectedIndex].text
                                )
                              }
                              disabled={isAppraisalLocked}>
                              <option value="">Select Year</option>
                              {_.map(years, (option) => (
                                <option key={option.value} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </select>
                            {errors.year?.message != undefined && (
                              <span className="form-error">
                                {errors.year?.message}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="col-form-label">Make</label>
                            {!autoFill && (
                              <select
                                className="form-select"
                                {...register("make")}
                                onChange={(e) =>
                                  makeChange(
                                    e.target.value,
                                    e.target.options[e.target.selectedIndex]
                                      .text
                                  )
                                }
                                disabled={isAppraisalLocked}>
                                <option value="" selected={!autoFill}>
                                  Select Make
                                </option>
                                {_.map(makes, (option) => (
                                  <option
                                    key={option.value}
                                    value={option.value}>
                                    {option.label}
                                  </option>
                                ))}
                              </select>
                            )}
                            {autoFill && (
                              <input
                                className="form-control"
                                {...register("make")}
                                disabled={autoFill || isAppraisalLocked}
                              />
                            )}
                            {errors.make?.message != undefined && (
                              <span className="form-error">
                                {errors.make?.message}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="col-form-label">Model</label>
                            {!autoFill && (
                              <select
                                className="form-select"
                                {...register("model")}
                                onChange={(e) =>
                                  modelChange(
                                    e.target.value,
                                    e.target.options[e.target.selectedIndex]
                                      .text
                                  )
                                }
                                disabled={isAppraisalLocked}>
                                <option value="" selected={!autoFill}>
                                  Select Model
                                </option>
                                {_.map(models, (option) => (
                                  <option
                                    key={option.value}
                                    value={option.value}>
                                    {option.label}
                                  </option>
                                ))}
                              </select>
                            )}
                            {autoFill && (
                              <input
                                className="form-control"
                                {...register("model")}
                                disabled={autoFill || isAppraisalLocked}
                              />
                            )}
                            {errors.model?.message != undefined && (
                              <span className="form-error">
                                {errors.model?.message}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="col-form-label">Trim</label>
                            {!autoFill && (
                              <select
                                className="form-select"
                                {...register("trim")}
                                onChange={(e) =>
                                  trimChange(
                                    e.target.value,
                                    e.target.options[e.target.selectedIndex]
                                      .text
                                  )
                                }
                                disabled={isAppraisalLocked}>
                                <option value="" selected={!autoFill}>
                                  Select Trim
                                </option>
                                {_.map(trims, (option) => (
                                  <option
                                    key={option.value}
                                    value={option.value}>
                                    {option.label}
                                  </option>
                                ))}
                              </select>
                            )}
                            {autoFill && (
                              <input
                                className="form-control"
                                {...register("trim")}
                                readOnly={isAppraisalLocked}
                              />
                            )}
                            {errors.trim?.message != undefined && (
                              <span className="form-error">
                                {errors.trim?.message}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="col-form-label">Mileage</label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("mileage")}
                              maxLength={42}
                              readOnly={isAppraisalLocked}
                            />
                            {errors.mileage?.message != undefined && (
                              <span className="form-error">
                                {errors.mileage?.message}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="col-form-label">
                              Exterior color
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("exterior_color")}
                              maxLength={42}
                              readOnly={isAppraisalLocked}
                            />
                            {errors.exterior_color?.message != undefined && (
                              <span className="form-error">
                                {errors.exterior_color?.message}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="col-form-label">
                              Interior color
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("interior_color")}
                              maxLength={42}
                              readOnly={isAppraisalLocked}
                            />
                            {errors.interior_color?.message != undefined && (
                              <span className="form-error">
                                {errors.interior_color?.message}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <label className="col-form-label">
                              Asking Price
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("price")}
                              maxLength={10}
                              readOnly={isAppraisalLocked}
                            />
                            {errors.price?.message != undefined && (
                              <span className="form-error">
                                {errors.price?.message}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <label className="col-form-label">
                              Estimated Repair Order Cost
                            </label>
                            <select
                              className="form-select"
                              {...register("estimated_repair_order_cost")}
                              disabled={isAppraisalLocked}>
                              <option value="">Select Cost</option>
                              <option value="$0 - $499">$0 - $499</option>
                              <option value="$500 - $999">$500 - $999</option>
                              <option value="$1,000 - $1,999">
                                $1,000 - $1,999
                              </option>
                              <option value="$2,000 - $3,999">
                                $2,000 - $3,999
                              </option>
                              <option value="Over $4,000">Over $4,000</option>
                            </select>
                            {errors.estimated_repair_order_cost?.message !=
                              undefined && (
                                <span className="form-error">
                                  {errors.estimated_repair_order_cost?.message}
                                </span>
                              )}
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group mb-24">
                            <label className="col-form-label">
                              Estimated Service Time
                            </label>
                            <select
                              className="form-select"
                              {...register("estimated_service_time")}>
                              <option value="">Select Time</option>
                              <option value="0-1 hour">0-1 hour</option>
                              <option value="1-2 hours">1-2 hours</option>
                              <option value="2-4 hours">2-4 hours</option>
                              <option value="4-6 hours">4-6 hours</option>
                              <option value="1 day">1 day</option>
                              <option value="1-3 days">1-3 days</option>
                              <option value="3-5 days">3-5 days</option>
                              <option value="1 week">1 week</option>
                              <option value="1-2 weeks">1-2 weeks</option>
                              <option value="2+ weeks">2+ weeks</option>
                            </select>
                            {errors.estimated_service_time?.message !=
                              undefined && (
                                <span className="form-error">
                                  {errors.estimated_service_time?.message}
                                </span>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="appraisal-form-detail vehicle-photos">
                    <div className="appraisal-form-head">
                      <div className="row">
                        <div className="col">
                          <h5 className="card-title">Vehicle Photos</h5>
                        </div>
                        {isAppraisalEditAllow && !isAppraisalLocked && (
                          <div className="col-auto">
                            <div className="upload-button" {...getRootProps()}>
                              <i className="icon icon-upload"></i>
                              <span>Upload Photo</span>
                              <input {...getInputProps()} />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="appraisal-form-inner">
                      <div className="row">
                        {responseData?.vehicle?.image.length == 0 &&
                          fields.length == 0 ? (
                          <div className="col-sm-12">
                            <p className="mt-2">No Images Found.</p>
                          </div>
                        ) : (
                          <Fancybox>
                            {responseData?.vehicle?.image.map((f, index) => (
                              <div
                                className="col-lg-3 col-sm-3 vehicleuplaodcol"
                                key={index}>
                                <div className="vehicle-photo-wrap">
                                  <img
                                    src={f}
                                    alt=""
                                    data-src={f}
                                    data-fancybox="gallery"
                                    className="img-fluid"
                                  />
                                  {isAppraisalEditAllow && (
                                    <i
                                      className="icon icon-close"
                                      onClick={() =>
                                        deleteImageHandler(
                                          f.replace(/^.*[\\\/]/, "")
                                        )
                                      }></i>
                                  )}
                                </div>
                              </div>
                            ))}
                          </Fancybox>
                        )}
                        {isAppraisalEditAllow &&
                          !isAppraisalLocked &&
                          fields.map((f, index) => (
                            <div
                              className="col-lg-3 col-sm-3 vehicleuplaodcol"
                              key={index}>
                              <div className="vehicle-photo-wrap">
                                <img
                                  src={f.preview}
                                  alt=""
                                  className="img-fluid"
                                />
                                <i
                                  className="icon icon-close"
                                  onClick={() => remove(index)}></i>
                              </div>
                            </div>
                          ))}
                        {isAppraisalEditAllow && !isAppraisalLocked && (
                          <div className="col-lg-3 col-sm-3 vehicleuplaodcol">
                            <div
                              className="vehicle-photos-upload"
                              {...getRootProps()}>
                              <i className="icon icon-add-picture"></i>
                              <input
                                {...getInputProps()}
                                disabled={isAppraisalLocked}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-12 appraisalcol">
                <div className="dashboard-card">
                  {isAppraisalAllow && (
                    <div className="appraisal-form-detail manager-details">
                      <div className="appraisal-form-head">
                        <div className="row">
                          <div className="col">
                            <h5 className="card-title">Submitted by</h5>
                          </div>
                        </div>
                      </div>
                      <div className="manager-details-inner">
                        <div className="row align-items-center">
                          <div className="col">
                            <div className="manager-details-user">
                              <div className="manager-image">
                                <img
                                  src={
                                    responseData?.lead?.userImage != ""
                                      ? responseData?.lead?.userImage
                                      : userImg
                                  }
                                  alt=""
                                />
                              </div>
                              <div className="manager-info">
                                <h4>{responseData?.lead?.userName}</h4>
                                <span>
                                  {responseData?.lead?.userEmail}{" "}
                                  {responseData?.lead?.userMobileNo && (
                                    <>
                                      •{" "}
                                      {
                                        formatPhoneNumber(
                                          responseData?.lead?.userMobileNo
                                        ).formatted
                                      }
                                    </>
                                  )}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col-auto">
                            <Link
                              to={`${responseData?.lead?.user_id ==
                                authCtx?.userData?.id
                                ? routes.Profile
                                : `/employees/view/${responseData?.lead?.user_id}`
                                }`}
                              className="btn btn-outline-secondary btn-sm">
                              {responseData?.lead?.user_id ==
                                authCtx?.userData?.id
                                ? "My Profile"
                                : "View Profile"}
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="appraisal-form-detail offer-details">
                    <div className="appraisal-form-head mb-24">
                      <div className="row">
                        <div className="col">
                          <h5 className="card-title">Offer Details</h5>
                        </div>
                      </div>
                    </div>
                    <div className="offer-details-inner mb-24">
                      <div
                        className={`row ${formData.status == 5 ? "align-items-center" : ""}`}>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <div className="row">
                              <div className="col">
                                <label className="form-label">Status</label>
                              </div>
                            </div>

                            <select
                              className={`form-select mt-13 ${statusClass}`}
                              {...register("status")}
                              onChange={onStatusChangeHandler}
                              disabled={
                                formData.status == 3
                                // authCtx.userData.id !==
                                // formData.service_advisor_assign &&
                                // authCtx.userData?.user_type == 2
                              }>
                              <option value="0">
                                New
                              </option>
                              <option
                                value="1"
                                disabled={
                                  authCtx.userData?.user_type == 2 ||
                                  authCtx.userData?.user_type == 4
                                }>
                                Appraised
                              </option>
                              <option
                                value="4"
                                disabled={
                                  authCtx.userData?.user_type == 2 &&
                                  authCtx.userData?.user_type == 4
                                }>
                                Appraisal Assigned
                              </option>
                              <option
                                value="2"
                              >
                                Offer Made
                              </option>
                              <option
                                value="6"
                              >
                                Counter Offer
                              </option>
                              <option
                                value="7"
                                disabled={
                                  authCtx.userData?.user_type == 2 ||
                                  authCtx.userData?.user_type == 4
                                }>
                                Final Offer
                              </option>
                              <option
                                value="3"
                                disabled={
                                  authCtx.userData?.user_type == 2 &&
                                  authCtx.userData?.user_type == 4
                                }>
                                Accepted Offer
                              </option>
                              <option value="5">No Deal</option>
                            </select>
                          </div>
                        </div>
                        {formData.status == 5 && (
                          <div className="col-sm-12 mt-3">
                            <div className="row">
                              <div className="col-sm-6">
                                <label className="customChecklabel mb-2">
                                  <div className="customCheckbox customradio">
                                    <input
                                      type="radio"
                                      {...register("no_deal_reason")}
                                      onChange={() =>
                                        onChangeHandler("Priced Too Low")
                                      }
                                      checked={
                                        noDealReason === "Priced Too Low"
                                      }
                                      disabled={
                                        authCtx.userData.id !==
                                        formData.service_advisor_assign &&
                                        authCtx.userData?.user_type == 2
                                      }
                                    />
                                    <span className="lable-text"></span>
                                  </div>
                                  Priced Too Low
                                </label>
                              </div>
                              <div className="col-sm-6">
                                <label className="customChecklabel mb-2">
                                  <div className="customCheckbox customradio">
                                    <input
                                      type="radio"
                                      {...register("no_deal_reason")}
                                      onChange={() =>
                                        onChangeHandler("Decided To Wait")
                                      }
                                      checked={
                                        noDealReason === "Decided To Wait"
                                      }
                                      disabled={
                                        authCtx.userData.id !==
                                        formData.service_advisor_assign &&
                                        authCtx.userData?.user_type == 2
                                      }
                                    />
                                    <span className="lable-text"></span>
                                  </div>
                                  Decided To Wait
                                </label>
                              </div>
                              <div className="col-sm-6">
                                <label className="customChecklabel mb-2">
                                  <div className="customCheckbox customradio">
                                    <input
                                      type="radio"
                                      {...register("no_deal_reason")}
                                      onChange={() =>
                                        onChangeHandler("Declined Offer")
                                      }
                                      checked={
                                        noDealReason === "Declined Offer"
                                      }
                                      disabled={
                                        authCtx.userData.id !==
                                        formData.service_advisor_assign &&
                                        authCtx.userData?.user_type == 2
                                      }
                                    />
                                    <span className="lable-text"></span>
                                  </div>
                                  Declined Offer
                                </label>
                              </div>
                              <div className="col-sm-6">
                                <label className="customChecklabel">
                                  <div className="customCheckbox customradio">
                                    <input
                                      type="radio"
                                      {...register("no_deal_reason")}
                                      onChange={() =>
                                        onChangeHandler("Sold Elsewhere")
                                      }
                                      checked={
                                        noDealReason === "Sold Elsewhere"
                                      }
                                      disabled={
                                        authCtx.userData.id !==
                                        formData.service_advisor_assign &&
                                        authCtx.userData?.user_type == 2
                                      }
                                    />
                                    <span className="lable-text"></span>
                                  </div>
                                  Sold Elsewhere
                                </label>
                              </div>
                              <div className="col-sm-6">
                                <label className="customChecklabel">
                                  <div className="customCheckbox customradio">
                                    <input
                                      type="radio"
                                      {...register("no_deal_reason")}
                                      onChange={() =>
                                        onChangeHandler("Dealer Not Interested")
                                      }
                                      checked={
                                        noDealReason === "Dealer Not Interested"
                                      }
                                      disabled={
                                        authCtx.userData.id !==
                                        formData.service_advisor_assign &&
                                        authCtx.userData?.user_type == 2
                                      }
                                    />
                                    <span className="lable-text"></span>
                                  </div>
                                  Dealer Not Interested
                                </label>
                              </div>
                              <div className="col-sm-6">
                                <label className="customChecklabel">
                                  <div className="customCheckbox customradio">
                                    <input
                                      type="radio"
                                      {...register("no_deal_reason")}
                                      onChange={() =>
                                        onChangeHandler("Unsolicited Appraisal")
                                      }
                                      checked={
                                        noDealReason === "Unsolicited Appraisal"
                                      }
                                      disabled={
                                        authCtx.userData.id !==
                                        formData.service_advisor_assign &&
                                        authCtx.userData?.user_type == 2
                                      }
                                    />
                                    <span className="lable-text"></span>
                                  </div>
                                  Unsolicited Appraisal
                                </label>
                              </div>
                            </div>
                          </div>
                        )}
                        {(finalDealAmount !== "" && formData.status == 3) ||
                          (formData.status != 0 && formData.status != 5 && (
                            <div className="col-sm-6">
                              <div className="form-group">
                                <div className="row">
                                  <div className="col">
                                    <label className="form-label">
                                      Offer
                                    </label>
                                  </div>
                                </div>
                                <div className="position-relative mt-13">
                                  <input
                                    type="text"
                                    className={`form-control deal_amount ${isAppraisalLocked ? "bg-image-none" : ""
                                      }`}
                                    {...register("deals_price")}
                                    maxLength={10}
                                    onBlur={onDealAmountBlur}
                                    readOnly={
                                      authCtx.userData?.user_type == 2 ||
                                      authCtx.userData?.user_type == 4 ||
                                      isAppraisalLocked
                                    }
                                  />
                                  {errors.deals_price?.message != undefined && (
                                    <span className="form-error">
                                      {errors.deals_price?.message}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          ))}

                        {formData.status == 3 && finalDealAmount !== "" && (
                          <div className="col-sm-6">
                            <div className="form-group">
                              <div className="row">
                                <div className="col">
                                  <label className="form-label">
                                    Offer
                                  </label>
                                </div>
                              </div>
                              <div className="position-relative mt-13">
                                <input
                                  type="text"
                                  className={`form-control`}
                                  value={formatPrice(finalDealAmount).formatted}
                                  maxLength={10}
                                  readOnly
                                />
                              </div>
                            </div>
                          </div>
                        )}

                        {formData.status == 3 && (
                          <div className="col-sm-6">
                            <div className="form-group mt-13">
                              <div className="row">
                                <div className="col">
                                  <label className="form-label">
                                    Deal Date
                                  </label>
                                </div>
                              </div>
                              <div className="position-relative mt-13">
                                <DatePicker
                                  className="form-control"
                                  onChange={setDealDate}
                                  value={dealDate}
                                  maxDate={new Date()}
                                  format="M-d-y"
                                  yearPlaceholder="YYYY"
                                  monthPlaceholder="MM"
                                  dayPlaceholder="DD"
                                  clearIcon={false}
                                  disabled={isAppraisalLocked}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                        {(formData.status == 6 || formData.status == 7) && (
                          <div className="col-sm-6">
                            <div className="form-group mt-13">
                              <div className="row">
                                <div className="col">
                                  <label className="form-label">
                                    Counter Offer
                                  </label>
                                </div>
                              </div>
                              <input
                                type="text"
                                className={`mt-13 form-control counter_offer_price`}
                                {...register("counter_offer_price")}
                                onBlur={updateOfferPrice}
                                maxLength={10}
                                readOnly={
                                  (authCtx.userData?.user_type == 2 &&
                                    formData.service_advisor_assign == 0) ||
                                  isAppraisalLocked
                                }
                              />
                              {errors.counter_offer_price?.message !=
                                undefined && (
                                  <span className="form-error">
                                    {errors.counter_offer_price?.message}
                                  </span>
                                )}
                            </div>
                          </div>
                        )}
                        {formData.status == 7 && (
                          <div className="col-sm-6">
                            <div className="form-group mt-13">
                              <div className="row">
                                <div className="col">
                                  <label className="form-label">
                                    Final Offer
                                  </label>
                                </div>
                              </div>
                              <input
                                type="text"
                                className={`mt-13 form-control final_offer_price`}
                                {...register("final_offer_price")}
                                onBlur={updateOfferPrice}
                                maxLength={10}
                                readOnly={
                                  (authCtx.userData?.user_type == 2 &&
                                    formData.service_advisor_assign == 0) ||
                                  isAppraisalLocked
                                }
                              />
                              {errors.final_offer_price?.message !=
                                undefined && (
                                  <span className="form-error">
                                    {errors.final_offer_price?.message}
                                  </span>
                                )}
                            </div>
                          </div>
                        )}
                        {(formData.status == 2 ||
                          formData.status == 4 ||
                          formData.status == 6 ||
                          formData.status == 7 ||
                          formData.status == 3) &&
                          authCtx.userData?.user_type != 2 &&
                          authCtx.userData?.user_type != 4 && (
                            <div className="col-sm-6">
                              <div className="form-group mt-13">
                                <div className="row">
                                  <div className="col">
                                    <label className="form-label">
                                      Assigned to
                                    </label>
                                  </div>
                                </div>
                                <select
                                  className="form-select with-bg mt-13"
                                  {...register("present_offer")}
                                  disabled={isAppraisalLocked}>
                                  <option value="0">Select Department</option>
                                  <option value="1">
                                    Service Advisor
                                  </option>
                                  <option value="2">
                                    Used Car Manager
                                  </option>
                                  {responseData?.lead?.saleUser === "true" && (
                                    <option value="3">
                                      Sales Department
                                    </option>
                                  )}
                                </select>
                              </div>
                            </div>
                          )}
                        {(formData.status == 2 ||
                          formData.status == 4 ||
                          formData.status == 6 ||
                          formData.status == 7 ||
                          formData.status == 3) &&
                          formData.present_offer == 1 &&
                          authCtx.userData?.user_type != 2 &&
                          authCtx.userData?.user_type != 4 && (
                            <div className="col-sm-6">
                              <div className="form-group mt-13">
                                <div className="row">
                                  <div className="col">
                                    <label className="form-label">
                                      Assign to Service Advisor
                                    </label>
                                  </div>
                                </div>
                                <select
                                  className="form-select with-bg mt-13"
                                  {...register("service_advisor_assign")}
                                  disabled={isAppraisalLocked}>
                                  <option value="0">
                                    Select Service Advisor
                                  </option>
                                  {_.map(employees, (option) => (
                                    <option
                                      key={option.value}
                                      value={option.value}
                                      selected={
                                        option.value ===
                                        responseData?.lead
                                          .service_advisor_assign
                                      }>
                                      {option.label}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          )}
                        {(formData.status == 2 ||
                          formData.status == 4 ||
                          formData.status == 6 ||
                          formData.status == 7 ||
                          formData.status == 3) &&
                          formData.present_offer == 2 &&
                          authCtx.userData?.user_type != 2 &&
                          authCtx.userData?.user_type != 4 && (
                            <div className="col-sm-6">
                              <div className="form-group mt-13">
                                <div className="row">
                                  <div className="col">
                                    <label className="form-label">
                                      Assign to Manager
                                    </label>
                                  </div>
                                </div>
                                <select
                                  className="form-select with-bg mt-13"
                                  {...register("manager_assign")}
                                  disabled={isAppraisalLocked}>
                                  <option value="0">Select Member</option>
                                  {_.map(managers, (option) => (
                                    <option
                                      key={option.value}
                                      value={option.value}
                                      selected={
                                        option.value ===
                                        responseData?.lead.manager_assign
                                      }>
                                      {option.label}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          )}

                        {(formData.status == 2 ||
                          formData.status == 4 ||
                          formData.status == 6 ||
                          formData.status == 7 ||
                          formData.status == 3) &&
                          formData.present_offer == 3 &&
                          authCtx.userData?.user_type != 2 &&
                          authCtx.userData?.user_type != 4 && (
                            <div className="col-sm-6">
                              <div className="form-group mt-13">
                                <div className="row">
                                  <div className="col">
                                    <label className="form-label">
                                      Assign to Sales Person
                                    </label>
                                  </div>
                                </div>
                                <select
                                  className="form-select with-bg mt-13"
                                  {...register("sales_assign")}
                                  disabled={isAppraisalLocked}>
                                  <option value="0">Select Sales Person</option>
                                  {_.map(salesUsers, (option) => (
                                    <option
                                      key={option.value}
                                      value={option.value}
                                      selected={
                                        option.value ===
                                        responseData?.lead.sales_assign
                                      }>
                                      {option.label}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                  <div className="appraisal-form-detail offer-notes">
                    <div className="appraisal-form-head">
                      <div className="row">
                        <div className="col">
                          <h5 className="card-title">Notes</h5>
                        </div>
                      </div>
                    </div>
                    <div className="offer-notes-inner">
                      {rawNotes.length == 0 &&
                        responseData?.note != undefined &&
                        responseData?.note.map((data, index) => (
                          <div className="offer-note" key={index}>
                            <div className="row">
                              <div className="col-auto">
                                <div className="offer-note-image">
                                  <img
                                    src={
                                      data?.userImage != ""
                                        ? data?.userImage
                                        : userImg
                                    }
                                    alt=""
                                  />
                                </div>
                              </div>
                              <div className="col">
                                <div className="offer-note-head">
                                  <div className="offer-note-info">
                                    <p>{data?.userName}</p>
                                    <span>
                                      {moment(data?.created_at).fromNow()}
                                    </span>
                                  </div>
                                  {(data?.user_id == authCtx?.userData?.id ||
                                    authCtx?.userData?.user_type == 0) && (
                                      <div className="noted-action-buttons">
                                        <button
                                          type="button"
                                          className="btn-icon"
                                          onClick={() => noteDeleteHandler(data)}>
                                          <i className="icon icon-delete-2"></i>
                                        </button>
                                        <button
                                          type="button"
                                          className="btn-icon"
                                          onClick={() => noteEditHandler(data)}>
                                          <i className="icon icon-edit"></i>
                                        </button>
                                      </div>
                                    )}
                                </div>
                                <div className="offer-note-detail">
                                  <p>{data?.lead_notes}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      {rawNotes.length != 0 &&
                        rawNotes.map((data, index) => (
                          <div className="offer-note" key={index}>
                            <div className="row">
                              <div className="col-auto">
                                <div className="offer-note-image">
                                  <img
                                    src={
                                      data?.userImage != ""
                                        ? data?.userImage
                                        : userImg
                                    }
                                    alt=""
                                  />
                                </div>
                              </div>
                              <div className="col">
                                <div className="offer-note-head">
                                  <div className="offer-note-info">
                                    <p>{data?.userName}</p>
                                    <span>
                                      {moment(data?.created_at).fromNow()}
                                    </span>
                                  </div>
                                  {(data?.user_id == authCtx?.userData?.id ||
                                    authCtx?.userData?.user_type == 0) && (
                                      <div className="noted-action-buttons">
                                        <button
                                          type="button"
                                          className="btn-icon"
                                          onClick={() => noteDeleteHandler(data)}>
                                          <i className="icon icon-delete-2"></i>
                                        </button>
                                        <button
                                          type="button"
                                          className="btn-icon"
                                          onClick={() => noteEditHandler(data)}>
                                          <i className="icon icon-edit"></i>
                                        </button>
                                      </div>
                                    )}
                                </div>
                                <div className="offer-note-detail">
                                  <p>{data?.lead_notes}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                    <div className="offer-note-input">
                      <form onSubmit={handleSubmit2(onNoteSubmit)}>
                        <div className="row g-0 align-items-center">
                          <div className="col">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Add Note"
                                {...register2("lead_notes")}
                              />
                              {errors2.lead_notes?.message != undefined && (
                                <span className="form-error">
                                  {errors2.lead_notes?.message}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-auto">
                            {noteId != null && (
                              <button
                                className="btn-icon me-2"
                                type="button"
                                onClick={() => noteCancelHandler()}>
                                <i className="icon icon-close"></i>
                              </button>
                            )}
                            <button className="btn-icon" type="submit">
                              <i className="icon icon-save"></i>
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {isAppraisalHistoryAllow && (
            <div
              className="tab-pane fade"
              id="pills-history"
              role="tabpanel"
              aria-labelledby="pills-history-tab">
              <div className="appraisal-form-head">
                <div className="row">
                  <div className="col">
                    <h5 className="card-title">History</h5>
                  </div>
                </div>
              </div>
              <div className="offer-notes-inner history-notes-inner">
                {leadHistory.length != 0 &&
                  leadHistory.map((data, key) => (
                    <Fragment key={key}>
                      {_.isString(data) ? (
                        <div className="history-date">
                          <span className="date-Title">{data}</span>
                        </div>
                      ) : (
                        <div className="offer-note">
                          <div className="row">
                            <div className="col-auto history-notescol">
                              <div className="offer-note-image">
                                <img
                                  src={
                                    data?.userImage != ""
                                      ? data?.userImage
                                      : userImg
                                  }
                                  alt=""
                                />
                              </div>
                            </div>
                            <div className="col history-notescol">
                              <div
                                className={`offer-note-head ${data?.action == "Notes Delete" ? "mb-0" : ""
                                  }`}>
                                <div className="offer-note-info">
                                  <p>{data?.title}</p>
                                  <span>
                                    {moment(data?.created_at).fromNow(true)} ago
                                  </span>
                                </div>
                              </div>
                              {(data?.action == "Lead Update" ||
                                data?.action == "Lead Change Status") && (
                                  <div className="offer-details-inner history-note-detail">
                                    <div className="row align-items-center historynoterow">
                                      {data?.old_data?.lead?.deals_date !=
                                        undefined && (
                                          <>
                                            <div className="col-lg-3 historynotecol">
                                              <div className="form-group mw-162">
                                                <label className="form-label">
                                                  Offer Date
                                                </label>
                                                <span className="amoiunt">
                                                  {moment(
                                                    data?.old_data?.lead?.deals_date
                                                  ).format("MM-DD-YYYY")}
                                                </span>
                                              </div>
                                            </div>
                                            {data?.old_data?.lead?.deals_date && (
                                              <div className="col-lg-3 historynotecol mw-100">
                                                <span className="arrow-icon">
                                                  <i className="icon icon-back-aero"></i>
                                                </span>
                                              </div>
                                            )}
                                          </>
                                        )}
                                      {data?.new_data?.lead?.deals_date !=
                                        undefined && (
                                          <div className="col-lg-3 historynotecol">
                                            <div className="form-group mw-162">
                                              <label className="form-label">
                                                Offer date
                                              </label>
                                              <span className="amoiunt">
                                                {moment(
                                                  data?.new_data?.lead?.deals_date
                                                ).format("MM-DD-YYYY")}
                                              </span>
                                            </div>
                                          </div>
                                        )}

                                      {data?.old_data?.lead?.deals_price != undefined &&
                                        data?.old_data?.lead?.deals_price !== '0.00' && (
                                          <>
                                            <div className="col-lg-3 historynotecol">
                                              <div className="form-group mw-162">
                                                <label className="form-label">
                                                  Offer 
                                                </label>
                                                <span className="amoiunt">
                                                  {formatCurrency(
                                                    data?.old_data?.lead
                                                      ?.deals_price
                                                  )}{" "}
                                                  USD
                                                </span>
                                              </div>
                                            </div>
                                          </>
                                        )}

                                      {data?.new_data?.lead?.deals_price !=
                                        undefined && (
                                          <>
                                            {data?.old_data?.lead?.deals_price !=
                                              undefined && data?.old_data?.lead?.deals_price !== '0.00' &&(
                                                <div className="col-lg-3 historynotecol mw-100">
                                                  <span className="arrow-icon">
                                                    <i className="icon icon-back-aero"></i>
                                                  </span>
                                                </div>
                                              )}
                                            <div className="col-lg-3 historynotecol">
                                              <div className="form-group mw-162">
                                                <label className="form-label">
                                                  Offer 
                                                </label>
                                                <span className="amoiunt">
                                                  {formatCurrency(
                                                    data?.new_data?.lead
                                                      ?.deals_price
                                                  )}{" "}
                                                  USD
                                                </span>
                                              </div>
                                            </div>
                                          </>
                                        )}

                                      {data?.old_data?.lead
                                        ?.counter_offer_price != undefined && data?.old_data?.lead?.counter_offer_price !== '0.00' &&(
                                          <>
                                            <div className="col-lg-3 historynotecol">
                                              <div className="form-group mw-162">
                                                <label className="form-label">
                                                  Counter Offer Price
                                                </label>
                                                <span className="amoiunt">
                                                  {formatCurrency(
                                                    data?.old_data?.lead
                                                      ?.counter_offer_price
                                                  )}{" "}
                                                  USD
                                                </span>
                                              </div>
                                            </div>
                                          </>
                                        )}
                                      {data?.new_data?.lead
                                        ?.counter_offer_price != undefined && (
                                          <>
                                            {data?.old_data?.lead
                                              ?.counter_offer_price !=
                                              undefined && data?.old_data?.lead?.counter_offer_price !== '0.00' &&(
                                                <div className="col-lg-3 historynotecol mw-100">
                                                  <span className="arrow-icon">
                                                    <i className="icon icon-back-aero"></i>
                                                  </span>
                                                </div>
                                              )}
                                            <div className="col-lg-3 historynotecol">
                                              <div className="form-group mw-162">
                                                <label className="form-label">
                                                  Counter Offer Price
                                                </label>
                                                <span className="amoiunt">
                                                  {formatCurrency(
                                                    data?.new_data?.lead
                                                      ?.counter_offer_price
                                                  )}{" "}
                                                  USD
                                                </span>
                                              </div>
                                            </div>
                                          </>
                                        )}

                                      {data?.old_data?.lead?.final_offer_price !=
                                        undefined && data?.old_data?.lead?.final_offer_price !== '0.00' &&(
                                          <>
                                            <div className="col-lg-3 historynotecol">
                                              <div className="form-group mw-162">
                                                <label className="form-label">
                                                  Final Offer Price
                                                </label>
                                                <span className="amoiunt">
                                                  {formatCurrency(
                                                    data?.old_data?.lead
                                                      ?.final_offer_price
                                                  )}{" "}
                                                  USD
                                                </span>
                                              </div>
                                            </div>
                                          </>
                                        )}
                                      {data?.new_data?.lead?.final_offer_price !=
                                        undefined && (
                                          <>
                                            {data?.old_data?.lead
                                              ?.final_offer_price != undefined && data?.old_data?.lead?.final_offer_price !== '0.00' &&(
                                                <div className="col-lg-3 historynotecol mw-100">
                                                  <span className="arrow-icon">
                                                    <i className="icon icon-back-aero"></i>
                                                  </span>
                                                </div>
                                              )}
                                            <div className="col-lg-3 historynotecol">
                                              <div className="form-group mw-162">
                                                <label className="form-label">
                                                  Final Offer Price
                                                </label>
                                                <span className="amoiunt">
                                                  {formatCurrency(
                                                    data?.new_data?.lead
                                                      ?.final_offer_price
                                                  )}{" "}
                                                  USD
                                                </span>
                                              </div>
                                            </div>
                                          </>
                                        )}

                                      {data?.old_data?.lead?.status !=
                                        undefined && (
                                          <>
                                            <div className="col-lg-3 historynotecol mw-100">
                                              <div className="form-group mw-168">
                                                <label className="form-label">
                                                  Status
                                                </label>
                                                {data?.old_data?.lead?.status ==
                                                  0 && (
                                                    <span className="status-new">
                                                      New
                                                    </span>
                                                  )}
                                                {data?.old_data?.lead?.status ==
                                                  1 && (
                                                    <span className="status-working">
                                                      Appraised
                                                    </span>
                                                  )}
                                                {data?.old_data?.lead?.status ==
                                                  2 && (
                                                    <span className="status-offermade">
                                                      Offer Made
                                                    </span>
                                                  )}
                                                {data?.old_data?.lead?.status ==
                                                  3 && (
                                                    <span className="status-accepted-purchase">
                                                      Accepted Offer
                                                    </span>
                                                  )}
                                                {data?.old_data?.lead?.status ==
                                                  4 && (
                                                    <span className="status-appraised">
                                                      Appraisal Assigned
                                                    </span>
                                                  )}
                                                {data?.old_data?.lead?.status ==
                                                  5 && (
                                                    <span className="status-no-deal">
                                                      No Deal
                                                    </span>
                                                  )}
                                                {data?.old_data?.lead?.status ==
                                                  6 && (
                                                    <span className="status-counteroffer">
                                                      Counter Offer
                                                    </span>
                                                  )}
                                                {data?.old_data?.lead?.status ==
                                                  7 && (
                                                    <span className="status-finaloffer">
                                                      Final Offer
                                                    </span>
                                                  )}
                                              </div>
                                            </div>
                                            <div className="col-lg-3 historynotecol mw-100">
                                              <span className="arrow-icon">
                                                <i className="icon icon-back-aero"></i>
                                              </span>
                                            </div>
                                          </>
                                        )}
                                      {data?.new_data?.lead?.status !=
                                        undefined && (
                                          <div className="col-lg-3 historynotecol mw-100">
                                            <div className="form-group mw-168">
                                              <label className="form-label">
                                                Status
                                              </label>
                                              {data?.new_data?.lead?.status ==
                                                0 && (
                                                  <span className="status-new">
                                                    New
                                                  </span>
                                                )}
                                              {data?.new_data?.lead?.status ==
                                                1 && (
                                                  <span className="status-working">
                                                    Appraised
                                                  </span>
                                                )}
                                              {data?.new_data?.lead?.status ==
                                                2 && (
                                                  <span className="status-offermade">
                                                    Offer Made
                                                  </span>
                                                )}
                                              {data?.new_data?.lead?.status ==
                                                3 && (
                                                  <span className="status-accepted-purchase">
                                                    Accepted Offer
                                                  </span>
                                                )}
                                              {data?.new_data?.lead?.status ==
                                                4 && (
                                                  <span className="status-appraised">
                                                    Appraisal Assigned
                                                  </span>
                                                )}
                                              {data?.new_data?.lead?.status ==
                                                5 && (
                                                  <span className="status-no-deal">
                                                    No Deal
                                                  </span>
                                                )}
                                              {data?.new_data?.lead?.status ==
                                                6 && (
                                                  <span className="status-counteroffer">
                                                    Counter Offer
                                                  </span>
                                                )}
                                              {data?.new_data?.lead?.status ==
                                                7 && (
                                                  <span className="status-finaloffer">
                                                    Final Offer
                                                  </span>
                                                )}
                                            </div>
                                          </div>
                                        )}
                                      {data?.new_data?.lead?.status == 5 &&
                                        data?.new_data?.lead?.no_deal_reason &&
                                        data?.new_data?.lead?.no_deal_reason !=
                                        "" && (
                                          <div className="col-lg-3">
                                            <div className="form-group">
                                              <label className="form-label">
                                                No Deal Reason
                                              </label>
                                              <span class="d-block">
                                                {
                                                  data?.new_data?.lead
                                                    ?.no_deal_reason
                                                }
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                    </div>
                                  </div>
                                )}
                              {data?.action == "Lead Add" && (
                                <div className="offer-details-inner history-note-detail">
                                  <div className="row historynoterow">
                                    <div className="col-lg-3 historynotecol">
                                      <div className="form-group mw-162">
                                        <label className="form-label">
                                          Customer
                                        </label>
                                        <span className="d-block">
                                          Name:
                                          <b>
                                            {data?.new_data?.first_name}{" "}
                                            {data?.new_data?.last_name}
                                          </b>
                                        </span>
                                        {data?.new_data?.email && (
                                          <span className="d-block">
                                            Email:{" "}
                                            <a
                                              href={`mailto:${data?.new_data?.email}`}>
                                              {data?.new_data?.email}
                                            </a>
                                          </span>
                                        )}
                                        {data?.new_data?.mobile_no && (
                                          <span className="d-block">
                                            Phone:{" "}
                                            <a
                                              href={`tel:${data?.new_data?.mobile_no}`}>
                                              {data?.new_data?.mobile_no}
                                            </a>
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                    <div className="col-lg-3 historynotecol">
                                      <div className="form-group w-522">
                                        <ul>
                                          <li>
                                            <span>Year</span>{" "}
                                            <b>{data?.new_data?.year}</b>
                                          </li>
                                          <li>
                                            <span>Make</span>{" "}
                                            <b>{data?.new_data?.make}</b>
                                          </li>
                                          <li>
                                            <span>Model</span>{" "}
                                            <b>{data?.new_data?.model}</b>
                                          </li>
                                          <li>
                                            <span>Trim</span>{" "}
                                            <b>{data?.new_data?.trim}</b>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {data?.action == "Notes Add" && (
                                <div className="offer-details-inner history-note-detail">
                                  <p>{data?.new_data?.lead_notes}</p>
                                </div>
                              )}
                              {data?.action == "Notes Update" && (
                                <div className="offer-details-inner history-note-detail">
                                  <div className="row historynoterow align-items-center">
                                    <div className="col-lg-4">
                                      <div className="form-group mw-162">
                                        <label className="form-label">
                                          Note
                                        </label>
                                        <p>{data?.old_data?.lead_notes}</p>
                                      </div>
                                    </div>
                                    <div className="col-lg-3 historynotecol mw-100">
                                      <span className="arrow-icon">
                                        <i className="icon icon-back-aero"></i>
                                      </span>
                                    </div>
                                    <div className="col-lg-4">
                                      <div className="form-group mw-162">
                                        <label className="form-label">
                                          Note
                                        </label>
                                        <p>{data?.new_data?.lead_notes}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </Fragment>
                  ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default AppraisalView;
