import React, { useContext, useEffect, useState } from "react";
import api from "../../api";
import useDebounce from "../../hook/useDebounce";
import AuthContext from "../../context/authContext";
import userImg from "../../assets/img/user.jpg";
import ReactPaginate from "react-paginate";
import moment from "moment";
import downloadCsv from "download-csv";
import Swal from "../Swal";
import DateRangePicker from "@wojtekmaj/react-daterange-picker/dist/DateRangePicker";
import { formatCurrency } from "../../utils/formats";
import BusinessContext from "../../context/businessContext";
import { Fragment } from "react";
import PipelineReport from "./PipelineReport";
import ClosingReport from "./ClosingReport";
import AppraisalByAdvisorReport from "./AppraisalByAdvisorReport";
import VehicleAppraisalReport from "./VehicleAppraisalReport";
import { useNavigate } from "react-router-dom";
import CommissionReport from "./Commission";

const Reports = () => {
  const [responseData, setResponsedata] = useState({});
  const [expandedRows, setExpandedRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [currentTab, setCurrentTab] = useState(1);
  const [roleType, setRoleType] = useState("");
  const [sortField, setSortField] = useState("");
  const [sortType, setSortType] = useState("desc");
  const [pagination, setPagination] = useState({
    total_records: 0,
    total_pages: 0,
    current: 0,
  });


  const authCtx = useContext(AuthContext);
  const businessCtx = useContext(BusinessContext);
  const [search, setSearch] = useState("");
  const searchDebouncedValue = useDebounce(search, 1000);
  const [reportDetails, setReportDetails] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    let filterData = {};
    if (roleType != "") {
      filterData = {
        ...filterData,
        user_type: roleType,
      };
    }
    if (searchDebouncedValue != "") {
      filterData = {
        ...filterData,
        search: searchDebouncedValue,
      };
    }
    if (selectedDate != null) {
      filterData = {
        ...filterData,
        duration: "5",
        start_date: moment(selectedDate[0]).format("YYYY-MM-DD"),
        end_date: moment(selectedDate[1]).format("YYYY-MM-DD"),
      };
    }
    fetchReports(filterData, 1);
  }, [
    searchDebouncedValue,
    roleType,
    selectedDate,
    businessCtx.selectedBusinessId,
  ]);

  async function fetchReports(formData = {}, page) {
    setLoading(true);
    if (
      authCtx.userData?.user_type == 3 &&
      businessCtx.selectedBusinessId != ""
    ) {
      formData.business_id = businessCtx.selectedBusinessId;
    } else if (authCtx.userData?.business_id) {
      formData.business_id = authCtx.userData?.business_id;
    }
    await api
      .post(`api/report/leads?page=${page}`, formData, {
        headers: {
          Authorization: `Bearer ${authCtx.userData?.token}`,
        },
      })
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          setResponsedata(response.data.data);
          setPagination(response.data.data.paginate);
        } else {
          setResponsedata([]);
          setPagination({
            total_records: 0,
            total_pages: 0,
            current: 0,
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response?.status == 401) {
          authCtx.logout();
        } else {
          Swal.fire(
            "Opps!",
            "Sorry we are not able to complete your request at this time. Please try again later.",
            "error"
          );
        }
      });
  }

  function sort(field, currentField, sort, page) {
    let filterData = {};
    if (roleType != "") {
      filterData = {
        ...filterData,
        user_type: roleType,
      };
    }
    if (searchDebouncedValue != "") {
      filterData = {
        ...filterData,
        search: searchDebouncedValue,
      };
    }
    if (selectedDate != null) {
      filterData = {
        ...filterData,
        duration: "5",
        start_date: moment(selectedDate[0]).format("YYYY-MM-DD"),
        end_date: moment(selectedDate[1]).format("YYYY-MM-DD"),
      };
    }
    if (field == currentField) {
      if (sort == "desc") {
        setSortType("asc");
        filterData = {
          ...filterData,
          field_name: field,
          order_by: "asc",
        };
        fetchReports(filterData, page);
      } else {
        setSortType("desc");
        filterData = {
          ...filterData,
          field_name: field,
          order_by: "desc",
        };
        fetchReports(filterData, page);
      }
    } else {
      setSortField(field);
      setSortType("desc");
      filterData = {
        ...filterData,
        field_name: field,
        order_by: "desc",
      };
      fetchReports(filterData, page);
    }
  }

  const onRangeChange = (value) => {
    setSelectedDate(value);
    setExpandedRows([]);
  };

  const onPaginationFetch = (e) => {
    let filterData = {};
    if (sortField != "" && sortType != "") {
      filterData = {
        ...filterData,
        field_name: sortField,
        order_by: sortType,
      };
    }
    if (roleType != "") {
      filterData = {
        ...filterData,
        user_type: roleType,
      };
    }
    if (searchDebouncedValue != "") {
      filterData = {
        ...filterData,
        search: searchDebouncedValue,
      };
    }
    if (selectedDate != null) {
      filterData = {
        ...filterData,
        duration: "5",
        start_date: moment(selectedDate[0]).format("YYYY-MM-DD"),
        end_date: moment(selectedDate[1]).format("YYYY-MM-DD"),
      };
    }
    fetchReports(filterData, e.selected + 1);
  };

  const currentDateTime = new Date();
  const exportHandler = async () => {
    let filterData = {
      action: "export",
    };
    if (sortField != "" && sortType != "") {
      filterData = {
        ...filterData,
        field_name: sortField,
        order_by: sortType,
      };
    }
    if (roleType != "") {
      filterData = {
        ...filterData,
        user_type: roleType,
      };
    }
    if (searchDebouncedValue != "") {
      filterData = {
        ...filterData,
        search: searchDebouncedValue,
      };
    }
    if (selectedDate != null) {
      filterData = {
        ...filterData,
        duration: "5",
        start_date: moment(selectedDate[0]).format("YYYY-MM-DD"),
        end_date: moment(selectedDate[1]).format("YYYY-MM-DD"),
      };
    }

    if (
      authCtx.userData?.user_type == 3 &&
      businessCtx.selectedBusinessId != ""
    ) {
      filterData = {
        ...filterData,
        business_id: businessCtx.selectedBusinessId,
      };
    } else if (authCtx.userData?.business_id) {
      filterData = {
        ...filterData,
        business_id: authCtx.userData?.business_id,
      };
    }
    await api
      .post("api/report/leads", filterData, {
        headers: {
          Authorization: `Bearer ${authCtx.userData?.token}`,
        },
      })
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          let exportData = response.data.data.map((item) => {
            if (authCtx.userData?.user_type == 3) {
              return {
                businessName: item.businessName,
                name: item.name,
                totalAppraisal: item.totalAppraisal,
                totalCompleted: item.totalCompleted,
                totalAmount: item.totalAmount,
                totalCommission: item.totalCommission,
              };
            } else if (authCtx.userData?.user_type == 4) {
              return {
                name: item.name,
                assign_name: item.assign_name,
                totalAppraisal: item.totalAppraisal,
                totalCompleted: item.totalCompleted,
                closingPercentage: item.closingPercentage,
              };
            } else {
              return {
                name: item.name,
                totalAppraisal: item.totalAppraisal,
                totalCompleted: item.totalCompleted,
                totalAmount: item.totalAmount,
                totalCommission: item.totalCommission,
              };
            }
          });

          let exportColumns;
          if (authCtx.userData?.user_type !== 4) {
            exportColumns = {
              name: "Employees",
              totalAppraisal: "Appraisals",
              totalCompleted: "Completed",
              totalAmount: "Total Amount",
              totalCommission: "Commission",
            };
          } else {
            exportColumns = {
              assign_name: "Sales Person",
              totalAppraisal: "Appraisals",
              totalCompleted: "Completed",
              closingPercentage: "Closing Percentage",
            };
          }

          if (authCtx.userData?.user_type == 3) {
            exportColumns = {
              businessName: "Business Name",
              ...exportColumns,
            };
          }
          

          downloadCsv(
            exportData,
            exportColumns,
            `report-${moment(currentDateTime).format("MM-DD-YYYY")}`
          );
        } else {
          Swal.fire("Try Again!", response.data.message, "info");
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response?.status == 401) {
          authCtx.logout();
        } else {
          Swal.fire(
            "Opps!",
            "Sorry we are not able to complete your request at this time. Please try again later.",
            "error"
          );
        }
      });
  };

  const clearHandler = () => {
    setSearch("");
    setSelectedDate(null);
    setExpandedRows([]);
  };

  const getReportDetails = async (businessId, id) => {
    if (expandedRows.includes(id)) {
      setExpandedRows([]);
    } else {

      let getReportPayload = {
        business_id: businessId,
        user_id: id
      };
      if (selectedDate !== null) {
        getReportPayload.duration = '5';
        getReportPayload.start_date = moment(selectedDate[0]).format("YYYY-MM-DD");
        getReportPayload.end_date = moment(selectedDate[1]).format("YYYY-MM-DD");
      }
      await api
        .post(
          `api/report/leads-details`, getReportPayload,
          {
            headers: {
              Authorization: `Bearer ${authCtx.userData?.token}`,
            },
          }
        )
        .then((response) => {
          if (response.data.status) {
            setReportDetails(response.data.data);
          } else {
            setReportDetails(null);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            authCtx.logout();
          } else {
            Swal.fire(
              "Opps!",
              "Sorry we are not able to complete your request at this time. Please try again later.",
              "error"
            );
          }
        });
      setExpandedRows([id]);
    }
  };

  return (
    <>
      <div className="appraisal-head-wrap edit-page mb-24">
        <div className="row">
          <div className="col">
            <div className="appraisal-head-title">
              <button
                type="button"
                className="btn-icon"
                onClick={() => navigate(-1)}>
                <i className="icon icon-back-aero"></i>
              </button>
              <h2 className="page-title mb-0">Reports</h2>
            </div>
          </div>
        </div>
      </div>
      <div className="appraisal-tabs">
        <ul className="nav nav-pills" id="pills-tab" role="tablist">
          <li className="nav-item" role="presentation">
            <button
              className="nav-link active"
              id="pills-dashboard-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-dashboard"
              type="button"
              role="tab"
              aria-controls="pills-dashboard"
              aria-selected="true"
              onClick={() => setCurrentTab(1)}>
              By Employees
            </button>
          </li>
          {authCtx.userData?.user_type !== 4 &&
            <>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="pills-appraisal-pipeline-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-appraisal-pipeline"
                  type="button"
                  role="tab"
                  aria-controls="pills-appraisal-pipeline"
                  aria-selected="false"
                  onClick={() => setCurrentTab(2)}>
                  Appraisal Pipeline
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="pills-appraisal-closing-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-appraisal-closing"
                  type="button"
                  role="tab"
                  aria-controls="pills-appraisal-closing"
                  aria-selected="false"
                  onClick={() => setCurrentTab(3)}>
                  Appraisal Closing
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="pills-appraisal-by-advisor-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-appraisal-by-advisor"
                  type="button"
                  role="tab"
                  aria-controls="pills-appraisal-by-advisor"
                  aria-selected="false"
                  onClick={() => setCurrentTab(4)}>
                  Appraisal by Advisor
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="pills-vehicle-appraisal-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-vehicle-appraisal"
                  type="button"
                  role="tab"
                  aria-controls="pills-vehicle-appraisal"
                  aria-selected="false"
                  onClick={() => setCurrentTab(5)}>
                  Vehicle Appraisal
                </button>
              </li>
            </>
          }
          {authCtx.userData?.user_type !== 2 &&
            authCtx.userData?.user_type !== 4 && (
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="pills-commission-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-commission"
                  type="button"
                  role="tab"
                  aria-controls="pills-commission"
                  aria-selected="false"
                  onClick={() => setCurrentTab(6)}>
                  Commission
                </button>
              </li>
            )}
        </ul>
        <div className="tab-content" id="pills-tabContent">
          <div
            className="tab-pane fade show active"
            id="pills-dashboard"
            role="tabpanel"
            aria-labelledby="pills-dashboard-tab">
            <div className="dashboard-count-filter dashboard-appraisals mb-24">
              <div className="row align-items-center">
                <div className="col">
                  <p className="page-dec">
                    In total there is <b>{pagination?.total_records}</b>{" "}
                    employees
                  </p>
                </div>
              </div>
            </div>
            <div className="dashboard-latest-appraisals">
              <div className="dashboard-card">
                <div className="dashboard-card-filter dashboard-count-filter">
                  <div className="row align-items-center">
                    <div className="col">
                      <div className="dashboard-navbar-search dashboardml">
                        <div className="form-group mb-0">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="dashboard-navbar-month dashboardml">
                        <DateRangePicker
                          onChange={(e) => onRangeChange(e)}
                          value={selectedDate}
                          format="M-d-y"
                          yearPlaceholder="yyyy"
                          monthPlaceholder="mm"
                          dayPlaceholder="dd"
                          calendarIcon={<i className="icon icon-calender"></i>}
                          maxDate={new Date()}
                          clearIcon={null}
                        />
                      </div>
                      <div className="dashboard-navbar-date-filter dashboardml">
                        <button
                          type="button"
                          className="date-filter"
                          onClick={() => clearHandler()}>
                          Clear <i className="icon icon-reset"></i>
                        </button>
                      </div>
                    </div>
                    <div className="col-auto dashboard-export-sticky reports-page">
                      <div className="dashboard-export">
                        <button
                          type="button"
                          className="btn btn-pink"
                          onClick={() => exportHandler()}>
                          <i className="icon icon-export"></i> Export
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="dashboard-latest-apraisals table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>#</th>
                        {authCtx.userData?.user_type == 3 && (
                          <th width="10%">Business Name</th>
                        )}
                        {authCtx.userData?.user_type !== 4 && (
                        <th width="10%">
                          {authCtx.userData?.user_type != 1 && authCtx.userData?.user_type != 2
                            ? "Added by"
                            : "Service Advisors"}{" "}
                          <span
                            className={`table-sort ${sortField == "name" ? sortType : ""
                              }`}
                            onClick={() =>
                              sort(
                                "name",
                                sortField,
                                sortType,
                                pagination.current
                              )
                            }></span>
                        </th>
                        )}
                        {authCtx.userData?.user_type == 4 && (
                          <th width="10%">
                            {authCtx.userData?.user_type != 1
                              ? "Sales Person"
                              : "Service Advisors"}{" "}
                          </th>
                        )}
                        <th width="12%">
                          Appraisals{" "}
                          <span
                            className={`table-sort ${sortField == "totalAppraisal" ? sortType : ""
                              }`}
                            onClick={() =>
                              sort(
                                "totalAppraisal",
                                sortField,
                                sortType,
                                pagination.current
                              )
                            }></span>
                        </th>
                        <th width="11%">
                          Completed{" "}
                          <span
                            className={`table-sort ${sortField == "totalCompleted" ? sortType : ""
                              }`}
                            onClick={() =>
                              sort(
                                "totalCompleted",
                                sortField,
                                sortType,
                                pagination.current
                              )
                            }></span>
                        </th>
                        {authCtx.userData?.user_type !== 4 && (
                          <th width="10%">
                            Total Amount{" "}
                            <span
                              className={`table-sort ${sortField == "totalAmount" ? sortType : ""
                                }`}
                              onClick={() =>
                                sort(
                                  "totalAmount",
                                  sortField,
                                  sortType,
                                  pagination.current
                                )
                              }></span>
                          </th>
                        )}
                        {authCtx.userData?.user_type !== 4 && (
                          <th width="15%">
                            Commission{" "}
                            <span
                              className={`table-sort ${sortField == "totalCommission" ? sortType : ""
                                }`}
                              onClick={() =>
                                sort(
                                  "totalCommission",
                                  sortField,
                                  sortType,
                                  pagination.current
                                )
                              }></span>
                          </th>
                        )}

                        {authCtx.userData?.user_type == 4 && (
                          <th width="10%">
                            Closing Percentage{" "}
                            <span
                              className={`table-sort ${sortField == "closingPercentage" ? sortType : ""
                                }`}
                              onClick={() =>
                                sort(
                                  "closingPercentage",
                                  sortField,
                                  sortType,
                                  pagination.current
                                )
                              }></span>
                          </th>
                        )}


                      </tr>
                    </thead>
                    <tbody>
                      {responseData?.lead && responseData?.lead.length != 0 ? (
                        responseData?.lead.map((data, i) => (
                          <Fragment key={data?.id}>
                            <tr key={data?.id}>
                              <td width="3%">{data?.auto_id}</td>
                              {authCtx.userData?.user_type == 3 && (
                                <td>{data?.businessName}</td>
                              )}
                              {authCtx.userData?.user_type !== 4 && (
                              <td>
                                <div className="table-user-image">
                                  <img
                                    src={
                                      data?.image != "" ? data?.image : userImg
                                    }
                                    alt=""
                                  />
                                </div>
                                {data?.name}
                              </td>
                              )}
                              {authCtx.userData?.user_type == 4 && (
                                <td>{data?.assign_name}</td>
                              )}
                              <td>{data?.totalAppraisal}</td>
                              <td>{data?.totalCompleted}</td>
                              {authCtx.userData?.user_type !== 4 && (
                                <td>{formatCurrency(data?.totalAmount)}</td>
                              )}
                              {authCtx.userData?.user_type !== 4 && (
                                <td>
                                  {formatCurrency(data?.totalCommission)}
                                  <button
                                    type="button"
                                    className={`expanded-btn ${expandedRows.includes(data?.id)
                                      ? "active"
                                      : ""
                                      }`}
                                    onClick={(e) =>
                                      getReportDetails(
                                        data?.business_id,
                                        data?.id
                                      )
                                    }>
                                    <i className="icon icon-chevron-down-thin"></i>
                                  </button>
                                </td>
                              )}

                              {authCtx.userData?.user_type == 4 && (
                                <td>
                                  {data?.closingPercentage}%
                                </td>
                              )}

                            </tr>
                            <Fragment>
                              {expandedRows.includes(data?.id) ? (
                                <tr className="is-expanded">
                                  <td colSpan="10">
                                    <table className="table">
                                      <thead>
                                        <tr>
                                          <th>#</th>
                                          <th>Vehicle</th>
                                          <th>Customer Name</th>
                                          <th>Status</th>
                                          <th>Submitted Date</th>
                                          <th>Offer Amount</th>
                                          <th>Submit Bonus</th>
                                          <th>Submit Contest</th>
                                          {/* <th>Submit Wishlist</th> */}
                                          <th>Accepted Bonus</th>
                                          <th>Accepted Contest</th>
                                          {/* <th>Accepted Wishlist</th> */}
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {reportDetails &&
                                          reportDetails.length != 0 ? (
                                          reportDetails.map((data, key) => (
                                            <tr key={key}>
                                              <td width="3%">
                                                {data?.sequence_id}
                                              </td>
                                              <td>
                                                {data?.vehicleImage !== "" && (
                                                  <div className="table-vehicle-image">
                                                    <img
                                                      src={data?.vehicleImage}
                                                      alt=""
                                                    />
                                                  </div>
                                                )}
                                                {data?.make} {data?.model}{" "}
                                                {data?.year}
                                              </td>
                                              <td>
                                                {data?.first_name}{" "}
                                                {data?.last_name}
                                              </td>
                                              <td>
                                                {data?.status == 0 && (
                                                  <span className="status-new">
                                                    New
                                                  </span>
                                                )}
                                                {data?.status == 1 && (
                                                  <span className="status-working">
                                                    Appraised
                                                  </span>
                                                )}
                                                {data?.status == 2 && (
                                                  <span className="status-offermade">
                                                    Offer Made
                                                  </span>
                                                )}
                                                {data?.status == 3 && (
                                                  <span className="status-accepted-purchase">
                                                    Accepted Offer
                                                  </span>
                                                )}
                                                {data?.status == 4 && (
                                                  <span className="status-appraised">
                                                    Appraisal Assigned
                                                  </span>
                                                )}
                                                {data?.status == 5 && (
                                                  <span className="status-no-deal">
                                                    No Deal
                                                  </span>
                                                )}
                                                {data?.status == 6 && (
                                                  <span className="status-counteroffer">
                                                    Counter Offer
                                                  </span>
                                                )}
                                                 {data?.status == 7 && (
                                                  <span className="status-finaloffer">
                                                    Final Offer
                                                  </span>
                                                )}
                                              </td>
                                              <td>
                                                {moment.utc(
                                                  data?.created_at
                                                ).format("MMMM D, YYYY")}
                                              </td>
                                              <td>
                                                {data?.deals_price != null
                                                  ? formatCurrency(
                                                    data?.deals_price
                                                  )
                                                  : "No Offer"}
                                              </td>
                                              <td>
                                                {formatCurrency(
                                                  data?.submit_appraisal_bonus
                                                )}
                                              </td>
                                              <td>
                                                {formatCurrency(
                                                  data?.submit_appraisal_contest
                                                )}
                                              </td>
                                              {/* <td>
                                                {formatCurrency(
                                                  data?.submit_appraisal_wishlist
                                                )}
                                              </td> */}
                                              <td>
                                                {formatCurrency(
                                                  data?.appraisal_won_bonus
                                                )}
                                              </td>
                                              <td>
                                                {formatCurrency(
                                                  data?.appraisal_won_contest
                                                )}
                                              </td>
                                              {/* <td>
                                                {formatCurrency(
                                                  data?.appraisal_won_wishlist
                                                )}
                                              </td> */}
                                            </tr>
                                          ))
                                        ) : (
                                          <tr className="text-start text-sm-center">
                                            <td colSpan={16}>
                                              No Commission's Appraisal Found.
                                            </td>
                                          </tr>
                                        )}
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                              ) : null}
                            </Fragment>
                          </Fragment>
                        ))
                      ) : (
                        <tr className="text-start text-sm-center">
                          <td colSpan={12}>
                            {loading ? "Loading..." : "No Reports Found."}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {pagination?.total_pages != undefined &&
              pagination?.total_pages != 1 && (
                <nav className="navigation pagination">
                  <ReactPaginate
                    breakLabel="..."
                    onPageChange={onPaginationFetch}
                    pageRangeDisplayed={2}
                    pageCount={pagination?.total_pages}
                    renderOnZeroPageCount={null}
                    containerClassName="nav-links"
                    activeLinkClassName="current"
                    pageLinkClassName="page-numbers"
                    previousLinkClassName="prev page-numbers"
                    previousLabel={<i className="icon icon-arrow-left"></i>}
                    nextLinkClassName="next page-numbers"
                    nextLabel={<i className="icon icon-arrow-right"></i>}
                  />
                </nav>
              )}
          </div>
          <div
            className="tab-pane fade"
            id="pills-appraisal-pipeline"
            role="tabpanel"
            aria-labelledby="pills-appraisal-pipeline-tab">
            {currentTab === 2 && <PipelineReport />}
          </div>
          <div
            className="tab-pane fade"
            id="pills-appraisal-closing"
            role="tabpanel"
            aria-labelledby="pills-appraisal-closing-tab">
            {currentTab === 3 && <ClosingReport />}
          </div>
          <div
            className="tab-pane fade"
            id="pills-appraisal-by-advisor"
            role="tabpanel"
            aria-labelledby="pills-appraisal-by-advisor-tab">
            {currentTab === 4 && <AppraisalByAdvisorReport />}
          </div>
          <div
            className="tab-pane fade"
            id="pills-vehicle-appraisal"
            role="tabpanel"
            aria-labelledby="pills-vehicle-appraisal-tab">
            {currentTab === 5 && <VehicleAppraisalReport />}
          </div>
          <div
            className="tab-pane fade"
            id="pills-commission"
            role="tabpanel"
            aria-labelledby="pills-commission-tab">
            {currentTab === 6 && <CommissionReport />}
          </div>
        </div>
      </div>
    </>
  );
};

export default Reports;
