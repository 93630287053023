import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import api from "../../api";
import routes from "../../constants/routesConstants";
import Swal from "../Swal";

const validationSchema = yup.object().shape({
  password: yup
    .string()
    .max(42)
    .required("Password is required.")
    .min(6, "Password must be at 6 char long"),
  confirmation_password: yup
    .string()
    .max(42)
    .when("password", (password, field) =>
      password
        ? field
            .required("Confirm Password is required.")
            .oneOf(
              [yup.ref("password")],
              "Password & Confirm Password  must be same."
            )
        : field
    ),
});

const ResetPassword = () => {
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();
  const { id } = useParams();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const showPasswordHandler = () => {
    setShowPassword(!showPassword);
  };

  const onSubmit = async (formValue) => {
    formValue.user_id = id;
    setLoading(true);
    await api
      .post("api/user/reset-password", formValue)
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          Swal.fire(response.data.message, "", "success");
          navigate(routes.Login);
        } else {
          Swal.fire("Try Again!", response.data.message, "info");
        }
      })
      .catch((error) => {
        setLoading(false);
        Swal.fire(
          "Opps!",
          "Sorry we are not able to complete your request at this time. Please try again later.",
          "error"
        );
      });
  };

  return (
    <section className="login-section reset-password-section">
      <div className="login-upper-section">
        <div className="container-fluid">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-5">
              <div className="login-inner mx-auto">
                <h2 className="login-title">Create New Password</h2>
                <div className="login-form">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="login-form-inner">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label className="form-label">
                              New Password <span>*</span>
                            </label>
                            <div className="form-group-with-password">
                              <input
                                type={`${showPassword ? "text" : "password"}`}
                                className="form-control password"
                                placeholder="Use strong password"
                                {...register("password")}
                                maxLength={42}
                              />
                              <i
                                onClick={showPasswordHandler}
                                className="icon icon-eye"
                              ></i>
                              {errors.password?.message != undefined && (
                                <span className="form-error">
                                  {errors.password?.message}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <div className="form-group-with-password">
                              <input
                                type="password"
                                className="form-control password"
                                placeholder="Confirm Password"
                                {...register("confirmation_password")}
                                maxLength={42}
                              />
                            </div>
                            {errors.confirmation_password?.message !=
                              undefined && (
                              <span className="form-error">
                                {errors.confirmation_password?.message}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <button
                            type="submit"
                            className="btn btn-pink"
                            disabled={loading}
                          >
                            Save and log in
                          </button>
                        </div>
                        {/* <div className="col-lg-12">
                          <p className="form-privacy-policy">
                            <a href="#">terms and conditions</a>r sit amet,
                            consectetur adipiscing elit. Adipiscing nunc, mattis
                            facilisis neque, magnis nulla donec morbi.
                          </p>
                        </div> */}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="login-down-section">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="login-copyright">
                <span className="me-0">
                  ©{new Date().getFullYear()} Vehicle Appraisal System | All
                  rights Reserved
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ResetPassword;
