import React, { useContext, useEffect, useState } from "react";
import api from "../../api";
import useDebounce from "../../hook/useDebounce";
import AuthContext from "../../context/authContext";
import ReactPaginate from "react-paginate";
import moment from "moment";
import Swal from "../Swal";
import { Link } from "react-router-dom";
import { Tooltip } from "antd";
import _ from "lodash";
import { formatCurrency } from "../../utils/formats";
import BusinessContext from "../../context/businessContext";
import downloadCsv from "download-csv";

const Payments = ({ businessId }) => {
  const [responseData, setResponseData] = useState({});
  const [loading, setLoading] = useState(false);
  const [period, setPeriod] = useState("");
  const [monthlyPeriod, setMonthlyPeriod] = useState([]);
  const [sortField, setSortField] = useState("");
  const [sortType, setSortType] = useState("desc");
  const [pagination, setPagination] = useState({
    total_records: 0,
    total_pages: 0,
    current: 0,
  });

  const authCtx = useContext(AuthContext);
  const businessCtx = useContext(BusinessContext);
  const [search, setSearch] = useState("");
  const searchDebouncedValue = useDebounce(search, 1000);
  const [paymentStatus, setPaymentStatus] = useState("");

  useEffect(() => {
    if (authCtx.userData?.user_type == 3) {
      getMonths();
    }
  }, []);

  function getMonths() {
    let monthsArray = [];
    let monthName = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    let d = new Date();
    d.setDate(1);
    for (let i = 0; i <= 11; i++) {
      if (i == 0) {
        d.setMonth(d.getMonth());
      } else {
        d.setMonth(d.getMonth() - 1);
      }
      monthsArray.push({
        label: monthName[d.getMonth()] + " " + d.getFullYear(),
        value: moment(new Date(d.getFullYear(), d.getMonth(), 1)).format(
          "YYYY-MM-DD"
        ),
      });
    }
    setMonthlyPeriod(monthsArray);
  }

  useEffect(() => {
    let filterData = {};
    if (businessCtx.selectedBusinessId != "") {
      filterData = {
        ...filterData,
        business_id: businessCtx.selectedBusinessId,
      };
    }
    if (searchDebouncedValue != "") {
      filterData = {
        ...filterData,
        search: searchDebouncedValue,
      };
    }
    if (paymentStatus !== "") {
      filterData = {
        ...filterData,
        paid: paymentStatus,
      };
    }
    if (period !== "") {
      filterData = {
        ...filterData,
        period: period,
      };
    }

    fetchSubscriptions(filterData, 1);
  }, [
    searchDebouncedValue,
    businessCtx.selectedBusinessId,
    paymentStatus,
    period,
  ]);

  async function fetchSubscriptions(formData = {}, page) {
    setLoading(true);
    if (businessId !== undefined) {
      formData.business_id = businessId;
    }
    await api
      .post(`api/invoice?page=${page}`, formData, {
        headers: {
          Authorization: `Bearer ${authCtx.userData?.token}`,
        },
      })
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          setResponseData(response.data.data);
          setPagination(response.data.data.paginate);
        } else {
          setResponseData([]);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          authCtx.logout();
        } else {
          Swal.fire(
            "Opps!",
            "Sorry we are not able to complete your request at this time. Please try again later.",
            "error"
          );
        }
      });
  }

  function sort(field, currentField, sort, page) {
    let filterData = {};
    if (businessCtx.selectedBusinessId != "") {
      filterData = {
        ...filterData,
        business_id: businessCtx.selectedBusinessId,
      };
    }
    if (searchDebouncedValue != "") {
      filterData = {
        ...filterData,
        search: searchDebouncedValue,
      };
    }
    if (paymentStatus !== "") {
      filterData = {
        ...filterData,
        paid: paymentStatus,
      };
    }
    if (period !== "") {
      filterData = {
        ...filterData,
        period: period,
      };
    }

    if (field == currentField) {
      if (sort == "desc") {
        setSortType("asc");
        filterData = {
          ...filterData,
          field_name: field,
          order_by: "asc",
        };
        fetchSubscriptions(filterData, page);
      } else {
        setSortType("desc");
        filterData = {
          ...filterData,
          field_name: field,
          order_by: "desc",
        };
        fetchSubscriptions(filterData, page);
      }
    } else {
      setSortField(field);
      setSortType("desc");
      filterData = {
        ...filterData,
        field_name: field,
        order_by: "desc",
      };
      fetchSubscriptions(filterData, page);
    }
  }

  const onPaginationFetch = (e) => {
    let filterData = {};
    if (sortField != "" && sortType != "") {
      filterData = {
        ...filterData,
        field_name: sortField,
        order_by: sortType,
      };
    }
    if (businessCtx.selectedBusinessId != "") {
      filterData = {
        ...filterData,
        business_id: businessCtx.selectedBusinessId,
      };
    }
    if (searchDebouncedValue != "") {
      filterData = {
        ...filterData,
        search: searchDebouncedValue,
      };
    }
    if (paymentStatus !== "") {
      filterData = {
        ...filterData,
        paid: paymentStatus,
      };
    }
    if (period !== "") {
      filterData = {
        ...filterData,
        period: period,
      };
    }

    fetchSubscriptions(filterData, e.selected + 1);
  };

  const clearHandler = () => {
    setSearch("");
    setPaymentStatus("");
    setPeriod("");
  };

  const currentDateTime = new Date();
  const exportHandler = async () => {
    let filterData = {
      action: "export",
    };
    if (sortField != "" && sortType != "") {
      filterData = {
        ...filterData,
        field_name: sortField,
        order_by: sortType,
      };
    }
    if (businessCtx.selectedBusinessId != "") {
      filterData = {
        ...filterData,
        business_id: businessCtx.selectedBusinessId,
      };
    }
    if (searchDebouncedValue != "") {
      filterData = {
        ...filterData,
        search: searchDebouncedValue,
      };
    }
    if (paymentStatus !== "") {
      filterData = {
        ...filterData,
        paid: paymentStatus,
      };
    }
    if (period !== "") {
      filterData = {
        ...filterData,
        period: period,
      };
    }
    await api
      .post("api/invoice", filterData, {
        headers: {
          Authorization: `Bearer ${authCtx.userData?.token}`,
        },
      })
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          let exportData = response.data.data.map((item) => {
            return {
              invoice_number: item.invoice_number,
              business_name: item.business_name,
              business_address:
                item.business_address != ""
                  ? item.business_address.replace(/,/g, "|")
                  : "-",
              amount: item.amount,
              paid_amount: item.paid_amount,
              payment_status: item.payment_status,
              payment_date: item.payment_date != "" ? item.payment_date : "-",
              period: item.period,
            };
          });

          let exportColumns = {
            invoice_number: "Invoice Number",
            business_name: "Name",
            business_address: "Address",
            amount: "Amount",
            paid_amount: "Paid Amount",
            payment_status: "Payment Status",
            payment_date: "Payment Date",
            period: "Period",
          };

          downloadCsv(
            exportData,
            exportColumns,
            `invoices-${moment(currentDateTime).format("MM-DD-YYYY")}`
          );
        } else {
          Swal.fire("Try Again!", response.data.message, "info");
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response?.status == 401) {
          authCtx.logout();
        } else {
          Swal.fire(
            "Opps!",
            "Sorry we are not able to complete your request at this time. Please try again later.",
            "error"
          );
        }
      });
  };

  return (
    <>
      <div className="dashboard-count-filter dashboard-appraisals  mb-24">
        <div className="row align-items-center">
          <div className="col">
            <h2 className="page-title">Invoices</h2>
            <p className="page-dec">
              In total there is <b>{pagination?.total_records}</b> invoices
            </p>
          </div>
        </div>
      </div>
      <div className="dashboard-latest-appraisals">
        <div className="dashboard-card">
          <div className="dashboard-count-filter dashboard-card-filter">
            <div className="row align-items-center">
              <div className="col-md col-12">
                <div className="dashboard-navbar-search dashboardml">
                  <div className="form-group mb-0">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </div>
                </div>
                <div className="dashboard-navbar-month dashboardml">
                  <div className="form-group">
                    <select
                      className="form-select"
                      value={paymentStatus}
                      onChange={(e) => setPaymentStatus(e.target.value)}>
                      <option value="">All</option>
                      <option value={1}>Paid</option>
                      <option value={2}>Unpaid</option>
                      <option value={3}>Overdue</option>
                    </select>
                  </div>
                </div>
                <div className="dashboard-navbar-month dashboardml">
                  <div className="form-group">
                    <select
                      className="form-select"
                      onChange={(e) => setPeriod(e.target.value)}
                      value={period}>
                      <option value="">All</option>
                      {_.map(monthlyPeriod, (option, key) => (
                        <option key={key} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="dashboard-navbar-date-filter dashboardml">
                  <button
                    type="button"
                    className="date-filter"
                    onClick={() => clearHandler()}>
                    Clear <i className="icon icon-reset"></i>
                  </button>
                </div>
              </div>
              <div className="col-md-auto col-12">
                <div className="dashboard-export">
                  <button
                    type="button"
                    className="btn btn-pink"
                    onClick={() => exportHandler()}
                    disabled={loading}>
                    <i className="icon icon-export"></i> Export
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="dashboard-latest-apraisals table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>
                    Business Name{" "}
                    <span
                      className={`table-sort ${sortField == "business_name" ? sortType : ""
                        }`}
                      onClick={() =>
                        sort(
                          "business_name",
                          sortField,
                          sortType,
                          pagination.current
                        )
                      }></span>
                  </th>
                  <th>
                    Business Address
                    <span
                      className={`table-sort ${sortField == "business_address" ? sortType : ""
                        }`}
                      onClick={() =>
                        sort(
                          "business_address",
                          sortField,
                          sortType,
                          pagination.current
                        )
                      }></span>
                  </th>
                  <th>
                    Subscription Period{" "}
                    <span
                      className={`table-sort ${sortField == "start_date" ? sortType : ""
                        }`}
                      onClick={() =>
                        sort(
                          "start_date",
                          sortField,
                          sortType,
                          pagination.current
                        )
                      }></span>
                  </th>
                  <th>
                    Amount{" "}
                    <span
                      className={`table-sort ${sortField == "amount" ? sortType : ""
                        }`}
                      onClick={() =>
                        sort("amount", sortField, sortType, pagination.current)
                      }></span>
                  </th>
                  <th>
                    Paid Amount{" "}
                    <span
                      className={`table-sort ${sortField == "paid_amount" ? sortType : ""
                        }`}
                      onClick={() =>
                        sort(
                          "paid_amount",
                          sortField,
                          sortType,
                          pagination.current
                        )
                      }></span>
                  </th>
                  <th>
                    Payment{" "}
                    <span
                      className={`table-sort ${sortField == "payment_status" ? sortType : ""
                        }`}
                      onClick={() =>
                        sort(
                          "payment_status",
                          sortField,
                          sortType,
                          pagination.current
                        )
                      }></span>
                  </th>
                  <th>
                    Payment Date{" "}
                    <span
                      className={`table-sort ${sortField == "payment_date" ? sortType : ""
                        }`}
                      onClick={() =>
                        sort(
                          "payment_date",
                          sortField,
                          sortType,
                          pagination.current
                        )
                      }></span>
                  </th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {responseData?.invoice && responseData?.invoice.length != 0 ? (
                  responseData?.invoice.map((data) => (
                    <tr key={data?.id}>
                      <td>{data?.id}</td>
                      <td>{data?.business_name}</td>
                      <td>{data?.business_address}</td>
                      <td>{data?.period}</td>
                      <td>{formatCurrency(data?.amount)}</td>
                      <td>{formatCurrency(data?.paid_amount)}</td>
                      <td>
                        <span
                          className={`${data?.payment_status !== undefined &&
                              data?.payment_status === "Paid"
                              ? "status-won"
                              : "" || data?.payment_status === "Unpaid"
                                ? "status-pending"
                                : "" || data?.payment_status === "Overdue"
                                  ? "status-rejected"
                                  : ""
                            }`}>
                          {data?.payment_status}
                        </span>
                      </td>
                      <td>
                        {data?.payment_date != ""
                          ? moment(data?.payment_date).format("MMMM D, YYYY")
                          : "-"}
                      </td>
                      <td>
                        <Tooltip
                          placement="top"
                          title={`${data?.payment_status === "Paid" ? "Edit" : "Add"
                            } Payment`}>
                          <Link
                            to={`/payments/create/${data?.id}`}
                            className="btn-icon">
                            <i className="icon icon-money"></i>
                          </Link>
                        </Tooltip>
                        <Tooltip placement="top" title="View Invoice">
                          <a
                            href={data?.invoice_name}
                            target="_blank"
                            className="btn-icon">
                            <i className="icon icon-invoice"></i>
                          </a>
                        </Tooltip>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr className="text-start text-sm-center">
                    <td colSpan={8}>
                      {loading ? "Loading..." : "No Records Found."}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {pagination?.total_pages != undefined && pagination?.total_pages != 1 && (
        <nav className="navigation pagination">
          <ReactPaginate
            breakLabel="..."
            onPageChange={onPaginationFetch}
            pageRangeDisplayed={2}
            pageCount={pagination?.total_pages}
            renderOnZeroPageCount={null}
            containerClassName="nav-links"
            activeLinkClassName="current"
            pageLinkClassName="page-numbers"
            previousLinkClassName="prev page-numbers"
            previousLabel={<i className="icon icon-arrow-left"></i>}
            nextLinkClassName="next page-numbers"
            nextLabel={<i className="icon icon-arrow-right"></i>}
          />
        </nav>
      )}
    </>
  );
};

export default Payments;