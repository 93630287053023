import React from "react";
import { Link } from "react-router-dom";
import routes from "../../constants/routesConstants";
import step1Img from "../../assets/img/step1.jpg";
import step2Img from "../../assets/img/step2.jpg";
import step5Img from "../../assets/img/step5.jpg";
import loginBannerImg from "../../assets/img/login-banner.png";
import Footer from "../Footer";

const Home = () => {
  return (
    <>
      <section className="home-banner-section">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-lg-5 order-2 order-lg-1">
              <div className="home-banner-con">
                <div className="sec-head">
                  <span className="sec-subtitle">
                    Turn Service Visits into Sales
                  </span>
                  <h1 className="sec-title">
                    Finally a way to motivate your service advisors to get more
                    appraisals!
                  </h1>
                </div>
                <div className="home-banner-buttons">
                  <Link to={`${routes.ScheduleDemo}`} className="btn btn-pink">
                    Schedule a Demo
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-7 order-1 order-lg-2">
              <div className="login-image">
                <img src={loginBannerImg} alt="" className="mx-auto" />
                <div className="dashboard-card is-total-appraisals">
                  <div className="dashboard-card-row">
                    <div className="dashboard-card-icon">
                      <i className="icon icon-total-appraisal"></i>
                    </div>
                    <div className="dashboard-card-text">
                      <h5 className="dashboard-card-count ffr">450</h5>
                      <span className="dashboard-card-label ffr">
                        Total Appraisals
                      </span>
                      <div className="progress">
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{ width: "60%" }}
                          aria-valuenow="60"
                          aria-valuemin="0"
                          aria-valuemax="100"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="dashboard-card is-total-won is-accepted-purchase">
                  <div className="dashboard-card-row">
                    <div className="dashboard-card-icon">
                      <i className="icon icon-won"></i>
                    </div>
                    <div className="dashboard-card-text">
                      <h5 className="dashboard-card-count ffr">123</h5>
                      <span className="dashboard-card-label ffr">
                        Completed
                      </span>
                      <div className="progress">
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{ width: "80%" }}
                          aria-valuenow="80"
                          aria-valuemin="0"
                          aria-valuemax="100"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="total-appraisals-added-card">
                  <span className="total-appraisals-count">32</span>
                  <h5 className="total-appraisals-text">
                    Appraisal added today
                  </h5>
                  <p>Check the appraisal and give it your offers</p>
                  <div className="total-appraisals-button-wrap">
                    <a
                      href="#"
                      className="btn btn-parrot"
                      style={{ pointerEvents: "none" }}>
                      Go to Appraisal
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="home-steps">
        <div className="container">
          <div className="home-step step-1">
            <div className="row">
              <div className="col-lg-6">
                <div className="home-steps-image">
                  <img src={step1Img} alt="" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="home-steps-con">
                  <div className="sec-head">
                    <span className="sec-subtitle">1</span>
                    <h2 className="sec-title">
                      Lead Generation Tool for Inventory Acquisition
                    </h2>
                    <p>
                      Increase dealer inventory by putting out cash offers on
                      vehicles while they are being serviced
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="home-step step-2">
            <div className="row flex-row-reverse">
              <div className="col-lg-6">
                <div className="home-steps-image">
                  <img src={step2Img} alt="" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="home-steps-con">
                  <div className="sec-head">
                    <span className="sec-subtitle">2</span>
                    <h2 className="sec-title">
                      Communication Between Service and Sales
                    </h2>
                    <p>
                      Service Advisors can quickly pass leads from the service
                      department to the buying/sales department
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="home-step step-3">
            <div className="row">
              <div className="col-lg-6">
                <div className="home-steps-con">
                  <div className="sec-head">
                    <span className="sec-subtitle">3</span>
                    <h2 className="sec-title">Quality Onsite Leads</h2>
                    <p>
                      Take advantage of being able to give customers offers in
                      person
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="home-steps-con">
                  <div className="sec-head">
                    <span className="sec-subtitle">4</span>
                    <h2 className="sec-title">
                      Incentivize Service Representatives with Commissions
                    </h2>
                    <p>
                      Service Advisors can be compensated for completed deals by
                      a flat rate or by a percentage of the cars value
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="home-step step-4">
            <div className="row">
              <div className="col-lg-8 order-2 order-lg-1">
                <div className="home-steps-con">
                  <div className="sec-head">
                    <span className="sec-subtitle">5</span>
                    <h2 className="sec-title">
                      Turn Buying Opportunities into Sales Opportunities
                    </h2>
                    <p>
                      Purchasing customer cars leads to them buying new cars
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 order-1 order-lg-2">
                <div className="home-steps-image">
                  <img src={step5Img} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="home-get-started">
        <div className="home-get-started-inner">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="home-get-started-con">
                  <h3 className="home-get-started-title">
                    How many cars came through your service drive last month?
                    How many did you buy? How do you track it?
                  </h3>
                  <div className="row align-items-center justify-content-between">
                    <div className="home-get-started-tie">
                      <span className="home-get-started-tie-text">
                        Let’s change that !{" "}
                      </span>
                      <Link
                        to={`${routes.ScheduleDemo}`}
                        className="home-get-started-button">
                        Schedule a Demo
                      </Link>
                    </div>
                    <div className="home-get-started-call-us-sec">
                      <div className="row text-center">
                        <div className="col-12">
                          <h4 className="text-white">Call us</h4>
                          <span className="text-white">
                            747-22-SPIFF / 747-227-7433
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Home;
