import "antd/dist/antd.css";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../api";
import routes from "../../constants/routesConstants";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import AuthContext from "../../context/authContext";
import { useForm } from "react-hook-form";
import Swal from "../Swal";
import _ from "lodash";
import { Tree, Switch } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import { ROLE_EDIT } from "../../constants/permissionConstants";
import usePermission from "../../hook/usePermission";

const validationSchema = yup.object().shape({
  role_name: yup.string().max(42).required("Role name is required."),
});

const RolesEdit = () => {
  const [loading, setLoading] = useState(false);
  const authCtx = useContext(AuthContext);

  const [treeData, setModuleData] = useState([]);
  const [SelectedModule, setSelectedModule] = useState([]);
  const [checkedModule, checkModuleData] = useState([]);
  const [status, changeStatus] = useState(false);
  const [userType, setUserType] = useState(null);

  const navigate = useNavigate();
  const { id } = useParams();

  const isRoleEditAllow = usePermission(ROLE_EDIT);
  useEffect(() => {
    if (!isRoleEditAllow) {
      navigate(routes.Dashboard);
    }
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  let tempSelectedModule = [];
  let treeArray = [];

  useEffect(() => {
    if (id != null) {
      api
        .get(`api/role/get-role/${id}`, {
          headers: { Authorization: `Bearer ${authCtx.userData?.token}` },
        })
        .then((response) => {
          if (response.data.status) {
            changeStatus(response.data.data.status);
            setUserType(response.data.data.user_type);
            setValue("role_name", response.data.data?.role_name);
            tempSelectedModule = response.data.data.permission;
            
            if (
              response.data.data?.appraisal_bonus != "0" &&
              response.data.data?.appraisal_bonus != null
            ) {
              setValue("appraisal_bonus", response.data.data?.appraisal_bonus);
            }
            if (response.data.data?.vehicle_appraisal_bonus_type == "0") {
            } else if (response.data.data?.vehicle_appraisal_bonus_type == "1") {
              setValue(
                "vehicle_appraisal_bonus_percentage_value",
                response.data.data?.vehicle_appraisal_bonus_value
              );
            } else if (response.data.data?.vehicle_appraisal_bonus_type == "2") {
              setValue(
                "vehicle_appraisal_bonus_dollar_value",
                response.data.data?.vehicle_appraisal_bonus_value
              );
            }
            if (response?.data?.data?.per_accepted_appraisal_manager !== null) {
              setValue(
                "per_accepted_appraisal_manager",
                response.data.data?.per_accepted_appraisal_manager
              );
            }
            


            api
              .post(
                `api/modules`,
                {},
                {
                  headers: {
                    Authorization: `Bearer ${authCtx.userData.token}`,
                  },
                }
              )
              .then((response) => {
                setModuleData(response.data.data);
                treeArray = response.data.data;
                setSelectedPermission();
              })
              .catch((error) => {
                if (error.response?.status == 401) {
                  authCtx.logout();
                }
              });
          }
        })
        .catch((error) => {
          if (error.response?.status == 401) {
            authCtx.logout();
          }
        });
    }
  }, [id]);

  function setSelectedPermission() {
    const dataArray = [];
    const arrayTemp = [];
    tempSelectedModule.forEach((item) => {
      _.find(treeArray, (temp) => {
        if (temp.id == item.module_id) {
          _.find(temp.children, (tempClone) => {
            if (item.permission_id == tempClone.id) {
              dataArray.push(`${item.module_id}-${item.permission_id}`);
              arrayTemp.push(tempClone.key);
            }
          });
        }
      });
    });
    setSelectedModule(arrayTemp);
    checkModuleData(dataArray);
  }

  const onCheck = (checkedKeys, info) => {
    setSelectedModule(checkedKeys);
    const dataArray = [];
    const checkNodeAarray = info.checkedNodes;
    checkNodeAarray.forEach((item) => {
      if (item.module_id) {
        dataArray.push(`${item.module_id}-${item.id}`);
      }
    });
    checkModuleData(dataArray);
  };

  function statusToggle(checked) {
    changeStatus(checked);
  }

  const onSubmit = async (formValue) => {
    const statusData = status ? 1 : 0;
    const formData = new FormData();
    formData.append("role_name", formValue.role_name);
    formData.append("status", statusData);
    checkedModule.forEach((item) => {
      formData.append("permission[]", item);
    });

    if (
      formValue.appraisal_bonus !== undefined &&
      formValue.appraisal_bonus !== "0"
    ) {
      formData.append("appraisal_bonus", formValue.appraisal_bonus);
    } else {
      formData.append("appraisal_bonus", "0");
    }

    if (
      formValue.vehicle_appraisal_bonus_dollar_value !== undefined &&
      formValue.vehicle_appraisal_bonus_dollar_value !== ""
    ) {
      formData.append("vehicle_appraisal_bonus_type", "2");
      formData.append(
        "vehicle_appraisal_bonus_value",
        formValue.vehicle_appraisal_bonus_dollar_value
      );
    } else {
      formData.append("vehicle_appraisal_bonus_value", 0);
      formData.append("vehicle_appraisal_bonus_type", "0");
    }

    if (
      formValue.per_accepted_appraisal_manager !== undefined &&
      formValue.per_accepted_appraisal_manager !== ""
    ) {
      formData.append(
        "per_accepted_appraisal_manager",
        formValue.per_accepted_appraisal_manager
      );
    }


    await api
      .post(`api/role/update-role/${id}`, formData, {
        headers: { Authorization: `Bearer ${authCtx.userData?.token}` },
      })
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          console.log(response.data);
          reset();
          Swal.fire(response.data.message, "", "success");
          navigate(routes.Roles);
        } else {
          Swal.fire("Try Again!", response.data.message, "info");
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response?.status == 401) {
          authCtx.logout();
        }
      });
  };

  return (
    <>
      <div className="appraisal-head-wrap mb-34">
        <div className="row">
          <div className="col">
            <div className="appraisal-head-title align-items-start">
              <button
                type="button"
                className="btn-icon"
                onClick={() => navigate(-1)}>
                <i className="icon icon-back-aero"></i>
              </button>
              <div className="page-head">
                <h2 className="page-title">Edit #{id} Role</h2>
                <p className="page-dec">Fill the required informations</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="login-inner add-new-user-inner">
        <div className="login-head">
          <h2 className="login-title">Update Role</h2>
          <p className="login-text">
            Please make sure to fill in all the required fields.
          </p>
        </div>
        <div className="login-form">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="login-form-inner">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="form-label">
                      Name <span>*</span>
                    </label>
                    <div className="position-relative">
                      <input
                        className="form-control"
                        {...register("role_name")}
                        maxLength={42}
                        readOnly
                      />
                      {errors.role_name?.message != undefined && (
                        <span className="form-error">
                          {errors.role_name?.message}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="form-label">
                      Status <span>*</span>
                    </label>
                    <div className="position-relative">
                      <Switch onChange={statusToggle} checked={status} />
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group">
                    <label className="form-label">
                      Permissions <span>*</span>
                    </label>
                    <Tree
                      showLine={{ showLeafIcon: false }}
                      switcherIcon={<CaretRightOutlined />}
                      onCheck={onCheck}
                      checkable={true}
                      treeData={treeData}
                      checkedKeys={SelectedModule}
                      autoExpandParent={true}
                    />
                  </div>
                </div>
                {userType !== null && userType !== 0 &&
                  userType !== 4 && (
                    <div className="notification settings-notification shadow-none p-0">
                      <div className="col-lg-12 settings-notificationbox mb-24">
                        <h5 className="notification-Title ffr mb-2">Spiffs</h5>
                        <div className="dashboard-notification table-responsive">
                          <table className="table" align="center">
                            <tbody>
                              {userType === 1 ? (
                                <tr>
                                  <td>
                                    <div className="input-group">
                                      <div className="form-group mb-0">
                                        <span className="input-group-text">$</span>{" "}
                                        <input className="form-control pl-0 rounded-0" type="text" {...register("per_accepted_appraisal_manager")}
                                          maxLength={7} />
                                      </div>
                                      <div className="label-text w-50">
                                        Per Accepted Appraisal Spiff
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              ) : (
                                <>
                                  <tr>
                                    <td>
                                      <div className="input-group">
                                        <div className="form-group mb-0">
                                          <span className="input-group-text">$</span>{" "}
                                          <input className="form-control pl-0 rounded-0" type="text" {...register("appraisal_bonus")} maxLength={7} />
                                        </div>
                                        <div className="label-text w-50">
                                          Per Appraisal Spiff
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <div className="input-group">
                                        <div className="form-group mb-0">
                                          <span className="input-group-text">$</span>{" "}
                                          <input className="form-control pl-0 rounded-0" type="text" {...register("vehicle_appraisal_bonus_dollar_value"
                                          )} maxLength={7} />
                                        </div>
                                        <div className="label-text w-50">
                                          Per Accepted Appraisal Spiff
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                </>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  )}

                <div className="col-lg-12">
                  <button
                    type="submit"
                    className="btn btn-pink"
                    disabled={loading}>
                    Update Role
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default RolesEdit;
