import React, { useContext, useEffect, useState } from "react";
import api from "../../api";
import AuthContext from "../../context/authContext";
import moment from "moment";
import Swal from "../Swal";
import DateRangePicker from "@wojtekmaj/react-daterange-picker/dist/DateRangePicker";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import BusinessContext from "../../context/businessContext";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    title: {
      display: false,
    },
  },
};

const labels = [
  "New",
  "Appraised",
  "Appraisal Assigned",
  "Offer Made",
  "Counter Offer",
  "Final Offer",
  "Accepted Offer",
  "No Deal",
];

const PipelineReport = () => {
  const [responseData, setResponseData] = useState({});
  const [filterType, setFilterType] = useState(4);
  const [selectedDate, setSelectedDate] = useState(null);
  const authCtx = useContext(AuthContext);
  const businessCtx = useContext(BusinessContext);

  useEffect(() => {
    if (filterType != 5) {
      getReportData({ date_type: filterType });
    } else if (filterType == 5) {
      getReportData({ date_type: 4 });
    }
  }, [filterType, businessCtx.selectedBusinessId]);

  const getReportData = async (formData = {}) => {
    if (
      authCtx.userData?.user_type == 3 &&
      businessCtx.selectedBusinessId != ""
    ) {
      formData.business_id = businessCtx.selectedBusinessId;
    } else if (authCtx.userData?.business_id) {
      formData.business_id = authCtx.userData?.business_id;
    }
    await api
      .post("api/report/appraisal-pipeline", formData, {
        headers: {
          Authorization: `Bearer ${authCtx.userData?.token}`,
        },
      })
      .then((res) => {
        setResponseData(res.data.data);
      })
      .catch((error) => {
        if (error.response.status == 401) {
          authCtx.logout();
        } else {
          Swal.fire(
            "Opps!",
            "Sorry we are not able to complete your request at this time. Please try again later.",
            "error"
          );
        }
      });
  };

  const onRangeChange = (value) => {
    setSelectedDate(value);
    if (value != null) {
      getReportData({
        date_type: filterType,
        start_date: moment(value[0]).format("YYYY-MM-DD"),
        end_date: moment(value[1]).format("YYYY-MM-DD"),
      });
    }
  };

  const filterOnChange = (value) => {
    setFilterType(value);
  };

  return (
    <>
      <div className="dashboard-count-filter mb-24">
        <div className="row align-items-center">
          <div className="col-auto">
            <span className="page-subtitle">
              Total <b>{responseData?.total_appraisal}</b> Appraisals
            </span>
          </div>
          {filterType == 5 && (
            <div className="col-auto pe-0">
              <DateRangePicker
                onChange={(e) => onRangeChange(e)}
                value={selectedDate}
                format="M-d-y"
                yearPlaceholder="yyyy"
                monthPlaceholder="mm"
                dayPlaceholder="dd"
                calendarIcon={<i className="icon icon-calender"></i>}
                maxDate={new Date()}
                clearIcon={null}
              />
            </div>
          )}
          <div className="col-auto">
            <div className="form-group">
              <select
                className="form-select"
                onChange={(e) => filterOnChange(e.target.value)}
                defaultValue={filterType}>
                <option value="1">This day</option>
                <option value="2">This week</option>
                <option value="3">This month</option>
                <option value="4">This year</option>
                <option value="5">Range</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-8">
          <Bar
            options={options}
            data={{
              labels,
              datasets: [
                {
                  label: "Appraisals Count",
                  data: [
                    responseData?.count?.new,
                    responseData?.count?.working,
                    responseData?.count?.appraised,
                    responseData?.count?.offer_made,
                    responseData?.count?.counter_offer_request,
                    responseData?.count?.final_offer,
                    responseData?.count?.accepted_offer,
                    responseData?.count?.no_deal,
                  ],
                  backgroundColor: [
                    "#43B0FB",
                    "#1E6FF8",
                    "#2446CE",
                    "#172F8A",
                    "#112368",
                    "#0B1845",
                    "#8bc34a",
                    "#f44646",
                  ],
                },
              ],
            }}
          />
        </div>
      </div>
    </>
  );
};

export default PipelineReport;
