import React, { useContext, useEffect, useState } from "react";
import api from "../api";
import Swal from "sweetalert2";
import AuthContext from "./authContext";

const BusinessContext = React.createContext({
  businessList: [],
  selectedBusinessId: "",
  onBusinessChange: (id) => { },
});

export const BusinessContextProvider = (props) => {
  const [selectedBusinessId, setSelectedBusinessId] = useState("");
  const [businessList, setBusinessList] = useState([]);

  const authCtx = useContext(AuthContext);

  useEffect(() => {
    const getBusinessList = () => {
      api
        .get(`api/get-all-business`, {
          headers: {
            Authorization: `Bearer ${authCtx.userData?.token}`,
          },
        })
        .then((response) => {
          if (response.data.status) {
            let businessListArray = [];
            if (response.data.data.length > 0) {
              businessListArray = response.data.data.map((element) => {
                return { value: element.id, label: element.business_name };
              });
              setBusinessList(businessListArray);
            }
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            authCtx.logout();
          } else {
            Swal.fire(
              "Opps!",
              "Sorry we are not able to complete your request at this time. Please try again later.",
              "error"
            );
          }
        });
    };
    if (authCtx.userData?.user_type === 3) {
      getBusinessList();
    }
  }, [authCtx.isLoggedIn]);

  const onBusinessChange = (id) => {
    setSelectedBusinessId(id);
  };

  const contextValue = {
    businessList: businessList,
    selectedBusinessId: selectedBusinessId,
    onBusinessChange: onBusinessChange,
  };

  return (
    <BusinessContext.Provider value={contextValue}>
      {props.children}
    </BusinessContext.Provider>
  );
};

export default BusinessContext;
