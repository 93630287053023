import React, { useContext, useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import api from "../../api";
import AuthContext from "../../context/authContext";
import Swal from "../Swal";
import useDebounce from "../../hook/useDebounce";
import { Link, useNavigate } from "react-router-dom";
import routes from "../../constants/routesConstants";

const InputCheck = ({ keyIdx, data, onChange }) => {
  const [isChecked, setIsChecked] = useState(false);
  const onChangeInput = () => {
    setIsChecked(!isChecked);
    onChange(!isChecked, data?.id);
  };
  useEffect(() => {
    setIsChecked(data?.status == 1 ? true : false);
  }, [data?.status]);
  return (
    <div className="switch-button">
      <input
        type="checkbox"
        hidden="hidden"
        id={`status${keyIdx}`}
        checked={isChecked}
        onChange={() => onChangeInput()}
      />
      <label className="switch" htmlFor={`status${keyIdx}`}></label>
    </div>
  );
};

const Business = () => {
  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [responseData, setResponsedata] = useState({});
  const [sortField, setSortField] = useState("");
  const [sortType, setSortType] = useState("desc");
  const [pagination, setPagination] = useState({
    total_records: 0,
    total_pages: 0,
    current: 0,
  });
  const [search, setSearch] = useState("");
  const searchDebouncedValue = useDebounce(search, 1000);
  const [businessName, setBusinessName] = useState("");
  const businessNameDebouncedValue = useDebounce(businessName, 1000);
  const [email, setEmail] = useState("");
  const emailDebouncedValue = useDebounce(email, 1000);
  const [manager, setManager] = useState("");
  const managerDebouncedValue = useDebounce(manager, 1000);
  const [employees, setEmployees] = useState("");
  const employeesDebouncedValue = useDebounce(employees, 1000);
  const [appraisal, setAppraisal] = useState("");
  const appraisalDebouncedValue = useDebounce(appraisal, 1000);
  const [won, setWon] = useState("");
  const wonDebouncedValue = useDebounce(won, 1000);
  const [noDeal, setNoDeal] = useState("");
  const noDealDebouncedValue = useDebounce(noDeal, 1000);
  const [revenue, setRevenue] = useState("");
  const revenueDebouncedValue = useDebounce(revenue, 1000);

  useEffect(() => {
    if (authCtx.userData?.user_type != 3) {
      navigate(routes.Dashboard);
    }
  }, []);

  useEffect(() => {
    let filterData = {};
    if (searchDebouncedValue != "") {
      filterData = {
        ...filterData,
        search: searchDebouncedValue,
      };
    }
    if (businessNameDebouncedValue != "") {
      filterData = {
        ...filterData,
        business_name: businessNameDebouncedValue,
      };
    }
    if (emailDebouncedValue != "") {
      filterData = {
        ...filterData,
        email: emailDebouncedValue,
      };
    }
    if (managerDebouncedValue != "") {
      filterData = {
        ...filterData,
        manager: managerDebouncedValue,
      };
    }
    if (appraisalDebouncedValue != "") {
      filterData = {
        ...filterData,
        appraisal: appraisalDebouncedValue,
      };
    }
    if (employeesDebouncedValue != "") {
      filterData = {
        ...filterData,
        employees: employeesDebouncedValue,
      };
    }
    if (wonDebouncedValue != "") {
      filterData = {
        ...filterData,
        won: wonDebouncedValue,
      };
    }
    if (noDealDebouncedValue != "") {
      filterData = {
        ...filterData,
        noDeal: noDealDebouncedValue,
      };
    }
    if (revenueDebouncedValue != "") {
      filterData = {
        ...filterData,
        revenue: revenueDebouncedValue,
      };
    }
    fetchBusiness(filterData, 1);
  }, [
    searchDebouncedValue,
    businessNameDebouncedValue,
    emailDebouncedValue,
    managerDebouncedValue,
    employeesDebouncedValue,
    appraisalDebouncedValue,
    wonDebouncedValue,
    noDealDebouncedValue,
    revenueDebouncedValue,
  ]);

  async function fetchBusiness(formData = {}, page) {
    setLoading(true);
    await api
      .post(`api/business?page=${page}`, formData, {
        headers: {
          Authorization: `Bearer ${authCtx.userData?.token}`,
        },
      })
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          setResponsedata(response.data.data);
          setPagination(response.data.data.paginate);
        } else {
          setResponsedata([]);
          setPagination({
            total_records: 0,
            total_pages: 0,
            current: 0,
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response?.status == 401) {
          authCtx.logout();
        } else {
          Swal.fire(
            "Opps!",
            "Sorry we are not able to complete your request at this time. Please try again later.",
            "error"
          );
        }
      });
  }

  function sort(field, currentField, sort, page) {
    let filterData = {};
    if (searchDebouncedValue != "") {
      filterData = {
        ...filterData,
        search: searchDebouncedValue,
      };
    }
    if (businessNameDebouncedValue != "") {
      filterData = {
        ...filterData,
        business_name: businessNameDebouncedValue,
      };
    }
    if (emailDebouncedValue != "") {
      filterData = {
        ...filterData,
        email: emailDebouncedValue,
      };
    }
    if (managerDebouncedValue != "") {
      filterData = {
        ...filterData,
        manager: managerDebouncedValue,
      };
    }
    if (appraisalDebouncedValue != "") {
      filterData = {
        ...filterData,
        appraisal: appraisalDebouncedValue,
      };
    }
    if (employeesDebouncedValue != "") {
      filterData = {
        ...filterData,
        employees: employeesDebouncedValue,
      };
    }
    if (wonDebouncedValue != "") {
      filterData = {
        ...filterData,
        won: wonDebouncedValue,
      };
    }
    if (noDealDebouncedValue != "") {
      filterData = {
        ...filterData,
        noDeal: noDealDebouncedValue,
      };
    }
    if (revenueDebouncedValue != "") {
      filterData = {
        ...filterData,
        revenue: revenueDebouncedValue,
      };
    }
    if (field == currentField) {
      if (sort == "desc") {
        setSortType("asc");
        filterData = {
          ...filterData,
          field_name: field,
          order_by: "asc",
        };
        fetchBusiness(filterData, page);
      } else {
        setSortType("desc");
        filterData = {
          ...filterData,
          field_name: field,
          order_by: "desc",
        };
        fetchBusiness(filterData, page);
      }
    } else {
      setSortField(field);
      setSortType("desc");
      filterData = {
        ...filterData,
        field_name: field,
        order_by: "desc",
      };
      fetchBusiness(filterData, page);
    }
  }

  const onPaginationFetch = (e) => {
    let filterData = {};
    if (sortField != "" && sortType != "") {
      filterData = {
        ...filterData,
        field_name: sortField,
        order_by: sortType,
      };
    }
    if (searchDebouncedValue != "") {
      filterData = {
        ...filterData,
        search: searchDebouncedValue,
      };
    }
    if (businessNameDebouncedValue != "") {
      filterData = {
        ...filterData,
        business_name: businessNameDebouncedValue,
      };
    }
    if (emailDebouncedValue != "") {
      filterData = {
        ...filterData,
        email: emailDebouncedValue,
      };
    }
    if (managerDebouncedValue != "") {
      filterData = {
        ...filterData,
        manager: managerDebouncedValue,
      };
    }
    if (appraisalDebouncedValue != "") {
      filterData = {
        ...filterData,
        appraisal: appraisalDebouncedValue,
      };
    }
    if (employeesDebouncedValue != "") {
      filterData = {
        ...filterData,
        employees: employeesDebouncedValue,
      };
    }
    if (wonDebouncedValue != "") {
      filterData = {
        ...filterData,
        won: wonDebouncedValue,
      };
    }
    if (noDealDebouncedValue != "") {
      filterData = {
        ...filterData,
        noDeal: noDealDebouncedValue,
      };
    }
    if (revenueDebouncedValue != "") {
      filterData = {
        ...filterData,
        revenue: revenueDebouncedValue,
      };
    }
    fetchBusiness(filterData, e.selected + 1);
  };

  const onStatusChange = async (value, id) => {
    await api
      .post(
        "api/business/update-business-status",
        { user_id: id, status: value == true ? 1 : 0 },
        {
          headers: {
            Authorization: `Bearer ${authCtx.userData?.token}`,
          },
        }
      )
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          Swal.fire(response.data.message, "", "success");
        } else {
          Swal.fire(response.data.message, "", "info");
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          authCtx.logout();
        } else {
          Swal.fire(
            "Opps!",
            "Sorry we are not able to complete your request at this time. Please try again later.",
            "error"
          );
        }
      });
    return false;
  };

  const clearHandler = () => {
    setSearch("");
    setEmail("");
    setEmployees("");
    setAppraisal("");
    setBusinessName("");
    setNoDeal("");
    setWon("");
    setManager("");
    setRevenue("");
  };

  return (
    <>
      <div className="dashboard-count-filter dashboard-appraisals mb-24">
        <div className="row align-items-center">
          <div className="col">
            <h2 className="page-title">Dealers</h2>
            <p className="page-dec">
              In total there is <b>{pagination?.total_records}</b> dealers
            </p>
          </div>
        </div>
      </div>
      <div className="dashboard-latest-appraisals">
        <div className="dashboard-card">
          <div className="dashboard-card-filter dashboard-count-filter">
            <div className="row align-items-center">
              <div className="col">
                <div className="dashboard-navbar-search dashboardml">
                  <div className="form-group mb-0">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </div>
                </div>
                <div className="dashboard-navbar-date-filter dashboardml">
                  <button
                    type="button"
                    className="date-filter"
                    onClick={() => clearHandler()}>
                    Clear <i className="icon icon-reset"></i>
                  </button>
                </div>
              </div>
              <div className="col-auto">
                <div className="dashboard-export">
                  <Link to={routes.BusinessCreate} className="btn btn-pink">
                    <i className="icon icon-add"></i> Add Dealer
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="dashboard-latest-apraisals business-table table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>
                    Business Name{" "}
                    <span
                      className={`table-sort ${
                        sortField == "business_name" ? sortType : ""
                      }`}
                      onClick={() =>
                        sort(
                          "business_name",
                          sortField,
                          sortType,
                          pagination.current
                        )
                      }></span>
                  </th>
                  <th>
                    Email{" "}
                    <span
                      className={`table-sort ${
                        sortField == "email" ? sortType : ""
                      }`}
                      onClick={() =>
                        sort("email", sortField, sortType, pagination.current)
                      }></span>
                  </th>
                  <th className="text-center">
                    Manager{" "}
                    <span
                      className={`table-sort ${
                        sortField == "totalManager" ? sortType : ""
                      }`}
                      onClick={() =>
                        sort(
                          "totalManager",
                          sortField,
                          sortType,
                          pagination.current
                        )
                      }></span>
                  </th>
                  <th className="text-center">
                    Employees
                    <span
                      className={`table-sort ${
                        sortField == "totalServiceWriter" ? sortType : ""
                      }`}
                      onClick={() =>
                        sort(
                          "totalServiceWriter",
                          sortField,
                          sortType,
                          pagination.current
                        )
                      }></span>
                  </th>
                  <th className="text-center">
                    Appraisal{" "}
                    <span
                      className={`table-sort ${
                        sortField == "totalAppraisal" ? sortType : ""
                      }`}
                      onClick={() =>
                        sort(
                          "totalAppraisal",
                          sortField,
                          sortType,
                          pagination.current
                        )
                      }></span>
                  </th>
                  <th className="text-center">
                    Won{" "}
                    <span
                      className={`table-sort ${
                        sortField == "totalWon" ? sortType : ""
                      }`}
                      onClick={() =>
                        sort(
                          "totalWon",
                          sortField,
                          sortType,
                          pagination.current
                        )
                      }></span>
                  </th>
                  <th className="text-center">
                    No Deal{" "}
                    <span
                      className={`table-sort ${
                        sortField == "totalNoDeal" ? sortType : ""
                      }`}
                      onClick={() =>
                        sort(
                          "totalNoDeal",
                          sortField,
                          sortType,
                          pagination.current
                        )
                      }></span>
                  </th>
                  <th>
                    Status{" "}
                    <span
                      className={`table-sort ${
                        sortField == "status" ? sortType : ""
                      }`}
                      onClick={() =>
                        sort("status", sortField, sortType, pagination.current)
                      }></span>
                  </th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="py-2">-</td>
                  <td className="py-2">
                    <div className="form-group mb-0">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search"
                        onChange={(e) => setBusinessName(e.target.value)}
                        value={businessName}
                      />
                    </div>
                  </td>
                  <td className="py-2">
                    <div className="form-group mb-0">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search"
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                      />
                    </div>
                  </td>
                  <td className="py-2">
                    <div className="form-group mb-0">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="i.e. >10"
                        onChange={(e) => setManager(e.target.value)}
                        value={manager}
                      />
                    </div>
                  </td>
                  <td className="py-2">
                    <div className="form-group mb-0">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="i.e. <10"
                        onChange={(e) => setEmployees(e.target.value)}
                        value={employees}
                      />
                    </div>
                  </td>
                  <td className="py-2">
                    <div className="form-group mb-0">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="i.e. 10"
                        onChange={(e) => setAppraisal(e.target.value)}
                        value={appraisal}
                      />
                    </div>
                  </td>
                  <td className="py-2">
                    <div className="form-group mb-0">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="i.e. 10"
                        onChange={(e) => setWon(e.target.value)}
                        value={won}
                      />
                    </div>
                  </td>
                  <td className="py-2">
                    <div className="form-group mb-0">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="i.e. 10"
                        onChange={(e) => setNoDeal(e.target.value)}
                        value={noDeal}
                      />
                    </div>
                  </td>
                  <td className="py-2">-</td>
                  <td className="py-2">-</td>
                </tr>
                {responseData?.business &&
                responseData?.business.length != 0 ? (
                  responseData?.business.map((data, key) => (
                    <tr key={key}>
                      <td width="3%">{data?.id}</td>
                      <td width="15%">
                        <Link to={`/business/view/${data?.id}`}>
                          {data?.business_name}
                        </Link>
                      </td>
                      <td>{data?.email}</td>
                      <td className="text-center">{data?.totalManager}</td>
                      <td className="text-center">
                        {data?.totalServiceWriter}
                      </td>
                      <td className="text-center">{data?.totalAppraisal}</td>
                      <td className="text-center">{data?.totalWon}</td>
                      <td className="text-center">{data?.totalNoDeal}</td>
                      <td>
                        <InputCheck
                          keyIdx={key}
                          data={data}
                          onChange={onStatusChange}
                        />
                      </td>
                      <td>
                        <Link
                          to={`/business/view/${data?.id}`}
                          className="btn-icon">
                          <i className="icon icon-eye-open"></i>
                        </Link>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr className="text-start text-sm-center">
                    <td colSpan={12}>
                      {loading ? "Loading..." : "No Dealers Found."}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {pagination?.total_pages != undefined && pagination?.total_pages != 1 && (
        <nav className="navigation pagination">
          <ReactPaginate
            breakLabel="..."
            onPageChange={onPaginationFetch}
            pageRangeDisplayed={2}
            pageCount={pagination?.total_pages}
            renderOnZeroPageCount={null}
            containerClassName="nav-links"
            activeLinkClassName="current"
            pageLinkClassName="page-numbers"
            previousLinkClassName="prev page-numbers"
            previousLabel={<i className="icon icon-arrow-left"></i>}
            nextLinkClassName="next page-numbers"
            nextLabel={<i className="icon icon-arrow-right"></i>}
          />
        </nav>
      )}
    </>
  );
};

export default Business;
