import React from "react";
import { Link, useNavigate } from "react-router-dom";
import routes from "../../constants/routesConstants";
import successImg from "../../assets/img/svg/successful.svg";
import { useEffect } from "react";

const PaymentSuccess = () => {
  let userName = localStorage.getItem("userName");
  const navigate = useNavigate();

  useEffect(() => {
    if (userName === null) {
      navigate(routes.Register);
    }
  }, []);
  return (
    <>
      <div className="wrapper">
        <section className="login-section reset-password-section successful-section">
          <div className="login-upper-section">
            <div className="container-fluid">
              <div className="row align-items-center justify-content-center">
                <div className="col-lg-5">
                  <div className="login-inner mx-auto">
                    <h2 className="login-title">
                      Well done <b>{userName} !</b>
                      <br /> Welcome to Vehicle Appraisal System{" "}
                    </h2>
                    <div className="successfulpayMian text-center">
                      <div className="successfulimg">
                        <img src={successImg} alt="" />
                      </div>
                      <h5 className="successfulTitle">
                        Your account has been created successfully
                      </h5>
                      {/* <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Donec feugiat urna, dd egestas gravida{" "}
                      </p> */}
                    </div>
                    <Link to={routes.Login} className="btn btn-pink w-100">
                      Go to Login
                    </Link>
                    {/* <p className="form-privacy-policy">
                      <a href="#">terms and conditions</a> r sit amet,
                      consectetur adipiscing elit. Adipiscing nunc, <br />
                      mattis facilisis neque, magnis nulla donec morbi.
                    </p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="login-down-section">
            <div className="container-fluid">
              <div className="row align-items-center">
                <div className="col-lg-12">
                  <div className="login-copyright">
                    <span className="me-0">
                      ©{new Date().getFullYear()} Vehicle Appraisal System | All
                      rights Reserved
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default PaymentSuccess;
