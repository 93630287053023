import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import * as yup from "yup";
import api from "../../api";
import routes from "../../constants/routesConstants";
import { useNavigate } from "react-router-dom";
import Swal from "../Swal";
import { formatPhoneNumber } from "../../utils/formats";

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email()
    .when("isPhone", {
      is: false,
      then: yup.string().max(42).required("Email is required."),
    }),
  isPhone: yup.boolean(),
  mobile_no: yup.string().when("isPhone", {
    is: true,
    then: yup
      .string()
      .required("Phone number is required.")
      .min(10, "Phone number is to short.")
      .max(12, "Phone number is to long."),
  }),
});

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const [sendType, setSendType] = useState("email");
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    watch,
  } = useForm({
    defaultValues: {
      isPhone: false,
    },
    resolver: yupResolver(validationSchema),
  });

  const mobile_no = watch("mobile_no");
  useEffect(() => {
    setValue("mobile_no", formatPhoneNumber(mobile_no).formatted);
  }, [mobile_no]);

  const onSubmit = async (formValue) => {
    setLoading(true);
    let formData = new FormData();
    formValue.type = "Email";
    if (formValue.isPhone == true) {
      formValue.type = "Phone";
      formData.append("mobile_no", formatPhoneNumber(formValue.mobile_no).base);
    } else {
      formData.append("email", formValue.email);
    }
    formData.append("type", formValue.type);
    await api
      .post("api/forgot-password", formData)
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          Swal.fire(response.data.message, "", "success");
          navigate(routes.Login);
        } else {
          Swal.fire("Try Again!", response.data.message, "info");
        }
      })
      .catch((error) => {
        setLoading(false);
        Swal.fire(
          "Opps!",
          "Sorry we are not able to complete your request at this time. Please try again later.",
          "error"
        );
      });
  };

  const sendTypeHandler = () => {
    reset();
    if (sendType == "phone") {
      setValue("isPhone", false);
      setSendType("email");
    } else {
      setValue("isPhone", true);
      setSendType("phone");
    }
  };

  return (
    <section className="login-section reset-password-section">
      <div className="login-upper-section">
        <div className="container-fluid">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-5">
              <div className="login-inner mx-auto">
                <h2 className="login-title">Reset your Password</h2>
                <div className="login-form">
                  <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                    <div className="login-form-inner">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="login-tabs">
                            <ul>
                              <li
                                className={`${
                                  sendType == "email" ? "active" : ""
                                }`}
                                onClick={() => sendTypeHandler()}
                              >
                                <i className="icon icon-mail-2"></i>Via Email
                              </li>
                              <li
                                className={`${
                                  sendType == "phone" ? "active" : ""
                                }`}
                                onClick={() => sendTypeHandler()}
                              >
                                <i className="icon icon-phone"></i>Via Phone
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label className="form-label">
                              Your Registered{" "}
                              {sendType == "email" ? "Email" : "Phone"}{" "}
                              <span>*</span>
                            </label>
                            <div className="position-relative">
                              {sendType == "email" ? (
                                <input
                                  type="email"
                                  className="form-control email"
                                  placeholder="Ex: Example@email.com"
                                  {...register("email")}
                                  maxLength={42}
                                />
                              ) : (
                                <input
                                  type="text"
                                  className="form-control phone"
                                  placeholder="Ex: 65454646546"
                                  {...register("mobile_no")}
                                  maxLength={12}
                                />
                              )}
                              {(errors.email?.message != undefined ||
                                errors.mobile_no?.message != undefined) && (
                                <span className="form-error">
                                  {sendType == "email"
                                    ? errors.email?.message
                                    : errors.mobile_no?.message}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <button
                            type="submit"
                            className="btn btn-pink"
                            disabled={loading}
                          >
                            Send me a reset link
                          </button>
                        </div>
                        {/* <div className="col-lg-12">
                          <p className="form-privacy-policy">
                            <a href="#">terms and conditions</a>r sit amet,
                            consectetur adipiscing elit. Adipiscing nunc, mattis
                            facilisis neque, magnis nulla donec morbi.
                          </p>
                        </div> */}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="login-down-section">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="login-copyright">
                <span className="me-0">
                  ©{new Date().getFullYear()} Vehicle Appraisal System | All
                  rights Reserved
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ForgotPassword;
