import React, { useEffect, useState } from "react";

const ThemeContext = React.createContext({
  isSidebarOpen: false,
  toggleSidebar: () => {},
  showDropShadow: false,
  showDropShadowHandler: () => {},
  hideDropShadowHandler: () => {},
  isContactPopupShow: false,
  contactPopupOpen: () => {},
  contactPopupClose: () => {},
  isGetHelpPopupShow: false,
  getHelpPopupOpen: () => {},
  getHelpPopupClose: () => {},
  isSendLinkPopupShow: false,
  sendLinkPopupOpen: () => {},
  sendLinkPopupClose: () => {},
  reload: 0,
  reloadHandler: () => {},
});

export const ThemeContextProvider = (props) => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [showDropShadow, setShowDropShadow] = useState(false);
  const [isContactPopupShow, setIsContactPopupShow] = useState(false);
  const [isGetHelpPopupShow, setIsGetHelpPopupShow] = useState(false);
  const [isSendLinkPopupShow, setIsSendLinkPopupShow] = useState(false);
  const [reload, setReload] = useState(0);

  useEffect(() => {
    if (showDropShadow) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [showDropShadow]);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
    setShowDropShadow(!showDropShadow);
  };

  const showDropShadowHandler = () => {
    setShowDropShadow(true);
  };

  const hideDropShadowHandler = () => {
    setShowDropShadow(false);
  };

  const contactPopupOpen = () => {
    setIsContactPopupShow(true);
    setShowDropShadow(true);
    setSidebarOpen(false);
    setIsGetHelpPopupShow(false);
  };

  const contactPopupClose = () => {
    setIsContactPopupShow(false);
    if (!isSidebarOpen) {
      setShowDropShadow(false);
    }
  };

  const getHelpPopupOpen = () => {
    setIsGetHelpPopupShow(true);
    setShowDropShadow(true);
    setSidebarOpen(false);
    setIsContactPopupShow(false);
  };

  const getHelpPopupClose = () => {
    setIsGetHelpPopupShow(false);
    if (!isSidebarOpen) {
      setShowDropShadow(false);
    }
  };

  const sendLinkPopupOpen = () => {
    setIsSendLinkPopupShow(true);
    setShowDropShadow(true);
  };

  const sendLinkPopupClose = () => {
    setIsSendLinkPopupShow(false);
    if (!isSidebarOpen) {
      setShowDropShadow(false);
    }
  };

  const reloadHandler = () => {
    setReload((v) => v + 1);
  };

  const contextValue = {
    isSidebarOpen: isSidebarOpen,
    toggleSidebar: toggleSidebar,
    showDropShadow: showDropShadow,
    showDropShadowHandler: showDropShadowHandler,
    hideDropShadowHandler: hideDropShadowHandler,
    isContactPopupShow: isContactPopupShow,
    contactPopupOpen: contactPopupOpen,
    contactPopupClose: contactPopupClose,
    isGetHelpPopupShow: isGetHelpPopupShow,
    getHelpPopupOpen: getHelpPopupOpen,
    getHelpPopupClose: getHelpPopupClose,
    isSendLinkPopupShow: isSendLinkPopupShow,
    sendLinkPopupOpen: sendLinkPopupOpen,
    sendLinkPopupClose: sendLinkPopupClose,
    reload: reload,
    reloadHandler: reloadHandler,
  };

  return (
    <ThemeContext.Provider value={contextValue}>
      {props.children}
    </ThemeContext.Provider>
  );
};

export default ThemeContext;
