import React, { useContext, useEffect, useState } from "react";
import api from "../../api";
import useDebounce from "../../hook/useDebounce";
import AuthContext from "../../context/authContext";
import userImg from "../../assets/img/user.jpg";
import ReactPaginate from "react-paginate";
import moment from "moment";
import downloadCsv from "download-csv";
import Swal from "../Swal";
import { Link, useNavigate } from "react-router-dom";
import routes from "../../constants/routesConstants";
import DateRangePicker from "@wojtekmaj/react-daterange-picker/dist/DateRangePicker";
import { formatPhoneNumber } from "../../utils/formats";
import BusinessContext from "../../context/businessContext";
import _ from "lodash";
import usePermission from "../../hook/usePermission";
import {
  EMPLOYEES_ADD,
  EMPLOYEES_DELETE,
  EMPLOYEES_EDIT,
  EMPLOYEES_LIST,
} from "../../constants/permissionConstants";

const Employees = ({ businessId, showEmployeeLogs }) => {
  const [responseData, setResponseData] = useState({});
  const [loading, setLoading] = useState(false);
  const [roleType, setRoleType] = useState("");
  const [sortField, setSortField] = useState("");
  const [sortType, setSortType] = useState("desc");
  const navigate = useNavigate();
  const [pagination, setPagination] = useState({
    total_records: 0,
    total_pages: 0,
    current: 0,
  });

  const authCtx = useContext(AuthContext);
  const businessCtx = useContext(BusinessContext);
  const [search, setSearch] = useState("");
  const searchDebouncedValue = useDebounce(search, 1000);
  const [selectedDate, setSelectedDate] = useState(null);
  const [roles, setRoles] = useState([]);

  const isEmployeeListAllow = usePermission(EMPLOYEES_LIST);
  const isEmployeeAddAllow = usePermission(EMPLOYEES_ADD);
  const isEmployeeEditAllow = usePermission(EMPLOYEES_EDIT);
  const isEmployeeDeleteAllow = usePermission(EMPLOYEES_DELETE);

  useEffect(() => {
    if (!isEmployeeListAllow) {
      navigate(routes.Dashboard);
    }
  }, []);

  useEffect(() => {
    let filterData = {};
    if (businessId !== undefined) {
      filterData = {
        ...filterData,
        business_id: businessId,
      };
    }
    api
      .post(`api/role`, filterData, {
        headers: {
          Authorization: `Bearer ${authCtx.userData?.token}`,
        },
      })
      .then((response) => {
        if (response.data.status) {
          let rolesArray = [];
          if (response.data.data?.roles.length > 0) {
            response.data.data?.roles.map((element) => {
              rolesArray.push({
                value: element.user_type,
                label: element.role_name,
              });
            });
            setRoles(rolesArray);
          } else {
            setRoles([]);
          }
        } else {
          setRoles([]);
        }
      })
      .catch((error) => {
        if (error.response?.status == 401) {
          authCtx.logout();
        }
      });
  }, []);

  useEffect(() => {
    let filterData = {};
    if (roleType != "") {
      filterData = {
        ...filterData,
        user_type: roleType,
      };
    }
    if (searchDebouncedValue != "") {
      filterData = {
        ...filterData,
        search: searchDebouncedValue,
      };
    }
    if (selectedDate != null) {
      filterData = {
        ...filterData,
        duration: "5",
        start_date: moment(selectedDate[0]).format("YYYY-MM-DD"),
        end_date: moment(selectedDate[1]).format("YYYY-MM-DD"),
      };
    }
    fetchEmployees(filterData, 1);
  }, [
    searchDebouncedValue,
    roleType,
    selectedDate,
    businessCtx.selectedBusinessId,
  ]);

  async function fetchEmployees(formData = {}, page) {
    setLoading(true);
    if (
      authCtx.userData?.user_type == 3 &&
      businessCtx.selectedBusinessId != ""
    ) {
      formData.business_id = businessCtx.selectedBusinessId;
    }
    if (businessId !== undefined) {
      formData.business_id = businessId;
    }
    await api
      .post(`api/users?page=${page}`, formData, {
        headers: {
          Authorization: `Bearer ${authCtx.userData?.token}`,
        },
      })
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          setResponseData(response.data.data);
          setPagination(response.data.data.paginate);
        } else {
          setResponseData([]);
          setPagination({
            total_records: 0,
            total_pages: 0,
            current: 0,
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          authCtx.logout();
        } else {
          Swal.fire(
            "Opps!",
            "Sorry we are not able to complete your request at this time. Please try again later.",
            "error"
          );
        }
      });
  }

  function sort(field, currentField, sort, page) {
    let filterData = {};
    if (roleType != "") {
      filterData = {
        ...filterData,
        user_type: roleType,
      };
    }
    if (searchDebouncedValue != "") {
      filterData = {
        ...filterData,
        search: searchDebouncedValue,
      };
    }
    if (selectedDate != null) {
      filterData = {
        ...filterData,
        duration: "5",
        start_date: moment(selectedDate[0]).format("YYYY-MM-DD"),
        end_date: moment(selectedDate[1]).format("YYYY-MM-DD"),
      };
    }
    if (field == currentField) {
      if (sort == "desc") {
        setSortType("asc");
        filterData = {
          ...filterData,
          field_name: field,
          order_by: "asc",
        };
        fetchEmployees(filterData, page);
      } else {
        setSortType("desc");
        filterData = {
          ...filterData,
          field_name: field,
          order_by: "desc",
        };
        fetchEmployees(filterData, page);
      }
    } else {
      setSortField(field);
      setSortType("desc");
      filterData = {
        ...filterData,
        field_name: field,
        order_by: "desc",
      };
      fetchEmployees(filterData, page);
    }
  }

  const onRangeChange = (value) => {
    setSelectedDate(value);
  };

  const onPaginationFetch = (e) => {
    let filterData = {};
    if (sortField != "" && sortType != "") {
      filterData = {
        ...filterData,
        field_name: sortField,
        order_by: sortType,
      };
    }
    if (roleType != "") {
      filterData = {
        ...filterData,
        user_type: roleType,
      };
    }
    if (searchDebouncedValue != "") {
      filterData = {
        ...filterData,
        search: searchDebouncedValue,
      };
    }
    if (selectedDate != null) {
      filterData = {
        ...filterData,
        duration: "5",
        start_date: moment(selectedDate[0]).format("YYYY-MM-DD"),
        end_date: moment(selectedDate[1]).format("YYYY-MM-DD"),
      };
    }
    fetchEmployees(filterData, e.selected + 1);
  };

  const deleteHandler = (id) => {
    if (id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          setLoading(true);
          api
            .post(
              "api/users/delete-user",
              { user_id: id },
              {
                headers: {
                  Authorization: `Bearer ${authCtx.userData?.token}`,
                },
              }
            )
            .then((response) => {
              setLoading(false);
              if (response.data.status) {
                fetchEmployees({}, 1);
                Swal.fire(response.data.message, "", "success");
              } else {
                Swal.fire("Try Again!", response.data.message, "info");
              }
            })
            .catch((error) => {
              setLoading(false);
              if (error.response.status == 401) {
                authCtx.logout();
              } else {
                Swal.fire(
                  "Opps!",
                  "Sorry we are not able to complete your request at this time. Please try again later.",
                  "error"
                );
              }
            });
        }
      });
    }
    return false;
  };

  const currentDateTime = new Date();
  const exportHandler = async () => {
    setLoading(true);
    let filterData = {
      action: "export",
    };
    if (sortField != "" && sortType != "") {
      filterData = {
        ...filterData,
        field_name: sortField,
        order_by: sortType,
      };
    }
    if (roleType != "") {
      filterData = {
        ...filterData,
        user_type: roleType,
      };
    }
    if (searchDebouncedValue != "") {
      filterData = {
        ...filterData,
        search: searchDebouncedValue,
      };
    }
    if (selectedDate != null) {
      filterData = {
        ...filterData,
        duration: "5",
        start_date: moment(selectedDate[0]).format("YYYY-MM-DD"),
        end_date: moment(selectedDate[1]).format("YYYY-MM-DD"),
      };
    }
    if (businessId !== undefined) {
      filterData = {
        ...filterData,
        business_id: businessId,
      };
    }
    await api
      .post("api/users", filterData, {
        headers: {
          Authorization: `Bearer ${authCtx.userData?.token}`,
        },
      })
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          let exportData = response.data.data.map((item) => {
            if (authCtx.userData?.user_type == 3) {
              return {
                businessName: item.businessName,
                name: item.name,
                role:
                  item?.role_name !== null
                    ? item?.role_name
                    : item?.user_type == 0
                      ? "Business"
                      : "-",
                mobile_no: item?.user_type == 0 ? formatPhoneNumber(item.business_phone).formatted : formatPhoneNumber(item.mobile_no).formatted,
                email: item.email,
                created_at: moment(item.created_at).format("MMMM D, YYYY"),
              };
            } else {
              return {
                name: item.name,
                role:
                  item?.role_name !== null
                    ? item?.role_name
                    : item?.user_type == 0
                      ? "Business"
                      : "-",
                mobile_no: item?.user_type == 0 ? formatPhoneNumber(item.business_phone).formatted : formatPhoneNumber(item.mobile_no).formatted,
                email: item.email,
                created_at: moment(item.created_at).format("MMMM D, YYYY"),
              };
            }
          });

          let exportColumns = {
            name: "Employees",
            role: "Role",
            mobile_no: "Phone Number",
            email: "Email",
            created_at: "Joining Date",
          };

          if (authCtx.userData?.user_type == 3) {
            exportColumns = {
              businessName: "Business Name",
              ...exportColumns,
            };
          }

          downloadCsv(
            exportData,
            exportColumns,
            `employees-${moment(currentDateTime).format("DD-MM-YYYY")}`
          );
        } else {
          Swal.fire("Try Again!", response.data.message, "info");
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response?.status == 401) {
          authCtx.logout();
        } else {
          Swal.fire(
            "Opps!",
            "Sorry we are not able to complete your request at this time. Please try again later.",
            "error"
          );
        }
      });
  };

  const clearHandler = () => {
    setSearch("");
    setRoleType("");
    setSelectedDate(null);
  };

  return (
    <>
      <div className="dashboard-count-filter dashboard-appraisals  mb-24">
        <div className="row align-items-center">
          <div className="col">
            <h2 className="page-title">Employees</h2>
            <p className="page-dec">
              In total there is <b>{pagination?.total_records}</b> employees
            </p>
          </div>
        </div>
      </div>
      <div className="dashboard-latest-appraisals">
        <div className="dashboard-card">
          <div className="dashboard-count-filter dashboard-card-filter">
            <div className="row align-items-center">
              <div className="col-md col-12">
                <div className="dashboard-navbar-search dashboardml">
                  <div className="form-group mb-0">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </div>
                </div>
                {(businessId !== undefined ||
                  (isEmployeeListAllow &&
                    authCtx.userData?.user_type != 3)) && (
                    <div className="dashboard-navbar-month dashboardml">
                      <div className="form-group">
                        <select
                          className="form-select"
                          onChange={(e) => setRoleType(e.target.value)}
                          value={roleType}>
                          <option value="">Select Role</option>
                          {_.map(roles, (option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  )}
                <div className="dashboard-navbar-month dashboardml">
                  <DateRangePicker
                    onChange={(e) => onRangeChange(e)}
                    value={selectedDate}
                    format="M-d-y"
                    yearPlaceholder="yyyy"
                    monthPlaceholder="mm"
                    dayPlaceholder="dd"
                    calendarIcon={<i className="icon icon-calender"></i>}
                    maxDate={new Date()}
                    clearIcon={null}
                  />
                </div>
                <div className="dashboard-navbar-date-filter dashboardml">
                  <button
                    type="button"
                    className="date-filter"
                    onClick={() => clearHandler()}>
                    Clear <i className="icon icon-reset"></i>
                  </button>
                </div>
              </div>
              <div className="col-auto">
                <div className="dashboard-export">
                  {isEmployeeAddAllow && (
                    <Link
                      to={`${businessId === undefined
                        ? routes.EmployeesCreate
                        : `/employees/create/${businessId}`
                        }`}
                      className="btn btn-pink">
                      <i className="icon icon-add"></i> Add Employee
                    </Link>
                  )}
                  <button
                    type="button"
                    className="btn btn-pink"
                    onClick={() => exportHandler()}
                    disabled={loading}>
                    <i className="icon icon-export"></i> Export
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="dashboard-latest-apraisals table-responsive">
            <table className="table">
              <thead>
                <tr>
                  {/* <th>#</th> */}
                  {authCtx.userData?.user_type == 3 && (
                    <th width="10%">Business Name</th>
                  )}
                  <th width="10%">
                    Employees
                    <span
                      className={`table-sort ${sortField == "name" ? sortType : ""
                        }`}
                      onClick={() =>
                        sort("name", sortField, sortType, pagination.current)
                      }></span>
                  </th>
                  <th width="12%">
                    Role
                    <span
                      className={`table-sort ${sortField == "user_type" ? sortType : ""
                        }`}
                      onClick={() =>
                        sort(
                          "user_type",
                          sortField,
                          sortType,
                          pagination.current
                        )
                      }></span>
                  </th>
                  <th width="12%">
                    Phone Number
                    <span
                      className={`table-sort ${sortField == "mobile_no" ? sortType : ""
                        }`}
                      onClick={() =>
                        sort(
                          "mobile_no",
                          sortField,
                          sortType,
                          pagination.current
                        )
                      }></span>
                  </th>
                  <th width="11%">
                    Email
                    <span
                      className={`table-sort ${sortField == "email" ? sortType : ""
                        }`}
                      onClick={() =>
                        sort("email", sortField, sortType, pagination.current)
                      }></span>
                  </th>
                  <th width="10%">
                    Joining Date
                    <span
                      className={`table-sort ${sortField == "created_at" ? sortType : ""
                        }`}
                      onClick={() =>
                        sort(
                          "created_at",
                          sortField,
                          sortType,
                          pagination.current
                        )
                      }></span>
                  </th>
                  {(isEmployeeEditAllow || isEmployeeDeleteAllow) && (
                    <th width="10%">Action</th>
                  )}
                </tr>
              </thead>
              <tbody>
                {responseData?.user && responseData?.user.length != 0 ? (
                  responseData?.user.map((data) => (
                    <tr key={data?.id}>
                      {/* <td width="3%">{data?.id}</td> */}
                      {authCtx.userData?.user_type == 3 && (
                        <td>{data?.businessName}</td>
                      )}
                      <td>
                        <div className="table-user-image">
                          <img
                            src={
                              data?.image != "" && data?.image != null
                                ? data?.image
                                : userImg
                            }
                            alt=""
                          />
                        </div>
                        {data?.name}
                      </td>
                      <td>
                        {data?.role_name !== null
                          ? data?.role_name
                          : data?.user_type == 0
                            ? "Dealer"
                            : "-"}
                      </td>
                      <td>
                        {data?.user_type === 0 ? data?.business_phone !== null
                          ? formatPhoneNumber(data?.business_phone).formatted
                          : "-" : data?.mobile_no !== null
                          ? formatPhoneNumber(data?.mobile_no).formatted
                          : "-"}
                      </td>
                      <td>{data?.email}</td>
                      <td>{moment.utc(data?.created_at).format("MMMM D, YYYY")}</td>
                      {(isEmployeeEditAllow || isEmployeeDeleteAllow) && (
                        <td>
                          {isEmployeeDeleteAllow && (
                            <button
                              type="button"
                              className="btn-icon"
                              onClick={() => deleteHandler(data?.id)}>
                              <i className="icon icon-delete"></i>
                            </button>
                          )}
                          {isEmployeeEditAllow && (
                            <Link
                              to={`/employees/view/${data?.id}`}
                              className="btn-icon">
                              <i className="icon icon-edit"></i>
                            </Link>
                          )}
                          {businessId !== undefined && (
                            <button
                              type="button"
                              className="btn-icon"
                              title="Employee Logs"
                              onClick={() => showEmployeeLogs(data?.id)}>
                              <i className="icon icon-reports"></i>
                            </button>
                          )}
                        </td>
                      )}
                    </tr>
                  ))
                ) : (
                  <tr className="text-start text-sm-center">
                    <td colSpan={8}>
                      {loading
                        ? "Loading..."
                        : `No Employee Found.`}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {pagination?.total_pages != undefined && pagination?.total_pages != 1 && (
        <nav className="navigation pagination">
          <ReactPaginate
            breakLabel="..."
            onPageChange={onPaginationFetch}
            pageRangeDisplayed={2}
            pageCount={pagination?.total_pages}
            renderOnZeroPageCount={null}
            containerClassName="nav-links"
            activeLinkClassName="current"
            pageLinkClassName="page-numbers"
            previousLinkClassName="prev page-numbers"
            previousLabel={<i className="icon icon-arrow-left"></i>}
            nextLinkClassName="next page-numbers"
            nextLabel={<i className="icon icon-arrow-right"></i>}
          />
        </nav>
      )}
    </>
  );
};

export default Employees;
