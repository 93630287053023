import React from "react";
import { formatCurrency } from "../../utils/formats";
const DealerDetails = ({
  name = "-",
  totalEmployees = 0,
  totalEarnings = 0,
  totalBalance = 0,
  email = "-",
  address = "-",
}) => {
  return (
    <div className="dashboard-card dashboard-dealer-details">
      <div className="row align-items-center">
        <div className="col">
          <h4 className="card-title">Dealer Details</h4>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-lg-6 order-lg-first order-first">
          <div className="dashboard-dealer-details-label">Name</div>
          <div className="dashboard-dealer-details-text">{name}</div>
        </div>
        <div className="col-lg-6 order-lg-first order-last">
          <div className="dashboard-dealer-details-label">Total Employees</div>
          <div className="dashboard-dealer-details-text">{totalEmployees}</div>
        </div>
        <div className="col-lg-6 order-lg-first order-first">
          <div className="dashboard-dealer-details-label">Email</div>
          <div className="dashboard-dealer-details-text">
            <a href={`mailto:${email}`}>{email}</a>
          </div>
        </div>
        <div className="col-lg-6 order-lg-first order-last">
          <div className="dashboard-dealer-details-label">Total Earnings</div>
          <div className="dashboard-dealer-details-text">
            {formatCurrency(totalEarnings)}
          </div>
        </div>
        <div className="col-lg-6 order-lg-first order-first">
          <div className="dashboard-dealer-details-label">Address</div>
          <div className="dashboard-dealer-details-text">{address}</div>
        </div>
      </div>
    </div>
  );
};

export default DealerDetails;
