import React, { Fragment, useContext, useEffect, useState } from "react";
import api from "../../api";
import useDebounce from "../../hook/useDebounce";
import AuthContext from "../../context/authContext";
import ReactPaginate from "react-paginate";
import moment from "moment";
import _ from "lodash";
import Swal from "../Swal";
import { useNavigate } from "react-router-dom";
import ThemeContext from "../../context/themeContext";

const Notifications = () => {
  const [responseData, setResponsedata] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    total_records: 0,
    total_pages: 0,
    current: 0,
  });

  const authCtx = useContext(AuthContext);
  const themeCtx = useContext(ThemeContext);
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const searchDebouncedValue = useDebounce(search, 1000);

  useEffect(() => {
    if (searchDebouncedValue != "") {
      fetchNotifications({ search: searchDebouncedValue }, 1);
    } else {
      fetchNotifications({}, 1);
    }
  }, [searchDebouncedValue]);

  async function fetchNotifications(formValue = {}, page) {
    setLoading(true);
    await api
      .post(`api/leads/notification?page=${page}`, formValue, {
        headers: {
          Authorization: `Bearer ${authCtx.userData?.token}`,
        },
      })
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          let notificationArray = [];
          for (let day in response.data.data?.notification) {
            notificationArray.push(day);
            response.data.data?.notification[day].map((n) => {
              notificationArray.push(n);
            });
          }
          setResponsedata(notificationArray);
          setPagination(response.data.data.paginate);
        } else {
          setResponsedata([]);
          setPagination({
            total_records: 0,
            total_pages: 0,
            current: 0,
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response?.status == 401) {
          authCtx.logout();
        } else {
          Swal.fire(
            "Opps!",
            "Sorry we are not able to complete your request at this time. Please try again later.",
            "error"
          );
        }
      });
  }

  const onPaginationFetch = (e) => {
    fetchNotifications({ search: searchDebouncedValue }, e.selected + 1);
  };

  const markAllReadHandler = () => {
    api
      .post(
        `api/leads/read-notification`,
        {},
        {
          headers: {
            Authorization: `Bearer ${authCtx.userData?.token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.status) {
          fetchNotifications({}, 1);
          themeCtx.reloadHandler();
        }
      })
      .catch((error) => {
        if (error.response.status == 401) {
          authCtx.logout();
        } else {
          Swal.fire(
            "Opps!",
            "Sorry we are not able to complete your request at this time. Please try again later.",
            "error"
          );
        }
      });
  };

  const markReadHandler = (notification_id, lead_id) => {
    api
      .post(
        `api/leads/get-lead`,
        {
          notification_id: notification_id,
          lead_id: lead_id,
        },
        {
          headers: {
            Authorization: `Bearer ${authCtx.userData?.token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.status) {
          navigate(`/appraisals/view/${lead_id}`);
        } else {
          Swal.fire("Try Again!", response.data.message, "info");
        }
      })
      .catch((error) => {
        if (error.response?.status == 401) {
          authCtx.logout();
        } else {
          Swal.fire(
            "Opps!",
            "Sorry we are not able to complete your request at this time. Please try again later.",
            "error"
          );
        }
      });
  };

  return (
    <>
      <div className="appraisal-head-wrap notifications-head-wrap  mb-24">
        <div className="row align-items-center">
          <div className="col">
            <div className="appraisal-head-title align-items-start">
              <button
                type="button"
                className="btn-icon"
                onClick={() => navigate(-1)}
              >
                <i className="icon icon-back-aero"></i>
              </button>
              <div className="page-head">
                <h2 className="page-title">Notification Center</h2>
                <p className="page-dec">
                  Ther is <b>{pagination?.total_records}</b> new notifications
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="dashboard-latest-appraisals">
        <div className="dashboard-card dashboard-notification">
          <div className="dashboard-count-filter dashboard-card-filter">
            <div className="row align-items-center">
              <div className="col mw-100">
                <div className="dashboard-navbar-search dashboardml">
                  <div className="form-group mb-0">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search"
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="col-auto mw-100">
                <div className="dashboard-export">
                  <button
                    type="button"
                    onClick={() => markAllReadHandler()}
                    className="btn btn-pink"
                  >
                    Mark all as read
                  </button>
                </div>
              </div>
            </div>
          </div>
          {responseData.length != 0 &&
            responseData.map((data, key) => (
              <Fragment key={key}>
                {_.isString(data) ? (
                  <div className="history-date">
                    <span className="date-Title">{data}</span>
                  </div>
                ) : (
                  <div
                    className="offer-note bglight"
                    onClick={() =>
                      markReadHandler(data?.id, data?.reference_id)
                    }
                  >
                    <div className="row align-items-center">
                      <div className="col-auto offer-notecol">
                        <div className="offer-note-image">
                          <i className="icon icon-notification-2"></i>
                        </div>
                      </div>
                      <div className="col offer-notecol">
                        <div className="offer-note-head">
                          <div className="offer-note-info">
                            <p>{data?.title}</p>
                          </div>
                          <div className="noted-action-buttons">
                            <span className="notifitimedaytext dashboardml">
                              {moment(data?.created_at).fromNow(true)} ago
                            </span>
                            {data?.is_read == "0" && (
                              <span className="status-new dashboardml">
                                New
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="offer-note-detail">
                          {data?.message != null && <p>{data?.message}</p>}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </Fragment>
            ))}
        </div>
      </div>
      {pagination?.total_pages != undefined && pagination?.total_pages != 1 && (
        <nav className="navigation pagination">
          <ReactPaginate
            breakLabel="..."
            onPageChange={onPaginationFetch}
            forcePage={parseInt(pagination?.current - 1)}
            pageRangeDisplayed={2}
            pageCount={pagination?.total_pages}
            renderOnZeroPageCount={null}
            containerClassName="nav-links"
            activeLinkClassName="current"
            pageLinkClassName="page-numbers"
            previousLinkClassName="prev page-numbers"
            previousLabel={<i className="icon icon-arrow-left"></i>}
            nextLinkClassName="next page-numbers"
            nextLabel={<i className="icon icon-arrow-right"></i>}
          />
        </nav>
      )}
    </>
  );
};

export default Notifications;
