const routes = {
  Home: "/",
  Login: "/login",
  PrivacyPolicy: "/privacy-policy",
  TermsCondition: "/terms-condition",
  ForgotPassword: "/forgot-password",
  ResetPassword: "/reset-password/:id",
  // Register: "/register",
  Register: "/login",
  Dashboard: "/dashboard",
  Appraisals: "/appraisals",
  AppraisalCreate: "/appraisals/create",
  AppraisalView: "/appraisals/view/:id",
  Reports: "/reports",
  Employees: "/employees",
  EmployeesCreate: "/employees/create",
  EmployeesCreateWithBusinessId: "/employees/create/:id",
  EmployeesCreatepassword: "/create-password/:id",
  EmployeesView: "/employees/view/:id",
  Profile: "/profile",
  Notifications: "/notifications",
  PaymentSuccess: "/payment-success",
  Business: "/business",
  BusinessView: "/business/view/:id",
  BusinessCreate: "/business/create",
  BusinessCreatePassword: "/dealer-create-password/:id/:name",
  Inquiry: "/inquiry",
  Faqs: "/faqs",
  FaqsCreate: "/faqs/create",
  FaqsEdit: "/faqs/edit/:id",
  Roles: "/roles",
  RolesCreate: "/roles/create",
  RolesEdit: "/roles/edit/:id",
  Subscriptions: "/subscriptions",
  SubscriptionDashboard: "/subscription-dashboard",
  Payments: "/payments",
  PaymentsCreate: "/payments/create/:id",
  ScheduleDemo: "/schedule-demo",
  ScheduleDemoList: "/schedule-demo-list",
  Vehicles: "/vehicles",
  VehiclesCreate: "/vehicles/create",
  VehiclesEdit: "/vehicles/edit/:id",
  Contest: "/contest",
  ContestCreate: "/contest/create",
  ContestEdit: "/contest/edit/:id",
  NotificationsPermissions: "/notifications-permissions"
};
export default routes;
