import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import api from "../../api";
import routes from "../../constants/routesConstants";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import AuthContext from "../../context/authContext";
import { useForm } from "react-hook-form";
import Swal from "../Swal";
import _ from "lodash";
import { formatPhoneNumber } from "../../utils/formats";
import { CaretRightOutlined } from "@ant-design/icons";
import { Tree } from "antd";
import usePermission from "../../hook/usePermission";
import { EMPLOYEES_ADD } from "../../constants/permissionConstants";
import BusinessContext from "../../context/businessContext";

const validationSchema = yup.object().shape({
  name: yup.string().max(42).required("Name is required."),
  email: yup
    .string()
    .max(42)
    .email("Email is invalid.")
    .max(255)
    .required("Email is required."),
  mobile_no: yup
    .string()
    .required("Phone number is required.")
    .min(10, "Phone number is to short.")
    .max(12, "Phone number is to long."),
  role_id: yup.string().required("Role is required."),
});

const EmployeesCreate = () => {
  const [loading, setLoading] = useState(false);
  const [roles, setRoles] = useState([]);
  const authCtx = useContext(AuthContext);
  const businessCtx = useContext(BusinessContext);
  const navigate = useNavigate();
  const { id } = useParams();

  const [treeData, setModuleData] = useState([]);
  const [SelectedModule, setSelectedModule] = useState([]);
  const [checkedModule, checkModuleData] = useState([]);
  const [userType, setUserType] = useState(null);

  const isEmployeeAddAllow = usePermission(EMPLOYEES_ADD);
  useEffect(() => {
    if (!isEmployeeAddAllow) {
      navigate(routes.Dashboard);
    }
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (id !== undefined) {
      setValue("business_id", id);
    }
  }, [businessCtx.businessList]);

  const role_id = watch("role_id");
  const business_id = watch("business_id");
  let tempSelectedModule = [];
  let treeArray = [];

  useEffect(() => {
    let formData = {};
    if (
      (authCtx.userData?.user_type == 3 && business_id !== "") ||
      (authCtx.userData?.user_type == 3 && id !== undefined)
    ) {
      formData.business_id = business_id || id;
    }

    
    api
      .post(`api/role/get-active-roles`, formData, {
        headers: {
          Authorization: `Bearer ${authCtx.userData?.token}`,
        },
      })
      .then((response) => {
        if (response.data.status) {
          let rolesArray = [];
          if (response.data.data.length > 0) {
            response.data.data.map((element) => {
              rolesArray.push({
                value: element.id,
                label: element.role_name,
              });
            });
            setRoles(rolesArray);
          } else {
            setRoles([]);
          }
        } else {
          setRoles([]);
        }
      })
      .catch((error) => {
        if (error.response?.status == 401) {
          authCtx.logout();
        }
      });
  }, [business_id]);

  useEffect(() => {
    if (role_id != "" && role_id != undefined) {
      setLoading(true);
      api
        .get(`api/role/get-role/${role_id}`, {
          headers: { Authorization: `Bearer ${authCtx.userData?.token}` },
        })
        .then((response) => {
          if (response.data.status) {
            setUserType(response.data.data.user_type);
            tempSelectedModule = response.data.data.permission;

            if (
              response.data.data?.appraisal_bonus != "0" &&
              response.data.data?.appraisal_bonus != null
            ) {
              setValue("appraisal_bonus", response.data.data?.appraisal_bonus);
            }
            if (response.data.data?.vehicle_appraisal_bonus_type == "0") {
            } else if (response.data.data?.vehicle_appraisal_bonus_type == "1") {
              setValue(
                "vehicle_appraisal_bonus_percentage_value",
                response.data.data?.vehicle_appraisal_bonus_value
              );
            } else if (response.data.data?.vehicle_appraisal_bonus_type == "2") {
              setValue(
                "vehicle_appraisal_bonus_dollar_value",
                response.data.data?.vehicle_appraisal_bonus_value
              );
            }
            if (response?.data?.data?.per_accepted_appraisal_manager !== null) {
              setValue(
                "per_accepted_appraisal_manager",
                response.data.data?.per_accepted_appraisal_manager
              );
            }

            api
              .post(
                `api/modules`,
                {},
                {
                  headers: {
                    Authorization: `Bearer ${authCtx.userData.token}`,
                  },
                }
              )
              .then((response) => {
                setLoading(false);
                setModuleData(response.data.data);
                treeArray = response.data.data;
                setSelectedPermission();
              })
              .catch((error) => {
                if (error.response?.status == 401) {
                  authCtx.logout();
                }
              });
          }
        })
        .catch((error) => {
          if (error.response?.status == 401) {
            authCtx.logout();
          }
        });
    }
  }, [role_id]);

  function setSelectedPermission() {
    const dataArray = [];
    const arrayTemp = [];
    tempSelectedModule.forEach((item) => {
      _.find(treeArray, (temp) => {
        if (temp.id == item.module_id) {
          _.find(temp.children, (tempClone) => {
            if (item.permission_id == tempClone.id) {
              dataArray.push(`${item.module_id}-${item.permission_id}`);
              arrayTemp.push(tempClone.key);
            }
          });
        }
      });
    });
    setSelectedModule(arrayTemp);
    checkModuleData(dataArray);
  }

  const onCheck = (checkedKeys, info) => {
    setSelectedModule(checkedKeys);
    const dataArray = [];
    const checkNodeAarray = info.checkedNodes;
    checkNodeAarray.forEach((item) => {
      if (item.module_id) {
        dataArray.push(`${item.module_id}-${item.id}`);
      }
    });
    checkModuleData(dataArray);
  };

  const mobile_no = watch("mobile_no");
  useEffect(() => {
    setValue("mobile_no", formatPhoneNumber(mobile_no).formatted);
  }, [mobile_no]);

  const onSubmit = async (formValue) => {
    setLoading(true);
    let formData = new FormData();
    formData.append("name", formValue.name);
    formData.append("email", formValue.email);
    formData.append("mobile_no", formatPhoneNumber(formValue.mobile_no).base);
    formData.append("role_id", formValue.role_id);
    checkedModule.forEach((item) => {
      formData.append("permission[]", item);
    });
    if (authCtx.userData?.user_type === 3) {
      formData.append("business_id", formValue.business_id);
    }

    if (formValue.appraisal_bonus !== undefined && formValue.appraisal_bonus !== "0" && userType === 2) {
      formData.append("appraisal_bonus", formValue.appraisal_bonus);
    } else {
      formData.append("appraisal_bonus", "0");
    }

    if (formValue.vehicle_appraisal_bonus_dollar_value !== undefined &&formValue.vehicle_appraisal_bonus_dollar_value !== "" && userType === 2) {
      formData.append("vehicle_appraisal_bonus_type", "2");
      formData.append(
        "vehicle_appraisal_bonus_value",
        formValue.vehicle_appraisal_bonus_dollar_value
      );
    } else {
      formData.append("vehicle_appraisal_bonus_value", 0);
      formData.append("vehicle_appraisal_bonus_type", "0");
    }


    if (formValue.per_accepted_appraisal_manager !== undefined && formValue.per_accepted_appraisal_manager !== "" && userType === 1) {
      formData.append(
        "per_accepted_appraisal_manager",
        formValue.per_accepted_appraisal_manager
      );
    }


    await api
      .post("api/users/add-user", formData, {
        headers: {
          Authorization: `Bearer ${authCtx.userData?.token}`,
        },
      })
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          Swal.fire(response.data.message, "", "success");
          reset();
          navigate(`/employees/view/${response.data.data?.id}`);
        } else {
          Swal.fire("Try Again!", response.data.message, "info");
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          authCtx.logout();
        } else {
          Swal.fire(
            "Opps!",
            "Sorry we are not able to complete your request at this time. Please try again later.",
            "error"
          );
        }
      });
  };

  return (
    <>
      <div className="appraisal-head-wrap mb-34">
        <div className="row">
          <div className="col">
            <div className="appraisal-head-title align-items-start">
              <button
                type="button"
                className="btn-icon"
                onClick={() => navigate(-1)}>
                <i className="icon icon-back-aero"></i>
              </button>
              <div className="page-head">
                <h2 className="page-title">Add New User</h2>
                <p className="page-dec">Fill the required informations</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="login-inner add-new-user-inner">
        <div className="login-head">
          <h2 className="login-title">Invite your employee to join you here</h2>
          <p className="login-text">Enter basic details to invite employee</p>
        </div>
        <div className="login-form">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="login-form-inner">
              <div className="row">
                {authCtx.userData?.user_type == 3 && (
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label className="form-label">
                        Business <span>*</span>
                      </label>
                      <div className="position-relative">
                        <select
                          className="form-control form-select"
                          {...register("business_id")}>
                          <option value="">Select Business</option>
                          {_.map(businessCtx.businessList, (option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                )}
                <div className="col-lg-12">
                  <div className="form-group">
                    <label className="form-label">
                      Full Name <span>*</span>
                    </label>
                    <div className="position-relative">
                      <input
                        type="text"
                        className="form-control"
                        {...register("name")}
                        maxLength={42}
                      />
                      {errors.name?.message != undefined && (
                        <span className="form-error">
                          {errors.name?.message}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group">
                    <label className="form-label">
                      Role <span>*</span>
                    </label>
                    <div className="position-relative">
                      <select
                        className="form-control form-select"
                        {...register("role_id")}>
                        <option value="">Select Role</option>
                        {_.map(roles, (option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                      {errors.role_id?.message != undefined && (
                        <span className="form-error">
                          {errors.role_id?.message}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                {role_id != "" && !loading && (
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label className="form-label">
                        Permissions <span>*</span>
                      </label>
                      <Tree
                        showLine={{ showLeafIcon: false }}
                        switcherIcon={<CaretRightOutlined />}
                        checkable
                        onCheck={onCheck}
                        treeData={treeData}
                        checkedKeys={SelectedModule}
                      />
                    </div>
                  </div>
                )}
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="form-label">
                      Phone number <span>*</span>
                    </label>
                    <div className="position-relative">
                      <input
                        className="form-control phone"
                        {...register("mobile_no")}
                        maxLength={12}
                      />
                      {errors.mobile_no?.message != undefined && (
                        <span className="form-error">
                          {errors.mobile_no?.message}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group">
                    <label className="form-label">
                      Email <span>*</span>
                    </label>
                    <div className="position-relative">
                      <input
                        className="form-control email"
                        {...register("email")}
                        maxLength={42}
                      />
                      {errors.email?.message != undefined && (
                        <span className="form-error">
                          {errors.email?.message}
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                {userType !== null && userType !== 0 &&
                  userType !== 4 && (
                    <div className="notification settings-notification shadow-none p-0">
                      <div className="col-lg-12 settings-notificationbox mb-24">
                        <h5 className="notification-Title ffr mb-2">Spiffs</h5>
                        <div className="dashboard-notification table-responsive">
                          <table className="table" align="center">
                            <tbody>
                              {userType === 1 ? (
                                <tr>
                                  <td>
                                    <div className="input-group">
                                      <div className="form-group mb-0">
                                        <span className="input-group-text">$</span>{" "}
                                        <input className="form-control pl-0 rounded-0" type="text" {...register("per_accepted_appraisal_manager")}
                                          maxLength={7} />
                                      </div>
                                      <div className="label-text w-50">
                                        Per Accepted Appraisal Spiff
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              ) : (
                                <>
                                  <tr>
                                    <td>
                                      <div className="input-group">
                                        <div className="form-group mb-0">
                                          <span className="input-group-text">$</span>{" "}
                                          <input className="form-control pl-0 rounded-0" type="text" {...register("appraisal_bonus")} maxLength={7} />
                                        </div>
                                        <div className="label-text w-50">
                                          Per Appraisal Spiff
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <div className="input-group">
                                        <div className="form-group mb-0">
                                          <span className="input-group-text">$</span>{" "}
                                          <input className="form-control pl-0 rounded-0" type="text" {...register("vehicle_appraisal_bonus_dollar_value"
                                          )} maxLength={7} />
                                        </div>
                                        <div className="label-text w-50">
                                          Per Accepted Appraisal Spiff
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                </>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  )}


                <div className="col-lg-12">
                  <div className="notice">
                    <p>
                      Your employee will receive an email with a link to join
                      this workspace.
                    </p>
                  </div>
                </div>

                <div className="col-lg-12">
                  <button
                    type="submit"
                    className="btn btn-pink"
                    disabled={loading}>
                    <i className="icon icon-add-user"></i>
                    Add User
                  </button>
                </div>
                <div className="col-lg-12">
                  <p className="login-form-login-link">
                    <Link to={routes.Employees} className="cancel">
                      Cancel
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default EmployeesCreate;
