import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../api";
import routes from "../../constants/routesConstants";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import AuthContext from "../../context/authContext";
import { useForm } from "react-hook-form";
import Swal from "../Swal";
import _ from "lodash";
import BusinessContext from "../../context/businessContext";

const years = _.map(
  new Array(new Date().getFullYear() + 1 - 1990),
  (_value, idx) => ({
    label: new Date().getFullYear() + 1 - idx,
    value: new Date().getFullYear() + 1 - idx,
  })
);

const VehiclesEdit = () => {
  const authCtx = useContext(AuthContext);
  const businessCtx = useContext(BusinessContext);
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [fromYear, setFromYear] = useState("");
  const [toYear, setToYear] = useState("");

  useEffect(() => {
    if (
      authCtx.userData?.user_type === 2 &&
      authCtx.userData?.user_type === 4
    ) {
      navigate(routes.Dashboard);
    }
  }, []);

  const validationSchema = yup.object().shape({
    year_from: yup
      .string()
      .required("From Year is required.")
      .test(
        "is-valid-from-year",
        "From Year must be smaller than To Year.",
        (value) => value < toYear
      ),
    year_to: yup
      .string()
      .required("To Year is required.")
      .test(
        "is-valid-to-year",
        "To Year must be greater than From Year.",
        (value) => value > fromYear
      ),
    make: yup.string().max(42).required("Make is required."),
    model: yup.string().max(42).required("Model is required."),
    trim: yup.string().max(42).required("Trim is required."),
    per_appraisal_spiff: yup
      .string()
      .max(5)
      .required("Per Appraisal Spiff is required."),
    per_accepted_appraisal_spiff: yup
      .string()
      .max(5)
      .required("Accepted Appraisal Spiff is required."),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    getFaqDetails();
  }, []);

  const getFaqDetails = async () => {
    await api
      .post(
        "api/need-vehicle/get",
        { need_vehicle_id: id },
        {
          headers: {
            Authorization: `Bearer ${authCtx.userData?.token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.status) {
          setFromYear(response.data.data?.year_from);
          setToYear(response.data.data?.year_to);
          setValue("year_from", response.data.data?.year_from);
          setValue("year_to", response.data.data?.year_to);
          setValue("make", response.data.data?.make);
          setValue("model", response.data.data?.model);
          setValue("trim", response.data.data?.trim);
          setValue(
            "per_appraisal_spiff",
            response.data.data?.per_appraisal_spiff
          );
          setValue(
            "per_accepted_appraisal_spiff",
            response.data.data?.per_accepted_appraisal_spiff
          );
          if (authCtx.userData?.user_type === 3) {
            setValue("business_id", response.data.data?.business_id);
          }
        }
      })
      .catch((error) => {
        if (error.response.status == 401) {
          authCtx.logout();
        } else {
          Swal.fire(
            "Opps!",
            "Sorry we are not able to complete your request at this time. Please try again later.",
            "error"
          );
        }
      });
  };

  const onSubmit = async (formValue) => {
    setLoading(true);
    formValue.need_vehicle_id = id;
    await api
      .post("api/need-vehicle/update", formValue, {
        headers: {
          Authorization: `Bearer ${authCtx.userData?.token}`,
        },
      })
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          Swal.fire(response.data.message, "", "success");
          reset();
          navigate(routes.Vehicles);
        } else {
          Swal.fire("Try Again!", response.data.message, "info");
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          authCtx.logout();
        } else {
          Swal.fire(
            "Opps!",
            "Sorry we are not able to complete your request at this time. Please try again later.",
            "error"
          );
        }
      });
  };

  return (
    <>
      <div className="appraisal-head-wrap mb-34">
        <div className="row">
          <div className="col">
            <div className="appraisal-head-title align-items-start">
              <button
                type="button"
                className="btn-icon"
                onClick={() => navigate(-1)}>
                <i className="icon icon-back-aero"></i>
              </button>
              <div className="page-head">
                <h2 className="page-title">Edit #{id} Vehicle</h2>
                <p className="page-dec">Fill the required informations</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="login-inner add-new-user-inner">
        <div className="login-head">
          <h2 className="login-title">Update Vehicle</h2>
          <p className="login-text">
            Please make sure to fill in all the required fields.
          </p>
        </div>
        <div className="login-form">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="login-form-inner">
              <div className="row">
                {authCtx.userData?.user_type == 3 && (
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label className="form-label">
                        Business <span>*</span>
                      </label>
                      <div className="position-relative">
                        <select
                          className="form-control form-select"
                          {...register("business_id")}>
                          <option value="">Select Business</option>
                          {_.map(businessCtx.businessList, (option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                )}
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="form-label">
                      From Year <span>*</span>
                    </label>
                    <div className="position-relative">
                      <select
                        className="form-control form-select"
                        {...register("year_from")}
                        onChange={(e) => setFromYear(e.target.value)}>
                        <option value="">Select Year</option>
                        {_.map(years, (option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                      {errors.year_from?.message != undefined && (
                        <span className="form-error">
                          {errors.year_from?.message}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="form-label">
                      To Year <span>*</span>
                    </label>
                    <div className="position-relative">
                      <select
                        className="form-control form-select"
                        {...register("year_to")}
                        onChange={(e) => setToYear(e.target.value)}>
                        <option value="">Select Year</option>
                        {_.map(years, (option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                      {errors.year_to?.message != undefined && (
                        <span className="form-error">
                          {errors.year_to?.message}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="form-label">
                      Make <span>*</span>
                    </label>
                    <div className="position-relative">
                      <input
                        className="form-control"
                        {...register("make")}
                        maxLength={42}
                      />
                      {errors.make?.message != undefined && (
                        <span className="form-error">
                          {errors.make?.message}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="form-label">
                      Model <span>*</span>
                    </label>
                    <div className="position-relative">
                      <input
                        className="form-control"
                        {...register("model")}
                        maxLength={42}
                      />
                      {errors.model?.message != undefined && (
                        <span className="form-error">
                          {errors.model?.message}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="form-label">
                      Trim <span>*</span>
                    </label>
                    <div className="position-relative">
                      <input
                        className="form-control"
                        {...register("trim")}
                        maxLength={42}
                      />
                      {errors.trim?.message != undefined && (
                        <span className="form-error">
                          {errors.trim?.message}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="form-label">
                      Per Appraisal Spiff <span>*</span>
                    </label>
                    <div className="position-relative">
                      <input
                        className="form-control"
                        {...register("per_appraisal_spiff")}
                        maxLength={5}
                      />
                      {errors.per_appraisal_spiff?.message != undefined && (
                        <span className="form-error">
                          {errors.per_appraisal_spiff?.message}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="form-label">
                      Per Accepted Appraisal Spiff <span>*</span>
                    </label>
                    <div className="position-relative">
                      <input
                        className="form-control"
                        {...register("per_accepted_appraisal_spiff")}
                        maxLength={5}
                      />
                      {errors.per_accepted_appraisal_spiff?.message !=
                        undefined && (
                        <span className="form-error">
                          {errors.per_accepted_appraisal_spiff?.message}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <button
                    type="submit"
                    className="btn btn-pink"
                    disabled={loading}>
                    <i className="icon icon-total-appraisal"></i>
                    Update Vehicle
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default VehiclesEdit;
