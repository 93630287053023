import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import api from "../../api";
import routes from "../../constants/routesConstants";
import AuthContext from "../../context/authContext";
import Swal from "../Swal";
import LatestAppraisals from "./LatestAppraisals";
import TopEmployees from "./TopEmployees";
import DateRangePicker from "@wojtekmaj/react-daterange-picker/dist/DateRangePicker";
import moment from "moment";
import _ from "lodash";
import { formatCurrency } from "../../utils/formats";
import BusinessContext from "../../context/businessContext";
import usePermission from "../../hook/usePermission";
import {
  APPRAISALS_ALL_LIST,
  APPRAISALS_OWN_OR_ASSIGNED_LIST,
  DASHBOARD_VIEW,
} from "../../constants/permissionConstants";
import DealerDetails from "./DealerDetails";
import trophyImg from "../../assets/img/trophy.svg";
import ContestInfo from "./ContestInfo";

const Dashboard = ({ businessId }) => {
  const [responseData, setResponseData] = useState({});
  const [filterType, setFilterType] = useState(4);
  const [selectedDate, setSelectedDate] = useState(null);
  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();
  const businessCtx = useContext(BusinessContext);
  const isDashboardAllow = usePermission(DASHBOARD_VIEW);
  const isAppraisalAllow = usePermission(APPRAISALS_ALL_LIST);
  const isAppraisalOwnAllow = usePermission(APPRAISALS_OWN_OR_ASSIGNED_LIST);

  useEffect(() => {
    if (!isDashboardAllow && (isAppraisalAllow || isAppraisalOwnAllow)) {
      navigate(routes.Appraisals);
    }
  }, [isDashboardAllow]);

  useEffect(() => {
    if (filterType != 5) {
      getDashboardData({ type: filterType });
    } else if (filterType == 5) {
      getDashboardData({ type: 4 });
    }
  }, [filterType, businessCtx.selectedBusinessId]);

  const getDashboardData = async (formData = {}) => {
    if (
      authCtx.userData?.user_type == 3 &&
      businessCtx.selectedBusinessId != ""
    ) {
      formData.business_id = businessCtx.selectedBusinessId;
    }
    if (businessId !== undefined) {
      formData.business_id = businessId;
    }
    await api
      .post("api/dashboard", formData, {
        headers: {
          Authorization: `Bearer ${authCtx.userData?.token}`,
        },
      })
      .then((res) => {
        setResponseData(res.data.data);
      })
      .catch((error) => {
        if (error.response.status == 401) {
          authCtx.logout();
        } else {
          Swal.fire(
            "Opps!",
            "Sorry we are not able to complete your request at this time. Please try again later.",
            "error"
          );
        }
      });
  };

  const onRangeChange = (value) => {
    setSelectedDate(value);
    if (value != null) {
      getDashboardData({
        type: filterType,
        start_date: moment(value[0]).format("YYYY-MM-DD"),
        end_date: moment(value[1]).format("YYYY-MM-DD"),
      });
    }
  };

  const filterOnChange = (value) => {
    setFilterType(value);
  };

  const lastMonthDate = moment().subtract(1, "months");
  return (
    <>
      <div className="dashboard-count-filter mb-24">
        <div className="row align-items-center">
          <div className="col">
            <h2 className="page-title">
              {businessId !== undefined
                ? responseData?.dealerDetails?.business_name
                : "Dashboard"}
            </h2>
            {authCtx.userData?.user_type == 3 && businessId !== undefined && (
              <span className="page-subtitle">
                Joined Date: {responseData?.dealerDetails?.joined_date}
              </span>
            )}
          </div>
          {filterType == 5 && (
            <div className="col-auto pe-0">
              <DateRangePicker
                onChange={(e) => onRangeChange(e)}
                value={selectedDate}
                format="M-d-y"
                yearPlaceholder="yyyy"
                monthPlaceholder="mm"
                dayPlaceholder="dd"
                calendarIcon={<i className="icon icon-calender"></i>}
                maxDate={new Date()}
                clearIcon={null}
              />
            </div>
          )}
          <div className="col-auto">
            <div className="form-group">
              <select
                className="form-select"
                onChange={(e) => filterOnChange(e.target.value)}
                defaultValue={filterType}>
                <option value="1">This day</option>
                <option value="2">This week</option>
                <option value="3">This month</option>
                <option value="4">This year</option>
                <option value="5">Range</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className="dashboard-count-wrap">
        <div className="row row-cols-2 row-cols-md-3 row-cols-xl-4 row-cols-xxl-5">
          <div className="col">
            <Link
              to={`/appraisals?type=${filterType}${businessId !== undefined ? `&business_id=${businessId}` : ``
                }`}>
              <div className="dashboard-card mb-24 is-total-appraisals">
                <div className="dashboard-card-row">
                  <div className="dashboard-card-icon">
                    <i className="icon icon-totalappraisals"></i>
                  </div>
                  <div className="dashboard-card-text">
                    <h5 className="dashboard-card-count">
                      {responseData?.dashboard?.totalAppraisals != undefined
                        ? responseData?.dashboard?.totalAppraisals
                        : 0}
                    </h5>
                    <span className="dashboard-card-label">
                      Total Appraisals
                    </span>
                    {/* <div className="progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: "75%" }}
                        aria-valuenow="75"
                        aria-valuemin="0"
                        aria-valuemax="10"></div>
                    </div> */}
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div className="col">
            {/* <Link to={`/appraisals?type=1`}> */}
            <Link to={`/appraisals?status=0&type=${filterType}`}>
              <div className="dashboard-card mb-24 is-today-appraisals">
                <div className="dashboard-card-row">
                  <div className="dashboard-card-icon">
                    <i className="icon icon-todayappraisals"></i>
                  </div>
                  <div className="dashboard-card-text">
                    <h5 className="dashboard-card-count">
                      {responseData?.dashboard?.new != undefined
                        ? responseData?.dashboard?.new
                        : 0}
                    </h5>
                    <span className="dashboard-card-label">New Appraisals</span>
                    {/* <div className="progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: "75%" }}
                        aria-valuenow="75"
                        aria-valuemin="0"
                        aria-valuemax="10"></div>
                    </div> */}
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div className="col">
            <Link to={`/appraisals?status=1&type=${filterType}`}>
              <div className="dashboard-card mb-24 is-working">
                <div className="dashboard-card-row">
                  <div className="dashboard-card-icon">
                    <i className="icon icon-working"></i>
                  </div>
                  <div className="dashboard-card-text">
                    <h5 className="dashboard-card-count">
                      {responseData?.dashboard?.working != undefined
                        ? responseData?.dashboard?.working
                        : 0}
                    </h5>
                    <span className="dashboard-card-label">Appraised</span>
                    {/* <div className="progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: "40%" }}
                        aria-valuenow="50"
                        aria-valuemin="0"
                        aria-valuemax="10"></div>
                    </div> */}
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div className="col">
            <Link to={`/appraisals?status=4&type=${filterType}`}>
              <div className="dashboard-card mb-24 is-appraised">
                <div className="dashboard-card-row">
                  <div className="dashboard-card-icon">
                    <i className="icon icon-appraised"></i>
                  </div>
                  <div className="dashboard-card-text">
                    <h5 className="dashboard-card-count">
                      {responseData?.dashboard?.appraised != undefined
                        ? responseData?.dashboard?.appraised
                        : 0}
                    </h5>
                    <span className="dashboard-card-label">Appraisal Assigned</span>
                    {/* <div className="progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: "50%" }}
                        aria-valuenow="50"
                        aria-valuemin="0"
                        aria-valuemax="10"></div>
                    </div> */}
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div className="col">
            <Link to={`/appraisals?status=2&type=${filterType}`}>
              <div className="dashboard-card mb-24 is-offermade">
                <div className="dashboard-card-row">
                  <div className="dashboard-card-icon">
                    <i className="icon icon-offermade"></i>
                  </div>
                  <div className="dashboard-card-text">
                    <h5 className="dashboard-card-count">
                      {responseData?.dashboard?.offerMade != undefined
                        ? responseData?.dashboard?.offerMade
                        : 0}
                    </h5>
                    <span className="dashboard-card-label">Offer Made</span>
                    {/* <div className="progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: "20%" }}
                        aria-valuenow="50"
                        aria-valuemin="0"
                        aria-valuemax="10"></div>
                    </div> */}
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div className="col">
            <Link to={`/appraisals?status=6&type=${filterType}`}>
              <div className="dashboard-card mb-24 is-counteroffer">
                <div className="dashboard-card-row">
                  <div className="dashboard-card-icon">
                    <i className="icon icon-counterofferrequsetchat"></i>
                  </div>
                  <div className="dashboard-card-text">
                    <h5 className="dashboard-card-count">
                      {responseData?.dashboard?.counterOfferRequest != undefined
                        ? responseData?.dashboard?.counterOfferRequest
                        : 0}
                    </h5>
                    <span className="dashboard-card-label">
                      Counter Offer
                    </span>
                    {/* <div className="progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: "20%" }}
                        aria-valuenow="50"
                        aria-valuemin="0"
                        aria-valuemax="10"></div>
                    </div> */}
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div className="col">
            <Link to={`/appraisals?status=7&type=${filterType}`}>
              <div className="dashboard-card mb-24 is-offermade">
                <div className="dashboard-card-row">
                  <div className="dashboard-card-icon">
                    <i className="icon icon-final-offer"></i>
                  </div>
                  <div className="dashboard-card-text">
                    <h5 className="dashboard-card-count">
                      {responseData?.dashboard?.finalOffer != undefined
                        ? responseData?.dashboard?.finalOffer
                        : 0}
                    </h5>
                    <span className="dashboard-card-label">Final Offer</span>
                    {/* <div className="progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: "20%" }}
                        aria-valuenow="50"
                        aria-valuemin="0"
                        aria-valuemax="10"></div>
                    </div> */}
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div className="col">
            <Link to={`/appraisals?status=3&type=${filterType}`}>
              <div className="dashboard-card mb-24 is-accepted-purchase">
                <div className="dashboard-card-row">
                  <div className="dashboard-card-icon">
                    <i className="icon icon-accepteddeal"></i>
                  </div>
                  <div className="dashboard-card-text">
                    <h5 className="dashboard-card-count">
                      {responseData?.dashboard?.acceptedOffer != undefined
                        ? responseData?.dashboard?.acceptedOffer
                        : 0}
                    </h5>
                    <span className="dashboard-card-label">Accepted Offer</span>
                    {/* <div className="progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: "20%" }}
                        aria-valuenow="50"
                        aria-valuemin="0"
                        aria-valuemax="10"></div>
                    </div> */}
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div className="col">
            <Link to={`/appraisals?status=5&type=${filterType}`}>
              <div className="dashboard-card mb-24 is-no-deal">
                <div className="dashboard-card-row">
                  <div className="dashboard-card-icon">
                    <i className="icon icon-no-deals"></i>
                  </div>
                  <div className="dashboard-card-text">
                    <h5 className="dashboard-card-count">
                      {responseData?.dashboard?.noDeal != undefined
                        ? responseData?.dashboard?.noDeal
                        : 0}
                    </h5>
                    <span className="dashboard-card-label">No Deal</span>
                    {/* <div className="progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: "50%" }}
                        aria-valuenow="50"
                        aria-valuemin="0"
                        aria-valuemax="10"></div>
                    </div> */}
                  </div>
                </div>
              </div>
            </Link>
          </div>
          {(authCtx?.userData?.user_type === 1 ||
            authCtx?.userData?.user_type === 2) && (
              <div className="col">
                <div className="dashboard-card mb-24 is-earnings">
                  <div className="dashboard-card-row">
                    <div className="dashboard-card-icon">
                      <i className="icon icon-earnings"></i>
                    </div>
                    <div className="dashboard-card-text">
                      <h5 className="dashboard-card-count">
                        {responseData?.dashboard?.earning != undefined
                          ? formatCurrency(responseData?.dashboard?.earning)
                          : 0}
                      </h5>
                      <span className="dashboard-card-label">Earnings</span>
                      {/* <div className="progress">
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{ width: "60%" }}
                          aria-valuenow="50"
                          aria-valuemin="0"
                          aria-valuemax="10"></div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            )}
          {/* {responseData?.dashboard?.oneTop !== undefined && (
            <div className="col">
              <Link
                to={`/appraisals/view/${responseData?.dashboard?.oneTop?.lead_id}`}>
                <div className="dashboard-card mb-24 is-most-accepted">
                  <div className="dashboard-card-row">
                    <div className="dashboard-card-icon">
                      <i className="icon icon-mostaccepted"></i>
                    </div>
                    <div className="dashboard-card-text">
                      <h5 className="dashboard-card-count">
                        {responseData?.dashboard?.oneTop?.totalAmount !=
                          undefined
                          ? formatCurrency(
                            responseData?.dashboard?.oneTop?.totalAmount
                          )
                          : 0}
                      </h5>
                      <span className="dashboard-card-label">
                        Most Accept Appraisals
                      </span>
                      <div className="progress">
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{ width: "89%" }}
                          aria-valuenow="50"
                          aria-valuemin="0"
                          aria-valuemax="10"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          )} */}
        </div>
      </div>
      <div className="dashboard-featured mb-24">
        <div className="row">
          {authCtx.userData?.user_type !== 2 &&
            authCtx.userData?.user_type !== 4 && (
              <div
                className={`${businessId !== undefined && responseData?.contestData !== null
                    ? "col-xl-4"
                    : authCtx.userData?.user_type === 3 ||
                      responseData?.contestData === null
                      ? "col-xl-8"
                      : "col-xl-4"
                  }`}>
                {businessId === undefined ? (
                  <TopEmployees
                    filterType={filterType}
                    selectedDate={selectedDate}
                  />
                ) : (
                  <>
                    {authCtx.userData?.user_type === 3 && (
                      <DealerDetails
                        address={responseData?.dealerDetails?.business_address}
                        email={responseData?.dealerDetails?.email}
                        name={responseData?.dealerDetails?.business_name}
                        totalEmployees={
                          responseData?.dealerDetails?.totalEmployees
                        }
                        totalEarnings={
                          responseData?.dealerDetails?.totalEarnings
                        }
                      />
                    )}
                  </>
                )}
              </div>
            )}
          {authCtx.userData?.user_type !== 4 && authCtx.userData?.user_type !== 2 && (
            <>
              <div className="col-xl-4 col-sm-6">
                <div className="dashboard-card mb-3 mb-sm-0">
                  <div className="highest-appraisal-count">
                    {responseData?.highestAppraisal?.leadCount !== undefined ? (
                      <>
                        <div className="highest-appraisal-count-header d-flex align-items-center">
                          <img src={trophyImg} alt="" />
                          <h6>
                            Most <b>Appraisals Added</b> last month by{" "}
                            {responseData?.highestAppraisal?.name}
                          </h6>
                        </div>
                        <div className="d-flex flex-wrap align-items-center">
                          <div className="flex-fill">
                            <h4>
                              {responseData?.highestAppraisal?.leadCount}{" "}
                              Appraisals
                            </h4>
                          </div>
                          <Link
                            to={`/appraisals?uid=${responseData?.highestAppraisal?.id
                              }&type=5&start_date=${lastMonthDate
                                .startOf("month")
                                .format("YYYY-MM-DD")}&end_date=${lastMonthDate
                                  .endOf("month")
                                  .format("YYYY-MM-DD")}`}
                            className="btn">
                            View Appraisals
                          </Link>
                        </div>
                      </>
                    ) : (
                      <div className="highest-appraisal-count-header d-flex align-items-center">
                        <img src={trophyImg} alt="" />
                        <h6>No appraisals created last month!</h6>
                      </div>
                    )}
                  </div>
                  <div className="highest-appraisal-count biggest-deal-month">
                    {responseData?.acceptedOffer?.name !== undefined ? (
                      <>
                        <div className="highest-appraisal-count-header d-flex align-items-center">
                          <img src={trophyImg} alt="" />
                          <h6>
                            Most <b>Appraisals Accepted</b> last month by{" "}
                            {responseData?.acceptedOffer?.name}
                          </h6>
                        </div>
                        <div className="d-flex flex-wrap align-items-center">
                          <div className="flex-fill">
                            <h4>
                              {responseData?.acceptedOffer?.acceptedOfferCount}{" "}
                              Appraisals
                            </h4>
                          </div>
                          <Link
                            to={`/appraisals?uid=${responseData?.acceptedOffer?.id
                              }&status=3&type=5&start_date=${lastMonthDate
                                .startOf("month")
                                .format("YYYY-MM-DD")}&end_date=${lastMonthDate
                                  .endOf("month")
                                  .format("YYYY-MM-DD")}`}
                            className="btn">
                            View Appraisal
                          </Link>
                        </div>
                      </>
                    ) : (
                      <div className="highest-appraisal-count-header d-flex align-items-center">
                        <img src={trophyImg} alt="" />
                        <h6>No deal made last month!</h6>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {/* <div className="col-xl-3 col-sm-6">
                <div className="dashboard-card">
                  <div className="row align-items-center">
                    <div className="col">
                      <h4 className="card-title">Wish List</h4>
                    </div>
                  </div>
                  <div className="mt-3">
                    {responseData?.needVehicle &&
                    responseData?.needVehicle?.length !== 0 ? (
                      <>
                        <ul className="dashboard-need-vehicle-list mb-3">
                          {responseData?.needVehicle?.length &&
                            responseData?.needVehicle?.map((data, key) => (
                              <li key={key}>
                                {data?.year_from}-{data?.year_to} {data?.make}{" "}
                                {data?.model} {data?.trim}{" "}
                                {formatCurrency(data?.per_appraisal_spiff)}{" "}
                                {formatCurrency(
                                  data?.per_accepted_appraisal_spiff
                                )}
                              </li>
                            ))}
                        </ul>
                        <Link
                          to={`/vehicles`}
                          className="btn btn-parrot btn-sm">
                          View All Vehicles
                        </Link>
                      </>
                    ) : (
                      <p>No Wish List Found.</p>
                    )}
                  </div>
                </div>
              </div> */}
              {(businessId !== undefined ||
                authCtx.userData?.user_type !== 3) &&
                responseData?.contestData !== null && (
                  <div className="col-xl-2 col-sm-6">
                    <ContestInfo data={responseData?.contestData} />
                  </div>
                )}
            </>
          )}
        </div>
      </div>
      <LatestAppraisals
        filterType={filterType}
        selectedDate={selectedDate}
        businessId={businessId}
      />
    </>
  );
};

export default Dashboard;
