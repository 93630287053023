import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../api";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import AuthContext from "../../context/authContext";
import { useForm } from "react-hook-form";
import Swal from "../Swal";
import _ from "lodash";
import DatePicker from "react-date-picker";
import routes from "../../constants/routesConstants";
import { formatPrice } from "../../utils/formats";
import moment from "moment";

const validationSchema = yup.object().shape({
  paid_amount: yup.string().required("Payment Amount is required."),
});

const PaymentsCreate = () => {
  const [loading, setLoading] = useState(false);
  const [responseData, setResponseData] = useState({});
  const [invoiceDate, setInvoiceDate] = useState(new Date());
  const [isReceivedFullAmount, setIsReceivedFullAmount] = useState(true);
  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();
  const { id, businessId } = useParams();

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const cheque_number = watch("cheque_number");
  useEffect(() => {
    setValue("cheque_number", onlyAlphaNum(cheque_number));
  }, [cheque_number]);

  function onlyAlphaNum(text = "") {
    return text.replace(/[^a-z0-9]/gi, "");
  }

  useEffect(() => {
    getSubscriptionDetails();
  }, []);

  const getSubscriptionDetails = async () => {
    await api
      .post(
        `api/invoice/${id}`,
        { business_id: businessId },
        {
          headers: {
            Authorization: `Bearer ${authCtx.userData?.token}`,
          },
        }
      )
      .then((response) => {
        setResponseData(response.data.data);
        if (response.data.data?.invoice !== undefined) {
          setValue("paid_amount", response.data.data?.invoice[0]?.amount);
        }
        if (response.data.data?.add_payment !== undefined) {
          setValue("paid_amount", response.data.data?.add_payment?.paid_amount);
          setValue("notes", response.data.data?.add_payment?.notes);
          setValue(
            "cheque_number",
            response.data.data?.add_payment?.cheque_number
          );
          setInvoiceDate(
            new Date(response.data.data?.add_payment?.payment_date)
          );
        }
      })
      .catch((error) => {
        if (error.response.status == 401) {
          authCtx.logout();
        } else {
          Swal.fire(
            "Opps!",
            "Sorry we are not able to complete your request at this time. Please try again later.",
            "error"
          );
        }
      });
  };

  const onSubmit = async (formValue) => {
    setLoading(true);

    let formData = {
      ...formValue,
      payment_date: moment(invoiceDate).format("YYYY-MM-DD"),
      paid_amount: formatPrice(formValue.paid_amount).base,
    };

    if (responseData?.invoice !== undefined) {
      formData = {
        ...formData,
        business_id: responseData?.invoice[0]?.business_id,
        invoice_id: responseData?.invoice[0]?.id,
      };
    }

    await api
      .post("api/add-payment", formData, {
        headers: {
          Authorization: `Bearer ${authCtx.userData?.token}`,
        },
      })
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          Swal.fire(response.data.message, "", "success");
          reset();
          navigate(routes.Payments);
        } else {
          Swal.fire("Try Again!", response.data.message, "info");
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          authCtx.logout();
        } else {
          Swal.fire(
            "Opps!",
            "Sorry we are not able to complete your request at this time. Please try again later.",
            "error"
          );
        }
      });
  };

  const handleReceivedFullAmount = () => {
    setIsReceivedFullAmount(!isReceivedFullAmount);
    if (!isReceivedFullAmount) {
      setValue("paid_amount", responseData?.plan?.price);
    }
  };

  return (
    <>
      <div className="appraisal-head-wrap mb-34">
        <div className="row">
          <div className="col">
            <div className="appraisal-head-title align-items-start">
              <button
                type="button"
                className="btn-icon"
                onClick={() => navigate(-1)}>
                <i className="icon icon-back-aero"></i>
              </button>
              <div className="page-head">
                <h2 className="page-title">
                  {responseData?.invoice !== undefined &&
                    responseData?.invoice[0]?.payment_status === "Paid"
                    ? "Edit"
                    : "Add"}{" "}
                  Payment
                </h2>
                <p className="page-dec">Fill the required informations</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6">
          <div className="login-inner add-new-appraisal-inner">
            <div className="add-new-appraisal-card">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="appraisal-form-detail customer-details">
                  <div className="appraisal-form-head">
                    <div className="row">
                      <div className="col-sm col-12">
                        <h5 className="card-title">
                          {responseData?.invoice !== undefined &&
                            responseData?.invoice[0]?.business_name}
                        </h5>
                        <div className="payment-business-email mb-sm-0 mb-3">
                          {responseData?.invoice !== undefined &&
                            responseData?.invoice[0]?.business_address}
                        </div>
                      </div>
                      <div className="col-sm-auto col-12 text-start text-sm-end">
                        <div className="payment-business-subscription-month">
                          Invoice Period:{" "}
                          {responseData?.invoice !== undefined &&
                            responseData?.invoice[0]?.period}
                        </div>
                        {responseData?.plan?.status !== undefined && (
                          <span
                            className={`${responseData?.invoice !== undefined &&
                              responseData?.invoice[0]?.payment_status === "Paid"
                              ? "status-won"
                              : "" ||
                                responseData?.invoice[0]?.payment_status ===
                                "Unpaid"
                                ? "status-pending"
                                : "" ||
                                  responseData?.invoice[0]?.payment_status ===
                                  "Overdue"
                                  ? "status-rejected"
                                  : ""
                              }`}>
                            {responseData?.invoice !== undefined &&
                              responseData?.invoice[0]?.payment_status}
                          </span>
                        )}
                        <div className="payment-business-subscription-exipre-after">
                          Invoice Number:{" "}
                          <b>
                            #
                            {responseData?.invoice !== undefined &&
                              responseData?.invoice[0]?.invoice_number}
                          </b>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="appraisal-form-inner mb-24">
                    <div className="row align-items-center">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="col-form-label">Payment Amount</label>
                          <input
                            className="form-control"
                            type="text"
                            {...register("paid_amount")}
                            readOnly={isReceivedFullAmount}
                          />
                          {errors.paid_amount?.message != undefined && (
                            <span className="form-error">
                              {errors.paid_amount?.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="receivedAmt"
                            checked={isReceivedFullAmount}
                            onChange={() => handleReceivedFullAmount()}
                          />
                          <label className="form-check-label" htmlFor="receivedAmt">
                            Received Full Amount
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label className="col-form-label">Cheque Number</label>
                          <input
                            className="form-control"
                            type="text"
                            {...register("cheque_number")}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="col-form-label">Received Date</label>
                          <DatePicker
                            className="form-control"
                            onChange={setInvoiceDate}
                            value={invoiceDate}
                            maxDate={new Date()}
                            format="M-d-y"
                            yearPlaceholder="YYYY"
                            monthPlaceholder="MM"
                            dayPlaceholder="DD"
                            clearIcon={false}
                          />
                          {errors.invoice_date?.message != undefined && (
                            <span className="form-error">
                              {errors.invoice_date?.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <label className="col-form-label">Note</label>
                          <input
                            className="form-control"
                            type="text"
                            {...register("notes")}
                          />
                          {errors.notes?.message != undefined && (
                            <span className="form-error">
                              {errors.notes?.message}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="new-appraisal-card-submit">
                  <div className="row newcard-submitrow justify-content-center">
                    <div className="col-md-6 newcard-submitcol">
                      <button
                        type="submit"
                        className="btn btn-pink"
                        disabled={loading}>
                        {loading && (
                          <div
                            className="spinner-border spinner-border-sm "
                            role="status">
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        )}{" "}
                        Submit Payment
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentsCreate;