import React from "react";
import { formatCurrency } from "../../utils/formats";
import contestImg from "../../assets/img/contest-medal.svg";
import moment from "moment";
const ContestInfo = ({ data }) => {
  return (
    <div className="dashboard-card contest-wrap">
      <div className="row align-items-center">
        <div className="col">
          <h4 className="card-title d-flex align-items-center text-start">
            <img src={contestImg} alt="" /> Contest
          </h4>
          <span className="contest-timeline-text text-capitalize d-flex align-items-center">
            <i className="icon icon-calender"></i>
            {moment(data?.start_date).utc().format("MMMM D, YYYY")} to <br />
            {moment(data?.end_date).utc().format("MMMM D, YYYY")}
          </span>
        </div>
      </div>
      <div className="mt-2">
        <div className="contest-items">
          {data?.per_appraisal_spiff && (
            <div className="contest-item mb-2">
              <span>Per Appraisal Spiff</span>
              <h4>{formatCurrency(data?.per_appraisal_spiff)}</h4>
            </div>
          )}
          {data?.per_accepted_appraisal_spiff && (
            <div className="contest-item">
              <span>Per Accepted Appraisal Spiff</span>
              <h4>{formatCurrency(data?.per_accepted_appraisal_spiff)}</h4>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ContestInfo;
