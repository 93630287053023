import React, { useContext, useEffect, useState } from "react";
import api from "../../api";
import AuthContext from "../../context/authContext";
import ReactPaginate from "react-paginate";
import moment from "moment";
import downloadCsv from "download-csv";
import Swal from "../Swal";
import { formatCurrency } from "../../utils/formats";
import ThemeContext from "../../context/themeContext";
import { Tooltip } from "antd";

const Subscriptions = () => {
  const [responseData, setResponsedata] = useState({});
  const [loading, setLoading] = useState(false);
  const [sortField, setSortField] = useState("");
  const [sortType, setSortType] = useState("desc");
  const [pagination, setPagination] = useState({
    total_records: 0,
    total_pages: 0,
    current: 0,
  });

  const authCtx = useContext(AuthContext);
  const themeCtx = useContext(ThemeContext);

  useEffect(() => {
    fetchSubscriptions({}, 1);
  }, []);

  async function fetchSubscriptions(formData = {}, page) {
    setLoading(true);
    await api
      .post(`api/invoice?page=${page}`, formData, {
        headers: {
          Authorization: `Bearer ${authCtx.userData?.token}`,
        },
      })
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          setResponsedata(response.data.data);
          setPagination(response.data.data.paginate);
        } else {
          setResponsedata([]);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response?.status == 401) {
          authCtx.logout();
        } else {
          Swal.fire(
            "Opps!",
            "Sorry we are not able to complete your request at this time. Please try again later.",
            "error"
          );
        }
      });
  }

  function sort(field, currentField, sort, page) {
    let filterData = {};
    if (field == currentField) {
      if (sort == "desc") {
        setSortType("asc");
        filterData = {
          ...filterData,
          field_name: field,
          order_by: "asc",
        };
        fetchSubscriptions(filterData, page);
      } else {
        setSortType("desc");
        filterData = {
          ...filterData,
          field_name: field,
          order_by: "desc",
        };
        fetchSubscriptions(filterData, page);
      }
    } else {
      setSortField(field);
      setSortType("desc");
      filterData = {
        ...filterData,
        field_name: field,
        order_by: "desc",
      };
      fetchSubscriptions(filterData, page);
    }
  }

  const onPaginationFetch = (e) => {
    let filterData = {};
    if (sortField != "" && sortType != "") {
      filterData = {
        ...filterData,
        field_name: sortField,
        order_by: sortType,
      };
    }
    fetchSubscriptions(filterData, e.selected + 1);
  };

  const currentDateTime = new Date();
  const exportHandler = async () => {
    let filterData = {
      action: "export",
    };
    if (sortField != "" && sortType != "") {
      filterData = {
        ...filterData,
        field_name: sortField,
        order_by: sortType,
      };
    }
    await api
      .post("api/invoice", filterData, {
        headers: {
          Authorization: `Bearer ${authCtx.userData?.token}`,
        },
      })
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          let exportData = response.data.data.map((item) => {
            return {
              invoice_number: item.invoice_number,
              period: item.period,
              amount: item.amount,
              paid_amount: item.paid_amount,
              payment_status: item.payment_status,
              payment_date: item.payment_date != "" ? item.payment_date : "-",
            };
          });

          let exportColumns = {
            invoice_number: "Invoice Number",
            period: "Subscription Period",
            amount: "Amount",
            paid_amount: "Paid Amount",
            payment_status: "Payment Status",
            payment_date: "Payment Date",
          };

          downloadCsv(
            exportData,
            exportColumns,
            `subscriptions-${moment(currentDateTime).format("MM-DD-YYYY")}`
          );
        } else {
          Swal.fire("Try Again!", response.data.message, "info");
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response?.status == 401) {
          authCtx.logout();
        } else {
          Swal.fire(
            "Opps!",
            "Sorry we are not able to complete your request at this time. Please try again later.",
            "error"
          );
        }
      });
  };

  const sendToMailHandler = async (id) => {
    setLoading(true);
    await api
      .post(
        "api/invoice-send-mail",
        {
          invoice_id: id,
        },
        {
          headers: {
            Authorization: `Bearer ${authCtx.userData?.token}`,
          },
        }
      )
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          Swal.fire(response.data.message, "", "success");
        } else {
          Swal.fire("Try Again!", response.data.message, "info");
        }
      })
      .catch((error) => {
        setLoading(false);
        Swal.fire(
          "Opps!",
          "Sorry we are not able to complete your request at this time. Please try again later.",
          "error"
        );
      });
  };

  return (
    <>
      <div className="dashboard-count-filter dashboard-appraisals mb-24">
        <div className="row align-items-center">
          <div className="col">
            <h2 className="page-title">Subscriptions</h2>
            <p className="page-dec">
              In total there is <b>{pagination?.total_records}</b> subscriptions
            </p>
          </div>
          <div className="col-auto">
            <div className="dashboard-export">
              <button
                type="button"
                className="btn btn-pink"
                onClick={() => exportHandler()}>
                <i className="icon icon-export"></i> Export
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="dashboard-latest-appraisals">
        <div className="dashboard-card">
          <div className="dashboard-card-filter dashboard-count-filter">
            <div className="row align-items-center">
              <div className="col-sm col-12">
                <div className="subscription-monthly-price">
                  Plan: {responseData?.plan?.name}
                </div>
                <div className="subscription-current-month">
                  Current Period: {responseData?.plan?.current_period}
                  {responseData?.plan?.status !== undefined && (
                    <span
                      className={`${
                        responseData?.plan?.status === "Inactive"
                          ? "status-inspected"
                          : "status-accepted-purchase"
                      }`}>
                      {responseData?.plan?.status}
                    </span>
                  )}
                </div>
              </div>
              {responseData?.plan?.expire !== undefined &&
                responseData?.plan?.expire <= 7 && (
                  <div className="col-sm-auto col-12">
                    <div className="subscription-ending-after">
                      <i className="icon icon-expiry"></i>{" "}
                      {responseData?.plan?.expire !== -1 ? (
                        <>
                          Expires in <b>{responseData?.plan?.expire} days</b>
                        </>
                      ) : (
                        "Expires Today"
                      )}
                    </div>
                  </div>
                )}
            </div>
          </div>
          <div className="dashboard-latest-apraisals table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>
                    Subscription Period
                    <span
                      className={`table-sort ${
                        sortField == "start_date" ? sortType : ""
                      }`}
                      onClick={() =>
                        sort(
                          "start_date",
                          sortField,
                          sortType,
                          pagination.current
                        )
                      }></span>
                  </th>
                  <th>
                    Amount{" "}
                    <span
                      className={`table-sort ${
                        sortField == "amount" ? sortType : ""
                      }`}
                      onClick={() =>
                        sort("amount", sortField, sortType, pagination.current)
                      }></span>
                  </th>
                  <th>
                    Paid Amount{" "}
                    <span
                      className={`table-sort ${
                        sortField == "paid_amount" ? sortType : ""
                      }`}
                      onClick={() =>
                        sort(
                          "paid_amount",
                          sortField,
                          sortType,
                          pagination.current
                        )
                      }></span>
                  </th>
                  <th>
                    Status{" "}
                    <span
                      className={`table-sort ${
                        sortField == "payment_status" ? sortType : ""
                      }`}
                      onClick={() =>
                        sort(
                          "payment_status",
                          sortField,
                          sortType,
                          pagination.current
                        )
                      }></span>
                  </th>
                  <th>
                    Payment Date{" "}
                    <span
                      className={`table-sort ${
                        sortField == "payment_date" ? sortType : ""
                      }`}
                      onClick={() =>
                        sort(
                          "payment_date",
                          sortField,
                          sortType,
                          pagination.current
                        )
                      }></span>
                  </th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {responseData?.invoice && responseData?.invoice.length != 0 ? (
                  responseData?.invoice.map((data) => (
                    <tr key={data?.id}>
                      <td width="3%">{data?.id}</td>
                      <td>{data?.period}</td>
                      <td>{formatCurrency(data?.amount)}</td>
                      <td>{formatCurrency(data?.paid_amount)}</td>
                      <td>
                        <span
                          className={`${
                            data?.payment_status !== undefined &&
                            data?.payment_status === "Paid"
                              ? "status-accepted-purchase"
                              : "" || data?.payment_status === "Unpaid"
                              ? "status-inspected"
                              : "" || data?.payment_status === "Overdue"
                              ? "status-no-deal"
                              : ""
                          }`}>
                          {data?.payment_status}
                        </span>
                      </td>
                      <td>
                        {data?.payment_date != ""
                          ? moment(data?.payment_date).format("MMMM D, YYYY")
                          : "-"}
                      </td>
                      <td>
                        <Tooltip placement="top" title="Send to mail">
                          <button
                            type="button"
                            className="btn-icon"
                            disabled={loading}
                            onClick={() => sendToMailHandler(data?.id)}>
                            <i className="icon icon-mail"></i>
                          </button>
                        </Tooltip>
                        <Tooltip placement="top" title="Download">
                          <a
                            download
                            target="_blank"
                            href={data?.invoice_name}
                            className="btn-icon">
                            <i className="icon icon-export"></i>
                          </a>
                        </Tooltip>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr className="text-start text-sm-center">
                    <td colSpan={8}>
                      {loading ? "Loading..." : "No Subscriptions Found."}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {pagination?.total_pages != undefined && pagination?.total_pages != 1 && (
        <nav className="navigation pagination">
          <ReactPaginate
            breakLabel="..."
            onPageChange={onPaginationFetch}
            pageRangeDisplayed={2}
            pageCount={pagination?.total_pages}
            renderOnZeroPageCount={null}
            containerClassName="nav-links"
            activeLinkClassName="current"
            pageLinkClassName="page-numbers"
            previousLinkClassName="prev page-numbers"
            previousLabel={<i className="icon icon-arrow-left"></i>}
            nextLinkClassName="next page-numbers"
            nextLabel={<i className="icon icon-arrow-right"></i>}
          />
        </nav>
      )}
      <span className="billing-support-text">
        <button type="button" onClick={() => themeCtx.contactPopupOpen()}>
          Billing Support
        </button>
        (Need Help? Have questions about your billing?)
      </span>
    </>
  );
};

export default Subscriptions;
