import React from "react";
import { Link } from "react-router-dom";
import routes from "../../constants/routesConstants";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-12">
            <div className="footer-con">
              <span>
                ©{new Date().getFullYear()} Vehicle Appraisal System | All
                rights Reserved
              </span>
              <ul>
                <li>
                  <Link to={routes.TermsCondition}>Terms of Service</Link>
                </li>
                <li>
                  <Link to={routes.PrivacyPolicy}>Privacy Policy</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
