import { BrowserRouter, Routes, Route } from "react-router-dom";
import Appraisals from "./components/Appraisals";
import AppraisalCreate from "./components/Appraisals/create";
import AppraisalView from "./components/Appraisals/view";
import Business from "./components/Business";
import BusinessView from "./components/Business/view";
import PrivacyPolicy from "./components/Cms/PrivacyPolicy";
import TermsCondition from "./components/Cms/TermsCondition";
import Dashboard from "./components/Dashboard";
import Employees from "./components/Employees";
import EmployeesCreate from "./components/Employees/create";
import EmployeesCreatePassword from "./components/Employees/createPassword";
import EmployeesView from "./components/Employees/view";
import Faqs from "./components/Faqs";
import FaqsCreate from "./components/Faqs/create";
import FaqsEdit from "./components/Faqs/edit";
import Home from "./components/Home";
import Inquiry from "./components/Inquiry";
import Login from "./components/Login";
import ForgotPassword from "./components/Login/ForgotPassword";
import ResetPassword from "./components/Login/ResetPassword";
import NotFound from "./components/NotFound";
import Notifications from "./components/Notifications";
import PaymentSuccess from "./components/PaymentSuccess";
import Profile from "./components/Profile";
import Register from "./components/Register";
import Reports from "./components/Reports";
import Roles from "./components/Roles";
import RolesCreate from "./components/Roles/create";
import RolesEdit from "./components/Roles/edit";
import Wrapper from "./components/Wrapper";
import WrapperTwo from "./components/Wrapper/WrapperTwo";
import routes from "./constants/routesConstants";
import BusinessCreate from "./components/Business/create";
import BusinessCreatePassword from "./components/Business/createPassword";
import ScheduleDemo from "./components/ScheduleDemo";
import ScheduleDemoList from "./components/ScheduleDemo/list";
import Vehicles from "./components/Vehicles";
import VehiclesEdit from "./components/Vehicles/edit";
import VehiclesCreate from "./components/Vehicles/create";
import Contest from "./components/Contest";
import ContestCreate from "./components/Contest/create";
import SendAppraisalForm from "./components/Appraisals/sendAppraisalForm";
import NotificationsPermissions from "./components/NotificationsPermissions";
import PaymentsCreate from "./components/Payments/create";
import Payments from "./components/Payments";
import Subscriptions from "./components/Subscriptions";
import SubscriptionDashboard from "./components/SubscribtionDashboard";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={routes.Login} element={<Login />} exact />
        <Route path={routes.ScheduleDemo} element={<ScheduleDemo />} exact />
        <Route
          path={routes.ForgotPassword}
          element={<ForgotPassword />}
          exact
        />
        <Route path={routes.ResetPassword} element={<ResetPassword />} exact />
        <Route path={routes.Register} element={<Register />} exact />
        <Route
          path={routes.EmployeesCreatepassword}
          element={<EmployeesCreatePassword />}
          exact
        />
        <Route
          path={routes.BusinessCreatePassword}
          element={<BusinessCreatePassword />}
          exact
        />
        <Route
          path={routes.PaymentSuccess}
          element={<PaymentSuccess />}
          exact
        />
        <Route element={<Wrapper />}>
          <Route path={routes.Dashboard} element={<Dashboard />} exact />
          <Route path={routes.Appraisals} element={<Appraisals />} exact />
          <Route path={routes.Reports} element={<Reports />} exact />
          <Route path={routes.Employees} element={<Employees />} exact />
          <Route path={routes.Roles} element={<Roles />} exact />
          <Route path={routes.RolesCreate} element={<RolesCreate />} exact />
          <Route path={routes.RolesEdit} element={<RolesEdit />} exact />
          <Route
            path={routes.EmployeesCreate}
            element={<EmployeesCreate />}
            exact
          />
          <Route
            path={routes.EmployeesCreateWithBusinessId}
            element={<EmployeesCreate />}
            exact
          />
          <Route
            path={routes.EmployeesView}
            element={<EmployeesView />}
            exact
          />
          <Route path={routes.Profile} element={<Profile />} exact />
          <Route
            path={routes.AppraisalCreate}
            element={<AppraisalCreate />}
            exact
          />
          <Route
            path={routes.AppraisalView}
            element={<AppraisalView />}
            exact
          />
          <Route
            path={routes.Notifications}
            element={<Notifications />}
            exact
          />
          <Route path={routes.Business} element={<Business />} exact />
          <Route path={routes.BusinessView} element={<BusinessView />} exact />
          <Route
            path={routes.BusinessCreate}
            element={<BusinessCreate />}
            exact
          />
          <Route
            path={routes.Subscriptions}
            element={<Subscriptions />}
            exact
          />
          <Route
            path={routes.SubscriptionDashboard}
            element={<SubscriptionDashboard />}
            exact
          />
          <Route path={routes.Payments} element={<Payments />} exact />
          <Route
            path={routes.PaymentsCreate}
            element={<PaymentsCreate />}
            exact
          />
          <Route path={routes.Inquiry} element={<Inquiry />} exact />
          <Route path={routes.Faqs} element={<Faqs />} exact />
          <Route path={routes.FaqsCreate} element={<FaqsCreate />} exact />
          <Route path={routes.FaqsEdit} element={<FaqsEdit />} exact />
          <Route path={routes.Vehicles} element={<Vehicles />} exact />
          <Route
            path={routes.VehiclesCreate}
            element={<VehiclesCreate />}
            exact
          />
          <Route path={routes.VehiclesEdit} element={<VehiclesEdit />} exact />
          <Route
            path={routes.ScheduleDemoList}
            element={<ScheduleDemoList />}
            exact
          />
          <Route path={routes.Contest} element={<Contest />} exact />
          <Route
            path={routes.ContestCreate}
            element={<ContestCreate />}
            exact
          />
          <Route path={routes.ContestEdit} element={<ContestCreate />} exact />
          <Route path={routes.NotificationsPermissions} element={<NotificationsPermissions />} exact />
        </Route>
        <Route element={<WrapperTwo />}>
          <Route path={routes.Home} element={<Home />} exact />
          <Route
            path={routes.PrivacyPolicy}
            element={<PrivacyPolicy />}
            exact
          />
          <Route
            path={routes.TermsCondition}
            element={<TermsCondition />}
            exact
          />
        </Route>
        <Route path="*" element={<NotFound />} replace />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
