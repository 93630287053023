import React, { useContext, useEffect, useState } from "react";
import api from "../../api";
import AuthContext from "../../context/authContext";
import ReactPaginate from "react-paginate";
import Swal from "../Swal";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import routes from "../../constants/routesConstants";
import {
  ROLE_ADD,
  ROLE_DELETE,
  ROLE_EDIT,
  ROLE_LIST,
} from "../../constants/permissionConstants";
import usePermission from "../../hook/usePermission";
import BusinessContext from "../../context/businessContext";

const Roles = () => {
  const navigate = useNavigate();
  const [responseData, setResponsedata] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sortField, setSortField] = useState("");
  const [sortType, setSortType] = useState("desc");
  const [pagination, setPagination] = useState({
    total_records: 0,
    total_pages: 0,
    current: 0,
  });

  const authCtx = useContext(AuthContext);
  const businessCtx = useContext(BusinessContext);

  const isRoleAddAllow = usePermission(ROLE_ADD);
  const isRoleEditAllow = usePermission(ROLE_EDIT);
  const isRoleDeleteAllow = usePermission(ROLE_DELETE);
  const isRoleListAllow = usePermission(ROLE_LIST);

  useEffect(() => {
    if (!isRoleListAllow) {
      navigate(routes.Dashboard);
    }
  }, []);

  useEffect(() => {
    let filterData = {};
    fetchRoles(filterData, 1);
  }, [businessCtx.selectedBusinessId]);

  async function fetchRoles(formData = {}, page) {
    setLoading(true);
    if (
      authCtx.userData?.user_type == 3 &&
      businessCtx.selectedBusinessId != ""
    ) {
      formData.business_id = businessCtx.selectedBusinessId;
    }
    await api
      .post(`api/role?page=${page}`, formData, {
        headers: {
          Authorization: `Bearer ${authCtx.userData?.token}`,
        },
      })
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          setResponsedata(response.data.data);
          setPagination(response.data.data.paginate);
        } else {
          setResponsedata([]);
          setPagination({
            total_records: 0,
            total_pages: 0,
            current: 0,
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response?.status == 401) {
          authCtx.logout();
        } else {
          Swal.fire(
            "Opps!",
            "Sorry we are not able to complete your request at this time. Please try again later.",
            "error"
          );
        }
      });
  }

  function sort(field, currentField, sort, page) {
    let filterData = {};
    if (field == currentField) {
      if (sort == "desc") {
        setSortType("asc");
        filterData = {
          ...filterData,
          field_name: field,
          order_by: "asc",
        };
        fetchRoles(filterData, page);
      } else {
        setSortType("desc");
        filterData = {
          ...filterData,
          field_name: field,
          order_by: "desc",
        };
        fetchRoles(filterData, page);
      }
    } else {
      setSortField(field);
      setSortType("desc");
      filterData = {
        ...filterData,
        field_name: field,
        order_by: "desc",
      };
      fetchRoles(filterData, page);
    }
  }

  const onPaginationFetch = (e) => {
    let filterData = {};
    fetchRoles(filterData, e.selected + 1);
  };

  const deleteHandler = (id) => {
    if (id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          setLoading(true);
          api
            .get(`api/role/delete-role/${id}`, {
              headers: {
                Authorization: `Bearer ${authCtx.userData?.token}`,
              },
            })
            .then((response) => {
              setLoading(false);
              if (response.data.status) {
                fetchRoles({}, 1);
                Swal.fire(response.data.message, "", "success");
              } else {
                Swal.fire("Try Again!", response.data.message, "info");
              }
            })
            .catch((error) => {
              setLoading(false);
              Swal.fire(
                "Opps!",
                "Sorry we are not able to complete your request at this time. Please try again later.",
                "error"
              );
            });
        }
      });
    }
    return false;
  };

  return (
    <>
      <div className="dashboard-count-filter dashboard-appraisals mb-24">
        <div className="row align-items-center">
          <div className="col">
            <h2 className="page-title">Roles</h2>
            <p className="page-dec">
              In total there is <b>{pagination?.total_records}</b> roles
            </p>
          </div>
          {isRoleAddAllow && (
            <div className="col-auto">
              <div className="dashboard-export">
                <Link to={routes.RolesCreate} className="btn btn-pink">
                  <i className="icon icon-add"></i> Add Role
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="dashboard-latest-appraisals">
        <div className="dashboard-card">
          <div className="dashboard-latest-apraisals table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>
                    Business Name{" "}
                    <span
                      className={`table-sort ${
                        sortField == "business_name" ? sortType : ""
                      }`}
                      onClick={() =>
                        sort(
                          "business_name",
                          sortField,
                          sortType,
                          pagination.current
                        )
                      }></span>
                  </th>
                  <th>
                    Name{" "}
                    <span
                      className={`table-sort ${
                        sortField == "role_name" ? sortType : ""
                      }`}
                      onClick={() =>
                        sort(
                          "role_name",
                          sortField,
                          sortType,
                          pagination.current
                        )
                      }></span>
                  </th>
                  <th>
                    Status{" "}
                    <span
                      className={`table-sort ${
                        sortField == "status" ? sortType : ""
                      }`}
                      onClick={() =>
                        sort("status", sortField, sortType, pagination.current)
                      }></span>
                  </th>
                  <th>
                    Date{" "}
                    <span
                      className={`table-sort ${
                        sortField == "created_at" ? sortType : ""
                      }`}
                      onClick={() =>
                        sort(
                          "created_at",
                          sortField,
                          sortType,
                          pagination.current
                        )
                      }></span>
                  </th>
                  {(isRoleEditAllow || isRoleDeleteAllow) && <th>Action</th>}
                </tr>
              </thead>
              <tbody>
                {responseData?.roles && responseData?.roles.length != 0 ? (
                  responseData?.roles.map((data) => (
                    <tr key={data?.id}>
                      <td>{data?.business_name}</td>
                      <td>{data?.role_name}</td>
                      <td>
                        {data?.status == 0 && (
                          <span className="status-rejected">Inactive</span>
                        )}
                        {data?.status == 1 && (
                          <span className="status-won">Active</span>
                        )}
                      </td>
                      <td>{moment(data?.created_at).format("MM-DD-YYYY")}</td>
                      {(isRoleEditAllow || isRoleDeleteAllow) && (
                        <td>
                          {isRoleEditAllow && data?.user_type === -1 && (
                            <button
                              type="button"
                              className="btn-icon"
                              onClick={() => deleteHandler(data?.id)}>
                              <i className="icon icon-delete"></i>
                            </button>
                          )}
                          {isRoleDeleteAllow && (
                            <Link
                              to={`/roles/edit/${data?.id}`}
                              className="btn-icon">
                              <i className="icon icon-edit"></i>
                            </Link>
                          )}
                        </td>
                      )}
                    </tr>
                  ))
                ) : (
                  <tr className="text-start text-sm-center">
                    <td colSpan={5}>
                      {loading ? "Loading..." : "No Roles Found."}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {pagination?.total_pages != undefined && pagination?.total_pages != 1 && (
        <nav className="navigation pagination">
          <ReactPaginate
            breakLabel="..."
            onPageChange={onPaginationFetch}
            pageRangeDisplayed={2}
            pageCount={pagination?.total_pages}
            renderOnZeroPageCount={null}
            containerClassName="nav-links"
            activeLinkClassName="current"
            pageLinkClassName="page-numbers"
            previousLinkClassName="prev page-numbers"
            previousLabel={<i className="icon icon-arrow-left"></i>}
            nextLinkClassName="next page-numbers"
            nextLabel={<i className="icon icon-arrow-right"></i>}
          />
        </nav>
      )}
    </>
  );
};

export default Roles;
